import Typography from '@material-ui/core/Typography';
import getAuthorizedAxiosInstance from 'utils/getAuthorizedAxiosInstance';
import { API_BASE_ADDRESS } from 'pages/audienceManager/variables';
import { Box } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import * as Styled from './index.styles';

const CreatedAndUpdatedDate: React.FC = () => {
  const { questionid }: { questionid: string } = useParams();
  const [question, setQuestion] = useState(null);

  useEffect(() => {
    const getQuestion = async () => {
      try {
        const axiosInstance = await getAuthorizedAxiosInstance();
        if (questionid && axiosInstance) {
          const res = await axiosInstance.get(
            `${API_BASE_ADDRESS}/questions/${questionid}`
          );
          setQuestion(res.data);
        } else {
          throw new Error('Get question failed.');
        }
      } catch (err) {
        console.log(err);
      }
    };
    getQuestion();
  }, []);

  return (
    <Box display="flex">
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        paddingRight={2}
      >
        {question &&
          question.updatedat !== undefined &&
          question.updatedat !== 0 && (
            <>
              <Styled.Title>Last Modified</Styled.Title>
              <Typography component="p" variant="body2">
                {new Date(Number(question.updatedat)).toLocaleDateString(
                  'en-US'
                )}
                ・
                {new Date(Number(question.updatedat)).toLocaleTimeString(
                  'en-US'
                )}
              </Typography>
            </>
          )}
      </Box>
      <Box
        style={{
          paddingRight: 32,
          borderLeft: '2px solid rgb(232 232 232)',
          paddingLeft: 16,
        }}
      >
        {question &&
          question.createdat !== undefined &&
          question.createdat !== 0 && (
            <>
              <Styled.Title>Created</Styled.Title>
              {question && question.createdby !== undefined && (
                <Typography component="p" variant="body2">
                  {question.createdby}
                </Typography>
              )}
              <Typography component="p" variant="body2">
                {new Date(Number(question.createdat)).toLocaleDateString(
                  'en-US'
                )}
                ・
                {new Date(Number(question.createdat)).toLocaleTimeString(
                  'en-US'
                )}
              </Typography>
            </>
          )}
      </Box>
    </Box>
  );
};

export default CreatedAndUpdatedDate;
