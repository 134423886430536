import React, { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from 'redux/hooks';

import _ from 'lodash';
import { Button, IconButton } from '@klover/attain-design-system';
// Material UI Components
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import CloseIcon from '@material-ui/icons/Close';
import CreateIcon from '@material-ui/icons/Create';
import InputAdornment from '@material-ui/core/InputAdornment';
import SaveIcon from '@material-ui/icons/Save';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import {
  Offer,
  addNotification,
  createOffer,
  saveOffer,
  toggleDuplicateOfferDialog,
  updateCurrentOffer,
} from '../slice';
import {
  Theme,
  ThemeProvider,
  createStyles,
  createTheme,
  makeStyles,
} from '@material-ui/core/styles';

import BootstrapTooltip from 'components/bootstrapTooltip';
import DraftStateSvg from '../draftStateIndicator';
import SaveOfferDisabledButtonMessage from '../saveDisabledButtonMessage';
import setSaveOfferButton from '../utils/setSaveOfferButton';
import tokens from 'tokens';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      margin: `${theme.spacing(0, 1)} !important`,
    },
    draftStateIcon: {
      marginTop: 5,
      marginLeft: 10,
    },
    editButton: {
      marginLeft: theme.spacing(4),
    },
  })
);

const inputs = createTheme({
  overrides: {
    MuiFormControl: {
      root: {
        maxHeight: 48,
      },
    },
    MuiFormLabel: {
      root: {
        maxHeight: 48,
      },
    },
    MuiInputLabel: {
      root: {
        marginTop: -5,

        '&$focused': {
          marginTop: 0,
        },
      },
    },
    MuiInputBase: {
      root: {
        maxHeight: 48,
      },
      input: {
        borderRadius: 10,
        alignSelf: 'center',
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 10,
      },
      input: {
        padding: '12px 14px',
      },
    },
    MuiButton: {
      root: {
        borderRadius: 10,
      },
      outlinedPrimary: {
        color: tokens.colorButtonPrimary,
      },
      containedPrimary: {
        backgroundColor: tokens.colorButtonPrimary,
        '&:hover': {
          backgroundColor: tokens.colorButtonPrimary,
        },
      },
    },
    MuiButtonBase: {
      root: {
        borderRadius: 10,
      },
    },
  },
});

interface OfferNameProps {
  isOfferLoading: boolean;
}

const OfferName: React.FC<OfferNameProps> = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const [editMode, setEditMode] = useState(false);

  const currentGroup = useAppSelector(
    (state) => state.offerWallReducer.currentGroup
  );
  const currentOffer = useAppSelector(
    (state) => state.offerWallReducer.currentOffer
  );
  const originalOffer = useAppSelector(
    (state) => state.offerWallReducer.originalOffer
  );

  const offerLoading = useAppSelector(
    (state) => state.offerWallReducer.ui.offerLoading
  );

  const isOfferLoading = offerLoading.state === 'loading';

  const handleCancel = () => {
    if (originalOffer) {
      const updatedOffer = {
        ...currentOffer,
        label: originalOffer.label,
      };
      dispatch(updateCurrentOffer(updatedOffer));
      setEditMode(false);
    } else {
      const updatedOffer = {
        ...currentOffer,
        label: '',
      };
      dispatch(updateCurrentOffer(updatedOffer));
    }
  };

  useEffect(() => {
    if (currentOffer && !currentOffer.label) {
      setEditMode(true);
    } else {
      setEditMode(false);
    }
  }, []);

  const handleEditName = () => {
    setEditMode(true);
  };

  const handleNameChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    const updatedOffer = {
      ...currentOffer,
      label: e.target.value,
    };
    dispatch(updateCurrentOffer(updatedOffer));
  };

  const isTheSame =
    currentOffer && originalOffer
      ? _.isEqual(
          _.omit(currentOffer, [
            'price',
            'backgroundimgurl',
            'categorytype',
            'zipCount',
            'zip_upload_status',
          ]),
          _.omit(originalOffer, [
            'price',
            'backgroundimgurl',
            'categorytype',
            'zipCount',
            'zip_upload_status',
          ])
        )
      : false;

  const handleSave = () => {
    const updatedOffer: Offer = {
      ...currentOffer,
    };
    if (originalOffer) {
      dispatch(saveOffer(updatedOffer));
      setEditMode(false);
    } else {
      if (currentGroup) {
        updatedOffer.offergroupid = currentGroup.id;
        dispatch(createOffer({ updatedOffer, currentGroup }));
        setEditMode(false);
      } else {
        dispatch(
          addNotification({
            state: 'error',
            message: `No current group.`,
          })
        );
      }
    }
  };

  const duplicateHandler = () =>
    dispatch(toggleDuplicateOfferDialog({ open: true }));

  const notDuplicable = isOfferLoading || !isTheSame;

  const saveOfferButtonSettings = setSaveOfferButton(currentOffer);
  const { isDisabled } = saveOfferButtonSettings;
  let { SaveTitle = <SaveOfferDisabledButtonMessage /> } =
    saveOfferButtonSettings;
  SaveTitle =
    SaveTitle === null ? <SaveOfferDisabledButtonMessage /> : SaveTitle;

  return (
    <>
      <ThemeProvider theme={inputs}>
        <Box display="flex" alignItems="center">
          {editMode ? (
            <>
              <TextField
                id="offer-label"
                label="Offer Label"
                onChange={handleNameChange}
                value={currentOffer?.label}
                variant="outlined"
                style={{ marginRight: 8 }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        color="default"
                        onClick={handleSave}
                        size="small"
                        disabled={isDisabled}
                      >
                        <OfferNameSaveIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <IconButton onClick={handleCancel}>
                <CloseIcon />
              </IconButton>
            </>
          ) : (
            <>
              <Typography component="h2" variant="h6">
                {currentOffer?.label}
              </Typography>

              <IconButton
                className={classes.editButton}
                color="default"
                onClick={handleEditName}
              >
                <CreateIcon />
              </IconButton>
            </>
          )}
        </Box>
        <BootstrapTooltip
          title={
            notDuplicable
              ? 'Please save to duplicate'
              : 'Zip code targeting will not be duplicated'
          }
        >
          <span>
            <Button
              className={classes.button}
              disabled={notDuplicable}
              disableElevation
              onClick={duplicateHandler}
              variant="outlined"
              color="primary"
            >
              Duplicate
            </Button>
          </span>
        </BootstrapTooltip>
        <BootstrapTooltip title={SaveTitle}>
          <span>
            <Button
              className={classes.button}
              disableElevation
              variant="contained"
              color="primary"
              onClick={handleSave}
              disabled={isDisabled}
            >
              Save
            </Button>
          </span>
        </BootstrapTooltip>
        <Box className={classes.draftStateIcon}>
          {!isOfferLoading && !isTheSame ? <DraftStateSvg /> : null}
        </Box>
      </ThemeProvider>
    </>
  );
};

const OfferNameSaveIcon = () => {
  return (
    <>{false ? <CircularProgress color="primary" size={20} /> : <SaveIcon />}</>
  );
};

export default OfferName;
