import ABTest from '../../assets/images/ab-test.svg';

import Field from 'components/Field';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import _debounce from 'lodash/debounce';
import { API_BASE_ADDRESS } from 'pages/audienceManager/variables';
import { Button, IconButton } from '@klover/attain-design-system';
import { Formik } from 'formik';
import { Link, useHistory, useLocation } from 'react-router-dom';
import {
  Offer,
  OfferGroup,
  ToggleGroupNameDialogAction,
  fetchGroup,
  fetchGroups,
  fetchOffer,
  loadOriginalOffer,
  searchOffers,
  setGroupDeletingState,
  setGroupSavingState,
  toggleGroupNameDialog,
  updateCurrentGroup,
  updateCurrentOffer,
} from './slice';
import { SelectAutoCompleteGroup } from '../customAudiences/index.styles';
import { TablePagination, TextField } from '@mui/material';
import { UploadStates } from 'constants/index';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import * as Styled from './index.styles';

// Material UI Components
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { LocalOfferRounded } from '@material-ui/icons';
import { RowingOutlined } from '@mui/icons-material';
import CapFullFilledIcon from '../../assets/images/trophy.png';
import CapInProgressIcon from '@mui/icons-material/QueryStats';

import Typography from '@material-ui/core/Typography';
import {
  Theme,
  ThemeProvider,
  createStyles,
  createTheme,
  makeStyles,
} from '@material-ui/core/styles';

// Page Components
import BootstrapTooltip from 'components/bootstrapTooltip';
import GroupDeleteDialog from './groupDeleteDialog';
import GroupName from './groupName';
import GroupNameDialog from './groupNameDialog';
import Header from 'components/Header';
import Option from 'components/Option';
import Table from 'components/Table';
import _ from 'lodash';
import allOptions from './offerOptions';
import axios from 'axios';
import tokens from 'tokens';
import { OfferWallContext } from 'context';
import { TimeRangeofConversionLabel } from './offerOptions/conversionCap';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    offerActive: {
      backgroundColor: tokens.colorMessage,
      color: tokens.colorDefault,
      float: 'left',
      height: 24,
      width: 24,
    },
    offerInactive: {
      backgroundColor: 'transparent',
      float: 'left',
      height: 24,
      width: 24,
    },
    offerIcon: {
      height: 18,
      width: 18,
    },
    offerNameButton: {
      flexWrap: 'wrap',
      marginLeft: 8,
    },
    placementLabel: {
      color: 'grey',
    },
    createGroup: {
      backgroundColor: tokens.colorButtonLight,
      bottom: 0,
      height: 68,
      padding: 16,
      position: 'fixed',
      width: 248,
    },
    divider: {
      height: 2,
    },
    groupList: {
      marginBottom: 'auto',
      maxHeight: '500px',
      minHeight: '400px',
      overflow: 'scroll',
    },
    header: {
      display: 'flex',
      height: '80px',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      width: '100%',
    },
    noOffers: {
      minHeight: '100%',
      display: 'flex',
      flexGrow: 1,
      alignItems: 'center',
      justifyContent: 'center',
      height: '80vh',
    },
    searchOffer: {
      margin: 16,
    },
    searchGroup: {
      margin: 8,
      marginTop: 16,
    },
    verticalDivider: {
      borderRight: '2px solid rgb(232 232 232)',
    },
    conversionValue: {
      display: 'flex',
      textAlign: 'end',
      marginLeft: '10px',
      alignItems: 'center',
    },
  })
);

const inputs = createTheme({
  overrides: {
    MuiFormControl: {
      root: {
        maxHeight: 48,
      },
    },
    MuiFormLabel: {
      root: {
        maxHeight: 48,
      },
    },
    MuiInputLabel: {
      root: {
        marginTop: -5,
        '&$focused': {
          marginTop: 0,
        },
      },
    },
    MuiInputBase: {
      root: {
        maxHeight: 48,
      },
      input: {
        borderRadius: 10,
        alignSelf: 'center',
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 10,
      },
      input: {
        padding: '12px 14px',
      },
    },
    MuiButton: {
      root: {
        borderRadius: 10,
      },
      outlinedPrimary: {
        color: tokens.colorButtonPrimary,
      },
      containedPrimary: {
        backgroundColor: tokens.colorButtonPrimary,
        '&:hover': {
          backgroundColor: tokens.colorButtonPrimary,
        },
      },
    },
    MuiButtonBase: {
      root: {
        borderRadius: 10,
      },
    },
  },
});

const OfferWallManager = () => {
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const { setShowCreativeRotation } = React.useContext(OfferWallContext);
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const [search, setSearch] = useState('');
  const [allGroups, setAllGroups] = React.useState(false);
  const [groupId, setGroupId] = React.useState(
    searchParams.get('groupId') || 'all'
  );
  const [oprations, setOprations] = useState({
    page: 0,
    rowsPerPage: 25,
    sortBy: 'updatedat',
    sortOrder: 'DESC',
    isActive: true,
  });

  const offerWallReducer = useAppSelector((state) => {
    return state.offerWallReducer;
  });

  const openGroupDeleteDialog = useAppSelector(
    (state) => state.offerWallReducer.ui.openGroupDeleteDialog
  );

  const openGroupNameDialog = useAppSelector(
    (state) => state.offerWallReducer.ui.openGroupNameDialog
  );

  const { currentGroup } = offerWallReducer;

  useEffect(() => {
    dispatch(fetchGroups());
  }, []);

  useEffect(() => {
    dispatch(searchOffers({ ...oprations, search, groupId }));
  }, [oprations, groupId]);

  useEffect(() => {
    if (openGroupNameDialog) return;
    const searchParams = new URLSearchParams(window.location.search);
    const id = searchParams.get('groupId');
    const currGroup = offerWallReducer.entities.groups.byId[id];
    if (currGroup) {
      dispatch(updateCurrentGroup(currGroup));
      setGroupId(currGroup.id);
    } else {
      setGroupId('all');
      setAllGroups(true);
    }
  }, [window.location.search, openGroupNameDialog]);

  useEffect(() => {
    if (groupId && groupId != 'all') {
      const currentGroup = offerWallReducer.entities.groups.byId[groupId];
      if (currentGroup) {
        setGroupId(currentGroup.id);
        setAllGroups(false);
      }
    } else {
      setAllGroups(true);
      dispatch(updateCurrentGroup(undefined));
    }

    updateURL(groupId);
  }, [groupId]);

  const offers = useAppSelector((state) => {
    return state.offerWallReducer.entities.offers.allIds.map((id) => {
      return state.offerWallReducer.entities.offers.byId[id];
    });
  });

  const groups = useAppSelector((state) => {
    return state.offerWallReducer.entities.groups.allIds
      .map((id) => state.offerWallReducer.entities.groups.byId[id])
      .filter((group) => {
        if (name !== '') {
          return group.name.toLowerCase().includes(name);
        } else {
          return true;
        }
      });
  });

  const groupLoading = useAppSelector(
    (state) => state.offerWallReducer.ui.groupLoading
  );

  const groupsLoading = useAppSelector(
    (state) => state.offerWallReducer.ui.groupsLoading
  );

  const addOfferHandler = () => {
    const newOffer = {
      label: '',
      offername: '',
      successurl: [null],
      onofferseenurltofire: [null],
      actionforpoints: 'ACTION_CONV',
      placementid: 1,
      devices: 'ALL_DEVICES',
      zip_upload_status: UploadStates.DONE,
      zipCount: 0,
    };
    dispatch(updateCurrentOffer(newOffer));
    dispatch(loadOriginalOffer(null));
  };

  const handleCreateGroup = () => {
    const actionProps: ToggleGroupNameDialogAction = {
      open: true,
    };
    dispatch(toggleGroupNameDialog(actionProps));
  };

  const updateURL = (groupId) => {
    window.history.replaceState(
      null,
      '',
      `${location.pathname}?groupId=${groupId}`
    );
  };

  const handleShowGroup = (id: number) => {
    setGroupId(id);
    setAllGroups(id ? false : true);
    setOprations({
      ...oprations,
      page: 0,
    });
  };

  const offerGroupsOptions = useMemo(() => {
    if (groups) {
      const data = [
        { label: 'All Offers', id: 'all' },
        ...groups
          ?.sort((a, b) => b.updated_at - a.updated_at)
          ?.map((a) => {
            return { label: a.name, id: `${a.id}` };
          }),
      ];
      return data;
    }
    return [];
  }, [groups]);

  const isOffersLoading = groupLoading.state === 'loading';
  const isGroupsLoading = groupsLoading.state === 'loading';

  const handleChangePage = (event, newPage) => {
    setOprations({
      ...oprations,
      page: newPage,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    const newPageSize = parseInt(event.target.value, 10);
    setOprations({
      ...oprations,
      page: 0,
      rowsPerPage: newPageSize,
    });
  };

  const handleOnSortChange = (sortInfo) => {
    let sortOrder = 'DESC';
    if (oprations.sortBy === sortInfo.column) {
      sortOrder = oprations.sortOrder == 'ASC' ? 'DESC' : 'ASC';
    }

    setOprations({
      ...oprations,
      sortBy: sortInfo.column,
      sortOrder: sortOrder,
      page: 0,
    });
  };

  const handleOnStatusFilterChange = (e: any) => {
    if (e.target.value !== 'all') {
      setOprations({
        ...oprations,
        page: 0,
        isActive: e.target.value === 'active' ? true : false,
      });
    } else {
      const updatedOperations = oprations;
      delete updatedOperations.isActive;

      setOprations({
        ...updatedOperations,
        page: 0,
      });
    }
  };

  const debounceFn = useCallback(
    _debounce((value) => {
      dispatch(searchOffers({ ...oprations, page: 0, search: value, groupId }));
      setOprations({
        ...oprations,
        page: 0,
      });
    }, 1000)
  );

  return (
    <>
      <Box className={classes.root}>
        <Header
          title="Offer Wall Manager"
          centerBorder={true}
          center={!allGroups ? currentGroup && <GroupName /> : null}
          action={
            <Styled.ButtonSpacing>
              {currentGroup && !allGroups ? (
                <Button
                  variant="contained"
                  onClick={() => {
                    history.push(`/main/offer-wall-manager/editor`);
                    addOfferHandler();
                  }}
                >
                  Create Offer
                </Button>
              ) : null}
              <Button
                variant="contained"
                onClick={() => setShowCreativeRotation(true)}
              >
                Create Rotation
              </Button>
            </Styled.ButtonSpacing>
          }
        />

        <Box height="100%" padding={2}>
          <Styled.SearchArea>
            {isGroupsLoading ? (
              <CircularProgress color="primary" size={20} />
            ) : (
              <>
                <SelectAutoCompleteGroup
                  disablePortal
                  id="select-offer-group"
                  options={offerGroupsOptions}
                  value={
                    offerGroupsOptions.find((offer) => offer.id == groupId)
                      ?.label || ''
                  }
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Select Offer Group" />
                  )}
                  onChange={(e, newValue) => {
                    if (newValue?.id) {
                      handleShowGroup(newValue.id);
                    }
                  }}
                  renderOption={(props, option) => (
                    <li {...props} key={option.id}>
                      {option.label}
                    </li>
                  )}
                />

                <Styled.Or>Or</Styled.Or>

                <ThemeProvider theme={inputs}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleCreateGroup}
                  >
                    Create Group
                  </Button>
                </ThemeProvider>

                <Formik>
                  {() => (
                    <Styled.Form onSubmit={(e) => e.preventDefault()}>
                      <Field
                        name="search"
                        placeholder="Search Offers"
                        value={search}
                        onChange={(e) => {
                          setSearch(e.target.value);
                          debounceFn(e.target.value);
                        }}
                      />
                    </Styled.Form>
                  )}
                </Formik>
                <Styled.Pipe></Styled.Pipe>

                <Styled.Or>Status: </Styled.Or>

                <Field
                  as="select"
                  id="offerStatusFilter"
                  name="selectofferStatusField"
                  value={
                    oprations?.isActive === true
                      ? 'active'
                      : oprations?.isActive === false
                      ? 'inactive'
                      : 'all'
                  }
                  onChange={(e) => {
                    handleOnStatusFilterChange(e);
                  }}
                >
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                  <option value="all">All</option>
                </Field>
              </>
            )}
          </Styled.SearchArea>

          {isOffersLoading ? (
            <LoadingOffers />
          ) : (
            <>
              {offers.length > 0 ? (
                <OfferTable
                  offers={offers}
                  selectedGroupId={groupId}
                  groups={groups}
                  handleShowGroup={handleShowGroup}
                  oprations={oprations}
                  handleChangePage={handleChangePage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                  handleOnSortChange={handleOnSortChange}
                />
              ) : (
                <>
                  {currentGroup ? (
                    <NoOffers
                      message={
                        search
                          ? `No offers with this query.`
                          : `This group doesn't have any offers yet.`
                      }
                      subline={
                        search
                          ? `Change your query.`
                          : `Start by clicking "Create Offer".`
                      }
                    />
                  ) : (
                    <NoCurrentGroup />
                  )}
                </>
              )}
            </>
          )}
        </Box>
      </Box>

      <GroupDeleteDialog open={openGroupDeleteDialog} />
      <GroupNameDialog open={openGroupNameDialog} />
    </>
  );
};

interface Props {
  offers: Offer[];
  groups: any;
  handleShowGroup: any;
  selectedGroupId: any;
  oprations: {
    page: number;
    rowsPerPage: number;
    isActive?: string;
  };
  handleChangePage: () => void;
  handleChangeRowsPerPage: () => void;
  handleOnSortChange: () => void;
}

const OfferTable = ({
  offers,
  selectedGroupId,
  groups,
  handleShowGroup,
  oprations,
  handleChangePage,
  handleChangeRowsPerPage,
  handleOnSortChange,
}: Props) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [abTests, setAbTests] = React.useState([]);
  const { page, rowsPerPage } = oprations;

  const data = React.useMemo(() => offers && offers.filter((x) => x), [offers]);

  const states = useAppSelector((state) => {
    return state.offerWallReducer.ui.states;
  });

  const handleShowOffer = (offer: Offer) => {
    dispatch(setGroupDeletingState({ state: 'idle' }));
    dispatch(setGroupSavingState({ state: 'idle' }));
    dispatch(fetchOffer(offer));
  };

  const currentDate = Date.now();

  useEffect(() => {
    axios
      .get(`${API_BASE_ADDRESS}/offer-manager/offers?list=true`)
      .then((res) => {
        setAbTests(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  const offerIDWithAbTests: string[] = [];
  const abTestObject = {};

  abTests.map((offer) => {
    if (offer.abtests) {
      offerIDWithAbTests.push(offer.offerid);
      abTestObject[offer.offerid] = offer.abtests[0].label;
    }
  });

  const count =
    data.length > 0
      ? typeof data[0].totalCount === 'string'
        ? Number(data[0].totalCount)
        : data[0].totalCount
      : 1;

  const columns = React.useMemo(
    () => [
      {
        Header: 'Offer Group',
        accessor: 'offergroupid',
        Cell: (row) => (
          <Button
            disabled={
              states &&
              RowingOutlined &&
              RowingOutlined.id &&
              states[RowingOutlined.id]
                ? true
                : false
            }
            color="primary"
            onClick={() => handleShowGroup(row.cell.value)}
            size="large"
          >
            {row && row.cell.value
              ? groups.find((a) => a.id === row.cell.value)?.name
              : groups.find((a) => a.id === selectedGroupId)?.name}
          </Button>
        ),
      },
      {
        Header: 'Offer Label',
        accessor: 'label',
        Cell: (row) => (
          <Styled.OfferLabel>
            {row.cell.row.original.isactive ? (
              <Avatar className={classes.offerActive} title="Status: ON">
                <LocalOfferRounded className={classes.offerIcon} />
              </Avatar>
            ) : (
              <Avatar className={classes.offerInactive} title="Status: OFF">
                <LocalOfferRounded color="disabled" fontSize="small" />
              </Avatar>
            )}
            {row.cell.row.original &&
            row.cell.row.original.offerid &&
            offerIDWithAbTests.includes(row.cell.row.original.offerid) ? (
              <BootstrapTooltip
                title={`A/B Test: ${
                  abTestObject[row.cell.row.original.offerid]
                }`}
              >
                <Styled.ActiveAbTestIcon
                  src={ABTest}
                  alt="Active A/B Test icon"
                />
              </BootstrapTooltip>
            ) : (
              <Styled.InactiveAbTestIcon
                src={ABTest}
                alt="Inactive A/B Test icon"
              />
            )}
            <Link
              onClick={() => handleShowOffer(row.cell.row.original)}
              to={
                states[row.cell.offerid]
                  ? '#'
                  : `/main/offer-wall-manager/editor/${row.cell.row.original.offerid}`
              }
            >
              {row.cell.value}
            </Link>
          </Styled.OfferLabel>
        ),
      },
      {
        Header: 'Offer ID',
        accessor: 'offerid',
        Cell: (row) => <Styled.OfferId>{row.cell.value}</Styled.OfferId>,
      },
      {
        Header: 'Placement',
        accessor: 'placementid',
        Cell: (row) => (
          <>
            {allOptions && allOptions.placementid
              ? _.startCase(allOptions.placementid[row.cell.value])
              : ''}
          </>
        ),
      },
      {
        Header: 'Last Modified',
        accessor: 'updatedat',
        Cell: (row) => (
          <>
            {row?.cell?.value
              ? new Date(Number(row.cell.value)).toLocaleDateString('en-US')
              : ''}
          </>
        ),
      },
      {
        Header: 'Start Date',
        accessor: 'startdate',
        Cell: (row) => (
          <>
            {row.cell && row.cell.value
              ? new Date(Number(row.cell.value)).toLocaleDateString('en-US')
              : ''}
          </>
        ),
      },
      {
        Header: 'End Date',
        accessor: 'enddate',
        Cell: (row) => (
          <>
            {row.cell && row.cell.value
              ? new Date(Number(row.cell.value)).toLocaleDateString('en-US')
              : ''}
          </>
        ),
      },
      {
        Header: 'Points',
        accessor: 'points',
        Cell: (row) => (
          <Styled.Points>
            {row.cell && row.cell.value ? row.cell.value.toLocaleString() : ''}
          </Styled.Points>
        ),
      },
      {
        Header: 'Impressions',
        accessor: 'impressions',
        Cell: (row) => (
          <Styled.Impressions>
            {row.cell && row.cell.value ? row.cell.value.toLocaleString() : 0}
          </Styled.Impressions>
        ),
      },
      {
        Header: 'Clicks',
        accessor: 'clicks',
        Cell: (row) => (
          <Styled.Clicks>
            {row.cell && row.cell.value ? row.cell.value.toLocaleString() : 0}
          </Styled.Clicks>
        ),
      },
      {
        Header: 'Conversion Cap',
        accessor: 'offertunegoals',
        Cell: (row) => {
          const goals = row.cell?.value || [];
          const activegoals = goals?.filter(
            (x) => x?.isconversioncapactive === 1
          );

          if (activegoals?.length > 0) {
            // Find best conversion rate object within the current row's offertunegolas
            const { bestConversionGoal, icon } = findBestConversionRate(
              activegoals || []
            );
            if (bestConversionGoal) {
              return (
                <div className={classes.conversionValue}>
                  <BootstrapTooltip
                    title={activegoals?.map((goal) => (
                      <div>
                        {goal?.currentconversionvalue?.toLocaleString() || 0} /{' '}
                        {goal?.conversioncapvalue?.toLocaleString() || 0}{' '}
                        {goal?.conversiontimerange &&
                          `${
                            TimeRangeofConversionLabel[goal.conversiontimerange]
                          }`}
                        {', '} Goal ID: {goal?.goalid}
                      </div>
                    ))}
                  >
                    <IconButton size="small" style={{ left: '5px' }}>
                      {icon}
                    </IconButton>
                  </BootstrapTooltip>
                </div>
              );
            }
          }

          return null;
        },
      },
    ],
    [
      classes.offerActive,
      classes.offerIcon,
      classes.offerInactive,
      currentDate,
      handleShowOffer,
      states,
    ]
  );

  return (
    <>
      <Table
        columns={columns}
        data={data}
        onSort={handleOnSortChange}
        operations={oprations}
        disabledColumns={['offertunegoals']}
      />
      <TablePagination
        component="div"
        count={count}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[25, 50, 100]}
        sx={{
          '.MuiTablePagination-selectLabel': {
            paddingTop: '13px',
          },
          '.MuiTablePagination-displayedRows': {
            paddingTop: '13px',
          },
        }}
      />
    </>
  );
};

export const NoOffers = ({
  message,
  subline,
}: {
  message: string;
  subline?: string;
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.noOffers}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <LocalOfferRounded />
        </Grid>
        <Grid item>
          <Typography component="h3" variant="h6">
            {message}
          </Typography>
        </Grid>
        <Grid item>
          <Typography component="h4" variant="caption">
            {subline}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

const NoCurrentGroup = () => {
  const classes = useStyles();

  return (
    <Box className={classes.noOffers}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <LocalOfferRounded />
        </Grid>
        <Grid item>
          <Typography component="h3" variant="h6">
            Create or select a group
          </Typography>
        </Grid>
        <Grid item>
          <Typography component="h4" variant="caption">
            Start by clicking &quot;Create Group&quot;.
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

const LoadingOffers = () => {
  const classes = useStyles();

  return (
    <Box className={classes.noOffers}>
      <CircularProgress color="primary" size={40} />
    </Box>
  );
};

/**
 * Retrieves offer tune goal object which have best conversion ratio.
 * @param {Array} data - The array of offer tune goals with active conversion cap.
 * @returns {Object} - An object containing best conversion ratio.
 */
export const findBestConversionRate = (goals) => {
  let bestConversionGoal = null;

  //Filter conversion active goals
  const activeGoals = goals.filter((x) => x?.isconversioncapactive);

  // Check if all filtered objects have currentconversionvalue = 0
  const allRatiosZero = activeGoals.every(
    (goal) => goal.currentconversionvalue === 0
  );

  if (allRatiosZero || activeGoals.length === 1) {
    // If all ratios are zero, return the first object from filtered data || Return the first object if only one exists
    bestConversionGoal = activeGoals[0];
  } else {
    //Find Goal with best conversion cap ratio
    bestConversionGoal = activeGoals.reduce((prev, current) => {
      const prevRatio = prev
        ? prev.currentconversionvalue / prev.conversioncapvalue
        : 0;
      const currentRatio =
        current.currentconversionvalue / current.conversioncapvalue;
      return currentRatio > prevRatio ? current : prev;
    }, null);
  }

  //Check if conversion cap is full filled
  const isConversionCapFullFilled = bestConversionGoal
    ? (bestConversionGoal?.currentconversionvalue || 0) /
        (bestConversionGoal?.conversioncapvalue || 0) >=
      1
      ? true
      : false
    : false;

  //Set icon to display conversioncapfullfill status
  const icon = isConversionCapFullFilled ? (
    <img src={CapFullFilledIcon} style={{ height: 22, width: 22 }} />
  ) : (
    <CapInProgressIcon style={{ height: 22, width: 22, color: '#4f41aaff' }} />
  );

  return {
    bestConversionGoal,
    icon,
  };
};

export default OfferWallManager;
