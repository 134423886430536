import React from 'react';
import { useAppSelector } from 'redux/hooks';

// Material UI Components
import AudienceIcon from '@material-ui/icons/SupervisedUserCircleRounded';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import tokens from 'tokens';
import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    audienceSize: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',

      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 24,
    },
    title: {
      paddingTop: '1.5em',

      fontStyle: 'normal',
      fontWeight: 300,
      fontSize: 11,
    },
    spinner: {
      color: tokens.colorSpinnerDark,
    },
  })
);

const TotalAudienceSize: React.FC = () => {
  const classes = useStyles();
  const updating = useAppSelector(
    (state) => state.audienceReducer.ui.totalAudienceUpdating
  );
  const audience = useAppSelector(
    (state) => state.audienceReducer.currentAudience
  );
  const modeled = useAppSelector((state) => {
    return state.audienceReducer.ui.modeled;
  });

  return (
    <Box display="flex">
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        paddingRight={2}
      >
        {audience &&
          audience.updatedAt !== undefined &&
          audience.updatedAt !== 0 && (
            <>
              <Typography className={classes.title}>Last modified</Typography>
              <Typography component="p" variant="body2">
                {new Date(Number(audience.updatedAt)).toLocaleDateString(
                  'en-US'
                )}
                ・
                {new Date(Number(audience.updatedAt)).toLocaleTimeString(
                  'en-US'
                )}
              </Typography>
            </>
          )}
      </Box>

      <Box
        style={{
          paddingRight: 32,
          borderLeft: '2px solid rgb(232 232 232)',
          paddingLeft: 16,
        }}
      >
        <Grid container direction="column" justifyContent="center">
          <Grid item xs>
            <Typography className={classes.title}>
              Estimated Audience Size
            </Typography>
          </Grid>
          <Grid item xs container direction="row" alignItems="center">
            <Grid item xs={2}>
              <AudienceIcon
                color="primary"
                className={classes.audienceSize}
                style={{ color: 'black', height: 24, width: 24 }}
              />
            </Grid>
            <Grid item xs={10}>
              <Typography
                className={classes.audienceSize}
                style={{ marginLeft: 8 }}
              >
                {updating ? (
                  <CircularProgress size={20} className={classes.spinner} />
                ) : audience && audience.totalSize ? (
                  audience.totalSize.toLocaleString()
                ) : (
                  '0'
                )}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default TotalAudienceSize;
