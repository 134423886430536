import styled from 'styled-components';
import tokens from 'tokens';
import * as Grid from 'components/Grid';

export const GridContainer = styled(Grid.Container)`
  padding: 0 !important;
`;

export const ButtonSpacing = styled.div`
  display: flex;

  & > * + * {
    margin-left: ${tokens.spacing.sm};
  }
`;

export const Label = styled.div`
  ${tokens.typography.label};
  margin-bottom: ${tokens.spacing.xs};
`;

export const ResponseLabel = styled.div`
  ${tokens.typography.label};
  margin-top: ${tokens.spacing.md};
  margin-bottom: ${tokens.spacing.xs};
`;

export const Row = styled.div`
  display: flex;

  & > button {
    margin-left: ${tokens.spacing.sm};
  }
`;

export const SubHeader = styled.h3`
  ${tokens.typography.subheaderSm};
  margin: ${tokens.spacing.lg} 0 ${tokens.spacing.sm};
`;

export const PickerSpace = styled.div`
  position: absolute;
  z-index: ${(props: { changeEmoji: boolean }) =>
    props.changeEmoji ? 10000 : 1};
  padding-bottom: ${tokens.spacing.sm};
`;

export const RadioSelectionSpace = styled.div`
  margin: ${tokens.spacing.md} -${tokens.spacing.xs};
  padding: 0px ${tokens.spacing.xs};
  overflow-y: scroll;
  height: 100px;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }
  ::-webkit-scrollbar:horizontal {
    height: 11px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
    background-color: rgba(0, 0, 0, 0.5);
  }
`;
