const partnerBuyerDimensionConfig = {
  dimensionName: 'Select Partner Buyer & Contact Info',
  showInEditor: true,
  showInCreation: true,
  inputs: [
    {
      stepperKeyName: 'bidder',
      stepperInputType: 'bidderselect',
      stepperInputLabel: 'DSP',
      required: false,
      forCreation: true,
      forEditor: true,
    },
    {
      stepperKeyName: 'buyer',
      stepperInputType: 'buyerselect',
      stepperInputLabel: 'Buyer',
      required: false,
      forCreation: true,
      forEditor: false,
    },
    {
      stepperKeyName: 'partnerbuyercontact',
      stepperInputType: 'textfield',
      stepperInputLabel: 'Partner Buyer Contact Info',
      muiProps: {
        label: 'Email',
      },
      type: 'email',
      helperText: '* Invalid partner buyer contact information',
      required: true,
      forCreation: true,
      forEditor: true,
    },
    {
      stepperKeyName: 'emailedat',
      stepperInputType: 'displaydate',
      stepperInputLabel: 'Email Date',
      muiProps: {
        label: 'Email Date',
        disabled: true,
      },
      forCreation: false,
      forEditor: false,
    },
    {
      stepperKeyName: 'confirmedat',
      stepperInputType: 'displaydate',
      stepperInputLabel: 'Confirmation Date',
      muiProps: {
        label: 'Confirmation Date',
        disabled: true,
      },
      forCreation: false,
      forEditor: false,
    },
    {
      stepperKeyName: 'launchedat',
      stepperInputType: 'displaydate',
      stepperInputLabel: 'Launch Date',
      muiProps: {
        label: 'Launch Date',
        disabled: true,
      },
      forCreation: false,
      forEditor: false,
    },
    {
      stepperKeyName: 'confirmed',
      stepperInputType: 'switch',
      stepperInputLabel: 'Confirmed',
      muiProps: {
        label: 'Confirmed Deal',
        disabled: true,
      },
      forCreation: false,
      forEditor: false,
    },
    {
      stepperKeyName: 'active',
      stepperInputType: 'switch',
      stepperInputLabel: 'Active',
      muiProps: {
        label: 'Disable Deal',
      },
      forCreation: false,
      forEditor: true,
    },
  ],
};

export default partnerBuyerDimensionConfig;
