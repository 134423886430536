import _ from 'lodash';
import { LoadingProps } from 'interfaces/ui';
import {
  NormalizedObjects,
  initialNormalizedObject,
} from 'interfaces/entities';
import { PayloadAction, Slice, createSlice, current } from '@reduxjs/toolkit';

export const ADMIN_USERS = new Set([
  'joseph.chung@joinklover.com',
  'alex.chung@joinklover.com',
  'chris@joinklover.com',
  'swan.ng@joinklover.com',
]);

export interface LineItem {
  lineitemname: string;
  lineitemid: number;
}

export interface DealJob {
  jobid: number;
  jobstatus: string;
  dealid: string;
  type: string;
  createdate: number;
  updatedate: number;
  error: string;
}

export interface Deal {
  dealid: string;
  xandrdealid?: number;
  dealname: string;
  biddername?: string;
  bidderid?: number;
  buyername?: string;
  buyercode?: string;
  partnerbuyercontact: string;
  submittedby: string;
  emailedat: number;
  active: boolean;
  confirmed: boolean;
  createdat: number;
  updatedat: number;
  confirmedat?: number;
  launchedat?: number;
  lineitems: Array<LineItem>;
  history?: Array<DealJob>;
}

export interface Bidder {
  bidderid: number;
  biddername: string;
}

export interface Buyer {
  buyercode: string;
  buyername: string;
}

interface DealsState {
  currentDimension?: any;
  currentDeal?: Deal;
  originalDeal?: Deal;
  entities: {
    deals: NormalizedObjects<Deal>;
    buyers: NormalizedObjects<Buyer>;
    bidders: NormalizedObjects<Bidder>;
    lineitems: NormalizedObjects<LineItem>;
  };
  ui: {
    bidderLabel: string;
    buyerLabel: string;
    manual: boolean;
    lineitemFilter: string;
    lineitemScrollPosition: number;
    errorFetching: boolean;
    validationMap: any;
    notifications: Array<LoadingProps>;
    queryLoading: boolean;
    dealLoading: boolean;
    dealsLoading: boolean;
    dealDeleteDialog: boolean;
    dealDimensionDialog: boolean;
    dealSendDialog: boolean;
  };
}

const initialState: DealsState = {
  entities: {
    deals: initialNormalizedObject,
    buyers: initialNormalizedObject,
    bidders: initialNormalizedObject,
    lineitems: initialNormalizedObject,
  },
  ui: {
    bidderLabel: '',
    buyerLabel: '',
    manual: false,
    lineitemFilter: '',
    lineitemScrollPosition: NaN,
    errorFetching: false,
    validationMap: {},
    notifications: [],
    queryLoading: false,
    dealLoading: false,
    dealsLoading: false,
    dealDeleteDialog: false,
    dealDimensionDialog: false,
    dealSendDialog: false,
  },
};

export const dealsSlice: Slice<DealsState> = createSlice({
  name: 'deals',
  initialState,
  reducers: {
    addNotification: (state, action) => {
      state.ui.notifications.push(action.payload);
    },
    clearDeals: (state) => {
      state.entities.deals = initialNormalizedObject;
    },
    clearCurrentDeal: (state) => {
      state.currentDeal = null;
    },
    clearOriginalDeal: (state) => {
      state.originalDeal = null;
    },
    clearBuyers: (state) => {
      state.entities.buyers = initialNormalizedObject;
    },
    clearBidders: (state) => {
      state.entities.bidders = initialNormalizedObject;
    },
    clearLineItems: (state) => {
      state.entities.lineitems = initialNormalizedObject;
    },
    createDeal: () => {
      //
    },
    deleteDeal: () => {
      //
    },
    fetchBidders: () => {
      //
    },
    fetchBuyers: () => {
      //
    },
    fetchDeal: () => {
      // Skeleton to get helpers to build actions
      // Refer saga.ts
    },
    fetchDeals: () => {
      // Skeleton to get helpers to build actions
      // Refer saga.ts
    },
    fetchLineItems: () => {
      //
    },
    launchDeal: () => {
      //
    },
    sendAgreement: () => {
      //
    },
    setCurrentDealDimension: (state, action) => {
      state.currentDimension = action.payload;
    },
    setCurrentDeal: (state, action) => {
      if (action.payload) state.currentDeal = action.payload;
    },
    setOriginalDeal: (state, action) => {
      if (action.payload) state.originalDeal = action.payload;
    },
    setDeal: (state, action: PayloadAction<Deal>) => {
      const currentDeal = action.payload;
      const dealsEntity = _.clone(current(state.entities.deals));
      const newAllIds = _.union(dealsEntity.allIds, [currentDeal.dealid]);
      const newById = _.clone(dealsEntity.byId);
      newById[currentDeal.dealid] = currentDeal;
      state.entities.deals.byId = newById;
      state.entities.deals.allIds = newAllIds;
    },
    setDeals: (state, action: PayloadAction<Array<Deal>>) => {
      for (let i = 0; i < action.payload.length; i++) {
        const currentDeal = action.payload[i];
        const dealsEntity = _.clone(current(state.entities.deals));
        const newAllIds = _.union(dealsEntity.allIds, [currentDeal.dealid]);
        const newById = _.clone(dealsEntity.byId);
        newById[currentDeal.dealid] = currentDeal;
        state.entities.deals.byId = newById;
        state.entities.deals.allIds = newAllIds;
      }
    },
    setBuyers: (state, action: PayloadAction<Array<Buyer>>) => {
      for (let i = 0; i < action.payload.length; i++) {
        const currentBuyer = action.payload[i];
        const buyersEntity = _.clone(current(state.entities.buyers));
        const newAllIds = _.union(buyersEntity.allIds, [
          currentBuyer.buyercode,
        ]);
        const newById = _.clone(buyersEntity.byId);
        newById[currentBuyer.buyercode] = currentBuyer;
        state.entities.buyers.byId = newById;
        state.entities.buyers.allIds = newAllIds;
      }
    },
    setBidder: (state, action: PayloadAction<Array<Bidder>>) => {
      for (let i = 0; i < action.payload.length; i++) {
        const currentBidder = action.payload[i];
        const biddersEntity = _.clone(current(state.entities.bidders));
        const newAllIds = _.union(biddersEntity.allIds, [
          currentBidder.bidderid.toString(),
        ]);
        const newById = _.clone(biddersEntity.byId);
        newById[currentBidder.bidderid] = currentBidder;
        state.entities.bidders.byId = newById;
        state.entities.bidders.allIds = newAllIds;
      }
    },
    setLineItems: (state, action: PayloadAction<Array<LineItem>>) => {
      for (let i = 0; i < action.payload.length; i++) {
        const currentLineItem = action.payload[i];
        const lineitemsEntity = _.clone(current(state.entities.lineitems));
        const newAllIds = _.union(lineitemsEntity.allIds, [
          currentLineItem.lineitemid.toString(),
        ]);
        const newById = _.clone(lineitemsEntity.byId);
        newById[currentLineItem.lineitemid] = currentLineItem;
        state.entities.lineitems.byId = newById;
        state.entities.lineitems.allIds = newAllIds;
      }
    },
    setQueryLoading: (state, action) => {
      state.ui.queryLoading = action.payload;
    },
    saveDeal: () => {
      // wrapper
    },
    setDealLoadingState: (state, action) => {
      state.ui.dealLoading = action.payload;
    },
    setDealsLoadingState: (state, action) => {
      state.ui.dealsLoading = action.payload;
    },
    setDealDeletingState: (state, action) => {
      state.ui.dealLoading = action.payload;
    },
    setDealSavingState: (state, action) => {
      state.ui.dealLoading = action.payload;
    },
    setDealDeleteDialogOpen: (state, action) => {
      state.ui.dealDeleteDialog = action.payload;
    },
    setDealsDimensionDialogOpen: (state, action) => {
      state.ui.dealDimensionDialog = action.payload;
    },
    setDealSendDialogOpen: (state, action) => {
      state.ui.dealSendDialog = action.payload;
    },
    setUIState: (state, action) => {
      const { value, uiPropertyName } = action.payload;
      state.ui[uiPropertyName] = value;
    },
    updateCurrentDeal: (state, action) => {
      const { value, propertyName } = action.payload;
      if (propertyName && state.currentDeal) {
        if (propertyName === 'lineitems') {
          const index = state.currentDeal?.lineitems.findIndex(
            (lineitem) => lineitem.lineitemid === value.lineitemid
          );
          if (index === -1 || index === undefined) {
            state.currentDeal?.lineitems.push(value);
          } else {
            state.currentDeal?.lineitems.splice(index, 1);
          }
        } else {
          state.currentDeal[propertyName] = value;
        }
      }
    },
    removeDeal: (state, action) => {
      const dealid = action.payload;
      if (state.entities.deals) {
        delete state.entities.deals.byId[dealid];
        state.entities.deals.allIds = state.entities.deals.allIds.filter(
          (x) => x != dealid
        );
      }
    },
    removeNotification: (state) => {
      state.ui.notifications = state.ui.notifications.slice(1);
    },
  },
});

export const {
  addNotification,
  clearDeals,
  clearBidders,
  clearCurrentDeal,
  clearOriginalDeal,
  clearBuyers,
  clearLineItems,
  createDeal,
  setCurrentDealDimension,
  deleteDeal,
  fetchBidders,
  fetchBuyers,
  fetchDeal,
  fetchDeals,
  fetchLineItems,
  launchDeal,
  sendAgreement,
  setBuyers,
  setCurrentDeal,
  setOriginalDeal,
  setQueryLoading,
  saveDeal,
  setDeal,
  setDeals,
  setLineItems,
  setDealLoadingState,
  setDealsLoadingState,
  setDealDeletingState,
  setDealSavingState,
  setDealDeleteDialogOpen,
  setDealsDimensionDialogOpen,
  setDealSendDialogOpen,
  setBidder,
  setUIState,
  updateCurrentDeal,
  removeDeal,
  removeNotification,
} = dealsSlice.actions;

export default dealsSlice.reducer;
