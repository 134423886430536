import Button from 'components/Button';
import Select from 'components/Select';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import DialogContentText from '@material-ui/core/DialogContentText';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import { Autocomplete, TextField } from '@mui/material';
import tokens from 'tokens';
import { Form as FormikForm } from 'formik';

export const ButtonComponent = styled(Button)``;

export const ButtonSpacing = styled.div`
  display: flex;

  & > * + * {
    margin-left: ${tokens.spacing.sm};
  }
`;

export const SearchArea = styled.div`
  margin-bottom: ${tokens.spacing.md};
`;

export const SelectOfferGroup = styled(Select)`
  width: 200px;
`;

export const Or = styled.span`
  margin: 0 ${tokens.spacing.sm};
  text-transform: uppercase;
`;

export const Form = styled(FormikForm)`
  border-left: 1px solid ${tokens.colorGray40};
  display: inline-block;
  margin-left: ${tokens.spacing.md};
  padding-left: ${tokens.spacing.md};
`;

export const RootContainer = styled.div`
  margin: 3px;
`;

export const Title = styled(Typography)`
  font-style: normal;
  font-weight: 500;
  font-size: 23px;
`;

export const CustomButton = styled(Button)`
  border-radius: 8px;

  &.MuiButton-outlinedPrimary {
    color: ${tokens.colorButtonPrimary};
  }

  &.MuiButton-containedPrimary {
    background-color: ${tokens.colorButtonPrimary};

    &:hover {
      background-color: ${tokens.colorButtonPrimary};
    }
  }
`;

export const EditButton = styled(IconButton)`
  margin-left: 20px;
  padding: 4px;
`;

export const DeleteButton = styled(IconButton)`
  margin-left: 4px;
  padding: 4px;
`;

export const BoldText = styled.span`
  fontweight: bolder;
`;

export const DialogText = styled(DialogContentText)`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
`;

export const ProgressBox = styled(Box)`
  width: 142;
  display: flex;
  justify-content: center;
`;

export const SelectAutoCompleteGroup = styled(Autocomplete)`
  width: 300px;
  display: inline-flex;
  & input {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
  }
`;

export const SelectAutoCompleteEmail = styled(Autocomplete)`
  width: 300px;
  display: inline-flex;
  position: relative;
  float: inline-end;
  margin-right: 0rem;
  & input {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
  }
`;

export const SelectAutoCompleteGroupInput = styled(TextField)({
  '& .MuiInputBase-input': {
    fontSize: `14px`,
  },
});

export const SelectAutoCompleteGroupOptions = styled.li`
  font-size: 14px;
`;
