import styled from 'styled-components';
import tokens from 'tokens';

export const Toggle = styled.div`
  margin: ${tokens.spacing.lg} 0;
`;

export const RadioGroup = styled.div`
  padding: ${tokens.spacing.md} 0;
`;
