import React from 'react';
import { DimensionCategory } from '../slice';

// Material UI Components
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import PersonIcon from '@material-ui/icons/Person';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import StoreIcon from '@material-ui/icons/Store';
import SwapHorizontalCircleIcon from '@material-ui/icons/SwapHorizontalCircle';
import tokens from 'tokens';
import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    bankDataIcon: {
      color: tokens.colorIconInfo,
    },
    demographicsIcon: {
      color: tokens.colorIconExtra,
    },
    merchantIcon: {
      color: tokens.colorIconWarning,
    },
    productsIcon: {
      color: tokens.colorIconSuccess,
    },
    transactionsIcon: {
      color: tokens.colorIconDanger,
    },
    questionIcon: {
      color: tokens.colorIconInfo,
    },
  })
);

interface Props {
  category: DimensionCategory;
}

const DimensionIcon = ({ category }: Props) => {
  const classes = useStyles();
  const icon = (category: DimensionCategory) => {
    switch (category) {
      case 'bankData':
        return <AccountBalanceIcon className={iconStyle(category)} />;
      case 'demographics':
        return <PersonIcon className={iconStyle(category)} />;
      case 'merchant':
        return <StoreIcon className={iconStyle(category)} />;
      case 'product':
        return <ShoppingBasketIcon className={iconStyle(category)} />;
      case 'transactions':
        return <SwapHorizontalCircleIcon className={iconStyle(category)} />;
      case 'survey':
        return <QuestionAnswerIcon className={iconStyle(category)} />;
    }
  };

  const iconStyle = (category: DimensionCategory) => {
    switch (category) {
      case 'bankData':
        return classes.bankDataIcon;
      case 'demographics':
        return classes.demographicsIcon;
      case 'merchant':
        return classes.merchantIcon;
      case 'product':
        return classes.productsIcon;
      case 'transactions':
        return classes.transactionsIcon;
      case 'survey':
        return classes.questionIcon;
    }
  };

  return <>{icon(category)}</>;
};

export default DimensionIcon;
