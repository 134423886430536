import dealNameDimensionConfig from './dealNameDimensionConfig';
import historyDimensionConfig from './historyDimensionConfig';
import lineItemDimensionConfig from './lineItemDimensionConfig';
import partnerBuyerDimensionConfig from './partnerBuyerDimensionConfig';

const dealDimensionConfig = [
  dealNameDimensionConfig,
  partnerBuyerDimensionConfig,
  lineItemDimensionConfig,
  historyDimensionConfig,
];

export default dealDimensionConfig;
