import styled from 'styled-components';
import tokens from 'tokens';

export const ResponseLabel = styled.div`
  ${tokens.typography.label};
  margin-top: ${tokens.spacing.md};
  margin-bottom: ${tokens.spacing.xs};
`;

export const Row = styled.div`
  display: flex;

  & > button {
    margin-left: ${tokens.spacing.sm};
  }
`;
