import React, { useMemo, useState } from 'react';
import {
  ToggleExportDialogAction,
  addNotification,
  exportAudience,
  fetchAudience,
  toggleAudienceExporting,
  toggleExportDialog,
} from '../slice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { useUser } from 'reactfire';
import { Button, IconButton } from '@klover/attain-design-system';
import { EXPORT_EMAIL_INFO_TEXT, SELECT_EXPORT_TYPE, ExportAudience, REQUIRED_FIELDS_ARE_MISSING, LoadingStates } from '../../../constants';
// Material UI Components
import { Tooltip } from '@mui/material';
import {
  CircularProgress
} from '@mui/material';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Box from '@material-ui/core/Box';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import tokens from 'tokens';
import { API_BASE_ADDRESS } from '../variables';
import {
  Theme,
  ThemeProvider,
  createStyles,
  createTheme,
  makeStyles,
} from '@material-ui/core/styles';
import AudienceExport from '../../../components/AudienceExport';
import { isFloat } from '../../../helper/helper';
import nanoid from 'utils/nanoid';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: theme.spacing(3),
    },
    divider: {
      borderLeft: '0.2em solid ' + tokens.colorDividerLgBorder,
    },
    email: {
      marginTop: '24px',
      marginBottom: theme.spacing(3),
      marginLeft: '6px'
    },
    title: {
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 23,
      paddingLeft: 16,
      paddingTop: 16,
    },
    selectExport: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: '100%'
    },
    selectExportTypeLabel: {
      fontSize: 16,
      fontWeight: 500,
      paddingBottom: 4
    }
  })
);

const dialog = createTheme({
  overrides: {
    MuiDialog: {
      paper: {
        borderRadius: 10,
        width: '60%',
        minHeight: '290px'
      },
    },
    MuiMenu: {
      list: {
        padding: 0,
      },
    },
    MuiInputBase: {
      input: {
        borderRadius: 10,
        padding: '12px 14px !important',
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 10,
      },
    },
    MuiButton: {
      root: {
        borderRadius: 8,
      },
      outlinedPrimary: {
        color: tokens.colorButtonPrimary,
      },
      containedPrimary: {
        backgroundColor: tokens.colorButtonPrimary,
        '&:hover': {
          backgroundColor: tokens.colorButtonContainedHoverbg,
        },
      },
    },
    MuiFormLabel: {
      asterisk: {
        color: '#db3131',
        '&$error': {
          color: '#db3131'
        },
      }
    }
  },
});

interface Props {
  open: boolean;
}

const ExportDialog = ({ open }: Props) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const audience = useAppSelector((state) => {
    if (state.audienceReducer && state.audienceReducer.currentAudience) {
      return state.audienceReducer.currentAudience;
    } else {
      return null;
    }
  });

  const selectOptions = [
    { text: ExportAudience.KLOVER_IDS, value: 'klover_ids' },
    // { text: ExportAudience.KLOVER_RAMP_IDS, value: 'rampid' },
    { text: ExportAudience.TRANSUNION, value: 'transunion' },
    // { text: ExportAudience.TRANSUNION_TTD, value: 'transunion_ttd' },
    // { text: ExportAudience.TRANSUNION_HEMS, value: 'transunion_hems' },
    { text: ExportAudience.CUSTOM_SEGMENT, value: 'custom_segment' },
    { text: ExportAudience.OTS_SEGMENT, value: 'ots_segment' }
  ];

  const { data: user } = useUser();
  const currentUser = user || { email: '' };
  const [emails, setEmails] = useState(currentUser.email);
  const [isExporting, setIsExporting] = useState(false);
  const [selectedExportType, setSelectedExportType] = useState('');
  const [segmentName, setSegmentName] = useState('');
  const [description, setDescription] = useState('');
  const [cpm, setCpm] = useState('');
  const [percentageOfMedia, setPercentageOfMedia] = useState('');
  const [partnerID, setPartnerID] = useState('');
  const [advertiserID, setAdvertiserID] = useState('');
  
  const dispatchClose = () => {
    const actionProps: ToggleExportDialogAction = {
      open: false,
    };
    dispatch(toggleExportDialog(actionProps));
  };

  const handleEmailsChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const emails = event.target.value as string;
    setEmails(emails);
  };

  const handleSegmentName = (event: React.ChangeEvent<{ value: unknown }>) => {
    const data = event.target.value as string;
    setSegmentName(data);
  };

  const handleDescription = (event: React.ChangeEvent<{ value: unknown }>) => {
    const data = event.target.value as string;
    setDescription(data);
  };

  const handleCpm = (event: any) => {
    setCpm(event.target.value);
  };

  const handlePercentageOfMedia = (event: any) => {
    setPercentageOfMedia(event.target.value);
  };

  const handlePartnerID = (event: any) => {
    setPartnerID(event.target.value);
  };

  const handleAdvertiserID = (event: any) => {
    setAdvertiserID(event.target.value);
  };

  const handleExport = (columns: Array<string>) => {
    if (emails && emails !== '') {
      try {
        setIsExporting(true);
        dispatch(toggleAudienceExporting(true));
        dispatch(exportAudience({ emails, columns }));
        dispatch(fetchAudience({ id }));
        dispatchClose();
        setIsExporting(false);
      } catch(e) {
        setIsExporting(false);
      }
    }
  };

  const handleExportToTransunion = (exportType: string) => {
    if (audience) {
      const { id, name } = audience;
      if (cpm !== '' && !isFloat(cpm)) {
        dispatch(
          addNotification({
            state: LoadingStates.ERROR,
            message: "Please enter valid CPM",
          })
        );
        return;
      }
      if (percentageOfMedia !== '' && !isFloat(percentageOfMedia)) {
        dispatch(
          addNotification({
            state: LoadingStates.ERROR,
            message: "Please enter valid % of media",
          })
        );
        return;
      }
      let payload = {
        id,
        name,
        emails : currentUser.email,
        exportType,
        segmentName,
        description,
        cpm: cpm !== '' ? parseFloat(cpm) : '',
        percentageOfMedia : percentageOfMedia !== '' ? parseFloat(percentageOfMedia) : '',
        partnerID,
        advertiserID,
      };
      setIsExporting(true);
      axios
        .post(`${API_BASE_ADDRESS}/audience-manager/export/transunion`, payload)
        .then(() => {
          setIsExporting(false);
          dispatchClose();
          clearData();
          setSelectedExportType('');
          dispatch(
            addNotification({
              state: 'done',
              message: `Your audience is being exported to TransUnion`,
            })
          );

          setTimeout(() => {
            dispatch(fetchAudience({ id }));
          }, 3000);
        })
        .catch(() => {
          setIsExporting(false);
          dispatchClose();
          setSelectedExportType('');
          clearData();
          dispatch(
            addNotification({
              state: 'error',
              message: `Your audience failed to export to TransUnion`,
            })
          );
        });
    }
  };

  const startExport = () => {
    if (selectedExportType === 'klover_ids') {
      handleExport(['kloverid']);
    } else if (selectedExportType === 'rampid') {
      handleExport(['kloverid', 'rampid']);
    } else if (selectedExportType === 'transunion') {
      handleExportToTransunion('transunion');
    } else if (selectedExportType === 'transunion_ttd') {
      handleExportToTransunion('transunion_ttd');
    } else if (selectedExportType === 'transunion_hems') {
      handleExportToTransunion('transunion_hems');
    } else if (selectedExportType === 'custom_segment') {
      handleExportToTransunion('custom_segment');
    } else if (selectedExportType === 'ots_segment') {
      handleExportToTransunion('ots_segment');
    }
  };

  const clearData = () => {
    setSegmentName('');
    setDescription('');
    setCpm('');
    setPercentageOfMedia('');
    setPartnerID('');
    setAdvertiserID('');
  }

  const handleClose = () => {
    setSelectedExportType('');
    clearData();
    dispatchClose();
  };

  const isRequiredFieldMissing = useMemo(() => {
    if (selectedExportType === 'custom_segment') {
      if (
        segmentName === '' ||
        cpm === '' ||
        percentageOfMedia === '' ||
        (partnerID === '' && advertiserID === '')
      ) {
        return true;
      }
    } else if (selectedExportType === 'ots_segment') {
      if (segmentName === '' || cpm === '' || percentageOfMedia === '') {
        return true;
      }
    }
    return false;
  }, [selectedExportType, segmentName, cpm, percentageOfMedia, partnerID, advertiserID]);

  return (
    <>
      <ThemeProvider theme={dialog}>
        <Dialog
          className={classes.root}
          onClose={handleClose}
          open={open}
          maxWidth="lg"
        >
          <Box m={1}>
            <Grid container alignItems="center">
              <Grid item xs={6}>
                <Typography className={classes.title}>
                  Export Audience
                </Typography>
              </Grid>
              <Grid item container justifyContent="flex-end" xs={6}>
                <IconButton onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Box>

          <DialogContent
            style={{ padding: '24px 32px 8px 32px', overflowY: 'hidden' }}
          >
            <Grid item xs={12}>
              <FormControl className={classes.selectExport}>
                <div className={classes.selectExportTypeLabel}>
                  {SELECT_EXPORT_TYPE}
                </div>
                <Select
                  labelId="export-type-label"
                  id="export-type"
                  value={selectedExportType}
                  onChange={(event: SelectChangeEvent) => {
                    clearData();
                    setSelectedExportType(event.target.value as string);
                  }}
                >
                  {selectOptions.map((item) => {
                    return (
                      <MenuItem key={item.value} value={item.value}>
                        {item.text}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            {(selectedExportType === 'custom_segment' ||
              selectedExportType === 'ots_segment') && (
                <AudienceExport
                  selectedExportType={selectedExportType}
                  handleSegmentName={handleSegmentName}
                  segmentName={segmentName}
                  handleDescription={handleDescription}
                  description={description}
                  handleCpm={handleCpm}
                  cpm={cpm}
                  handlePercentageOfMedia={handlePercentageOfMedia}
                  percentageOfMedia={percentageOfMedia}
                  handlePartnerID={handlePartnerID}
                  partnerID={partnerID}
                  advertiserID={advertiserID}
                  handleAdvertiserID={handleAdvertiserID}
                />
              )}
            {(selectedExportType === 'klover_ids' ||
              selectedExportType === 'rampID') && (
                <>
                  <DialogContentText
                    style={{
                      fontStyle: 'normal',
                      fontWeight: 'normal',
                      fontSize: 16,
                      paddingLeft: 8,
                      marginTop: '3rem',
                    }}
                  >
                    {EXPORT_EMAIL_INFO_TEXT}
                  </DialogContentText>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        className={classes.email}
                        onChange={handleEmailsChange}
                        value={emails}
                        fullWidth
                        id="email"
                        label="Email Address"
                        type="email"
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                </>
              )}
          </DialogContent>

          {/* Bottom Divider */}
          <Grid container spacing={0} style={{ marginTop: 50 }}>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Grid>

          {/* Actions */}
          <Box m={1}>
            <DialogActions>
              <Button color="primary" onClick={handleClose} variant="outlined">
                Cancel
              </Button>
              <Tooltip
                title={isRequiredFieldMissing ? REQUIRED_FIELDS_ARE_MISSING : ''}
                placement="top"
                arrow
              >
                <span>
                  <Button
                    color="primary"
                    disableElevation
                    onClick={() => startExport()}
                    variant="contained"
                    disabled={!selectedExportType || isExporting || (selectedExportType === 'klover_ids' && !emails) || (selectedExportType === 'rampID' && !emails) || isRequiredFieldMissing}
                  >
                    {isExporting ? <CircularProgress size={20} /> : 'Export'}
                  </Button>
                </span>
              </Tooltip>
            </DialogActions>
          </Box>
        </Dialog>
      </ThemeProvider>
    </>
  );
};

export default ExportDialog;
