import { Button } from '@klover/attain-design-system';
import Dialog from 'components/Dialog';
import ErrorMsg from 'components/ErrorMsg';
import Field from 'components/Field';
import React from 'react';
import Toggle from 'components/Toggle';
import _ from 'lodash';
import axios from 'axios';
import { CircularProgress } from '@material-ui/core';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { FormikProvider, useFormik } from 'formik';
import { Offer } from '../slice';
import { OfferWallContext } from 'context';
import { Tooltip } from 'antd';
import { nanoid } from 'nanoid';
import * as Grid from 'components/Grid';
import * as Styled from './index.styles';
import * as Yup from 'yup';

const CreateAbTest = () => {
  const {
    setShowCreateAbTest,
    setAbTests,
    abTests,
    setExistingAbTest,
    existingAbTest,
    setSnackBarOptions,
  } = React.useContext(OfferWallContext);
  const [groups, setGroups] = React.useState([]);
  const [groupid, setGroupid] = React.useState('');
  const [search, setSearch] = React.useState('');
  const [availableOffers, setAvailableOffers] = React.useState([]);
  const [selectedOffers, setSelectedOffers] = React.useState([]);
  const [saving, setSaving] = React.useState(false);
  const selectedMap = selectedOffers.reduce(
    (a, { offerid }: Offer) => ({ ...a, [offerid]: 1 }),
    {}
  );

  const handleSave = (payload) => {
    setSaving(true);
    if (existingAbTest) {
      axios
        .put(
          `${process.env.API_BASE_ADDRESS}/ab-tests/${existingAbTest}`,
          payload
        )
        .then(() => {
          const filteredAbTests = abTests.filter(
            ({ abtestid }) => abtestid != existingAbTest
          );
          setAbTests([...filteredAbTests, payload]);
          setSnackBarOptions({
            open: true,
            message: 'Successfully created AB Test',
            error: false,
          });
          setSaving(false);
        })
        .catch((e) => {
          const errorMessages =
            e.response.data.errors &&
            e.response.data.errors.map((error) => error.message);
          setSaving(false);
          setSnackBarOptions({
            open: true,
            message: errorMessages.join('/n'),
            error: true,
          });
        });
    } else {
      axios
        .post(`${process.env.API_BASE_ADDRESS}/ab-tests/`, payload)
        .then((res) => {
          setAbTests([...abTests, res.data]);
          setSaving(false);
          setSnackBarOptions({
            open: true,
            message: 'Successfully created AB Test',
            error: false,
          });
        })
        .catch((e) => {
          const errorMessages =
            e.response.data.errors &&
            e.response.data.errors.map((error) => error.message);
          setSaving(false);
          setSnackBarOptions({
            open: true,
            message: errorMessages.join('/n'),
            error: true,
          });
        });
    }
  };

  const validationSchema = Yup.object().shape({
    label: Yup.string().required('A label is required.'),
    high: Yup.number()
      .integer('Percentage must be an integer.')
      .min(1, 'There should be at least 1 percent.')
      .required('The percentage is required'),
    slots: Yup.number()
      .integer('Slots must be an integer.')
      .min(1, 'There should be at least 1 slot.')
      .required('The number of slots are required.'),
  });

  const formik = useFormik({
    initialValues: {
      abtestid: '',
      label: '',
      low: 0,
      high: 0,
      slots: 0,
      placementid: 1,
      testingtype: 'randomizer',
      isactive: false,
    },
    validationSchema,
    onSubmit: (values) => {
      const modifiedValues = _.clone(values);
      modifiedValues.placementid = parseInt(modifiedValues.placementid);
      if (slots) {
        modifiedValues.slots = parseInt(modifiedValues.slots);
      } else {
        modifiedValues.slots = 0;
      }
      if (high) {
        modifiedValues.high = parseInt(modifiedValues.high);
      } else {
        modifiedValues.high = 0;
      }
      modifiedValues.offers = selectedOffers.map(({ offerid }) => ({
        offerid,
      }));
      handleSave(modifiedValues);
    },
  });
  const { high, placementid, testingtype, slots, isactive } = formik.values;

  const handleFetchAvailableOffers = async (placementid: number) => {
    try {
      const res = await axios.get(
        `${process.env.API_BASE_ADDRESS}/offer-manager/offers?abtests=true&placementid=${placementid}`
      );
      setAvailableOffers(
        res.data.map((offer: Offer) => ({
          ...offer,
          offerid: `${offer.offerid}`,
        }))
      );
    } catch (e) {
      console.log(e);
    }
  };

  React.useEffect(() => {
    axios
      .get(`${process.env.API_BASE_ADDRESS}/offer-manager/offer-groups`)
      .then((res) => {
        setGroups(res.data);
      })
      .catch((e) => {
        console.log('Errors:', e);
      });
    handleFetchAvailableOffers(placementid);
    if (existingAbTest) {
      axios
        .get(`${process.env.API_BASE_ADDRESS}/ab-tests/${existingAbTest}`)
        .then((res) => {
          const {
            offers,
            placementid,
            slots,
            testingtype,
            isactive,
            high,
            low,
            label,
            abtestid,
          } = res.data;
          formik.setValues({
            abtestid,
            placementid,
            slots,
            testingtype,
            isactive,
            high,
            low,
            label,
          });
          setSelectedOffers(offers);
        })
        .catch((e) => {
          console.log('Errors:', e);
        });
    }
  }, []);
  const filteredOffers = availableOffers.filter(
    ({ offerid, offergroupid, label, abtestid }) => {
      if (abtestid) return false;
      if (groupid) {
        if (offergroupid != groupid) return false;
      }
      if (search) {
        if (label && !label.toUpperCase().includes(search.toUpperCase()))
          return false;
      }
      if (selectedMap) {
        if (selectedMap[offerid]) return false;
      }
      return true;
    }
  );
  const onDragEnd = (res) => {
    const { destination, source } = res;
    if (!destination) return;
    const sourceIndex = source.index;
    const sourceId = source.droppableId;
    const destinationId = destination.droppableId;
    const destinationIndex = destination ? destination.index : sourceIndex;
    const newSelection = _.clone(selectedOffers);
    if (destinationId == 'selectedOffers') {
      if (sourceId == 'availableOffers') {
        const [removed] = filteredOffers.splice(sourceIndex, 1);
        newSelection.splice(destinationIndex, 0, removed);
      } else {
        const [removed] = newSelection.splice(sourceIndex, 1);
        newSelection.splice(destinationIndex, 0, removed);
      }
    } else if (destinationId == 'availableOffers') {
      newSelection.splice(sourceIndex, 1);
    }
    setSelectedOffers(newSelection);
  };

  const validationErrors = Object.values(formik.errors);

  return (
    <FormikProvider value={formik}>
      <Dialog
        onDismiss={() => {
          setShowCreateAbTest(false);
          setExistingAbTest('');
        }}
        showClose={true}
        onCloseClick={() => {
          setShowCreateAbTest(false);
          formik.resetForm();
        }}
        title={`${existingAbTest ? 'Edit' : 'Create'} A/B Test`}
        buttons={
          <>
            <Tooltip
              zIndex={999999999999999}
              title={
                validationErrors && validationErrors.length
                  ? validationErrors.map((error) => {
                    if (typeof error == 'string') return <div>{error}</div>;
                    if (error.length && _.isArray(error))
                      return (
                        <div>Please fill out all possible responses.</div>
                      );
                    return null;
                  })
                  : null
              }
            >
              <span>
                <Button
                  variant="contained"
                  onClick={() => {
                    formik.handleSubmit();
                    setShowCreateAbTest(false);
                  }}
                  disabled={saving || validationErrors.length > 0}
                >
                  {saving ? <CircularProgress size={20} /> : 'Save'}
                </Button>
              </span>
            </Tooltip>
            <Button
              onClick={() => {
                setShowCreateAbTest(false);
                formik.resetForm();
              }}
              variant="outlined"
            >
              Close
            </Button>
          </>
        }
      >
        <DragDropContext onDragEnd={onDragEnd}>
          <Grid.Row>
            <Grid.Col xs={6}>
              <Styled.SubHeaderClean>Step 1</Styled.SubHeaderClean>
              <Field
                label="A/B Test Name"
                name="label"
                placeholder="A/B Test 1"
                fullWidth
              />

              <ErrorMsg name="label" />
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col xs={6}>
              <Styled.SubHeader>Step 2</Styled.SubHeader>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            {!existingAbTest ? (
              <Grid.Col xs={4}>
                <Field
                  as="select"
                  label="Select Placement"
                  name="placementid"
                  value={placementid}
                  onChange={(e) => {
                    formik.handleChange(e);
                    handleFetchAvailableOffers(e.target.value);
                  }}
                  fullWidth
                >
                  <option value="1">Offer Wall</option>
                  <option value="2">Dashboard</option>
                  <option value="3">Klover+</option>
                  <option value="4">Onboarding</option>
                </Field>
              </Grid.Col>
            ) : null}
            <Grid.Col xs={2}>
              <Field
                as="select"
                label="Test Type"
                name="testingtype"
                value={testingtype}
                onChange={formik.handleChange}
                fullWidth
              >
                <option value="randomizer">Random</option>
              </Field>
            </Grid.Col>
            <Grid.Col xs={2}>
              <Field
                label="Percentage"
                name="high"
                value={high}
                min={0}
                max={100}
                onChange={(e) => {
                  e.preventDefault();
                  const { value } = e.target;
                  if (!value) {
                    formik.handleChange(e);
                  } else {
                    if (value >= 0 && value <= 100) {
                      formik.handleChange(e);
                      if (value.includes('.')) {
                        formik.setFieldValue(`high`, parseInt(value));
                      }
                    }
                  }
                }}
                type="number"
                placeholder="%"
                fullWidth
              />

              <ErrorMsg name="high" />
            </Grid.Col>
            <Grid.Col xs={2}>
              <Field
                label="Slots"
                name="slots"
                value={slots}
                min={0}
                max={100}
                onChange={(e) => {
                  e.preventDefault();
                  const { value } = e.target;
                  if (!value) {
                    formik.handleChange(e);
                  } else {
                    if (value >= 0 && value <= 100) {
                      formik.handleChange(e);
                      if (value.includes('.')) {
                        formik.setFieldValue(`slots`, parseInt(value));
                      }
                    }
                  }
                }}
                type="number"
                fullWidth
              />

              <ErrorMsg name="slots" />
            </Grid.Col>
            <Grid.Col>
              <Styled.Label>Status</Styled.Label>
              <Toggle
                checked={isactive}
                onClick={() => formik.setFieldValue('isactive', !isactive)}
              />
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col xs={6}>
              <Styled.SubHeader>Step 3</Styled.SubHeader>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col xs={6}>
              <Field
                as="select"
                label="Select Offer Group (optional)"
                name="group"
                value={groupid}
                onChange={(e) => setGroupid(e.target.value)}
                fullWidth
              >
                <option value="">All Offers</option>
                {groups && groups.length
                  ? groups.map(({ name, id }) => (
                    <option value={id} key={nanoid()}>
                      {name}
                    </option>
                  ))
                  : null}
              </Field>
            </Grid.Col>
            <Grid.Col xs={6}>
              <Field
                label="Search Offers"
                name="search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                placeholder={'e.g Calypso Beauty'}
                fullWidth
              />
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col xs={6}>
              <Styled.SubHeader>Available Offers</Styled.SubHeader>
              <Droppable droppableId="availableOffers">
                {(droppableProvided) => (
                  <Styled.OfferList ref={droppableProvided.innerRef}>
                    {filteredOffers.map((offer: Offer, index) => (
                      <Draggable
                        key={offer.offerid}
                        draggableId={offer.offerid}
                        index={index}
                      >
                        {(draggableProvided) => (
                          <Styled.OfferListItem
                            disabled={false}
                            selected={false}
                            key={index}
                            ref={draggableProvided.innerRef}
                            {...draggableProvided.draggableProps}
                            {...draggableProvided.dragHandleProps}
                          >
                            {offer.offerid ? `(ID: ${offer.offerid}) ` : ''}
                            {offer.label ? offer.label : ''}
                          </Styled.OfferListItem>
                        )}
                      </Draggable>
                    ))}
                  </Styled.OfferList>
                )}
              </Droppable>
            </Grid.Col>
            <Grid.Col xs={6}>
              <Styled.SubHeader>Selected Offers</Styled.SubHeader>
              <Droppable droppableId="selectedOffers">
                {(droppableProvided) => (
                  <Styled.OfferList ref={droppableProvided.innerRef}>
                    {selectedOffers.map((offer: Offer, index) => (
                      <Draggable
                        key={offer.offerid}
                        draggableId={offer.offerid}
                        index={index}
                      >
                        {(draggableProvided) => (
                          <Styled.OfferListItem
                            disabled={false}
                            selected={false}
                            key={index}
                            ref={draggableProvided.innerRef}
                            {...draggableProvided.draggableProps}
                            {...draggableProvided.dragHandleProps}
                          >
                            {offer.offerid ? `(ID: ${offer.offerid}) ` : ''}
                            {offer.label ? offer.label : ''}
                          </Styled.OfferListItem>
                        )}
                      </Draggable>
                    ))}
                  </Styled.OfferList>
                )}
              </Droppable>
            </Grid.Col>
          </Grid.Row>
        </DragDropContext>
      </Dialog>
    </FormikProvider>
  );
};

export default CreateAbTest;
