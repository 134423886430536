import CloseIcon from '@mui/icons-material/Close';
import Dialog from 'components/Dialog';
import Field from 'components/Field';
import FileInput from 'components/FileInput';
import React, { useState } from 'react';
import getAuthorizedAxiosInstance from 'utils/getAuthorizedAxiosInstance';
import { API_BASE_ADDRESS } from 'pages/audienceManager/variables';
import { AxiosResponse } from 'axios';
import { Button, IconButton } from '@klover/attain-design-system';
import { CircularProgress, Grid, LinearProgress } from '@mui/material';
import { CustomAudienceGroup } from '..';
import { FormikProvider, useFormik } from 'formik';
import { KDSPageNames } from 'constants/index';
import { useUser } from 'reactfire';
import * as Styled from './index.styles';
import Alert from '@mui/material/Alert';
import { validateCustomAudienceCsv } from '../../../helper/helper';

type formikVals = {
  audienceName: string;
  file: File | null;
};
interface Props {
  show: boolean;
  setShow: (bool: boolean) => void;
  selectedGroup: CustomAudienceGroup | null;
  reFetchAudiences: () => void;
}

const CreateCustomAudienceDialog = ({
  show,
  setShow,
  selectedGroup,
  reFetchAudiences,
}: Props) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const initialVals: formikVals = { audienceName: '', file: null };
  const { data: user } = useUser();
  const [loaded, setLoaded] = useState(0);
  const [fileSize, setFileSize] = useState(0);
  const [validationDetails, setValidationDetails] = useState(null);

  const handleClose = () => {
    formik.resetForm();
    setIsProcessing(false);
    setShow(false);
    setValidationDetails(null);
  };

  const formik = useFormik({
    initialValues: initialVals,
    onSubmit: async (values) => {
      handleSubmit(values);
    },
  });


  const handleFileSelected = (file: File) => {
    validateCustomAudienceCsv(file, setValidationDetails);
    formik.setFieldValue('file', file);
    setFileSize(file?.size || 0);
  };

  const handleSubmit = async (values: formikVals) => {
    try {
      setIsProcessing(true);
      if (values.file && values.audienceName) {
        const file = values.file;
        const audienceName = values.audienceName;
        const groupId = selectedGroup?.id.toString() || '';
        const email = user?.email || '';
        const formData = new FormData();
        formData.append('file', file);
        formData.append('name', audienceName);
        formData.append('email', email);
        formData.append('group_id', groupId);
        formData.append('enctype', 'multipart/form-data');
        const authorizedAxios = await getAuthorizedAxiosInstance();
        const config = {
          onUploadProgress: (progressEvent: ProgressEvent) => {
            setLoaded(progressEvent.loaded);
          },
        };
        if (authorizedAxios) {
          const res: AxiosResponse<any> = await authorizedAxios.post(
            `${API_BASE_ADDRESS}/${KDSPageNames.CUSTOMAUDIENCES}/audiences`,
            formData,
            config
          );
        }
        reFetchAudiences();
        handleClose();
        setValidationDetails(null);
      }
    } catch (e) {
      console.log('the error', e);
      handleClose();
      setValidationDetails(null);
    }
  };

  const { file, audienceName } = formik.values;
  return (
    <Dialog
      aria-label="create-custom-audience"
      aria-describedby="circular-process"
      aria-busy={isProcessing}
      onDismiss={handleClose}
      title="Create custom audience"
      isOpen={show}
      showClose
      onCloseClick={handleClose}
      buttons={
        <>
          <span>
            <Button
              variant="contained"
              type="submit"
              onClick={formik.handleSubmit}
              disabled={isProcessing || !file || !audienceName || (validationDetails && !validationDetails?.valid)}
            >
              {isProcessing ? (
                <>
                  <CircularProgress size={25} id="circular-process" />
                </>
              ) : (
                'Upload'
              )}
            </Button>
          </span>
          <Button
            onClick={handleClose}
            variant="outlined"
            disabled={isProcessing}
          >
            Cancel
          </Button>
        </>
      }
    >
      {isProcessing ? (
        <Grid container direction="column" rowGap={2}>
          <Grid item>
            <LinearProgress
              variant="determinate"
              value={(loaded * 100) / fileSize}
            />
          </Grid>
          <Grid item>{loaded < fileSize ? 'Uploading' : 'Processing'}</Grid>
        </Grid>
      ) : (
        <FormikProvider value={formik}>
          <Grid container direction="column" rowGap={3}>
            <Grid item>
              <Field
                name="audienceName"
                label="Custom Audience Name"
                fullWidth
                required
                disabled={isProcessing}
              ></Field>
            </Grid>
            <Grid item>
              <Grid item>
                <Styled.InformationalText>
                  Please remember to remove header before uploading.
                  </Styled.InformationalText>
              </Grid>
              <Grid
                container
                direction="row"
                columnGap={1}
                alignItems="flex-end"
              >
                <Grid item>
                  <Field
                    disabled={true}
                    value={
                      formik.values.file
                        ? formik.values.file.name
                        : 'No file selected'
                    }
                    name="file"
                    label="Custom audience Ids file"
                  ></Field>
                  </Grid>
                <Grid item>
                  {file ? (
                    <IconButton
                        onClick={() => {
                          formik.setFieldValue('file', null);
                          setValidationDetails(null)
                        }}
                    >
                      <CloseIcon />
                    </IconButton>
                  ) : (
                    <FileInput
                      setFile={handleFileSelected}
                      isDisabled={isProcessing}
                    />
                  )}
                </Grid>
                </Grid>
                {(validationDetails !== null && !validationDetails?.valid) &&
                  <Grid item style={{ marginTop: 16 }}>
                    <Alert variant="outlined" severity="error">
                      {validationDetails?.message}
                    </Alert>
                  </Grid>
                }
            </Grid>
          </Grid>
        </FormikProvider>
      )}
    </Dialog>
  );
};

export default CreateCustomAudienceDialog;
