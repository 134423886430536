import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useAppSelector } from 'redux/hooks';

const useStyles = makeStyles(() =>
  createStyles({
    requiredRow: {
      display: 'flex',
    },
    requiredRowEmoji: {
      paddingRight: '0.5em',
    },
    messageTextAlignment: {
      textAlign: 'left',
      padding: 10,
    },
  })
);

const checkMark = String.fromCodePoint(9989);
const xMark = String.fromCodePoint(10060);

const LaunchDealsDisabledButtonMessage = () => {
  const classes = useStyles();
  const originalDeal = useAppSelector(
    (state) => state.dealsReducer.originalDeal
  );
  return (
    <div className={classes.messageTextAlignment}>
      <div>Looks like you&apos;re trying to launch the deal</div>
      <br />
      <div>To launch make sure you have</div>
      <br />
      <div className={classes.requiredRow}>
        <div className={classes.requiredRowEmoji}>
          {originalDeal?.confirmed ? checkMark : xMark}
        </div>
        <div>Deal Confirmed</div>
      </div>
      <div className={classes.requiredRow}>
        <div className={classes.requiredRowEmoji}>
          {originalDeal?.partnerbuyercontact ? checkMark : xMark}
        </div>
        <div>Partner Buyer Contact</div>
      </div>
      <div className={classes.requiredRow}>
        <div className={classes.requiredRowEmoji}>
          {originalDeal?.buyercode ? checkMark : xMark}
        </div>
        <div>Buyer Selected</div>
      </div>
      <div className={classes.requiredRow}>
        <div className={classes.requiredRowEmoji}>
          {!originalDeal?.launchedat ? checkMark : xMark}
        </div>
        <div>Deal Unlaunched</div>
      </div>
      {/* <div>
        {'Make sure to '}
        <strong>SAVE</strong>
        {' your work to ensure that all checkboxes can be properly checked'}
      </div> */}
      <br />
    </div>
  );
};

export default LaunchDealsDisabledButtonMessage;
