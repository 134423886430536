import styled from 'styled-components';
import tokens from 'tokens';
import { Box, Paper } from '@mui/material';

export const OuterContainer = styled(Box)`
  margin: ${tokens.spacing.sm};
`;

export const InnerContainer = styled(Paper)`
  padding: ${tokens.spacing.md};
  border-radius: 10px;
  border: 1px solid ${tokens.colorGray20};
`;
