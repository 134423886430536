const historyDimensionConfig = {
  dimensionName: 'Job Status',
  showInEditor: true,
  showInCreation: false,
  inputs: [
    {
      stepperKeyName: 'history',
      stepperInputType: 'history',
      stepperInputLabel: 'Jobs',
      forCreation: false,
      forEditor: true,
    },
  ],
};

export default historyDimensionConfig;
