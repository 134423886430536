const lineItemDimensionConfig = {
  dimensionName: 'Select Line Items',
  showInEditor: true,
  showInCreation: true,
  inputs: [
    {
      stepperKeyName: 'lineitems',
      stepperInputType: 'lineitemSelector',
      stepperInputLabel: 'Line Item',
      helperText: '* An associated line item is required',
      forCreation: true,
      forEditor: true,
    },
  ],
};

export default lineItemDimensionConfig;
