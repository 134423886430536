import React from 'react';
import { Button, IconButton } from '@klover/attain-design-system';
import { useAppDispatch, useAppSelector } from 'redux/hooks';

// Material UI Components
import Box from '@material-ui/core/Box';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import tokens from 'tokens';
import {
  Theme,
  ThemeProvider,
  createStyles,
  createTheme,
  makeStyles,
} from '@material-ui/core/styles';
import { sendAgreement, setDealSendDialogOpen } from '../slice';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: theme.spacing(3),
    },
    // divider: {
    //   borderLeft: '0.2em solid #ccc',
    // },
    title: {
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 23,
      paddingLeft: 16,
      paddingTop: 16,
    },
  })
);

const dialog = createTheme({
  overrides: {
    MuiDialog: {
      paper: {
        borderRadius: 10,
      },
      paperWidthMd: {
        maxWidth: 1100,
      },
    },
    MuiMenu: {
      list: {
        padding: 0,
      },
    },
    MuiInputBase: {
      input: {
        borderRadius: 10,
        padding: '12px 14px',
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 10,
      },
    },
    MuiButton: {
      root: {
        borderRadius: 8,
      },
      outlinedPrimary: {
        color: tokens.colorButtonPrimary,
      },
      containedPrimary: {
        backgroundColor: tokens.colorButtonPrimary,
        '&:hover': {
          backgroundColor: tokens.colorButtonPrimary,
        },
      },
    },
  },
});

const DealSendDialog = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const open = useAppSelector((state) => state.dealsReducer.ui.dealSendDialog);
  const originalDeal = useAppSelector(
    (state) => state.dealsReducer.originalDeal
  );

  const dispatchClose = () => {
    dispatch(setDealSendDialogOpen(false));
  };

  const handleSend = () => {
    dispatch(sendAgreement(originalDeal?.dealid));
    dispatch(setDealSendDialogOpen(false));
  };

  const handleClose = () => {
    dispatchClose();
  };

  return (
    <>
      <ThemeProvider theme={dialog}>
        <Dialog
          className={classes.root}
          fullWidth={true}
          onClose={handleClose}
          open={open}
        >
          <Box m={1}>
            <Grid container alignItems="center">
              <Grid item xs={11}>
                <Typography className={classes.title}>
                  <strong>{originalDeal?.dealname}</strong>
                </Typography>
              </Grid>
              <Grid item container justifyContent="flex-end" xs={1}>
                <IconButton onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Box>
          <DialogContent style={{ padding: '24px 32px 0px 32px' }}>
            <DialogContentText
              style={{
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: 16,
              }}
            >
              Are you sure you want to resend the deal agreement for{' '}
              <strong>{originalDeal?.dealname}</strong> to{' '}
              <strong>{originalDeal?.partnerbuyercontact}</strong>
            </DialogContentText>
          </DialogContent>
          <Grid container spacing={0} style={{ marginTop: 50 }}>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Grid>
          <Box m={1}>
            <DialogActions>
              <Button color="primary" onClick={handleClose} variant="outlined">
                Cancel
              </Button>
              <Button
                color="primary"
                disableElevation
                onClick={handleSend}
                variant="contained"
              >
                SEND AGREEMENT
              </Button>
            </DialogActions>
          </Box>
        </Dialog>
      </ThemeProvider>
    </>
  );
};

export default DealSendDialog;
