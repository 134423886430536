export const CSV_FILE_FORMAT_ERROR =
  'The CSV file should contain a single column with no header, and include only valid audience IDs';
export const VALID_FILE = 'Valid file';
export const ERROR_PROCESSING_FILE = 'Error occurred during file processing';
export const IN_PROGRESS = 'In Progress';
export const COPY_POPOVER_TITLE = 'Click to copy and view text';
export const IMPORTING_FILE = 'Importing file';
export const SUCCESS_FILE = 'Import Complete';
export const TARGETING_TESTING = 'Targeting Testing';
export const USER_ID_EMAIL = "User ID/Email";
export const CLEAR_FILTERS = "Clear Filters";
export const TEST_TARGETING = "Test Targeting";
