import LogRocket from 'logrocket';
import React from 'react';
import UserIcon from 'assets/images/account-circle.svg';
import tokens from 'tokens';
import { ILoginReducer } from 'types/types';
import { Link } from 'react-router-dom';
import {
  Menu,
  MenuItem,
  ThemeProvider,
  createStyles,
  createTheme,
  makeStyles,
} from '@material-ui/core';
import {
  Offer,
  setZipCount,
  setZipsFileUploadingState,
} from 'pages/offerWallManager/slice';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { addNotification } from 'pages/audienceManager/slice';
import {
  deleteNotification,
  fetchNotifications,
} from 'pages/notifications/slice';
import { logOutAction } from 'pages/auth/actions';
import { useUser } from 'reactfire';

import { DefaultPaginationValues, FsCollections } from 'constants/index';

import {
  DocumentData,
  QuerySnapshot,
  collection,
  onSnapshot,
} from 'firebase/firestore';
import { fs } from 'utils/firebase';

const useStyles = makeStyles(() =>
  createStyles({
    profile: {
      color: tokens.colorBodynegative,
      cursor: 'pointer',
      fontSize: 12,
    },
    user: {
      marginLeft: tokens.spacing.sm,
      maxWidth: 150,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: 'inline-block',
      color: tokens.colorBodynegative,
      lineHeight: '12px',
    },
  })
);

const menu = createTheme({
  overrides: {
    MuiMenu: {
      list: {
        padding: 0,
      },
    },
  },
});

const Profile: React.FC = () => {
  const dispatch = useDispatch();
  const { data: user } = useUser();
  const currentUser = user || { email: '' };
  const classes = useStyles();

  // Actions
  const logOut = () => dispatch(logOutAction());

  // Reducers
  const authData: ILoginReducer = useSelector(
    (state: RootStateOrAny) => state.loginReducer
  );
  const currentOfferId: string | undefined = useSelector(
    (state: RootStateOrAny) => state.offerWallReducer.currentOffer?.offerid
  );
  const handleSignOut = () => {
    if (authData.data.res.uid) {
      logOut();
    }
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // load notifications on first open and set logRocket user
  React.useEffect(() => {
    LogRocket.identify(user.uid, {
      email: currentUser.email,
    });
    dispatch(
      fetchNotifications({
        currentNotifications: [],
        queryLimit: DefaultPaginationValues.limit,
        filterRead: false,
        filterEmail: false,
        userEmail: currentUser.email,
        loadMore: false,
      })
    );
  }, []);

  const onToasterSnapshot = (snapshot: QuerySnapshot<DocumentData>) => {
    const data = snapshot.docChanges()[0]?.doc.data();
    const id = snapshot.docChanges()[0]?.doc.id;
    const type = snapshot.docChanges()[0]?.type;
    if (type === 'added') {
      if (currentUser.email === data.email) {
        dispatch(
          addNotification({
            state: data.state.toLowerCase(),
            message: data.message,
          })
        );
      }
    }
    dispatch(deleteNotification(id));
  };

  const onNotificationsSnapshot = (snapshot: QuerySnapshot<DocumentData>) => {
    const data = snapshot.docChanges()[0]?.doc.data();
    const type = snapshot.docChanges()[0]?.type;
    if (type === 'added') {
      if (Object.hasOwn(data.refObj, 'zipsTotal')) {
        dispatch(setZipsFileUploadingState(data.state));
        dispatch(setZipCount(data.refObj.zipsTotal));
      }
    }
  };

  // firestore listeners
  React.useEffect(() => {
    const notificationsRef = collection(fs, FsCollections.NOTIFICATIONS);
    const toastersRef = collection(fs, FsCollections.TOASTERS);
    const unsubToasters = onSnapshot(toastersRef, onToasterSnapshot);
    const unsubNotifications = onSnapshot(
      notificationsRef,
      onNotificationsSnapshot
    );
    return () => {
      unsubToasters();
      unsubNotifications();
    };
  }, []);

  return (
    <div>
      <Link onClick={handleClick} to="#">
        <img src={UserIcon} alt="" />
        <span className={classes.user}>{currentUser.email}</span>
      </Link>

      <ThemeProvider theme={menu}>
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          style={{ padding: 0 }}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <MenuItem onClick={handleSignOut}>Sign out</MenuItem>
        </Menu>
      </ThemeProvider>
    </div>
  );
};

export default Profile;
