import DateFnsUtils from '@date-io/date-fns';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import _ from 'lodash';
import { Button, IconButton, Select } from '@klover/attain-design-system';
import { UploadStates } from 'constants/index';
import {
  addNotification,
  clearChanges,
  createOffer,
  saveOffer,
  toggleOfferDimensionDialog,
  updateCurrentOffer,
  updateCurrentOfferProperty,
  uploadZipsFile,
} from '../slice';
import { getEpochTime } from '../../../helper/helper';
import { nanoid } from 'nanoid';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { useUser } from 'reactfire';
import * as Styled from './index.styles';
import 'date-fns';

// Material UI Components
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Box from '@material-ui/core/Box';
import ClearIcon from '@material-ui/icons/Clear';
import CloseIcon from '@material-ui/icons/Close';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import WarningIcon from '@mui/icons-material/WarningAmberOutlined';

import Dialog from '@material-ui/core/Dialog';
import Divider from '@material-ui/core/Divider';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import InputLabel from '@material-ui/core/InputLabel';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import {
  Theme,
  ThemeProvider,
  createStyles,
  createTheme,
  makeStyles,
  withStyles,
} from '@material-ui/core/styles';

// all options for the select components
import AudienceSelector from '../audienceSelector';
import BootstrapTooltip from 'components/bootstrapTooltip';
import DeleteZipsDialog from '../deleteZipsDialog';
import SaveOfferDisabledButtonMessage, {
  OfferAllProperties,
} from '../saveDisabledButtonMessage';
import allOptions from '../offerOptions';
import setSaveOfferButton, {
  validateGoalIDs,
} from '../utils/setSaveOfferButton';
import validateOfferInputs, {
  validateOfferRequiredFields,
  validateStartEndDate,
} from '../utils/validateOfferInputs';
import { ValidationErrorMessages } from '../../../constants';

//components
import DeleteZipsType from './DeleteZipsType';
import FileInputType from './FileInputType';
import tokens from 'tokens';
import isOfferDisabled from 'utils/isOfferDisabled';

const checkMark = String.fromCodePoint(9989);
const xMark = String.fromCodePoint(10060);

const {
  FUTURE_START_DATE_ACTIVE_OFFER,
  END_DATE_EARLIER_THAN_START_DATE,
  CURRENT_DATE_NOT_IN_RANGE,
  START_DATE_SHOULD_BE_IN_FUTURE,
} = ValidationErrorMessages;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    alignInputs: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    alignItems: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    buttonFormat: {
      margin: '0em 0.5em',
    },
    formControl: {
      minWidth: 450,
    },
    close: {
      height: 24,
      width: 24,
    },
    columnTitles: {
      color: 'grey',
      display: 'flex',
      fontSize: 10,
      padding: 10,
      paddingBottom: 0,
      marginLeft: 24,
    },
    columnNameAndValue: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      fontSize: 10,
      padding: 10,
      marginLeft: 24,
    },
    columnNameAndValueForUrlArray: {
      display: 'flex',
      alignItems: 'top',
      fontSize: 10,
      padding: 10,
      marginLeft: 24,
    },
    divider: {
      borderLeft: '3px solid ' + tokens.colorDividerBorder,
      paddingLeft: 5,
      flexGrow: 1,
    },
    header: {
      padding: '10px 12px 10px 24px',
    },
    icon: {
      height: 24,
      width: 24,
    },
    inputColumn: {
      display: 'flex',
      flexDirection: 'column',
    },
    inputColumnSpacing: {
      paddingTop: 10,
    },
    menuItem: {
      backgroundColor: tokens.colorDefault,
    },
    moreVertIcon: {
      position: 'absolute',
      marginTop: -13,
      right: 55,
    },
    operatorControl: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      minWidth: 50,
    },
    spinner: {
      marginTop: 9,
      color: tokens.colorSpinnerCircular,
    },
    tags: {
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 14,
    },
    timeframe: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
      maxHeight: 48,
      minWidth: 120,
      marginBottom: 28,
    },
    titleForUrlArray: {
      paddingTop: 15,
    },
    textInput: {
      paddingBottom: 15,
      width: 450,
    },
    textInputAndMoreVertIcons: {
      display: 'flex',
      alignItems: 'center',
    },
    root: {
      margin: theme.spacing(3),
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      marginLeft: 107,
      height: 22,
      marginBottom: 5,
    },
    dividerWithText: {
      padding: '5px 0px 0px 0px',
      width: '30vw',
    },
    deleteButton: {
      marginTop: -6,
      marginLeft: 4,
      padding: 4,
    },
    lineDivider: {
      margin: '1% 3%',
    },
    tuneGoalWrapper: {
      border: '1px solid lightgray',
      paddingTop: '15px',
      margin: '10px',
      borderRadius: '25px',
    },
  })
);

const dialog = createTheme({
  overrides: {
    MuiDialog: {
      paper: {
        borderRadius: 10,
        overflowX: 'hidden',
      },
      paperWidthMd: {
        maxWidth: 1100,
      },
    },
    MuiMenu: {
      list: {
        padding: 0,
      },
    },
    MuiInputBase: {
      input: {
        borderRadius: 10,
        padding: '12px 14px',
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 10,
      },
    },
    MuiButton: {
      root: {
        borderRadius: 8,
      },
      outlinedPrimary: {
        color: tokens.colorButtonPrimary,
      },
      containedPrimary: {
        backgroundColor: tokens.colorButtonPrimary,
        '&:hover': {
          backgroundColor: tokens.colorButtonPrimary,
        },
      },
    },
  },
});

const icon = createTheme({
  overrides: {
    MuiSvgIcon: {
      root: {
        width: 32,
        height: 32,
      },
    },
  },
});

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: tokens.colorButtonSuccess,
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: tokens.colorButtonSuccess,
      border: '6px solid ' + tokens.colorButtonDefault,
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(
  ({ classes, keyName, disabled = false, disabledTooltip = '', ...props }) => {
    const dispatch = useAppDispatch();
    const currentOffer = useAppSelector(
      (state) => state.offerWallReducer.currentOffer
    );
    const handleActivateOffer = () => {
      let updatedOffer = {
        ...currentOffer,
        [keyName]: currentOffer[keyName] ? 0 : 1,
      };

      const offertunegoals = [];
      if (
        updatedOffer.multipleconversionsenabled &&
        updatedOffer?.offertunegoals?.length === 0
      ) {
        offertunegoals.push({
          goalid: 0,
          pointsawarded: undefined,
          status: 'insert',
          isconversioncapactive: false,
          conversiontimerange: undefined,
          conversioncapvalue: undefined,
          currentconversionvalue: undefined,
        });
      }

      updatedOffer = { ...updatedOffer, offertunegoals };

      dispatch(
        updateCurrentOfferProperty({ updatedOffer, propertyName: keyName })
      );
    };
    return (
      <Tooltip title={disabled ? disabledTooltip : ''} placement="right">
        <span>
          <Switch
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            classes={{
              root: classes.root,
              switchBase: classes.switchBase,
              thumb: classes.thumb,
              track: classes.track,
              checked: classes.checked,
            }}
            onClick={handleActivateOffer}
            checked={currentOffer[keyName] ? true : false}
            disabled={disabled}
            {...props}
          />
        </span>
      </Tooltip>
    );
  }
);

const FlightDates = ({ isValidEndDate, isValidStartDate }) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const currentOffer = useAppSelector(
    (state) => state.offerWallReducer.currentOffer
  );
  const handleUpdateOffer = (date, keyName) => {
    const updatedOffer = {
      ...currentOffer,
      [keyName]: Date.parse(date),
    };
    dispatch(
      updateCurrentOfferProperty({ updatedOffer, propertyName: keyName })
    );
  };

  const clearDatesHandler = () => {
    const updatedOffer = {
      ...currentOffer,
      enddate: null,
      startdate: null,
    };
    dispatch(updateCurrentOffer(updatedOffer));
  };

  const today = Date.now();
  const tomorrow = new Date().setDate(new Date().getDate() + 1);

  return (
    <div>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DatePicker
          id="date-picker-start"
          className={classes.timeframe}
          label={'Start'}
          format="MM/dd/yyyy"
          value={currentOffer?.startdate || null}
          onChange={(date) => handleUpdateOffer(date, 'startdate')}
          maxDate={currentOffer?.isactive ? today : new Date('2100-01-01')}
          helperText={
            isValidStartDate
              ? ''
              : currentOffer?.isactive
              ? FUTURE_START_DATE_ACTIVE_OFFER
              : START_DATE_SHOULD_BE_IN_FUTURE
          }
          {...(!currentOffer?.isactive && { minDate: tomorrow })}
        />
        <DatePicker
          id="date-picker-end"
          minDate={
            currentOffer?.startdate && currentOffer?.startdate > today
              ? currentOffer?.startdate
              : today
          }
          className={classes.timeframe}
          label={'End'}
          format="MM/dd/yyyy"
          value={currentOffer?.enddate || null}
          onChange={(date) => handleUpdateOffer(date, 'enddate')}
          helperText={isValidEndDate ? '' : END_DATE_EARLIER_THAN_START_DATE}
        />
      </MuiPickersUtilsProvider>
      <IconButton
        size="small"
        onClick={clearDatesHandler}
        style={{ marginTop: '30px' }}
      >
        <ClearIcon />
      </IconButton>
    </div>
  );
};

const MoreVertMenu = ({
  index,
  keyName,
}: {
  index: number;
  keyName: string;
}) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const currentOffer = useAppSelector(
    (state) => state.offerWallReducer.currentOffer
  );
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleDelete = (index) => {
    let cloneOfArrayOfUrls = currentOffer[keyName].slice();
    cloneOfArrayOfUrls.splice(index, 1);

    if (cloneOfArrayOfUrls.length === 0) {
      cloneOfArrayOfUrls = [null];
    }

    const updatedOffer = {
      ...currentOffer,
      [keyName]: cloneOfArrayOfUrls,
    };
    dispatch(
      updateCurrentOfferProperty({ updatedOffer, propertyName: keyName })
    );
    setAnchorEl(null);
  };
  return (
    <Grid className={classes.moreVertIcon}>
      <IconButton size="small" onClick={handleClick} style={{ marginLeft: 16 }}>
        <MoreVertIcon className={classes.icon} />
      </IconButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={() => handleDelete(index)}>
          <ListItemIcon>
            <DeleteOutlineIcon fontSize="small" className={classes.icon} />
          </ListItemIcon>
          Delete
        </MenuItem>
      </Menu>
    </Grid>
  );
};

const MultipleUrlEditor = ({
  keyName,
  inputLabel,
}: {
  keyName: string;
  inputLabel: string;
}) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const currentOffer = useAppSelector(
    (state) => state.offerWallReducer.currentOffer
  );
  const arrayOfUrls = currentOffer[keyName];
  const handleUpdateOffer = (value, i) => {
    const cloneOfArrayOfUrls = arrayOfUrls.slice();
    // assigning the array at that index to the value
    cloneOfArrayOfUrls[i] = value;
    // assigning the array to the successurl property
    const updatedOffer = {
      ...currentOffer,
      [keyName]: cloneOfArrayOfUrls,
    };
    dispatch(
      updateCurrentOfferProperty({ updatedOffer, propertyName: keyName })
    );
  };
  const handleAddNewInput = () => {
    const cloneOfArrayOfUrls = arrayOfUrls.slice();
    cloneOfArrayOfUrls.push(null);
    const updatedOffer = {
      ...currentOffer,
      [keyName]: cloneOfArrayOfUrls,
    };
    dispatch(
      updateCurrentOfferProperty({ updatedOffer, propertyName: keyName })
    );
  };

  return (
    <Grid className={classes.alignInputs} key={nanoid()}>
      <Grid className={classes.alignItems}>
        {arrayOfUrls.map((url, i) => {
          return (
            <Grid className={classes.textInputAndMoreVertIcons} key={nanoid()}>
              <Grid>
                <TextField
                  key={nanoid()}
                  variant="outlined"
                  label={`${inputLabel.slice(0, inputLabel.length - 1)} ${
                    i + 1
                  }`}
                  onBlur={(e) => {
                    handleUpdateOffer(e.target.value.trim(), i);
                  }}
                  onKeyPress={(e) => {
                    if (e.code === 'Enter') {
                      if (e.target.value.length) {
                        handleUpdateOffer(e.target.value, i);
                      }
                    }
                  }}
                  defaultValue={url}
                  className={classes.textInput}
                />
              </Grid>
              <MoreVertMenu index={i} keyName={keyName} />
            </Grid>
          );
        })}
      </Grid>
      <Grid>
        <IconButton onClick={handleAddNewInput}>
          <AddCircleIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};

/**
 * Functional component to display validation message for offer start and end dates.
 * @param {currentOffer} - Represet Current Offer and optional flag to skip validation.
 * @returns JSX element representing the validation message.
 */
export const OfferStartEndValidationMessage = (props) => {
  const { currentOffer } = props || {};

  const isValidDate = validateStartEndDate(currentOffer);
  const isValidOffer = !isOfferDisabled(currentOffer);
  const isMessageShow = !currentOffer.isactive && isValidDate;

  if (isMessageShow) {
    // Determine message content based on validation status
    const Icon = isValidOffer ? InfoIcon : WarningIcon;
    const messageContent = (
      <>
        <span>
          {isValidOffer ? checkMark : xMark} Offer is{' '}
          <b>{isValidOffer ? 'VALID' : 'INVALID'} </b> - it will{' '}
          {!isValidOffer && 'not'} start and end as expected
        </span>
        {'    '}
        <BootstrapTooltip title={<OfferAllProperties />}>
          <Icon
            style={{
              color: isValidOffer ? 'green' : 'red',
              padding: '2px',
              marginTop: '-1px',
            }}
          />
        </BootstrapTooltip>
      </>
    );

    return (
      <span style={{ fontSize: '16px', display: 'flex' }}>
        {messageContent}
      </span>
    );
  }

  return null;
};

const InputType = ({
  inputLabel,
  inputType,
  keyName,
  isValidEndDate,
  isValidStartDate,
}: any) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [helperText, setHelperText] = useState('');
  const [isInputValid, setIsInputValid] = useState(true);

  const currentOffer = useAppSelector(
    (state) => state.offerWallReducer.currentOffer
  );
  useEffect(() => {
    if (keyName === 'zips' || keyName === 'states') {
      const returnObj = validateOfferInputs(currentOffer[keyName], keyName);
      setIsInputValid(returnObj.isValid);
      setHelperText(returnObj.helperText);
    } else if (
      ['mediarate', 'cap', 'points', 'pointsawarded'].includes(keyName)
    ) {
      const returnObj = validateOfferInputs(currentOffer[keyName], keyName);
      setIsInputValid(returnObj.isValid);
      setHelperText(returnObj.helperText);
    }
  }, [currentOffer]);

  const handleUpdateOffer = (value) => {
    let updatedOffer = {
      ...currentOffer,
      [keyName]: value,
    };
    if (keyName === 'ctaurl') {
      const splitUrl = value.split('?');
      const queryString = `?${splitUrl[1]}`;
      const urlParams = new URLSearchParams(queryString);
      const tuneofferid = urlParams.get('offer_id');
      if (tuneofferid) {
        updatedOffer = {
          ...updatedOffer,
          tuneofferid,
        };
      }
    }
    if (keyName === 'mediarate' && value === '') {
      updatedOffer = {
        ...updatedOffer,
        mediarate: null,
      };
    }
    dispatch(
      updateCurrentOfferProperty({ updatedOffer, propertyName: keyName })
    );
  };

  function areEpochTimesOnSameDate(epochTime1, epochTime2, epochTime3) {
    const date1 = new Date(epochTime1);
    const date2 = new Date(epochTime2);
    const date3 = new Date(epochTime3);
    const isSameYear =
      date1.getUTCFullYear() === date2.getUTCFullYear() &&
      date2.getUTCFullYear() === date3.getUTCFullYear();
    const isSameMonth =
      date1.getUTCMonth() === date2.getUTCMonth() &&
      date2.getUTCMonth() === date3.getUTCMonth();
    const isSameDay =
      date1.getUTCDate() === date2.getUTCDate() &&
      date2.getUTCDate() === date3.getUTCDate();

    return isSameYear && isSameMonth && isSameDay;
  }

  const disableIsActive = useMemo(() => {
    const startdate = currentOffer?.startdate;
    const enddate = currentOffer?.enddate;
    const currentDate = getEpochTime(Date.now(), 6, 0, 0, 0);
    if (startdate === null || enddate === null) {
      return false;
    }
    if (areEpochTimesOnSameDate(startdate, enddate, currentDate)) {
      return false;
    }
    if (startdate === undefined || enddate === undefined) {
      return false;
    }
    if (
      currentDate >= getEpochTime(startdate, 0, 0, 0, 0) &&
      currentDate <= getEpochTime(enddate, 23, 59, 59, 999)
    ) {
      return false;
    }
    return true;
  }, [currentOffer]);

  switch (inputType) {
    case 'audienceSelector':
      return <AudienceSelector />;
    case 'flightpicker':
      return (
        <>
          <FlightDates
            isValidEndDate={isValidEndDate}
            isValidStartDate={isValidStartDate}
          />
          <OfferStartEndValidationMessage currentOffer={currentOffer} />
        </>
      );
    case 'select':
      return (
        <FormControl
          color="primary"
          variant="outlined"
          className={classes.formControl}
        >
          <Select
            label={inputLabel}
            onChange={(e) => handleUpdateOffer(e.target.value)}
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
            }}
            value={currentOffer[keyName] || ''}
          >
            {allOptions[keyName]
              ? Object.keys(allOptions[keyName]).map((x) => {
                  return (
                    <MenuItem
                      key={nanoid()}
                      style={{ backgroundColor: 'white' }}
                      value={x}
                    >
                      {_.startCase(allOptions[keyName][x])}
                    </MenuItem>
                  );
                })
              : null}
          </Select>
        </FormControl>
      );
    case 'switch':
      return (
        <IOSSwitch
          keyName={keyName}
          disabled={disableIsActive}
          disabledTooltip={CURRENT_DATE_NOT_IN_RANGE}
        />
      );
    case 'textfield':
      return (
        <TextField
          variant="outlined"
          label={inputLabel}
          onBlur={(e) => handleUpdateOffer(e.target.value.trim())}
          defaultValue={currentOffer[keyName]}
          className={classes.textInput}
          required={validateOfferRequiredFields(keyName, currentOffer)}
        />
      );
    case 'textblock':
      return (
        <TextField
          error={!isInputValid}
          helperText={helperText}
          variant="outlined"
          label={inputLabel}
          multiline
          rows={4}
          onBlur={(e) => handleUpdateOffer(e.target.value.trim())}
          defaultValue={currentOffer[keyName]}
          className={classes.textInput}
          required={validateOfferRequiredFields(keyName, currentOffer)}
        />
      );
    case 'urlarray':
      return <MultipleUrlEditor keyName={keyName} inputLabel={inputLabel} />;
    case 'number':
      return (
        <TextField
          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
          variant="outlined"
          label={inputLabel}
          onBlur={(e) => handleUpdateOffer(e.target.value.trim())}
          defaultValue={currentOffer[keyName]}
          className={classes.textInput}
          error={!isInputValid}
          helperText={helperText}
          required={validateOfferRequiredFields(keyName, currentOffer)}
        />
      );
    default:
      return null;
  }
};

const OfferDimensionDialog = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const { data: user } = useUser();
  const currentUser = user || { email: '' };

  const open = useAppSelector(
    (state) => state.offerWallReducer.ui.offerDimensionDialog
  );
  const currentGroup = useAppSelector(
    (state) => state.offerWallReducer.currentGroup
  );
  const currentOffer = useAppSelector(
    (state) => state.offerWallReducer.currentOffer
  );
  const currentOfferDimension = useAppSelector(
    (state) => state.offerWallReducer.currentOfferDimension
  );
  const zipStatus = useAppSelector(
    (state) => state.offerWallReducer.currentOffer?.zip_upload_status
  );

  const originalOffer = useAppSelector(
    (state) => state.offerWallReducer.originalOffer
  );

  const [isOpen, setOpenDialog] = useState(false);

  const zipCount = currentOffer?.zipCount;

  const saveOfferButtonSettings = setSaveOfferButton(currentOffer);
  const { isDisabled, isValidStartDate, isValidEndDate } =
    saveOfferButtonSettings;
  let { SaveTitle = <SaveOfferDisabledButtonMessage /> } =
    saveOfferButtonSettings;
  SaveTitle =
    SaveTitle === null ? <SaveOfferDisabledButtonMessage /> : SaveTitle;

  const currentInputs = currentOfferDimension
    ? _.flatten(
        currentOfferDimension.inputs.map((x) => {
          if (x.multipleDimensions) return x.multipleDimensions;
          return x.key;
        })
      )
    : [];

  const [file, setFile] = useState(null);

  const handleClose = () => {
    dispatch(toggleOfferDimensionDialog(false));
  };

  const handleSave = () => {
    const updatedOffer = {
      ...currentOffer,
    };
    if (file) {
      const data = {
        file: file,
        offerId: updatedOffer.offerid,
        userEmail: currentUser.email,
      };
      dispatch(uploadZipsFile(data));
    }
    if (originalOffer) {
      dispatch(saveOffer(updatedOffer));
    } else {
      if (currentGroup) {
        updatedOffer.offergroupid = currentGroup.id;
        dispatch(createOffer({ updatedOffer, currentGroup }));
      } else {
        dispatch(
          addNotification({
            state: 'error',
            message: `No current group.`,
          })
        );
      }
    }
    handleClose();
  };

  const handleClearChanges = () => {
    dispatch(clearChanges(currentInputs));
  };

  /**
   * Switch UI component for Offer Tune Goal
   */
  const TuneGoalSwitch = withStyles((theme) => ({
    root: {
      width: 42,
      height: 26,
      padding: 0,
    },
    switchBase: {
      padding: 1,
      '&$checked': {
        transform: 'translateX(16px)',
        color: theme.palette.common.white,
        '& + $track': {
          backgroundColor: tokens.colorButtonSuccess,
          opacity: 1,
          border: 'none',
        },
      },
      '&$focusVisible $thumb': {
        color: tokens.colorButtonSuccess,
        border: '6px solid ' + tokens.colorButtonDefault,
      },
    },
    thumb: {
      width: 24,
      height: 24,
    },
    track: {
      borderRadius: 26 / 2,
      border: `1px solid ${theme.palette.grey[400]}`,
      backgroundColor: theme.palette.grey[50],
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
  }))(({ classes, checked, onChangeHandler }) => {
    return (
      <Switch
        disableRipple
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked,
        }}
        onChange={onChangeHandler}
        checked={checked}
      />
    );
  });

  const TuneGoals = ({ input }) => {
    const classes = useStyles();
    const tuneGoals = currentOffer && currentOffer[input.key];

    const handleUpdateOffer = (value, key, i) => {
      const offertunegoals = JSON.parse(
        JSON.stringify(currentOffer[input.key])
      );
      const currentItem = offertunegoals[i];
      if (currentItem?.status !== 'insert') {
        currentItem.status = 'update';
        if (key === 'goalid' && currentItem.originalGoalId === undefined) {
          currentItem.originalGoalId = currentItem[key];
        }
      }

      if (
        key === 'conversiontimerange' &&
        !currentItem.oldconversiontimerange //Holds Old value of oldconversiontimerange
      ) {
        currentItem.oldconversiontimerange = currentItem.conversiontimerange;
      }

      currentItem[key] = value;
      if (
        key === 'isconversioncapactive' &&
        value &&
        !currentItem.conversiontimerange
      ) {
        currentItem.conversiontimerange = 4;
      }

      const updatedOffer = { ...currentOffer, offertunegoals };
      dispatch(
        updateCurrentOfferProperty({
          updatedOffer,
        })
      );
    };

    const handleDelete = (index: number) => {
      const offertunegoals =
        JSON.parse(JSON.stringify(currentOffer[input.key])) || [];
      const currentItem = offertunegoals[index];
      if (currentItem?.status === 'insert') {
        offertunegoals.splice(index, 1);
      }
      currentItem['status'] = 'delete';
      const updatedOffer = { ...currentOffer, offertunegoals };
      dispatch(
        updateCurrentOfferProperty({
          updatedOffer,
        })
      );
    };

    const handleAddNewInput = () => {
      const offertunegoals = currentOffer[input.key]
        ? JSON.parse(JSON.stringify(currentOffer[input.key]))
        : [];
      offertunegoals.push({
        goalid: undefined,
        pointsawarded: undefined,
        status: 'insert',
        isconversioncapactive: false,
        conversiontimerange: undefined,
        conversioncapvalue: undefined,
        currentconversionvalue: undefined,
      });
      const updatedOffer = { ...currentOffer, offertunegoals };
      dispatch(
        updateCurrentOfferProperty({
          updatedOffer,
        })
      );
    };

    /**
     * Responsible to render component based on offer goal's key.
     * @param goal : goal object
     * @param item : current UI object respect to goal object
     * @param inputName : label to textbox
     * @param i : current item index
     * @returns : Lib component renders
     */
    const goalComponents = (goal, item, inputName, i) => {
      const { key, inputType, inputLabel } = item;
      let isValidInput = true;
      let helperText = '';
      if (['pointsawarded', 'goalid', 'conversioncapvalue'].includes(key)) {
        const returnObj = validateOfferInputs(goal[key], key);
        isValidInput = returnObj?.isValid;
        helperText = returnObj?.helperText;
      }

      const tuneGoalSwitchHandler = (e) => {
        handleUpdateOffer(e.target.checked, key, i);
      };

      switch (key) {
        case 'goalid':
        case 'pointsawarded':
        case 'conversioncapvalue': {
          return (
            <TextField
              variant="outlined"
              label={inputName}
              onBlur={(e) => handleUpdateOffer(e.target.value.trim(), key, i)}
              defaultValue={goal[key]}
              className={classes.textInput}
              error={!isValidInput}
              helperText={helperText}
              required={validateOfferRequiredFields(key, currentOffer)}
            />
          );
        }
        case 'conversiontimerange': {
          return (
            <FormControl
              color="primary"
              variant="outlined"
              className={classes.formControl}
            >
              <Select
                label={inputLabel}
                onChange={(e) =>
                  handleUpdateOffer(e.target.value.trim(), key, i)
                }
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                  getContentAnchorEl: null,
                }}
                value={goal[key]}
              >
                {allOptions[key]
                  ? Object.keys(allOptions[key]).map((x) => {
                      return (
                        <MenuItem
                          key={nanoid()}
                          style={{ backgroundColor: 'white' }}
                          value={x}
                        >
                          {_.startCase(allOptions[key][x])}
                        </MenuItem>
                      );
                    })
                  : null}
              </Select>
            </FormControl>
          );
        }
        case 'isconversioncapactive': {
          return (
            <TuneGoalSwitch
              onChangeHandler={tuneGoalSwitchHandler}
              checked={goal[key] ? true : false}
            />
          );
        }
        default:
          return null;
      }
    };

    const multiGoals =
      tuneGoals
        ?.map((goal, i: number) => {
          if (goal.status === 'delete') return null;
          const index = i + 1;

          const childs = input?.child.map((item, j) => {
            const { key, alternativeLabel } = item;

            let inputName = alternativeLabel
              ? alternativeLabel
              : item.inputLabel;
            inputName =
              inputName.replace('Awardfor', 'Award For') + ` ${index}`;

            if (
              ['conversioncapvalue', 'conversiontimerange'].includes(key) &&
              !goal.isconversioncapactive
            ) {
              return null;
            }

            return (
              <>
                <Grid
                  key={`goal_${index}_${key}`}
                  className={classes.columnNameAndValueForUrlArray}
                >
                  <Grid item xs={6} className={classes.titleForUrlArray}>
                    <div>
                      <span className="MuiTypography-body1">
                        {inputName}
                        <RequiredField offer={currentOffer} field={key} />
                      </span>
                      {key === 'conversiontimerange' && (
                        <BootstrapTooltip
                          title={
                            <span>
                              All times are based on CST (UTC-5) or CDT (UTC-6)
                            </span>
                          }
                        >
                          <IconButton size="small" style={{ left: '5px' }}>
                            <HelpOutlineIcon
                              style={{
                                fontSize: '18px',
                                color: 'lightgrey',
                              }}
                            />
                          </IconButton>
                        </BootstrapTooltip>
                      )}
                    </div>
                  </Grid>
                  <Grid>
                    {goalComponents(goal, item, inputName, i)}
                    {j % 6 === 0 && (
                      <IconButton
                        style={{ marginTop: '6px' }}
                        color="error"
                        onClick={() => handleDelete(i)}
                      >
                        <DeleteForeverIcon />
                      </IconButton>
                    )}
                  </Grid>
                </Grid>
              </>
            );
          });

          return <div className={classes.tuneGoalWrapper}>{childs}</div>;
        })
        .filter((goal) => goal) || null;

    return (
      <>
        {multiGoals}
        <Grid className={classes.alignInputs} key="addNewGoalGrid">
          <Grid className={classes.alignItems} />
          <Grid>
            <IconButton onClick={handleAddNewInput}>
              <AddCircleIcon />
            </IconButton>
          </Grid>
        </Grid>
      </>
    );
  };

  const pickedFromOriginal = _.pick(originalOffer, currentInputs);
  const pickedFromCurrent = _.pick(currentOffer, currentInputs);
  const isTheSame =
    pickedFromCurrent && pickedFromOriginal
      ? _.isEqual(pickedFromOriginal, pickedFromCurrent)
      : false;

  return (
    <>
      <ThemeProvider theme={dialog}>
        <Dialog
          className={classes.root}
          fullWidth={true}
          maxWidth="md"
          onClose={handleClose}
          open={open}
          disableEnforceFocus
        >
          <Box className={classes.header}>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={6}>
                <Title
                  dimensionName={
                    currentOfferDimension && currentOfferDimension.dimensionName
                      ? currentOfferDimension.dimensionName
                      : 'No Dimension'
                  }
                />
              </Grid>
              <Grid item container justifyContent="flex-end" xs={6}>
                <IconButton onClick={handleClose}>
                  <CloseIcon className={classes.close} />
                </IconButton>
              </Grid>
            </Grid>
          </Box>

          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Grid>

          <Box style={{ margin: '24px 0px 24px 0px' }}>
            <Grid className={classes.columnTitles}>
              <Grid item xs={6}>
                NAME
              </Grid>
              <Grid>VALUE</Grid>
            </Grid>
            <Divider />
            {currentOfferDimension && currentOfferDimension.inputs ? (
              <Grid className={classes.inputColumnSpacing}>
                <Grid className={classes.inputColumn}>
                  {currentOfferDimension.inputs.map((input) => {
                    const inputName = input.alternativeLabel
                      ? input.alternativeLabel
                      : _.startCase(input.inputLabel);
                    const { inputLabel } = input;
                    const key = input.key;
                    const inputType = input.inputType;
                    if (
                      key === 'successurl' &&
                      currentOffer &&
                      currentOffer.actionforpoints !== 'SUCCESS_URL'
                    )
                      return null;
                    if (
                      key === 'zips' &&
                      (zipStatus !== UploadStates.DONE || zipCount || file)
                    ) {
                      return null;
                    }

                    if (
                      key === 'offertunegoals' &&
                      currentOffer.multipleconversionsenabled
                    ) {
                      return <TuneGoals input={input} />;
                    }
                    return (
                      <>
                        {key === 'multipleconversionsenabled' && (
                          <Grid container spacing={0}>
                            <Grid item xs={12}>
                              <Divider />
                            </Grid>
                          </Grid>
                        )}
                        {inputType !== 'urlarray' ? (
                          <Grid
                            key={nanoid()}
                            className={classes.columnNameAndValue}
                          >
                            <Grid item xs={6}>
                              <Typography>
                                {inputLabel !== 'flightDates' ? (
                                  <>{inputName}</>
                                ) : (
                                  <div style={{ marginTop: '38px' }}>
                                    {inputName}
                                  </div>
                                )}
                                <RequiredField
                                  offer={currentOffer}
                                  field={key}
                                />
                                {(inputLabel === 'zipCodes' ||
                                  inputLabel === 'states') && (
                                  <BootstrapTooltip
                                    title={
                                      inputLabel === 'zipCodes' ? (
                                        <Grid>
                                          <span style={{ fontWeight: 'bold' }}>
                                            Maximum: 50
                                          </span>
                                          <br />
                                          Example 1: 68495, 95046, 60385
                                          <br />
                                          <span style={{ fontWeight: 'bold' }}>
                                            or
                                          </span>
                                          <br />
                                          Example 2:
                                          <br />
                                          68495
                                          <br />
                                          95046
                                          <br />
                                          60385
                                        </Grid>
                                      ) : (
                                        <Grid>
                                          Example 1: WA, OR, CA
                                          <br />
                                          <span style={{ fontWeight: 'bold' }}>
                                            or
                                          </span>
                                          <br />
                                          Example 2:
                                          <br />
                                          WA
                                          <br />
                                          OR
                                          <br />
                                          CA
                                        </Grid>
                                      )
                                    }
                                  >
                                    <IconButton
                                      size="small"
                                      style={{ left: '5px' }}
                                    >
                                      <HelpOutlineIcon
                                        style={{
                                          fontSize: '18px',
                                          color: 'lightgrey',
                                        }}
                                      />
                                    </IconButton>
                                  </BootstrapTooltip>
                                )}
                              </Typography>
                            </Grid>
                            <Grid>
                              {inputType === 'file' ? (
                                currentOffer?.zipCount ? (
                                  <DeleteZipsType
                                    setOpenDialog={setOpenDialog}
                                    zipCount={zipCount}
                                    zipStatus={zipStatus}
                                  />
                                ) : (
                                  <FileInputType
                                    file={file}
                                    setFile={setFile}
                                  />
                                )
                              ) : (
                                <InputType
                                  inputLabel={inputName}
                                  inputType={inputType}
                                  keyName={key}
                                  isValidEndDate={isValidEndDate}
                                  isValidStartDate={isValidStartDate}
                                />
                              )}
                            </Grid>
                          </Grid>
                        ) : (
                          <Grid
                            key={nanoid()}
                            className={classes.columnNameAndValueForUrlArray}
                          >
                            <Grid
                              item
                              xs={6}
                              className={classes.titleForUrlArray}
                            >
                              <Typography>
                                {inputName}{' '}
                                <RequiredField
                                  offer={currentOffer}
                                  field={key}
                                />{' '}
                              </Typography>
                            </Grid>
                            <Grid>
                              <InputType
                                inputLabel={inputName}
                                inputType={inputType}
                                keyName={key}
                              />
                            </Grid>
                          </Grid>
                        )}
                      </>
                    );
                  })}
                </Grid>
              </Grid>
            ) : null}
          </Box>

          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Grid>

          <Box m={3} marginTop={2} marginBottom={2}>
            <Grid className={classes.buttonContainer}>
              <Grid>
                <BootstrapTooltip
                  title={isTheSame ? 'There are no changes' : ''}
                >
                  <Box mr={1}>
                    <Button
                      color="primary"
                      className={classes.buttonFormat}
                      variant="contained"
                      disableElevation
                      disabled={isTheSame}
                      onClick={handleClearChanges}
                    >
                      Clear Changes
                    </Button>
                  </Box>
                </BootstrapTooltip>
              </Grid>
              <Grid>
                <BootstrapTooltip title={SaveTitle}>
                  <span>
                    <Button
                      color="primary"
                      className={classes.buttonFormat}
                      disableElevation
                      disabled={isDisabled}
                      variant="contained"
                      onClick={handleSave}
                    >
                      Save
                    </Button>
                  </span>
                </BootstrapTooltip>
              </Grid>
            </Grid>
          </Box>
        </Dialog>
        <DeleteZipsDialog
          isOpen={isOpen}
          setOpenDialog={setOpenDialog}
          user={currentUser.email}
        />
      </ThemeProvider>
    </>
  );
};

const Title = ({ dimensionName }: any) => (
  <ThemeProvider theme={icon}>
    <Grid container item xs={12} alignItems="center">
      <Grid item container direction="column" xs={11} style={{ padding: 5 }}>
        <Grid item>
          <Typography component="h3" variant="h6">
            {_.startCase(dimensionName)}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  </ThemeProvider>
);

/**
 * Functional component to render a required field indicator based on validation result.
 * @param props - Props containing offer object and field to validate.
 * @returns JSX element representing the required field indicator.
 */
const RequiredField = (props) => {
  const { offer, field } = props;
  const isRequired = validateOfferRequiredFields(field, offer);
  if (isRequired) {
    return <span style={{ color: 'red' }}> * </span>;
  }

  return null;
};

export default OfferDimensionDialog;
