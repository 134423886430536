import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { useEffect } from 'react';

// Components
import DealDeleteDialog from '../dealDeleteDialog';
import DealDimensions from '../dealDimensions';

// Material UI Components
import DealName from '../dealName';
import DealSendDialog from '../dealSendDialog';
import DealsDimensionDialog from '../dealsDimensionDialog';
import { Box, CircularProgress, Grid } from '@material-ui/core';
import { clearLineItems, fetchDeal, setUIState } from '../slice';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    height: '100%',
  },
  noDeal: {
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  dealUnknown: {
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dealUnknownText: {
    fontSize: 20,
    textAlign: 'center',
  },
}));

const DealEditor = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const originalDeal = useAppSelector(
    (state) => state.dealsReducer.originalDeal
  );
  const dealLoading = useAppSelector(
    (state) => state.dealsReducer.ui.dealLoading
  );

  const errorFetching = useAppSelector(
    (state) => state.dealsReducer.ui.errorFetching
  );

  const currentDealDimension = useAppSelector(
    (state) => state.dealsReducer.currentDimension
  );

  useEffect(() => {
    dispatch(setUIState({ value: true, uiPropertyName: 'manual' }));
    dispatch(setUIState({ value: false, uiPropertyName: 'errorFetching' }));
    dispatch(
      fetchDeal({
        dealid: history.location.pathname.split('/').pop(),
      })
    );
    dispatch(setUIState({ value: '', uiPropertyName: 'lineitemFilter' }));
    dispatch(clearLineItems(null));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (
    history.location.pathname.split('/').length !== 5 ||
    history.location.pathname.split('/').pop()?.length !== 36
  ) {
    history.push('/main/pmp-deals/');
  }

  const shouldRenderContent =
    originalDeal && originalDeal.dealname && !errorFetching && !dealLoading;

  return (
    <div className={classes.root}>
      {shouldRenderContent && <DealName />}
      {shouldRenderContent && <DealDimensions />}
      {shouldRenderContent && currentDealDimension && <DealsDimensionDialog />}
      {!dealLoading ? <DealDeleteDialog /> : null}
      {!dealLoading ? <DealSendDialog /> : null}
      {dealLoading && <LoadingDeal />}
      {errorFetching && (
        <div className={classes.dealUnknown}>
          <div className={classes.dealUnknownText}>
            404 Error: This page does not exist.
          </div>
        </div>
      )}
    </div>
  );
};

const LoadingDeal = () => {
  const classes = useStyles();
  return (
    <Box flexGrow={1} className={classes.noDeal}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <CircularProgress color="primary" size={40} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default DealEditor;
