import Header from 'components/Header';
import styled from 'styled-components';
import tokens from 'tokens';
import { Container } from 'components/Grid';
import { Typography } from '@material-ui/core';

export const ViewQuestionHeader = styled(Header)`
  border: none;
  width: 100%;
`;

export const EmojiContainer = styled.div`
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background-color: ${tokens.colorSpecial};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Emoji = styled.div`
  font-size: 25px;
`;

export const Points = styled.div`
  ${tokens.typography.body}
  font-size: 0.95rem;
  padding-top: 0.7em;
`;

export const Trophy = styled.span`
  font-size: 17.5px;
`;

export const Question = styled.div`
  ${tokens.typography.subheaderSm}
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const QuestionDetailsAndPreviewContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const QuestionDetailsAndPreview = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 69rem;
`;

export const QuestionText = styled(Typography)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 343px;
`;

export const CardContainer = styled(Container)`
  border: 1px solid ${tokens.colorSuccess};
  padding-top: 1rem;
  padding-bottom: 1.5rem;
  border-radius: 10px;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.07);
  width: 69rem;
  margin: 0;
`;

export const CardTitle = styled(Typography)`
  padding-bottom: 1rem;
`;

export const ActionContainer = styled.div`
  width: 100%;
`;

export const Nothing = styled.div`
  padding: 0 ${tokens.spacing.md};
`;

export const ContentWrapper = styled.div`
  padding: ${tokens.spacing.md} 0;
`;

export const Label = styled.div`
  ${tokens.typography.label}
`;

export const Info = styled.div`
  ${tokens.typography.body}
`;

export const TableRow = styled.tr`
  font-size: 16px;
  line-height: 24px;
  &:nth-child(even) {
    background: ${tokens.colorGray05};
  }
`;

export const Table = styled.table`
  width: 100%;
`;

export const AudienceRow = styled.div`
  ${tokens.typography.body}
  display: flex;

  &:nth-child(even) {
    background: ${tokens.colorGray05};
  }

  & > div {
    width: 50%;
  }
`;

export const Active = styled.span`
  color: green;
`;

export const Inactive = styled.span`
  color: red;
`;

export const ButtonSpacing = styled.div`
  display: flex;

  & > * + * {
    margin-left: ${tokens.spacing.sm};
  }
`;
