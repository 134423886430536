import Dialog from 'components/Dialog';
import React from 'react';
import axios from 'axios';
import { OfferWallContext } from 'context';
import { Tooltip } from 'antd';
import { Button } from '@klover/attain-design-system';

const RemoveAbTest = () => {
  const [abTestLabel, setAbTestLabel] = React.useState('');
  const {
    existingAbTest,
    setExistingAbTest,
    setRemoveAbTest,
    abTests,
    setAbTests,
    setSnackBarOptions,
  } = React.useContext(OfferWallContext);
  const close = () => {
    setExistingAbTest(null);
    setRemoveAbTest(false);
  };
  const handleSubmit = () => {
    if (existingAbTest) {
      axios
        .delete(`${process.env.API_BASE_ADDRESS}/ab-tests/${existingAbTest}`)
        .then(() => {
          setAbTests(
            abTests.filter((abTest) => abTest.abtestid !== existingAbTest)
          );
          setSnackBarOptions({
            open: true,
            message: 'Successfully deleted AB Test',
            error: false,
          });
        })
        .catch((e) => {
          console.log('Error:', e);
          setSnackBarOptions({
            open: true,
            message: 'Failed to delete AB Test',
            error: true,
          });
        });
    }
    close();
  };

  React.useEffect(() => {
    if (existingAbTest) {
      axios
        .get(`${process.env.API_BASE_ADDRESS}/ab-tests/${existingAbTest}`)
        .then(({ data }) => {
          setAbTestLabel(data.label);
        })
        .catch((e) => {
          console.log('Error:', e);
        });
    }
  }, []);

  const disableDelete = !existingAbTest;

  return (
    <Dialog
      onDismiss={close}
      showClose={true}
      onCloseClick={close}
      title={'Remove AB Test'}
      buttons={
        <>
          <Tooltip
            zIndex={999999999999999}
            title={disableDelete ? 'Creative rotation no longer exists' : ''}
          >
            <span>
              <Button
                variant="contained"
                color="error"
                type="submit"
                onClick={handleSubmit}
              >
                Delete
              </Button>
            </span>
          </Tooltip>
          <Button onClick={close} variant="outlined">
            Close
          </Button>
        </>
      }
    >
      <p>
        Are you sure you&apos;d like to remove AB tests from{' '}
        <strong>{abTestLabel}?</strong>
      </p>
    </Dialog>
  );
};

export default RemoveAbTest;
