import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import HistoryIcon from '@mui/icons-material/History';
import ExportDialog from '../exportDialog';
import React, { useMemo, useState } from 'react';
import Table from 'components/Table';
import _debounce from 'lodash/debounce';
import { Box, TablePagination } from '@mui/material';
import { Button, IconButton } from '@klover/attain-design-system';
import { CircularProgress } from '@material-ui/core';
import { CustomAudience } from 'interfaces/customAudiences';
import { UploadStates } from 'constants/index';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import * as Styled from './index.styles';
import ExportHistroryDialog from 'pages/audienceManager/exportHistoryDialog';
import { AudienceExportStatus } from 'pages/audienceManager';
import CopyPopover from 'components/CopyPopover';
import { Tooltip } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import { ERROR_PROCESSING_FILE, IN_PROGRESS } from '../../../content';
import AudienceStatusHeader from 'pages/audienceManager/audienceStatus';

interface Props {
  customAudiences: Array<CustomAudience>;
  groups: any;
  selectedGroupId: any;
  handleSelectGroup: any;
  oprations: any;
  setOprations: any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    audienceName: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: 220,
      display: 'inline-block',
      cursor: 'pointer',
      fontSize: 14,
    },
    taxonomy: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: 180,
      display: 'inline-block',
      fontSize: 14,
    },
    createdBy: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: 150,
      display: 'inline-block',
      fontSize: 14,
    },
    audienceStatus: {
      display: 'flex',
      gap: '5px',
      alignItems: 'center',
    },
    fileName: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: 210,
      display: 'inline-block',
      fontSize: 14,
    },
  })
);

const CustomAudienceTable = (props: Props) => {
  const {
    customAudiences,
    groups,
    selectedGroupId,
    handleSelectGroup,
    oprations,
    setOprations,
  } = props;
  const classes = useStyles();
  const [openModal, setOpenModal] = useState(false);
  const [id, setId] = useState(0);
  const [name, setName] = useState('');
  const { page, rowsPerPage } = oprations;
  const [openExportHistoryDialog, setOpenExportHistoryDialog] = useState(false);
  const [currentAudienceHistory, setCurrentAudienceHistory] = useState([]);
  const [currentAudience, setCurrentAudience] = useState(null);

  const handleExport = (id: number, name: string) => {
    setName(name);
    setId(id);
    setOpenModal(true);
  };
  const columns = useMemo(
    () => [
      {
        Header: 'Audience Group',
        accessor: 'group_id',
        Cell: (row) => (
          <Button
            color="primary"
            onClick={() => handleSelectGroup(row?.cell?.value)}
            size="large"
            style={{ fontSize: '14px' }}
          >
            {row && row.cell.value
              ? groups?.find((a: any) => a?.id === row?.cell?.value)?.name
              : groups?.find((a: any) => a?.id === selectedGroupId)?.name}
          </Button>
        ),
      },
      {
        Header: 'Audience Name',
        accessor: 'name',
        Cell: (row: any) => {
          return (
            <Styled.AudienceName>
              <CopyPopover
                value={row?.cell && row.cell?.value}
                className={classes.audienceName}
                title={`(${row?.cell?.row?.original?.id})  ${
                  row?.cell?.value || null
                }`}
              />
            </Styled.AudienceName>
          );
        },
      },
      {
        Header: 'Audience ID',
        accessor: 'id',
        Cell: (row: any) => {
          return <>{row?.cell?.value}</>;
        },
      },
      {
        Header: 'Taxonomy',
        accessor: 'segmentName',
        Cell: (row) => (
          <Styled.Taxonomy>
            <CopyPopover
              value={row?.cell && row.cell?.value}
              className={classes.taxonomy}
            />
          </Styled.Taxonomy>
        ),
      },
      {
        Header: 'Sent File Name',
        accessor: 'sentFilename',
        Cell: (row: any) => {
          return (
            <Styled.FileName>
              <CopyPopover
                value={row.cell?.value || ''}
                className={classes.fileName}
              />
            </Styled.FileName>
          );
        },
      },
      {
        Header: <AudienceStatusHeader />,
        accessor: 'exportStatus',
        Cell: (row) => (
          <Styled.AudienceStatus>
            <AudienceExportStatus value={row?.cell && row.cell?.value} />{' '}
            <IconButton
              onClick={() => {
                setCurrentAudienceHistory(
                  row?.cell?.row?.original?.exportHistory || []
                );
                setCurrentAudience(row?.cell?.row?.original);
                setOpenExportHistoryDialog(true);
              }}
            >
              <HistoryIcon />
            </IconButton>
          </Styled.AudienceStatus>
        ),
      },
      {
        Header: 'Created By',
        accessor: 'email',
        Cell: (row: any) => {
          return (
            <Styled.CreatedBy>
              <CopyPopover
                value={row?.cell && row.cell?.value}
                className={classes.createdBy}
              />
            </Styled.CreatedBy>
          );
        },
      },
      {
        Header: 'Audience Size',
        accessor: 'size',
        Cell: (row: any) => {
          return (
            <Styled.AudienceSize>
              {new Number(row.cell.value).toLocaleString()}
            </Styled.AudienceSize>
          );
        },
      },
      {
        Header: 'Export Audience',
        Cell: (row: any) => {
          const loadingState = row.row.original.status;
          if (loadingState === UploadStates.DONE) {
            return (
              <IconButton
                onClick={() => {
                  handleExport(row.row.original.id, row.row.original.name);
                }}
                style={{ paddingLeft: 0 }}
              >
                <CloudUploadIcon />
              </IconButton>
            );
          } else if (loadingState === UploadStates.ERROR) {
            return (
              <Tooltip title={ERROR_PROCESSING_FILE} placement="bottom" arrow>
                <ErrorIcon style={{ color: '#ff2c2c' }} />
              </Tooltip>
            );
          } else {
            return (
              <Tooltip title={IN_PROGRESS} placement="bottom" arrow>
                <CircularProgress size={24} />
              </Tooltip>
            );
          }
        },
      },
    ],
    [groups]
  );

  const columnsSort = [
    {
      id: 'createdat',
      desc: true,
    },
  ];

  const count =
    customAudiences && customAudiences?.length > 0
      ? typeof customAudiences[0].totalcount === 'string'
        ? Number(customAudiences[0].totalcount)
        : customAudiences[0].totalcount
      : 1;

  const handleChangePage = (event: any, newPage: number) => {
    setOprations({
      ...oprations,
      page: newPage,
    });
  };

  const handleChangeRowsPerPage = (event: any) => {
    const newPageSize = parseInt(event.target.value, 10);
    setOprations({
      ...oprations,
      page: 0,
      rowsPerPage: newPageSize,
    });
  };

  const handleOnSortChange = (sortInfo: string) => {
    console.log('Sorting:', sortInfo);
    let sortOrder = 'DESC';
    if (oprations.sortBy === sortInfo.column) {
      sortOrder = oprations.sortOrder == 'ASC' ? 'DESC' : 'ASC';
    }
    setOprations({
      ...oprations,
      sortBy: sortInfo.column,
      sortOrder: sortOrder,
      page: 0,
    });
  };

  return customAudiences ? (
    <Box>
      <Table
        columns={columns}
        data={customAudiences}
        onSort={handleOnSortChange}
        operations={oprations}
        disabledColumns={['segmentName', 'exportStatus', 'sentFilename']}
        hiddenColumns={['id']}
      />
      <ExportDialog
        isOpen={openModal}
        setIsOpen={setOpenModal}
        audienceId={id}
        audienceName={name}
      />
      <ExportHistroryDialog
        isOpen={openExportHistoryDialog}
        setOpenDialog={setOpenExportHistoryDialog}
        data={currentAudienceHistory}
        customAudience={currentAudience}
      />
      <TablePagination
        component="div"
        count={count}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[25, 50, 100]}
        sx={{
          '.MuiTablePagination-selectLabel': {
            paddingTop: '13px',
          },
          '.MuiTablePagination-displayedRows': {
            paddingTop: '13px',
          },
        }}
      />
    </Box>
  ) : null;
};

export default CustomAudienceTable;
