import { UploadStates } from 'constants/index';
import convertTimeStampToDateTime from 'utils/dateTimestampConversion';
import ErrorIcon from '@mui/icons-material/Error';
import { CircularProgress } from '@material-ui/core';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import {
  IMPORTING_FILE,
  ERROR_PROCESSING_FILE,
  SUCCESS_FILE,
} from '../../../content/index';

/**
 * Enum for types mapping to their string representations as key
 */
const ENUM_EXPORT_TYPE_KEY: { [key: string]: string } = {
  transunion: 'transunion',
  custom_segment: 'custom_segment',
  ots_segment: 'ots_segment',
  klover_ids: 'klover_ids',
};

/**
 * Enum for types mapping to their string representations
 */
export const ENUM_EXPORT_TYPE: { [key: string]: string } = {
  transunion: 'TransUnion',
  transunion_hems: 'TransUnion HEMs Only',
  custom_segment: 'Custom Segment',
  ots_segment: 'OTS Segment',
  klover_ids: 'Klover IDs',
};

/**
 * Enum for status mapping to their string representations
 */
export const ENUM_EXPORT_STATUS: { [key: string]: string } = {
  export_started: 'export started',
  sent_to_transunion: 'Audience sent to TransUnion',
  received_from_transunion: 'Lookalike received from TransUnion',
  created_ttd: 'Created the Trade Desk segment',
};

/**
 * Enum status for audience export status
 */
export const ENUM_AUDIENCE_EXPORT_STATUS: { [key: string]: string } = {
  NOT_SENT: 'Not sent',
  SENT: 'Sent',
  MODELING_COMPLETED: 'Modeling Complete',
  LATE: 'Late',
  ERROR: 'Error',
  LIVE_IN_TTD: 'Live In TTD',
};

/**
 * Enum status for audience type status
 */
const ENUM_AUDIENCE_TYPE_STATUS: { [key: string]: string } = {
  PENDING: 'Pending',
  OTS: 'OTS Audience',
  CUSTOM_AUDIENCE: 'Custom Audience',
  KLOVER_ID: 'IDs Exported',
};

/**
 * Enum status for platform type status
 */
const ENUM_PLATFORM_TYPE_STATUS: { [key: string]: string } = {
  OTS_PFT: 'Trade Desk + LiveRamp',
  CUSTOM_PFT: 'Trade Desk',
  TRANSUNION_PFT: 'LiveRamp',
  KLOVER_ID_PFT: 'Unknown',
};

/**
 * Export status column mapping with data key & datatype
 */

export const getStatusDetails = (exportType: string) => {
  const statusDetails = [
    {
      label: ENUM_EXPORT_STATUS['export_started'],
      key: 'timestampStatusExportStarted',
      type: 'datetime',
    },
    {
      label: ENUM_EXPORT_STATUS['sent_to_transunion'],
      key: 'timestampStatusSentToTransunion',
      type: 'datetime',
    },
    {
      label: ENUM_EXPORT_STATUS['received_from_transunion'],
      key: 'timestampStatusReceivedFromTransunion',
      type: 'datetime',
    },
  ];

  if (exportType !== 'transunion') {
    statusDetails.push({
      label: ENUM_EXPORT_STATUS['created_ttd'],
      key: 'timestampStatusTtdSegmentCreated',
      type: 'datetime',
    });
  }

  return statusDetails;
};

/**
 * Segment Details column mapping with datakey & datatype
 */
export const segmentDetails = [
  { label: 'Segment Name', key: 'segmentName' },
  { label: 'Description', key: 'description' },
  { label: 'CPM', key: 'cpm' },
  { label: '% of media', key: 'percentOfMedia' },
  { label: 'Partner ID', key: 'publisherId' },
  { label: 'Advertiser ID', key: 'advertiserId' },
];

/**
 * Help to get latest status value of current export
 * @param exportItem : Export object
 * @returns : string
 */
export const getExportLatestStatus = (exportItem: any) => {
  const {
    statusExportStarted = false,
    statusSentToTransunion = false,
    statusReceivedFromTransunion = false,
    statusTtdSegmentCreated = false,
    exportType,
  } = exportItem || {};
  if (statusTtdSegmentCreated) return ENUM_EXPORT_STATUS['created_ttd'];
  if (statusReceivedFromTransunion)
    return ENUM_EXPORT_STATUS['received_from_transunion'];
  if (statusSentToTransunion) return ENUM_EXPORT_STATUS['sent_to_transunion'];
  if (statusExportStarted)
    return `${ENUM_EXPORT_TYPE[exportType]} ${ENUM_EXPORT_STATUS['export_started']}`;
  return '';
};

/**
 * Convert current timestamp to specific 'MM/DD/YYYY HH:mm A' format along with split (date-time) functionality
 * @param timestamp : number
 * @param isSplitTime : boolean
 * @returns : render object
 */
export const convertToDateTime = (timestamp: number, isSplitTime = false) => {
  if (!timestamp) return '';
  const dateTime = convertTimeStampToDateTime(timestamp, 'MM/DD/YYYY HH:mm A');
  if (dateTime) {
    if (!isSplitTime) return dateTime;
    const splitedTime = dateTime.split(' ');
    return (
      <>
        <span>{splitedTime[0]}</span>
        <br />
        <span>{`${splitedTime[1]} ${splitedTime[2]}`}</span>
      </>
    );
  }

  return '';
};

/**
 * Convert all props to valid format
 * @param arrayOfObjects : array objects
 * @returns new array with valid props
 */
export const modifyProps = (arrayOfObjects: any[]): any[] => {
  return arrayOfObjects.map((obj: any) => {
    const newObj: any = {};
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        if (!key.includes('_')) {
          newObj[key] = obj[key];
        } else {
          const propParts: string[] = key.split('_');
          const propName: string = propParts
            .map((part: string, index: number) => {
              if (index !== 0) {
                return part.charAt(0).toUpperCase() + part.slice(1);
              }
              return part;
            })
            .join('');
          newObj[propName] = obj[key];
        }
      }
    }
    return newObj;
  });
};

/**
 * Return file uploading status Icon and Status for Custom Audience
 * @param status : uploaded file status
 * @returns status and statusIcon
 */
export const getFileUploadingStatus = (status: string) => {
  const { DONE, LOADING, ERROR } = UploadStates;
  switch (status) {
    case LOADING:
      return {
        status: IMPORTING_FILE,
        statusIcon: <CircularProgress size={24} />,
      };
    case ERROR:
      return {
        status: ERROR_PROCESSING_FILE,
        statusIcon: <ErrorIcon style={{ color: '#ff2c2c' }} />,
      };
    case DONE:
      return {
        status: SUCCESS_FILE,
        statusIcon: (
          <CloudDoneIcon
            style={{ color: '#413762', marginLeft: '16px', fontSize: '26px' }}
          />
        ),
      };
    default:
      return { status: '', statusIcon: '' };
  }
};

/**
 * Help to retrive audience_type & platform_type from export history
 * @param exportHistory : array of export history
 * @returns object with audience_type & platform_type
 */
export const getAudienceAndPlatformTypeStatus = (exportHistory: any[]) => {
  const { transunion, custom_segment, ots_segment, klover_ids } =
    ENUM_EXPORT_TYPE_KEY;
  const { PENDING, OTS, CUSTOM_AUDIENCE, KLOVER_ID } =
    ENUM_AUDIENCE_TYPE_STATUS;
  const { OTS_PFT, CUSTOM_PFT, TRANSUNION_PFT, KLOVER_ID_PFT } =
    ENUM_PLATFORM_TYPE_STATUS;

  if (exportHistory.length === 0)
    return {
      audience_type: PENDING,
      platform_type: '',
    };

  const { exportType } = exportHistory[0];
  switch (exportType) {
    case klover_ids:
      return {
        audience_type: KLOVER_ID,
        platform_type: KLOVER_ID_PFT,
      };
    case ots_segment:
      return {
        audience_type: OTS,
        platform_type: OTS_PFT,
      };
    case transunion:
      return {
        audience_type: CUSTOM_AUDIENCE,
        platform_type: TRANSUNION_PFT,
      };
    case custom_segment:
      return {
        audience_type: CUSTOM_AUDIENCE,
        platform_type: CUSTOM_PFT,
      };

    default:
      return {};
  }
};
