import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';

import { Button, IconButton } from '@klover/attain-design-system';
import {
  ToggleDuplicateAudienceDialogAction,
  duplicateAudience,
  toggleDuplicateAudienceDialog,
} from '../slice';
import { useUser } from 'reactfire';

// Material UI Components
import Box from '@material-ui/core/Box';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import tokens from 'tokens';
import {
  Theme,
  ThemeProvider,
  createStyles,
  createTheme,
  makeStyles,
} from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: theme.spacing(3),
    },
    divider: {
      borderLeft: '0.2em solid ' + tokens.colorDividerLgBorder,
    },
    audienceName: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(3),
    },
    title: {
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 23,
      paddingLeft: 16,
      paddingTop: 16,
    },
  })
);

const dialog = createTheme({
  overrides: {
    MuiFormLabel: {
      asterisk: {
        color: tokens.colorTextDanger,
        '&$error': {
          color: tokens.colorTextDanger,
        },
      },
    },
    MuiDialog: {
      paper: {
        borderRadius: 10,
      },
      paperWidthMd: {
        maxWidth: 1100,
      },
    },
    MuiMenu: {
      list: {
        padding: 0,
      },
    },
    MuiInputBase: {
      input: {
        borderRadius: 10,
        padding: '12px 14px',
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 10,
      },
    },
    MuiButton: {
      root: {
        borderRadius: 8,
      },
      outlinedPrimary: {
        color: tokens.colorButtonPrimary,
      },
      containedPrimary: {
        backgroundColor: tokens.colorButtonPrimary,
        '&:hover': {
          backgroundColor: tokens.colorButtonPrimary,
        },
      },
    },
  },
});

interface Props {
  open: boolean;
}

const DuplicateAudienceDialog = ({ open }: Props) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { data: user } = useUser();
  const currentUser = user || { email: '' };

  const currentAudience = useAppSelector(
    (state) => state.audienceReducer.currentAudience
  );

  const initialAudienceName =
    currentAudience && currentAudience.name
      ? `${currentAudience.name} (copy)`
      : '';

  const [audienceName, setAudienceName] = useState(initialAudienceName);

  const dispatchClose = () => {
    setAudienceName(initialAudienceName);
    const actionProps: ToggleDuplicateAudienceDialogAction = {
      open: false,
    };
    dispatch(toggleDuplicateAudienceDialog(actionProps));
  };

  const handleNameChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const audienceName = event.target.value as string;
    setAudienceName(audienceName);
  };

  const handleDuplicate = () => {
    const updatedAudience = {
      ...currentAudience,
      created_by_email: currentUser.email,
      updated_by_email: currentUser.email,
    };
    dispatch(
      duplicateAudience({ audience: updatedAudience, name: audienceName })
    );
  };

  const audienceLoading = useAppSelector(
    (state) => state.audienceReducer.ui.audienceLoading
  );

  const isAudienceLoading = audienceLoading.state === 'loading';

  const handleClose = () => {
    dispatchClose();
  };

  return (
    <>
      <ThemeProvider theme={dialog}>
        <Dialog
          className={classes.root}
          fullWidth={true}
          onClose={handleClose}
          open={open}
        >
          <Box m={1}>
            <Grid container alignItems="center">
              <Grid item xs={6}>
                <Typography className={classes.title}>
                  Duplicate Audience
                </Typography>
              </Grid>
              <Grid item container justifyContent="flex-end" xs={6}>
                <IconButton disabled={isAudienceLoading} onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Box>

          {/* <Box m={3}> */}
          <DialogContent style={{ padding: '24px 32px 8px 32px' }}>
            <DialogContentText
              style={{
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: 16,
              }}
            >
              How do you want to name the duplicate audience?
            </DialogContentText>
            <TextField
              required
              disabled={isAudienceLoading}
              className={classes.audienceName}
              onChange={handleNameChange}
              value={audienceName}
              fullWidth
              id="duplicate-audience-name"
              label="Audience Name"
              type="text"
              variant="outlined"
            />
          </DialogContent>
          {/* </Box> */}

          {/* Bottom Divider */}
          <Grid container spacing={0} style={{ marginTop: 50 }}>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Grid>

          {/* Actions */}
          <Box m={1}>
            <DialogActions>
              <Button
                color="primary"
                disabled={isAudienceLoading}
                onClick={handleClose}
                variant="outlined"
              >
                Cancel
              </Button>

              <Button
                color="primary"
                disableElevation
                disabled={!audienceName.length || isAudienceLoading}
                onClick={handleDuplicate}
                variant="contained"
              >
                Duplicate Audience
              </Button>
            </DialogActions>
          </Box>
        </Dialog>
      </ThemeProvider>
    </>
  );
};

export default DuplicateAudienceDialog;
