import { Button } from "@klover/attain-design-system";
import getAuthorizedAxiosInstance from "utils/getAuthorizedAxiosInstance";
import { API_BASE_ADDRESS } from "pages/audienceManager/variables";
import { CircularProgress } from "@mui/material";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import {
  KDSPageNames,
  LoadingStates,
  REQUIRED_FIELDS_ARE_MISSING,
} from "constants/index";
import { addNotification } from "pages/audienceManager/slice";
import { useAppDispatch } from "redux/hooks";
import { useMemo, useState } from "react";
import { useUser } from "reactfire";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import { SELECT_EXPORT_TYPE, ExportAudience } from "../../../constants";
import AudienceExport from "../../../components/AudienceExport";
import tokens from "tokens";
import {
  Theme,
  ThemeProvider,
  createStyles,
  createTheme,
  makeStyles,
} from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import { IconButton } from "@klover/attain-design-system";
import { Tooltip } from "@mui/material";
import { isFloat } from '../../../helper/helper';
import nanoid from 'utils/nanoid';

interface Props {
  audienceId: number;
  audienceName: string;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: theme.spacing(3),
    },
    title: {
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: 23,
      paddingLeft: 16,
      paddingTop: 16,
    },
    selectExport: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: "100%",
    },
    selectExportTypeLabel: {
      fontSize: 16,
      fontWeight: 500,
      paddingBottom: 4,
    },
  })
);

const dialog = createTheme({
  overrides: {
    MuiDialog: {
      paper: {
        borderRadius: 10,
        width: "60%",
        minHeight: "290px",
      },
    },
    MuiMenu: {
      list: {
        padding: 0,
      },
    },
    MuiInputBase: {
      input: {
        borderRadius: 10,
        padding: "12px 14px !important",
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 10,
      },
    },
    MuiButton: {
      root: {
        borderRadius: 8,
      },
      outlinedPrimary: {
        color: tokens.colorButtonPrimary,
      },
      containedPrimary: {
        backgroundColor: tokens.colorButtonPrimary,
        "&:hover": {
          backgroundColor: tokens.colorButtonContainedHoverbg,
        },
      },
    },
    MuiFormLabel: {
      asterisk: {
        color: "#db3131",
        "&$error": {
          color: "#db3131",
        },
      },
    },
  },
});

const ExportDialog = (props: Props) => {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const { data: user } = useUser();
  const currentUser = user || { email: "" };
  const { audienceId, audienceName, isOpen, setIsOpen } = props;
  const [isExporting, setIsExporting] = useState(false);

  const selectOptions = [
    { text: ExportAudience.TRANSUNION, value: "transunion" },
    // { text: ExportAudience.TRANSUNION_TTD, value: 'transunion_ttd' },
    // { text: ExportAudience.TRANSUNION_HEMS, value: 'transunion_hems' },
    { text: ExportAudience.CUSTOM_SEGMENT, value: "custom_segment" },
    { text: ExportAudience.OTS_SEGMENT, value: "ots_segment" },
  ];
  const [selectedExportType, setSelectedExportType] = useState("");
  const [segmentName, setSegmentName] = useState("");
  const [description, setDescription] = useState("");
  const [cpm, setCpm] = useState("");
  const [percentageOfMedia, setPercentageOfMedia] = useState("");
  const [partnerID, setPartnerID] = useState("");
  const [advertiserID, setAdvertiserID] = useState("");

  const handleSegmentName = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const data = event.target.value as string;
    setSegmentName(data);
  };

  const handleDescription = (event: React.ChangeEvent<{ value: unknown }>) => {
    const data = event.target.value as string;
    setDescription(data);
  };

  const handleCpm = (event: any) => {
    setCpm(event.target.value);
  };

  const handlePercentageOfMedia = (event: any) => {
    setPercentageOfMedia(event.target.value);
  };

  const handlePartnerID = (event: any) => {
    setPartnerID(event.target.value);
  };

  const handleAdvertiserID = (event: any) => {
    setAdvertiserID(event.target.value);
  };

  const handleExportToTransunion = async (exportType: string) => {
    try {
      if (cpm !== '' && !isFloat(cpm)) {
        dispatch(
          addNotification({
            state: LoadingStates.ERROR,
            message: "Please enter valid CPM",
          })
        );
        return;
      }
      if (percentageOfMedia !== '' && !isFloat(percentageOfMedia)) {
        dispatch(
          addNotification({
            state: LoadingStates.ERROR,
            message: "Please enter valid % of media",
          })
        );
        return;
      }
      setIsExporting(true);
      let payload = {
        id: audienceId,
        name: audienceName,
        emails: currentUser.email,
        exportType,
        segmentName,
        description,
        cpm: cpm !== '' ? parseFloat(cpm) : '',
        percentageOfMedia : percentageOfMedia !== '' ? parseFloat(percentageOfMedia) : '',
        partnerID,
        advertiserID,
      };
      const axiosInstance = await getAuthorizedAxiosInstance();
      if (axiosInstance) {
        await axiosInstance.post(
          `${API_BASE_ADDRESS}/${KDSPageNames.CUSTOMAUDIENCES}/export/transunion`,
          payload
        );
        dispatch(
          addNotification({
            state: LoadingStates.DONE,
            message: "Started Export to transunion",
          })
        );
      } else {
        throw { message: "Failed to authorize axios instance" };
      }
    } catch (e) {
      console.log(e);
      dispatch(
        addNotification({
          state: LoadingStates.ERROR,
          message: "Failed to start Export",
        })
      );
    }
    handleClose();
  };

  const startExport = () => {
    if (selectedExportType === "transunion") {
      handleExportToTransunion("transunion");
    } else if (selectedExportType === "transunion_ttd") {
      handleExportToTransunion("transunion_ttd");
    } else if (selectedExportType === "transunion_hems") {
      handleExportToTransunion("transunion_hems");
    } else if (selectedExportType === "custom_segment") {
      handleExportToTransunion("custom_segment");
    } else if (selectedExportType === "ots_segment") {
      handleExportToTransunion("ots_segment");
    }
  };

  const clearData = () => {
    setSegmentName("");
    setDescription("");
    setCpm("");
    setPercentageOfMedia("");
    setPartnerID("");
    setAdvertiserID("");
  };

  const handleClose = () => {
    setSelectedExportType("");
    clearData();
    setIsExporting(false);
    setIsOpen(false);
  };

  const isRequiredFieldMissing = useMemo(() => {
    if (selectedExportType === 'custom_segment') {
      if (segmentName === '' || cpm === '' || percentageOfMedia === '' || (partnerID === '' && advertiserID === '')) {
        return true;
      }
    } else if (selectedExportType === 'ots_segment') {
      if (segmentName === '' || cpm === '' || percentageOfMedia === '') {
        return true;
      }
    }
    return false;
  }, [selectedExportType, segmentName, cpm, percentageOfMedia, partnerID, advertiserID]);

  return (
    <>
      <ThemeProvider theme={dialog}>
        <Dialog
          className={classes.root}
          onClose={handleClose}
          open={isOpen}
          maxWidth="lg"
        >
          <Box m={1}>
            <Grid container alignItems="center">
              <Grid item xs={6}>
                <Typography className={classes.title}>
                  Export Custom Audience
                </Typography>
              </Grid>
              <Grid item container justifyContent="flex-end" xs={6}>
                <IconButton onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Box>
          <DialogContent
            style={{ padding: "24px 32px 8px 32px", overflowY: "hidden" }}
          >
            <Grid item xs={12}>
              <FormControl className={classes.selectExport}>
                <div className={classes.selectExportTypeLabel}>
                  {SELECT_EXPORT_TYPE}
                </div>
                <Select
                  labelId="export-type-label"
                  id="export-type"
                  value={selectedExportType}
                  onChange={(event: SelectChangeEvent) => {
                    clearData();
                    setSelectedExportType(event.target.value as string);
                  }}
                >
                  {selectOptions.map((item) => {
                    return (
                      <MenuItem key={item.value} value={item.value}>
                        {item.text}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>

            {(selectedExportType === "custom_segment" ||
              selectedExportType === "ots_segment") && (
              <div>
                <AudienceExport
                  selectedExportType={selectedExportType}
                  handleSegmentName={handleSegmentName}
                  segmentName={segmentName}
                  handleDescription={handleDescription}
                  description={description}
                  handleCpm={handleCpm}
                  cpm={cpm}
                  handlePercentageOfMedia={handlePercentageOfMedia}
                  percentageOfMedia={percentageOfMedia}
                  handlePartnerID={handlePartnerID}
                  partnerID={partnerID}
                  advertiserID={advertiserID}
                  handleAdvertiserID={handleAdvertiserID}
                />
              </div>
            )}
          </DialogContent>
          {/* Bottom Divider */}
          <Grid container spacing={0} style={{ marginTop: 50 }}>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Grid>

          {/* Actions */}
          <Box m={1}>
            <DialogActions>
              <Button color="primary" onClick={handleClose} variant="outlined">
                Cancel
              </Button>
              <Tooltip
                title={
                  isRequiredFieldMissing ? REQUIRED_FIELDS_ARE_MISSING : ""
                }
                placement="top"
                arrow
              >
                <span>
                  <Button
                    color="primary"
                    disableElevation
                    onClick={() => startExport()}
                    variant="contained"
                    disabled={
                      !selectedExportType ||
                      isExporting ||
                      isRequiredFieldMissing
                    }
                  >
                    {isExporting ? <CircularProgress size={20} /> : "Export"}
                  </Button>
                </span>
              </Tooltip>
            </DialogActions>
          </Box>
        </Dialog>
      </ThemeProvider>
    </>
  );
};

export default ExportDialog;
