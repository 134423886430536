import ABTest from '../../../assets/images/ab-test.svg';
import Header from 'components/Header';
import MuiAlert from '@material-ui/lab/Alert';
import React from 'react';
import Table from 'components/Table';
import axios from 'axios';
import { API_BASE_ADDRESS } from 'pages/audienceManager/variables';
import { Box, CircularProgress, Snackbar } from '@material-ui/core';
import { Button, IconButton } from '@klover/attain-design-system';
import { Delete, Edit } from '@material-ui/icons';
import { OfferWallContext } from 'context';
import * as Styled from './index.styles';

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const ABTesting = () => {
  const {
    abTests,
    setAbTests,
    abTestsLoading,
    setAbTestsLoading,
    setShowCreateAbTest,
    setExistingAbTest,
    setRemoveAbTest,
    snackBarOptions,
    onCloseSnackbar,
  } = React.useContext(OfferWallContext);
  React.useEffect(() => {
    setAbTestsLoading(true);
    axios
      .get(`${API_BASE_ADDRESS}/ab-tests`)
      .then((res) => {
        setAbTests(res.data);
        setAbTestsLoading(false);
      })
      .catch((e) => {
        setAbTestsLoading(false);
        console.log(e);
      });
  }, []);

  const columns = React.useMemo(
    () => [
      {
        Header: 'A/B Test Name',
        accessor: 'label',
        Cell: (row) => (
          <Styled.AbTestName>
            {row.cell.row.original && row.cell.row.original.isactive ? (
              <Styled.ActiveAbTestIcon
                src={ABTest}
                alt="Active A/B Test icon"
                title="Status: ON"
              />
            ) : (
              <Styled.InactiveAbTestIcon
                src={ABTest}
                alt="Inactive A/B Test icon"
                title="Status: OFF"
              />
            )}
            {row.cell.value}
          </Styled.AbTestName>
        ),
      },
      {
        Header: 'Placement',
        accessor: 'placementid',
        Cell: (row) => (
          <div>
            {row.cell.value == 1
              ? 'Offer Wall'
              : row.cell.value === 2
              ? 'Dashboard'
              : 'Klover Plus'}
          </div>
        ),
      },
      {
        Header: 'Test Type',
        accessor: 'testingtype',
        Cell: (row) => <div>{row.cell.value}</div>,
      },
      {
        Header: 'Percentage',
        accessor: 'high',
        Cell: (row) => <div>{row.cell.value}</div>,
      },
      {
        Header: 'Slots',
        accessor: 'slots',
        Cell: (row) => <div>{row.cell.value}</div>,
      },
      {
        Header: 'Offers',
        accessor: 'count',
        Cell: (row) => {
          let count = 0;
          if (row.cell.value) {
            count = row.cell.value;
          } else if (
            row.row &&
            row.row.original &&
            row.row.original.offers &&
            row.row.original.offers.length
          ) {
            count = row.row.original.offers.length;
          }
          return <div>{count}</div>;
        },
      },
      {
        Header: 'Actions',
        Cell: (row) => {
          const { abtestid } = row.cell.row.original;
          return (
            <>
              <Styled.ButtonSpacing>
                <IconButton
                  onClick={() => {
                    setShowCreateAbTest(true);
                    setExistingAbTest(abtestid);
                  }}
                  size="small"
                  title="Edit A/B Test"
                >
                  <Edit />
                </IconButton>
                <IconButton
                  color="error"
                  onClick={() => {
                    setExistingAbTest(abtestid);
                    setRemoveAbTest(true);
                  }}
                  size="small"
                  title="Delete A/B Test"
                >
                  <Delete />
                </IconButton>
              </Styled.ButtonSpacing>
            </>
          );
        },
      },
    ],
    []
  );

  return (
    <>
      <Header
        title="A/B Testing"
        action={
          <Styled.ButtonSpacing>
            <Button
              variant="contained"
              onClick={() => setShowCreateAbTest(true)}
            >
              Create A/B Test
            </Button>
          </Styled.ButtonSpacing>
        }
      />
      <Box height="100%" padding={2}>
        {abTestsLoading ? (
          <CircularProgress size={40} />
        ) : (
          <Table columns={columns} data={abTests} />
        )}
      </Box>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snackBarOptions.open}
        autoHideDuration={6000}
        onClose={onCloseSnackbar}
        style={{ zIndex: 9999 }}
      >
        <Alert severity={snackBarOptions.error ? 'error' : 'success'}>
          {snackBarOptions.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ABTesting;
