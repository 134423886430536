const dealNameDimensionConfig = {
  dimensionName: 'New Deal Name',
  showInEditor: false,
  showInCreation: true,
  inputs: [
    {
      stepperKeyName: 'dealname',
      stepperInputType: 'textfield',
      stepperInputLabel: 'Deal Name',
      muiProps: {
        label: 'Deal Name',
      },
      helperText: '* A deal name is required',
      required: true,
      forCreation: true,
      //leaving this true for now
      forEditor: true,
    },
  ],
};

export default dealNameDimensionConfig;
