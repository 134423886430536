import styled from 'styled-components';
import tokens from 'tokens';

export const Table = styled.table`
  background: #fff;
  border-collapse: collapse;
  width: 100%;
`;

export const TableHeader = styled.th`
  background: #fff;
  ${tokens.typography.label}
  padding: ${tokens.spacing.sm};
  position: sticky;
  text-align: left;
  top: 0; /* Don't forget this, required for the stickiness */
  z-index: 1;

  &:after {
    content: '';
    height: 1px;
    background: #dcdcdc;
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
  }
`;

export const TableFlex = styled.div`
  display: flex;
`;

export const HeaderName = styled.div`
  flex-shrink: 1;
`;

export const SortArrow = styled.div`
  padding-left: ${tokens.spacing.xs};
  transform: translateY(1px);
`;

export const TableBodyRow = styled.tr`
  &:nth-child(odd) {
    background: #372d760a;
  }
`;

export const TableCell = styled.td`
  ${tokens.typography.body}
  padding: ${tokens.spacing.sm} 15px ${tokens.spacing.sm} 0px;

  &:first-child {
    font-weight: 600;
  }

  a {
    color: ${tokens.colorBrandprimary};
    font-weight: bold;
    min-width: 0;
    padding: 0;
  }
`;
