import {
  CUSTOM_SEGMENT_INFORMATION,
  OTS_SEGMENT_INFORMATION,
  TRADE_DESK_SEGMENT_FIELD_NAME,
  TRADE_DESK_SEGMENT_DETAILS
} from "../../constants";
// Material UI Components
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import tokens from "tokens";
import {
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    informationText: {
      marginTop: "1rem",
    },
    segmentDivider: {
      background: tokens.colorDividerLgBorder,
      height: "1px",
      border: "none",
      marginLeft: "1rem",
    },
    segmentInputFieldsContainer: {
      display: "flex",
      padding: "1rem 1rem 0rem 1rem",
    },
    segmentInputFieldsLeft: {
      flex: 1,
      borderRight: "1px solid" + tokens.colorDividerLgBorder,
      paddingRight: "3rem",
    },
    segmentInputFieldsRight: {
      flex: 1,
      marginTop: "3rem",
      marginLeft: "1rem",
    },
    tradeDeskDataSegmentLabel: {
      paddingBottom: "1.5rem",
      paddingLeft: 4,
      fontSize: 16,
      fontWeight: 500,
    },
    OrLabel: {
      fontSize: 16,
      fontWeight: 500,
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  })
);

const AudienceExport = (props: any) => {
  const classes = useStyles();
  const {
    selectedExportType,
    handleSegmentName,
    segmentName,
    handleDescription,
    description,
    handleCpm,
    cpm,
    handlePercentageOfMedia,
    percentageOfMedia,
    handlePartnerID,
    partnerID,
    advertiserID,
    handleAdvertiserID,
  } = props;
  return (
    <>
      <Grid item xs={12}>
        <ul className={classes.informationText}>
          {selectedExportType === "custom_segment" &&
            CUSTOM_SEGMENT_INFORMATION.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          {selectedExportType === "ots_segment" &&
            OTS_SEGMENT_INFORMATION.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
        </ul>
      </Grid>
      <hr className={classes.segmentDivider} />
      <div className={classes.segmentInputFieldsContainer}>
        <div className={classes.segmentInputFieldsLeft}>
          <div className={classes.tradeDeskDataSegmentLabel}>
            {TRADE_DESK_SEGMENT_DETAILS}
          </div>
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <TextField
                onChange={handleSegmentName}
                value={segmentName}
                fullWidth
                id="segmentName"
                label={selectedExportType === "custom_segment" ? TRADE_DESK_SEGMENT_FIELD_NAME.CUSTOM_SEGMENT_NAME: TRADE_DESK_SEGMENT_FIELD_NAME.OTS_SEGMENT_NAME}
                type="text"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                onChange={handleDescription}
                value={description}
                fullWidth
                id="description"
                label={TRADE_DESK_SEGMENT_FIELD_NAME.DESCRIPTION}
                type="text"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
        </div>
        <div className={classes.segmentInputFieldsRight}>
          <Grid container spacing={0}>
            <Grid item xs={5}>
              <TextField
                onChange={handleCpm}
                value={cpm}
                fullWidth
                id="cpm"
                label={TRADE_DESK_SEGMENT_FIELD_NAME.CPM}
                type="text"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                required
              />
            </Grid>
            <Grid item xs={2} />
            <Grid item xs={5}>
              <TextField
                onChange={handlePercentageOfMedia}
                value={percentageOfMedia}
                fullWidth
                id="percentageOfMedia"
                label={TRADE_DESK_SEGMENT_FIELD_NAME.PERCENT_OF_MEDIA}
                type="text"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                required
              />
            </Grid>
          </Grid>
          {selectedExportType === "custom_segment" && (
            <Grid container spacing={0} style={{ marginTop: 36 }}>
              <Grid item xs={5}>
                <TextField
                  onChange={handlePartnerID}
                  value={partnerID}
                  fullWidth
                  id="partnerID"
                  label={TRADE_DESK_SEGMENT_FIELD_NAME.PARTNER_ID}
                  type="text"
                  variant="outlined"
                  disabled={advertiserID !== null && advertiserID !== ""}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  required
                />
              </Grid>
              <Grid item xs={2}>
                <div className={classes.OrLabel}>Or</div>
              </Grid>
              <Grid item xs={5}>
                <TextField
                  onChange={handleAdvertiserID}
                  value={advertiserID}
                  fullWidth
                  id="advertiserID"
                  label={TRADE_DESK_SEGMENT_FIELD_NAME.ADVERTISER_ID}
                  type="text"
                  variant="outlined"
                  disabled={partnerID !== null && partnerID !== ""}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  required
                />
              </Grid>
            </Grid>
          )}
        </div>
      </div>
    </>
  );
};
export default AudienceExport;