import React from 'react';
import lunr from 'lunr';
import { Button } from '@klover/attain-design-system';
import { Link, useHistory } from 'react-router-dom';
import * as Styled from './index.styles';

// Material UI Components
import {
  Theme,
  ThemeProvider,
  createStyles,
  createTheme,
  makeStyles,
} from '@material-ui/core/styles';

import AttachMoney from '@material-ui/icons/AttachMoney';
import BootstrapTooltip from 'components/bootstrapTooltip';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Header from 'components/Header';
import InputAdornment from '@material-ui/core/InputAdornment';
import Search from '@material-ui/icons/Search';
import Table from 'components/Table';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import dealNameDimensionConfig from './configs/dealNameDimensionConfig';
import tokens from 'tokens';
import { Avatar } from '@material-ui/core';
import {
  Deal,
  clearDeals,
  fetchDeals,
  setCurrentDealDimension,
  setUIState,
} from './slice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
      width: '100%',
    },
    buttonContainer: {
      marginLeft: 'auto',
    },
    dealActive: {
      backgroundColor: tokens.colorMessage,
      color: tokens.colorDefault,
      height: 24,
      width: 24,
    },
    dealActiveIcon: {
      height: 18,
      width: 18,
    },
    dealInactive: {
      backgroundColor: tokens.colorWarning,
      height: 24,
      width: 24,
    },
    dealButton: {
      textTransform: 'none',
      padding: 0,
    },
    dealButtonInnerContainer: {
      paddingLeft: 11,
      paddingRight: 11,
      paddingTop: 8,
      paddingBottom: 8,
    },
    dealCell: {
      minWidth: 240,
    },
    dealNameInnerContainer: {
      display: 'flex',
      flex: 1,
      flexDirection: 'row',
      alignItems: 'center',
    },
    divider: {
      height: 2,
    },
    header: {
      display: 'flex',
      height: '80px',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      width: '100%',
    },
    headerButton: {
      marginLeft: 'auto',
    },
    noDeals: {
      minHeight: '100%',
      display: 'flex',
      flexGrow: 1,
      alignItems: 'center',
      justifyContent: 'center',
      height: '80vh',
    },
    searchSalesLiftStudy: {
      margin: 16,
    },
    searchGroup: {
      margin: 8,
      marginTop: 16,
    },
    subNav: {
      minWidth: 250,
    },
    tableHeaderText: {
      fontSize: '.9em',
      color: tokens.colorButtonGray10,
    },
    tableCellText: {
      fontSize: '.8em',
    },
    tableHighlightText: {
      fontSize: '1em',
      color: tokens.colorButtonGray10,
    },
    textInput: {
      width: '100%',
      marginLeft: 'auto',
      alignSelf: 'center',
    },
    toolTipTextAlignment: {
      textAlign: 'left',
      padding: 10,
    },
  })
);

const inputs = createTheme({
  overrides: {
    MuiFormControl: {
      root: {
        maxHeight: 48,
      },
    },
    MuiFormLabel: {
      root: {
        maxHeight: 48,
      },
    },
    MuiInputLabel: {
      root: {
        marginTop: -5,

        '&$focused': {
          marginTop: 0,
        },
      },
    },
    MuiInputBase: {
      root: {
        maxHeight: 48,
      },
      input: {
        borderRadius: 10,
        alignSelf: 'center',
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 10,
      },
      input: {
        padding: '12px 14px',
      },
    },
    MuiButton: {
      root: {
        borderRadius: 10,
      },
      outlinedPrimary: {
        color: tokens.colorButtonPrimary,
      },
      containedPrimary: {
        backgroundColor: tokens.colorButtonPrimary,
        '&:hover': {
          backgroundColor: tokens.colorButtonPrimary,
        },
      },
    },
    MuiButtonBase: {
      root: {
        borderRadius: 10,
      },
    },
  },
});

const DealsManager = () => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const dealsAllIds = useAppSelector((state) => {
    return state.dealsReducer.entities.deals.allIds;
  });

  const dealsById = useAppSelector((state) => {
    return state.dealsReducer.entities.deals.byId;
  });

  const dealsLoading = useAppSelector(
    (state) => state.dealsReducer.ui.dealsLoading
  );

  React.useEffect(() => {
    dispatch(clearDeals(null));
    dispatch(fetchDeals(null));
    dispatch(
      setUIState({
        value: false,
        uiPropertyName: 'errorFetching',
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formatDealsForTable = (deal: Deal) => {
    return {
      //we need this id for lunr to reference
      id: deal.dealid,
      dealName: deal.dealname,
      dealId: deal.dealid,
      xandrDealId: deal.xandrdealid || 'N/A',
      creationDate: new Date(Number(deal.createdat))
        .toLocaleString('en-US')
        .replace(', ', '・'),
      biddername: deal.biddername,
      bidderid: deal.bidderid,
      buyername: deal.buyername,
      buyercode: deal.buyercode,
      partnerBuyerContact: deal.partnerbuyercontact,
      lineItems: deal.lineitems.length || 'N/A',
      submittedBy: deal.submittedby,
      confirmed: deal.confirmed,
      confirmedAt: deal.confirmedat
        ? new Date(Number(deal.confirmedat))
            .toLocaleString('en-US')
            .replace(', ', '・')
        : 'N/A',
      emailedAt: deal.emailedat
        ? new Date(Number(deal.emailedat))
            .toLocaleString('en-US')
            .replace(', ', '・')
        : 'N/A',
      launchedat: deal.launchedat
        ? new Date(Number(deal.launchedat))
            .toLocaleString('en-US')
            .replace(', ', '・')
        : 'N/A',
      active: deal.active,
      original: deal,
    };
  };

  const getDealRows = () => {
    return dealsAllIds.map((dealId) => {
      return formatDealsForTable(dealsById[dealId]);
    });
  };

  const deals = getDealRows();

  return (
    <ThemeProvider theme={inputs}>
      <Box className={classes.root}>
        <Header
          title="Klover PMP Deals Manager"
          action={
            <Styled.ButtonSpacing>
              <Styled.ButtonComponent
                onClick={() => {
                  history.push(`/main/pmp-deals/creator`);
                  dispatch(setCurrentDealDimension(dealNameDimensionConfig));
                }}
              >
                Create New Deal
              </Styled.ButtonComponent>
            </Styled.ButtonSpacing>
          }
        />

        {dealsLoading ? (
          <LoadingDeals />
        ) : deals.length === 0 ? (
          <NoExistingDeals />
        ) : (
          <DealsTable deals={deals} />
        )}
      </Box>
    </ThemeProvider>
  );
};

const DealsTable = ({ deals }: any) => {
  const classes = useStyles();
  const history = useHistory();
  const [searchInput, setSearchInput] = React.useState<string>('');
  const [lunrIndex, setLunrIndex] = React.useState<any>();

  React.useEffect(() => {
    setLunrIndex(
      lunr((lunrObj: any) => {
        lunrObj.field('dealName');
        lunrObj.field('xandrDealId');
        lunrObj.field('buyername');
        lunrObj.field('buyercode');
        lunrObj.field('biddername');
        lunrObj.field('bidderid');
        lunrObj.field('partnerBuyerContact');
        lunrObj.field('submittedBy');
        deals.forEach((deal: any) => {
          lunrObj.add(deal);
        });
      })
    );
  }, [deals]);

  const getFilteredDeals = React.useCallback(
    (searchInput) => {
      const refSet = new Set();
      if (lunrIndex) {
        lunrIndex.search(`*${searchInput}*`).forEach((queryRes: any) => {
          refSet.add(queryRes.ref);
        });
        return deals.filter((deal: any) => refSet.has(deal.id.toString()));
      } else {
        return deals;
      }
    },
    [lunrIndex, deals]
  );

  const data = React.useMemo(
    () => (searchInput === '' ? deals : getFilteredDeals(searchInput.trim())),
    [deals, getFilteredDeals, searchInput]
  );

  const columns = React.useMemo(
    () => [
      {
        Header: 'Deal Name',
        accessor: 'dealname',
        Cell: (row) => (
          <Styled.DealNameWrapper>
            {row.cell.row.original.active &&
            row.cell.row.original.confirmed &&
            row.cell.row.original.launchedat !== 'N/A' ? (
              <BootstrapTooltip
                title={
                  <ToolTipMessage
                    active={row.cell.row.original.active}
                    confirmed={row.cell.row.original.confirmed}
                    confirmedAt={row.cell.row.original.confirmedAt}
                    launchedAt={row.cell.row.original.launchedat}
                  />
                }
              >
                <Avatar className={classes.dealActive}>
                  <AttachMoney className={classes.dealActiveIcon} />
                </Avatar>
              </BootstrapTooltip>
            ) : (
              <BootstrapTooltip
                title={
                  <ToolTipMessage
                    active={row.cell.row.original.active}
                    confirmed={row.cell.row.original.confirmed}
                    confirmedAt={row.cell.row.original.confirmedAt}
                  />
                }
              >
                <Avatar className={classes.dealInactive}>
                  <AttachMoney color="disabled" fontSize="small" />
                </Avatar>
              </BootstrapTooltip>
            )}

            <Button
              color="primary"
              className={classes.dealButton}
              onClick={() => {
                history.push(
                  `/main/pmp-deals/editor/${row.cell.row.original.dealId}`
                );
              }}
              variant="text"
              size="large"
              fullWidth
              disableRipple
            >
              <Grid
                container
                direction="column"
                className={classes.dealButtonInnerContainer}
              >
                <Typography color="primary" component="p" variant="body2">
                  {row.cell.value}
                </Typography>
                <Typography
                  color="textSecondary"
                  component="p"
                  variant="caption"
                  align="left"
                >
                  ID: {row.cell.row.original.xandrDealId}
                </Typography>
              </Grid>
            </Button>
          </Styled.DealNameWrapper>
        ),
      },
      {
        Header: 'Buyer Name',
        accessor: 'buyername',
        Cell: (row) => (
          <>
            <Typography component="p" variant="body2">
              {row.cell.value ? row.cell.value : 'No Buyer Selected'} (ID:{' '}
              {row.cell.row.original.buyercode
                ? row.cell.row.original.buyercode
                : 'N/A'}
              )
            </Typography>
            <Typography
              color="textSecondary"
              component="p"
              variant="caption"
              align="left"
            >
              {row.cell.row.original.biddername
                ? row.cell.row.original.biddername
                : 'No DSP Selected'}{' '}
              (ID:{' '}
              {row.cell.row.original.bidderid
                ? row.cell.row.original.bidderid
                : 'N/A'}
              )
            </Typography>
          </>
        ),
      },
      {
        Header: 'Partner Buyer Contact',
        accessor: 'partnerBuyerContact',
        Cell: (row) => (
          <>
            <Typography component="p" variant="body2">
              {row.cell.value}
            </Typography>
            <Typography
              color="textSecondary"
              component="p"
              variant="caption"
              align="left"
            >
              Emailed: {row.cell.row.original.emailedAt}
            </Typography>
          </>
        ),
      },
      {
        Header: 'Submitted By',
        accessor: 'submittedBy',
        Cell: (row) => (
          <>
            <Typography component="p" variant="body2">
              {row.cell.value}
            </Typography>
            <Typography
              color="textSecondary"
              component="p"
              variant="caption"
              align="left"
            >
              Created: {row.cell.row.original.creationDate}
            </Typography>
          </>
        ),
      },
      {
        Header: 'Line Items',
        accessor: 'lineItems',
        id: 4, //id's needed is "accessor" is the same
        Cell: (row) => (
          <>
            <Typography component="p" variant="body2">
              {row.cell.value}
            </Typography>
          </>
        ),
      },
    ],
    [
      classes.dealActive,
      classes.dealActiveIcon,
      classes.dealButton,
      classes.dealButtonInnerContainer,
      classes.dealInactive,
    ]
  );

  return (
    <Styled.TableWrapper>
      <ThemeProvider theme={inputs}>
        <Styled.SearchWrapper>
          <TextField
            variant="outlined"
            label="Search Deals"
            onChange={(e) => setSearchInput(e.target.value)}
            defaultValue={searchInput}
            className={classes.textInput}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Search color="disabled" />
                </InputAdornment>
              ),
            }}
          />
        </Styled.SearchWrapper>
      </ThemeProvider>

      <Table columns={columns} data={data} />
    </Styled.TableWrapper>
  );
};

const ToolTipMessage = ({
  active,
  confirmed,
  confirmedAt,
  launchedAt,
}: any) => {
  const classes = useStyles();
  return (
    <div className={classes.toolTipTextAlignment}>
      <div>Deal Status</div>
      <li>Active: {active ? 'Yes' : 'No'}</li>
      <li>Confirmed: {confirmed ? 'Yes' : 'No'}</li>
      {confirmed && <li>Confirmed At: {confirmedAt}</li>}
      {launchedAt ? <li>Launched At: {launchedAt}</li> : <li>Launched: No</li>}
    </div>
  );
};

const NoExistingDeals = () => {
  const classes = useStyles();

  return (
    <Box className={classes.noDeals}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <AttachMoney />
        </Grid>
        <Grid item>
          <Typography component="h3" variant="h6">
            Deals haven&apos;t been created yet.
          </Typography>
        </Grid>
        <Grid item>
          <Typography component="h4" variant="caption">
            Start by clicking &quot;Create New Deal&quot;.
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

const LoadingDeals = () => {
  const classes = useStyles();

  return (
    <Box className={classes.noDeals}>
      <CircularProgress color="primary" size={40} />
    </Box>
  );
};

export default DealsManager;
