import React, { useEffect, useState } from 'react';
import compareAudiences from 'utils/compareAudiences';
import { Button, IconButton } from '@klover/attain-design-system';
import {
  ToggleDuplicateAudienceDialogAction,
  ToggleExportDialogAction,
  createAudience,
  saveAudience,
  toggleDraftStateDialog,
  toggleDuplicateAudienceDialog,
  toggleExportDialog,
  updateCurrentAudience,
} from '../slice';
import { buildAudienceObject } from '../sagas/audiences';
import { nanoid } from 'nanoid';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { useUser } from 'reactfire';

// Material UI Components
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import CloseIcon from '@material-ui/icons/Close';
import CreateIcon from '@material-ui/icons/Create';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import InputAdornment from '@material-ui/core/InputAdornment';
import SaveIcon from '@material-ui/icons/Save';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import {
  Theme,
  ThemeProvider,
  createStyles,
  createTheme,
  makeStyles,
} from '@material-ui/core/styles';

// Components
import AudienceDeleteDialog from '../audienceDeleteDialog';
import BootstrapTooltip from 'components/bootstrapTooltip';
import HistoryIcon from '@mui/icons-material/History';
import ExportHistroryDialog from '../exportHistoryDialog';
import tokens from 'tokens';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    audienceName: {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      width: 200,
    },
    button: {
      margin: `${theme.spacing(0, 1)} !important`,
    },
    exportSpinner: {
      margin: '0.15em 1.2em',
    },
    editButtonBox: {
      marginLeft: theme.spacing(4),
    },
    form: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
    },
  })
);

const inputs = createTheme({
  overrides: {
    MuiFormControl: {
      root: {
        maxHeight: 48,
      },
    },
    MuiFormLabel: {
      root: {
        maxHeight: 48,
      },
    },
    MuiInputLabel: {
      root: {
        marginTop: -5,

        '&$focused': {
          marginTop: 0,
        },
      },
    },
    MuiInputBase: {
      root: {
        maxHeight: 48,
      },
      input: {
        borderRadius: 10,
        alignSelf: 'center',
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 10,
      },
      input: {
        padding: '12px 14px',
      },
    },
    MuiButton: {
      root: {
        borderRadius: 10,
      },
      outlinedPrimary: {
        color: tokens.colorButtonPrimary,
      },
      containedPrimary: {
        backgroundColor: tokens.colorButtonPrimary,
        '&:hover': {
          backgroundColor: tokens.colorButtonPrimary,
        },
      },
    },
    MuiButtonBase: {
      root: {
        borderRadius: 10,
      },
    },
  },
});

interface AudienceNameProps {
  isAudienceLoading: boolean;
}

// we need to know when audience is loading to determine if we should disable some text fields and buttons
const AudienceName: React.FC<AudienceNameProps> = ({ isAudienceLoading }) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const [editMode, setEditMode] = useState(false);
  const [name, setName] = useState('');
  const [notChanging, setNotChanging] = useState(true);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openExportHistoryDialog, setOpenExportHistoryDialog] = useState(false);
  const { data: user } = useUser();
  const currentUser = user || { email: '' };

  const entitiesById = useAppSelector((state) => {
    return state.audienceReducer.entities.audiences.byId;
  });

  const currentGroup = useAppSelector((state) => {
    return state.audienceReducer.currentGroup;
  });

  const currentAudience = useAppSelector((state) => {
    return state.audienceReducer.currentAudience;
  });

  const isSaveDisabled = currentAudience ? currentAudience.name === '' : true;
  const isExportDisabled = currentAudience
    ? currentAudience.dimensions.length === 0
    : true;

  const currentDimensions = useAppSelector((state) => {
    return state.audienceReducer.currentDimensions;
  });

  const currentDimensionGroups = useAppSelector((state) => {
    return state.audienceReducer.currentDimensionGroups;
  });

  const currentConditions = useAppSelector((state) => {
    return state.audienceReducer.currentConditions;
  });

  const originalAudience = useAppSelector((state) => {
    return state.audienceReducer.originalAudience;
  });

  const audienceExporting = useAppSelector(
    (state) => state.audienceReducer.ui.audienceExporting
  );

  useEffect(() => {
    setName(currentAudience?.name || '');
    const isNameBlank = currentAudience?.name
      ? currentAudience.name === ''
      : true;
    setEditMode(isNameBlank);
  }, [currentAudience]);

  const exportHandler = () => {
    const actionProps: ToggleExportDialogAction = {
      open: true,
    };
    dispatch(toggleExportDialog(actionProps));
  };
  // added error handling
  const groupId = currentGroup
    ? currentGroup.id
    : originalAudience
    ? originalAudience.group_id
    : null;

  const updatedAudience = {
    ...currentAudience,
    updatedAt: Date.now(),
  };

  const audienceObject = buildAudienceObject({
    updatedAudience,
    currentDimensions,
    currentDimensionGroups,
    currentConditions,
  });

  const isTheSame = compareAudiences(originalAudience, audienceObject);

  const duplicateHandler = () => {
    const actionProps: ToggleDuplicateAudienceDialogAction = {
      open: true,
    };

    if (originalAudience) {
      if (isTheSame) {
        dispatch(toggleDuplicateAudienceDialog(actionProps));
      } else {
        dispatch(toggleDraftStateDialog({ open: true, duplicate: true }));
      }
    } else {
      dispatch(toggleDuplicateAudienceDialog(actionProps));
    }
  };

  const handleCancel = () => {
    setEditMode(false);
    if (currentAudience) {
      setName(currentAudience?.name);
    }
  };

  const handleEditName = () => {
    setEditMode(true);
  };

  const handleNameChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const name = event.target.value as string;
    setName(name);
  };

  const handleSave = () => {
    if (currentAudience) {
      const updatedAudience = {
        ...currentAudience,
        name: name,
        updatedAt: Date.now(),
        groupId: groupId,
        updated_by_email: currentUser.email,
        created_by_email: currentUser.email,
      };

      const normalizedObjects = {
        updatedAudience,
        currentDimensions,
        currentDimensionGroups,
        currentConditions,
      };

      const audienceExists = entitiesById[currentAudience.id];
      if (audienceExists) {
        dispatch(saveAudience(normalizedObjects));
      } else {
        dispatch(createAudience(normalizedObjects));
      }

      // TODO: move this to audiences saga
      dispatch(updateCurrentAudience(updatedAudience));
    }
  };

  return (
    <>
      <ThemeProvider theme={inputs}>
        {editMode ? (
          <Box className={classes.form}>
            <TextField
              disabled={isAudienceLoading}
              key={currentAudience?.name && notChanging ? nanoid() : null} // setting the key to the audience name to force confimation of render so that there is no overlap between audience name and label.
              onFocus={() => setNotChanging(false)} // but on focus we want to switch off the forced render
              id="audience-name"
              label="Audience Name"
              onChange={handleNameChange}
              defaultValue={currentAudience?.name}
              value={name}
              variant="outlined"
              style={{ marginRight: 8 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <BootstrapTooltip title={'Save Audience'}>
                      <span>
                        <IconButton
                          color="default"
                          disabled={name.length === 0 || isAudienceLoading}
                          onClick={handleSave}
                          size="small"
                        >
                          <NameSaveIcon isDisabled={isAudienceLoading} />
                        </IconButton>
                      </span>
                    </BootstrapTooltip>
                  </InputAdornment>
                ),
              }}
            />
            <BootstrapTooltip title={'Exit Save Audience'}>
              <span>
                <IconButton disabled={isAudienceLoading} onClick={handleCancel}>
                  <CloseIcon />
                </IconButton>
              </span>
            </BootstrapTooltip>
          </Box>
        ) : (
          <>
            <Box flexDirection="column">
              <BootstrapTooltip title={currentAudience?.name}>
                <Typography
                  className={classes.audienceName}
                  component="h2"
                  variant="h6"
                >
                  {currentAudience?.name}
                </Typography>
              </BootstrapTooltip>

              <Typography component="h3" variant="body2">
                {currentGroup?.name}
              </Typography>
            </Box>
            <Box className={classes.editButtonBox}>{/* spacing */}</Box>
            <BootstrapTooltip
              title={
                isAudienceLoading
                  ? 'Wait for Audience Load'
                  : 'Edit Audience Name'
              }
            >
              <span>
                <IconButton
                  color="default"
                  disabled={isAudienceLoading}
                  onClick={handleEditName}
                >
                  <CreateIcon />
                </IconButton>
              </span>
            </BootstrapTooltip>
            <BootstrapTooltip
              title={
                isAudienceLoading ? 'Wait for Audience Load' : 'Delete Audience'
              }
            >
              <span>
                <IconButton
                  color="default"
                  disabled={isAudienceLoading}
                  onClick={() => setOpenDeleteDialog(true)}
                >
                  <DeleteForeverIcon />
                </IconButton>
              </span>
            </BootstrapTooltip>
            <BootstrapTooltip
              title={
                isAudienceLoading
                  ? 'Wait for Audience Load'
                  : !isTheSame
                  ? 'Save to Duplicate'
                  : ''
              }
            >
              <span>
                <Button
                  className={classes.button}
                  disabled={isAudienceLoading || !isTheSame}
                  disableElevation
                  onClick={duplicateHandler}
                  variant="outlined"
                  color="primary"
                >
                  Duplicate
                </Button>
              </span>
            </BootstrapTooltip>
            <BootstrapTooltip
              title={
                isAudienceLoading
                  ? 'Wait for Audience Load'
                  : isExportDisabled
                  ? 'Add a Dimension'
                  : !isTheSame
                  ? 'Save to Export'
                  : ''
              }
            >
              <span>
                <Button
                  className={classes.button}
                  disabled={
                    isExportDisabled ||
                    isAudienceLoading ||
                    !isTheSame ||
                    audienceExporting
                  }
                  onClick={exportHandler}
                  variant="outlined"
                  color="primary"
                >
                  {audienceExporting ? (
                    <CircularProgress
                      className={classes.exportSpinner}
                      color="primary"
                      size={20}
                    />
                  ) : (
                    'Export'
                  )}
                </Button>
              </span>
            </BootstrapTooltip>
            <BootstrapTooltip
              title={
                isAudienceLoading
                  ? 'Wait for Audience Load'
                  : 'Show Audience History'
              }
            >
              <Button
                className={classes.button}
                onClick={() => setOpenExportHistoryDialog(true)}
                variant="outlined"
                color="primary"
                disabled={isAudienceLoading}
                startIcon={<HistoryIcon style={{ fontSize: '25px' }} />}
              >
                Audience History
              </Button>
            </BootstrapTooltip>

            <Button
              className={classes.button}
              disabled={isSaveDisabled || isAudienceLoading}
              disableElevation
              onClick={handleSave}
              variant="contained"
              color="primary"
            >
              Save
            </Button>
          </>
        )}
      </ThemeProvider>
      <AudienceDeleteDialog
        isOpen={openDeleteDialog}
        setOpenDialog={setOpenDeleteDialog}
        user={currentUser.email}
      />
      <ExportHistroryDialog
        isOpen={openExportHistoryDialog}
        setOpenDialog={setOpenExportHistoryDialog}
        data={currentAudience?.exportHistory || []}
        normalAudience={currentAudience}
      />
    </>
  );
};

interface NameSaveProps {
  isDisabled: boolean;
}

const NameSaveIcon = ({ isDisabled }: NameSaveProps) => {
  return (
    <>
      {isDisabled ? (
        <CircularProgress color="primary" size={20} />
      ) : (
        <SaveIcon />
      )}
    </>
  );
};

export default AudienceName;
