import React, { useEffect, useState } from 'react';
import nanoid from 'utils/nanoid';
import { useAppDispatch, useAppSelector } from 'redux/hooks';

import { SnackBarOptions, defaultSnackBarOptions } from 'interfaces/ui';

import { Button, IconButton } from '@klover/attain-design-system';
import {
  OfferGroup,
  ToggleGroupNameDialogAction,
  createGroup,
  setGroupSavingState,
  toggleGroupNameDialog,
} from '../slice';

// Material UI Components
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import tokens from 'tokens';
import {
  Theme,
  ThemeProvider,
  createStyles,
  createTheme,
  makeStyles,
} from '@material-ui/core/styles';

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: theme.spacing(3),
    },
    groupName: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(3),
    },
    title: {
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 23,
      paddingLeft: 16,
      paddingTop: 16,
    },
  })
);

const dialog = createTheme({
  overrides: {
    MuiDialog: {
      paper: {
        borderRadius: 10,
      },
      paperWidthMd: {
        maxWidth: 1100,
      },
    },
    MuiMenu: {
      list: {
        padding: 0,
      },
    },
    MuiInputBase: {
      input: {
        borderRadius: 10,
        padding: '12px 14px',
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 10,
      },
    },
    MuiButton: {
      root: {
        borderRadius: 8,
      },
      outlinedPrimary: {
        color: tokens.colorButtonPrimary,
      },
      containedPrimary: {
        backgroundColor: tokens.colorButtonPrimary,
        '&:hover': {
          backgroundColor: tokens.colorButtonPrimary,
        },
      },
    },
  },
});

interface Props {
  open: boolean;
}

const GroupNameDialog = ({ open }: Props) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const [name, setName] = useState('');
  const [snackBarOptions, setSnackBarOptions] = useState<SnackBarOptions>(
    defaultSnackBarOptions
  );

  const groupSaving = useAppSelector(
    (state) => state.offerWallReducer.ui.groupSaving
  );

  useEffect(() => {
    let snackBarOptions: SnackBarOptions;
    setName('');
    switch (groupSaving.state) {
      case 'done':
        setName('');
        dispatchClose();

        snackBarOptions = {
          open: true,
          severity: 'success',
          message: groupSaving.message || '',
        };
        break;
      case 'failed':
        snackBarOptions = {
          open: true,
          severity: 'error',
          message: groupSaving.message || '',
        };
        break;
      default:
        snackBarOptions = {
          open: false,
          severity: 'success',
          message: '',
        };
        break;
    }
    setSnackBarOptions(snackBarOptions);
  }, [groupSaving]);

  const closeSnackBar = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') return;
    const updatedSnackBarOptions = {
      ...snackBarOptions,
      open: false,
    };
    dispatch(setGroupSavingState({ state: 'idle' }));
    setSnackBarOptions(updatedSnackBarOptions);
  };

  const loading = groupSaving.state === 'loading';

  const dispatchClose = () => {
    const actionProps: ToggleGroupNameDialogAction = {
      open: false,
    };
    setSnackBarOptions(defaultSnackBarOptions);
    dispatch(toggleGroupNameDialog(actionProps));
  };

  const handleNameChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const name = event.target.value as string;
    setName(name);
  };

  const handleCreateGroup = () => {
    if (name && name !== '') {
      dispatch(createGroup(newGroup));
    } else {
      dispatch(
        setGroupSavingState({
          state: 'failed',
          message: 'Group name is required',
        })
      );
    }
  };

  const handleClose = () => {
    dispatchClose();
  };

  const isCreateDisabled = name === '';

  const newGroup: OfferGroup = {
    id: nanoid(),
    name: name,
    offers: [],
  };

  return (
    <>
      <ThemeProvider theme={dialog}>
        <Dialog
          className={classes.root}
          fullWidth={true}
          onClose={handleClose}
          open={open}
        >
          <Box m={1}>
            <Grid container alignItems="center">
              <Grid item xs={6}>
                <Typography className={classes.title}>New Group</Typography>
              </Grid>
              <Grid item container justifyContent="flex-end" xs={6}>
                <IconButton onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Box>

          {/* <Box m={3}> */}
          <DialogContent style={{ padding: '24px 32px 8px 32px' }}>
            <DialogContentText
              style={{
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: 16,
              }}
            >
              How do you want to name the new group?
            </DialogContentText>
            <TextField
              className={classes.groupName}
              onChange={handleNameChange}
              value={name}
              fullWidth
              id="name"
              label="Group Name"
              type="name"
              variant="outlined"
            />
          </DialogContent>
          {/* </Box> */}

          {/* Bottom Divider */}
          <Grid container spacing={0} style={{ marginTop: 150 }}>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Grid>

          {/* Actions */}
          <Box m={1}>
            <DialogActions>
              <Button color="primary" onClick={handleClose} variant="outlined">
                Cancel
              </Button>

              {loading ? (
                <Box
                  width={142}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <CircularProgress color="primary" size={20} />
                </Box>
              ) : (
                <Button
                  color="primary"
                  disabled={isCreateDisabled}
                  disableElevation
                  onClick={handleCreateGroup}
                  variant="contained"
                >
                  Create Group
                </Button>
              )}
            </DialogActions>
          </Box>
        </Dialog>
      </ThemeProvider>

      <Snackbar
        open={snackBarOptions.open}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={2000}
        onClose={closeSnackBar}
      >
        <Alert onClose={closeSnackBar} severity={snackBarOptions.severity}>
          {snackBarOptions.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default GroupNameDialog;
