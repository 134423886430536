import React, { useEffect, useRef } from 'react';
import _ from 'lodash';
import { Button, IconButton } from '@klover/attain-design-system';
import { nanoid } from 'nanoid';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import 'date-fns';

// Material UI Components
import Box from '@material-ui/core/Box';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import tokens from 'tokens';
import {
  Autocomplete,
  ToggleButton,
  ToggleButtonGroup,
} from '@material-ui/lab';
import {
  Bidder,
  Buyer,
  LineItem,
  clearBuyers,
  fetchBidders,
  fetchBuyers,
  fetchLineItems,
  saveDeal,
  setCurrentDeal,
  setDealsDimensionDialogOpen,
  setOriginalDeal,
  setUIState,
  updateCurrentDeal,
} from '../slice';
import { Check, Clear } from '@material-ui/icons';
import { Checkbox, Switch } from '@material-ui/core';
import {
  Theme,
  ThemeProvider,
  createStyles,
  createTheme,
  makeStyles,
} from '@material-ui/core/styles';

// all options for the select components

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    buttonFormat: {
      margin: '0em 0.5em',
    },
    formControl: {
      minWidth: 450,
    },
    checkIcon: {
      color: 'lightgreen',
      marginLeft: '5px',
    },
    clearIcon: {
      color: 'red',
      marginLeft: '5px',
    },
    close: {
      height: 24,
      width: 24,
    },
    columnTitles: {
      color: 'grey',
      display: 'flex',
      fontSize: 10,
      padding: 10,
      paddingTop: 30,
      paddingBottom: 0,
      marginLeft: 24,
    },
    columnNameAndValue: {
      display: 'flex',
      alignItems: 'center',
      fontSize: 10,
      padding: 10,
      marginLeft: 24,
    },
    datePickerStyling: {
      width: 200,
    },
    divider: {
      borderLeft: '3px solid ' + tokens.colorSuccess,
      paddingLeft: 5,
      flexGrow: 1,
    },
    header: {
      padding: '10px 12px 10px 24px',
    },
    icon: {
      height: 24,
      width: 24,
    },
    inputColumn: {
      display: 'flex',
      flexDirection: 'column',
    },
    inputColumnSpacing: {
      paddingTop: 10,
    },
    lineItemContainer: {
      display: 'flex',
      flexDirection: 'column',
      overflow: 'scroll',
      height: 200,
      width: 450,
    },
    textInput: {
      paddingBottom: 15,
      width: 450,
    },
    selectInput: {
      paddingBottom: 15,
      width: 450,
    },
    root: {
      margin: theme.spacing(3),
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      marginLeft: 107,
      height: 22,
      marginBottom: 5,
    },
    dealsChecklistContainer: {
      display: 'flex',
      alignItems: 'center',
      fontSize: 15,
    },
    noDealsText: {
      padding: '1em',
      fontSize: 15,
    },
    toggleButtonGroup: {
      marginBottom: 4,
      width: 450,
    },
    toggleButtonSelected: {
      color: tokens.colorTextDefault,
    },
  })
);

const button = createTheme({
  overrides: {
    MuiToggleButton: {
      root: {
        border: '2px solid rgba(0, 0, 0, 0.12)',
        borderColor: tokens.colorButtonPrimary,
        borderRadius: 10,
        marginBottom: 4,

        '&:hover': {
          borderColor: tokens.colorButtonPrimary,
        },
        '&$selected': {
          backgroundColor: tokens.colorButtonPrimary,
          '&:hover': {
            backgroundColor: tokens.colorButtonPrimary,
          },
        },
      },
      label: {
        color: tokens.colorTextPrimary,
        textTransform: 'none',
        '&:hover': {
          color: tokens.colorTextPrimary,
        },
      },
    },
  },
});

const dialog = createTheme({
  overrides: {
    MuiDialog: {
      paper: {
        borderRadius: 10,
      },
      paperWidthMd: {
        maxWidth: 1100,
      },
    },
    MuiMenu: {
      list: {
        padding: 0,
      },
    },
    MuiInputBase: {
      input: {
        borderRadius: 10,
        padding: '12px 14px',
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 10,
      },
    },
    MuiButton: {
      root: {
        borderRadius: 8,
      },
      outlinedPrimary: {
        color: tokens.colorButtonPrimary,
      },
      containedPrimary: {
        backgroundColor: tokens.colorButtonPrimary,
        '&:hover': {
          backgroundColor: tokens.colorButtonPrimary,
        },
      },
    },
  },
});

const icon = createTheme({
  overrides: {
    MuiSvgIcon: {
      root: {
        width: 32,
        height: 32,
      },
    },
  },
});

const inputs = createTheme({
  overrides: {
    MuiFormControl: {
      root: {
        maxHeight: 48,
      },
    },
    MuiFormLabel: {
      root: {
        maxHeight: 48,
      },
    },
    MuiInputLabel: {
      root: {
        marginTop: -5,

        '&$focused': {
          marginTop: 0,
        },
      },
    },
    MuiInputBase: {
      root: {
        maxHeight: 48,
      },
      input: {
        borderRadius: 10,
        alignSelf: 'center',
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 10,
      },
      input: {
        padding: '12px 14px',
      },
    },
    MuiButton: {
      root: {
        borderRadius: 10,
      },
      outlinedPrimary: {
        color: tokens.colorButtonPrimary,
      },
      containedPrimary: {
        backgroundColor: tokens.colorButtonPrimary,
        '&:hover': {
          backgroundColor: tokens.colorButtonPrimary,
        },
      },
    },
    MuiButtonBase: {
      root: {
        borderRadius: 10,
      },
    },
  },
});

const EMAIL_REGEX = new RegExp(
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);

const LineItemSelector = ({ handleOnClick }: any) => {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const scrollRef = useRef() as React.RefObject<HTMLDivElement>;

  const currentDeal = useAppSelector((state) => state.dealsReducer.currentDeal);
  const lineitems = useAppSelector((state) =>
    state.dealsReducer.entities.lineitems.allIds.map((id) => ({
      ...state.dealsReducer.entities.lineitems.byId[id],
    }))
  );
  const inputValue = useAppSelector(
    (state) => state.dealsReducer.ui.lineitemFilter
  );
  const scrollPosition = useAppSelector(
    (state) => state.dealsReducer.ui.lineitemScrollPosition
  );

  const setInputValue = (value: string) => {
    dispatch(setUIState({ value, uiPropertyName: 'lineitemFilter' }));
  };

  const handleClickWrapper = (value: LineItem) => {
    if (scrollRef.current) {
      dispatch(
        setUIState({
          value: scrollRef.current.scrollTop,
          uiPropertyName: 'lineitemScrollPosition',
        })
      );
    }
    handleOnClick(value);
  };

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollPosition;
    }
  }, [scrollPosition]);

  useEffect(() => {
    dispatch(fetchLineItems(null));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <TextField
        label={'Search by line-item name and  line-item ID'}
        variant="outlined"
        className={classes.textInput}
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
      />
      <div className={classes.lineItemContainer} ref={scrollRef}>
        <ThemeProvider theme={inputs}>
          {lineitems && lineitems.length ? (
            lineitems
              .filter(
                (lineitem) =>
                  lineitem.lineitemid.toString().includes(inputValue) ||
                  lineitem.lineitemname
                    .toLowerCase()
                    .includes(inputValue.toLowerCase()) ||
                  currentDeal?.lineitems.find(
                    (currentlineitem) =>
                      lineitem.lineitemid === currentlineitem.lineitemid
                  )
              )
              .map((lineitem) => (
                <div key={nanoid()} className={classes.dealsChecklistContainer}>
                  <div>
                    <Checkbox
                      color="primary"
                      checked={
                        currentDeal
                          ? !!currentDeal.lineitems.find(
                              (li) => li.lineitemid === lineitem.lineitemid
                            )
                          : false
                      }
                      onClick={() => handleClickWrapper(lineitem)}
                    />
                  </div>
                  <div>
                    {lineitem.lineitemname} (ID: {lineitem.lineitemid})
                  </div>
                </div>
              ))
          ) : (
            <div className={classes.noDealsText}>No available line items</div>
          )}
        </ThemeProvider>
      </div>
    </div>
  );
};

const BidderSelect = ({ handleChange }: any) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const currentDeal = useAppSelector((state) => state.dealsReducer.currentDeal);
  const fetchedBidders: Array<Bidder> = useAppSelector((state) =>
    state.dealsReducer.entities.bidders.allIds.map((id) => ({
      biddername: state.dealsReducer.entities.bidders.byId[id].biddername,
      bidderid: state.dealsReducer.entities.bidders.byId[id].bidderid,
    }))
  );

  const inputValue = useAppSelector(
    (state) => state.dealsReducer.ui.bidderLabel
  );

  const setInputValue = (value: string) => {
    dispatch(setUIState({ value, uiPropertyName: 'bidderLabel' }));
  };

  useEffect(() => {
    dispatch(
      setUIState({
        value: currentDeal?.bidderid
          ? `${currentDeal?.biddername} (ID: ${currentDeal?.bidderid})`
          : '',
        uiPropertyName: 'bidderLabel',
      })
    );
    dispatch(fetchBidders(null));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Autocomplete
      value={
        currentDeal && currentDeal.biddername && currentDeal.bidderid
          ? {
              biddername: currentDeal.biddername,
              bidderid: currentDeal.bidderid,
            }
          : {
              biddername: '',
              bidderid: '',
            }
      }
      inputValue={inputValue}
      options={fetchedBidders}
      onChange={(_e, v, r) => {
        if (r === 'clear') {
          setInputValue('');
          handleChange({
            biddername: '',
            bidderid: '',
            buyercode: '',
          });
        }
        if (v?.bidderid && v?.biddername) {
          setInputValue(`${v.biddername} (ID: ${v.bidderid})`);
          handleChange(v);
        }
      }}
      getOptionLabel={(option) =>
        `${option.biddername} (ID: ${option.bidderid})`
      }
      onBlur={() => {
        if (currentDeal?.bidderid) {
          setInputValue(
            `${currentDeal.biddername} (ID: ${currentDeal.bidderid})`
          );
        }
      }}
      openOnFocus
      disabled={!!currentDeal?.launchedat}
      renderInput={(params) => (
        <TextField
          label={'Search by DSP'}
          className={classes.textInput}
          {...params}
          value={inputValue}
          onChange={(e) => {
            const nextInputValue = e.target.value;
            setInputValue(nextInputValue);
          }}
          variant="outlined"
        />
      )}
    />
  );
};

const BuyerSelect = ({ handleChange }: any) => {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const manual = useAppSelector((state) => state.dealsReducer.ui.manual);
  const currentDeal = useAppSelector((state) => state.dealsReducer.currentDeal);
  const inputValue = useAppSelector(
    (state) => state.dealsReducer.ui.buyerLabel
  );
  const fetchedBuyers: Array<Buyer> = useAppSelector((state) =>
    state.dealsReducer.entities.buyers.allIds.map((id) => ({
      buyername: state.dealsReducer.entities.buyers.byId[id].buyername,
      buyercode: state.dealsReducer.entities.buyers.byId[id].buyercode,
    }))
  );

  const setInputValue = (value: string) => {
    dispatch(setUIState({ value, uiPropertyName: 'buyerLabel' }));
  };

  const handleManualBuyerChange = (e: any) => {
    handleChange({
      buyercode: e.target.value,
      buyername: '',
    });
  };

  const handleChangeWrapper = (v: any) => {
    handleChange({ ...v });
  };

  const handleChangeManual = (_e: any, value: boolean) => {
    dispatch(clearBuyers(null));
    dispatch(setUIState({ value, uiPropertyName: 'manual' }));
    handleChange({
      buyercode: '',
      buyername: '',
    });
    setInputValue('');
  };

  useEffect(() => {
    dispatch(
      setUIState({
        value: currentDeal?.buyercode
          ? `${currentDeal?.buyername} (ID: ${currentDeal?.buyercode})`
          : '',
        uiPropertyName: 'buyerLabel',
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        width: 450,
      }}
    >
      {!currentDeal?.launchedat && (
        <ThemeProvider theme={button}>
          <ToggleButtonGroup
            color="primary"
            value={manual}
            exclusive
            size="small"
            onChange={handleChangeManual}
          >
            <ToggleButton style={{ marginLeft: 'auto' }} value={true}>
              <span className={manual ? classes.toggleButtonSelected : ''}>
                Manual
              </span>
            </ToggleButton>
            <ToggleButton value={false}>
              <span className={!manual ? classes.toggleButtonSelected : ''}>
                Search
              </span>
            </ToggleButton>
          </ToggleButtonGroup>
        </ThemeProvider>
      )}
      {manual ? (
        <TextField
          variant="outlined"
          onBlur={handleManualBuyerChange}
          defaultValue={currentDeal?.buyercode}
          className={classes.textInput}
          label={'Buyer ID/Code'}
          disabled={!currentDeal?.bidderid || !!currentDeal?.launchedat}
        />
      ) : (
        <Autocomplete
          id={nanoid()}
          value={
            currentDeal && currentDeal.buyercode && currentDeal.buyername
              ? {
                  buyername: currentDeal.buyername,
                  buyercode: currentDeal.buyercode,
                }
              : {
                  buyername: '',
                  buyercode: '',
                }
          }
          inputValue={inputValue}
          onChange={(_e, v, r) => {
            if (r === 'clear') {
              handleChange({
                buyercode: '',
                buyername: '',
              });
              setInputValue('');
            }
            if (v?.buyername && v?.buyercode) {
              setInputValue(`${v?.buyername} (ID: ${v?.buyercode})`);
              handleChangeWrapper(v);
            }
          }}
          onBlur={() => {
            if (currentDeal?.buyercode)
              setInputValue(
                `${currentDeal.buyername} (ID: ${currentDeal.buyercode})`
              );
          }}
          openOnFocus
          getOptionLabel={(option) =>
            `${option.buyername} (ID: ${option.buyercode})`
          }
          options={fetchedBuyers}
          disabled={!currentDeal?.bidderid || !!currentDeal?.launchedat}
          renderInput={(params) => (
            <TextField
              label={'Search Buyer Name/ID'}
              className={classes.textInput}
              {...params}
              value={inputValue}
              onChange={(e) => {
                const nextInputValue = e.target.value;
                setInputValue(nextInputValue);
                if (nextInputValue.length === 3) {
                  //refetch when input val === 3
                  dispatch(
                    fetchBuyers({
                      bidderid: currentDeal?.bidderid,
                      queryString: nextInputValue,
                    })
                  );
                } else if (nextInputValue.length < 3) {
                  dispatch(clearBuyers(null));
                }
              }}
              variant="outlined"
            />
          )}
        />
      )}
    </div>
  );
};

export const InputType = (props: any) => {
  const {
    className,
    inputType,
    keyName,
    helperText,
    required,
    type,
    muiProps,
    displaymode,
  } = props;
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const currentDeal = useAppSelector((state) => state.dealsReducer.currentDeal);
  const validationMap = useAppSelector(
    (state) => state.dealsReducer.ui.validationMap
  );

  const clearBuyerField = () => {
    dispatch(
      updateCurrentDeal({
        value: '',
        propertyName: 'buyercode',
      })
    );
    dispatch(
      updateCurrentDeal({
        value: '',
        propertyName: 'buyername',
      })
    );
    dispatch(
      setUIState({
        value: '',
        uiPropertyName: 'buyerLabel',
      })
    );
    dispatch(clearBuyers(null));
  };

  const handleUpdateDeal = (value: any) => {
    if (
      required &&
      (_.isEmpty(value) || (type === 'email' && !isValidEmail(value)))
    ) {
      dispatch(
        setUIState({
          value: {
            ...validationMap,
            [keyName]: true,
          },
          uiPropertyName: 'validationMap',
        })
      );
    } else {
      dispatch(
        setUIState({
          value: {
            ...validationMap,
            [keyName]: false,
          },
          uiPropertyName: 'validationMap',
        })
      );
    }
    switch (keyName) {
      case 'bidder':
        dispatch(
          updateCurrentDeal({
            value: value.biddername,
            propertyName: 'biddername',
          })
        );
        dispatch(
          updateCurrentDeal({
            value: value.bidderid,
            propertyName: 'bidderid',
          })
        );
        clearBuyerField();
        break;
      case 'buyer':
        dispatch(
          updateCurrentDeal({
            value: value.buyername,
            propertyName: 'buyername',
          })
        );
        dispatch(
          updateCurrentDeal({
            value: value.buyercode,
            propertyName: 'buyercode',
          })
        );
        break;
      default:
        dispatch(updateCurrentDeal({ value, propertyName: keyName }));
    }
  };

  const getHelperText = () => {
    return validationMap[keyName] ? helperText : '';
  };

  const isValidEmail = (email: string): boolean => {
    return EMAIL_REGEX.test(email.toLowerCase());
  };

  const isError = () => {
    return validationMap[keyName];
  };

  switch (inputType) {
    case 'textfield':
      return (
        <TextField
          variant="outlined"
          onBlur={(e) => handleUpdateDeal(e.target.value)}
          defaultValue={currentDeal ? currentDeal[keyName] : ''}
          helperText={getHelperText()}
          type={type || 'string'}
          error={isError()}
          className={className || classes.textInput}
          {...muiProps}
        />
      );
    case 'switch':
      if (displaymode) {
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            {currentDeal[keyName] ? (
              <>
                <Typography noWrap>Yes</Typography>
                <Check className={classes.checkIcon} />
              </>
            ) : (
              <>
                <Typography noWrap>No</Typography>
                <Clear className={classes.clearIcon} />
              </>
            )}
          </div>
        );
      } else {
        return (
          <Switch
            color="primary"
            disableRipple
            onChange={(e) => handleUpdateDeal(e.target.checked)}
            checked={currentDeal[keyName] || false}
            {...muiProps}
          />
        );
      }
    case 'bidderselect':
      return <BidderSelect handleChange={handleUpdateDeal} />;
    case 'buyerselect':
      return <BuyerSelect handleChange={handleUpdateDeal} />;
    case 'lineitemSelector':
      return <LineItemSelector handleOnClick={handleUpdateDeal} />;
    //display type cases
    case 'displaydate':
      return (
        <Typography variant="body1">
          {currentDeal[keyName]
            ? new Date(Number(currentDeal[keyName]))
                .toLocaleString('en-US')
                .replace(', ', '・')
            : 'N/A'}
        </Typography>
      );
    default:
      return null;
  }
};

const DealsDimensionDialog = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const open = useAppSelector(
    (state) => state.dealsReducer.ui.dealDimensionDialog
  );

  const currentDeal = useAppSelector((state) => state.dealsReducer.currentDeal);
  const currentDealDimension = useAppSelector(
    (state) => state.dealsReducer.currentDimension
  );

  const originalDeal = useAppSelector(
    (state) => state.dealsReducer.originalDeal
  );

  const isValidEmail = (email: string): boolean => {
    return EMAIL_REGEX.test(email.toLowerCase());
  };

  const isInvalid = () => {
    const currentInputs = currentDealDimension.inputs;
    for (let i = 0; i < currentInputs.length; i++) {
      if (
        (currentInputs[i].required &&
          currentDeal &&
          _.isEmpty(currentDeal[currentInputs[i].stepperKeyName])) ||
        (currentInputs[i].type === 'email' &&
          !isValidEmail(currentDeal?.partnerbuyercontact))
      ) {
        return true;
      }
    }
    return false;
  };

  const handleClearChanges = () => {
    dispatch(
      setUIState({
        value: `${originalDeal?.biddername} (ID: ${originalDeal?.bidderid})`,
        uiPropertyName: 'bidderLabel',
      })
    );
    dispatch(
      setUIState({
        value: `${originalDeal?.buyername} (ID: ${originalDeal?.buyercode})`,
        uiPropertyName: 'buyerLabel',
      })
    );
    dispatch(setCurrentDeal(_.cloneDeep(originalDeal)));
  };

  const handleClose = () => {
    handleClearChanges();
    dispatch(setDealsDimensionDialogOpen(false));
  };

  const handleSave = () => {
    dispatch(setOriginalDeal(_.cloneDeep(currentDeal)));
    dispatch(setDealsDimensionDialogOpen(false));
    dispatch(saveDeal(currentDeal));
  };

  return (
    <>
      <ThemeProvider theme={dialog}>
        <Dialog
          className={classes.root}
          fullWidth={true}
          maxWidth="md"
          onClose={handleClose}
          open={open}
        >
          <Box className={classes.header}>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={6}>
                <Title dimensionName={currentDealDimension.dimensionName} />
              </Grid>
              <Grid item container justifyContent="flex-end" xs={6}>
                <IconButton onClick={handleClose}>
                  <CloseIcon className={classes.close} />
                </IconButton>
              </Grid>
            </Grid>
          </Box>

          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Grid>
          <Grid className={classes.columnTitles}>
            <Grid item xs={6}>
              NAME
            </Grid>
            <Grid>VALUE</Grid>
          </Grid>
          <Divider />
          {currentDealDimension ? (
            <Grid className={classes.inputColumnSpacing}>
              <Grid className={classes.inputColumn}>
                {currentDealDimension.inputs.map(
                  ({
                    stepperKeyName,
                    required,
                    stepperInputType,
                    stepperInputLabel,
                    helperText,
                    muiProps,
                    forEditor,
                  }: any) => {
                    if (!stepperInputType) return null;
                    return (
                      <Grid
                        key={nanoid()}
                        className={classes.columnNameAndValue}
                      >
                        <Grid item xs={6}>
                          <Typography>{stepperInputLabel}</Typography>
                        </Grid>
                        <Grid>
                          <InputType
                            inputType={stepperInputType}
                            keyName={stepperKeyName}
                            helperText={helperText || ''}
                            required={required || null}
                            muiProps={muiProps}
                            displaymode={!forEditor}
                          />
                        </Grid>
                      </Grid>
                    );
                  }
                )}
              </Grid>
            </Grid>
          ) : null}
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Grid>

          <Box m={3} marginTop={2} marginBottom={2}>
            <Grid className={classes.buttonContainer}>
              <Grid>
                <span>
                  <Button
                    color="primary"
                    className={classes.buttonFormat}
                    variant="contained"
                    disableElevation
                    onClick={handleClearChanges}
                  >
                    Clear Changes
                  </Button>
                </span>
                <span>
                  <Button
                    color="primary"
                    className={classes.buttonFormat}
                    disableElevation
                    variant="contained"
                    disabled={isInvalid()}
                    onClick={handleSave}
                  >
                    Save
                  </Button>
                </span>
              </Grid>
            </Grid>
          </Box>
        </Dialog>
      </ThemeProvider>
    </>
  );
};

const Title = ({ dimensionName }: any) => (
  <ThemeProvider theme={icon}>
    <Grid container item xs={12} alignItems="center">
      <Grid item container direction="column" xs={11} style={{ padding: 5 }}>
        <Grid item>
          <Typography component="h3" variant="h6">
            {_.startCase(dimensionName)}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  </ThemeProvider>
);

export default DealsDimensionDialog;
