import AudienceEditor from '../audienceManager/editor';
import AudienceManager from '../audienceManager';
import CustomAudiences from 'pages/customAudiences';
import CustomSnackBar from '../audienceManager/customSnackBar';
import DealsCreator from '../pmpDealsManager/dealsCreator';
import DealsEditor from '../pmpDealsManager/dealsEditor';
import Forecasting from '../forecasting';
import Help from '../help';
import Insights from '../insights';
import LogLevelData from '../pmpDealsManager/logLevelData';
import Login from 'pages/auth';
import Navigation from 'components/navigation';
import Notifications from '../notifications';
import OfferWallPages from './offerWallPages';
import PmpDealsManager from '../pmpDealsManager';
import Profile from 'components/profile';
import QuickQuestionPages from './quickQuestionPages';
import React from 'react';
import SalesLiftEditor from '../salesLiftStudyManager/editor';
import SalesLiftStudyCreator from '../salesLiftStudyManager/salesLiftStudyCreator';
import SalesLiftStudyManager from '../salesLiftStudyManager';
import Spinner from 'components/spinner';
import tokens from 'tokens';
import {
  Badge,
  Box,
  Grid,
  Paper,
  Theme,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import { ILoginReducer } from 'types/types';
import { IconButton } from '@klover/attain-design-system';
import { Link, Route, Switch } from 'react-router-dom';
import { Notification } from 'interfaces/notifications';
import { Notifications as NotificationsIcon } from '@material-ui/icons';
import { RootStateOrAny, useSelector } from 'react-redux';
import { removeNotification as removeAudienceNotification } from '../audienceManager/slice';
import { removeNotification as removeDealsNotification } from '../pmpDealsManager/slice';
import { removeNotification as removeOfferNotification } from '../offerWallManager/slice';
import { removeNotification as removeSalesLiftStudyNotification } from '../salesLiftStudyManager/slice';
import { setAudienceLoadingState } from '../../pages/audienceManager/slice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { useHistory } from 'react-router';
import { useSigninCheck } from 'reactfire';
import { LoadingStates } from 'constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      height: '100vh',
    },
    title: {
      color: tokens.colorBodynegative,
      fontWeight: 600,
      lineHeight: '40px',
      paddingBottom: tokens.spacing.lg,
    },
    grid: {
      marginLeft: 240,
      flexGrow: 1,
    },
    navigation: {
      height: '100vh',
      width: '240px',
      color: theme.palette.text.primary,
      backgroundColor: '#372D76',
      borderRadius: 0,
      position: 'fixed',
      top: 0,
      padding: 16,
      margin: 0,
    },
    notificationBadge: {
      padding: '8px',
      marginLeft: '-6px',
      color: '#DADEE2 !important',
      '&:hover': {
        color: 'white',
      },
      '&:focus': {
        color: 'white',
      },
    },
    profile: {
      position: 'fixed',
      bottom: 24,
      left: 20,
    },
    main: {
      backgroundColor: tokens.colorPagebg,
      height: '100vh',
      overflowY: 'scroll',
    },
  })
);

const NotAuthorized: React.FC = () => {
  console.log('Not Authorized');
  return <Spinner />;
};

const Main: React.FC = () => {
  const dispatch = useAppDispatch();
  const { status, data: signInCheckResult } = useSigninCheck();
  const classes = useStyles();
  const history = useHistory();
  // Reducers
  const authData: ILoginReducer = useSelector(
    (state: RootStateOrAny) => state.loginReducer
  );

  const audienceManagerNotifications = useAppSelector(
    (state) => state.audienceReducer.ui.notifications
  );

  const currentNotifications = useAppSelector((state) => {
    return state.notificationsReducer.currentNotifications;
  });

  const unreadNotifications = currentNotifications?.reduce(
    (accumulator: number, currVal: Notification) => {
      return currVal.read ? accumulator : accumulator + 1;
    },
    0
  );

  const offerWallManagerNotifications = useAppSelector(
    (state) => state.offerWallReducer.ui.notifications
  );

  const salesLiftStudyManagerNotifications = useAppSelector(
    (state) => state.salesLiftStudiesReducer.ui.notifications
  );

  const dealsManagerNotifications = useAppSelector(
    (state) => state.dealsReducer.ui.notifications
  );

  React.useEffect(() => {
    if (!authData.isLoggedIn) {
      history.push('/');
    }
  }, [authData, history]);
  
  React.useEffect(() => {
    dispatch(setAudienceLoadingState({ state: LoadingStates.DONE }));
  }, []);

  if (status === 'loading') {
    return <NotAuthorized />;
  }

  if (signInCheckResult.signedIn === true) {
    return (
      <div className={classes.root}>
        <Grid container spacing={0}>
          <Grid item>
            <Paper className={classes.navigation}>
              <Box>
                <Typography
                  className={classes.title}
                  component="h1"
                  variant="h5"
                >
                  Klover Data Suite
                </Typography>
              </Box>
              <Box>
                <Navigation />
              </Box>
              <Box className={classes.profile}>
                <IconButton
                  onClick={() => {
                    history.push('/main/notifications');
                  }}
                >
                  <Badge
                    badgeContent={unreadNotifications}
                    overlap="circular"
                    className={classes.notificationBadge}
                  >
                    <NotificationsIcon />
                  </Badge>
                </IconButton>
                <Profile />
              </Box>
            </Paper>
          </Grid>
          <Grid item className={classes.grid}>
            <Box className={classes.main} mt={0} ml={0}>
              <Switch>
                <Route exact path="/main/audience-manager">
                  <AudienceManager />
                </Route>
                <Route path="/main/audience-manager/editor/:audienceid?">
                  <AudienceEditor />
                </Route>
                <Route exact path="/main/custom-audience-manager">
                  <CustomAudiences />
                </Route>
                <Route
                  path="/main/offer-wall-manager"
                  component={OfferWallPages}
                />
                <Route
                  path="/main/quick-questions-manager"
                  component={QuickQuestionPages}
                />
                <Route exact path="/main/pmp-deals">
                  <PmpDealsManager />
                </Route>
                <Route path="/main/pmp-deals/creator">
                  <DealsCreator />
                </Route>
                <Route path="/main/pmp-deals/editor/*">
                  <DealsEditor />
                </Route>
                <Route path="/main/pmp-deals/log-level-data">
                  <LogLevelData />
                </Route>
                <Route exact path="/main/sales-lift-studies">
                  <SalesLiftStudyManager />
                </Route>
                <Route path="/main/sales-lift-studies/creator">
                  <SalesLiftStudyCreator />
                </Route>
                <Route path="/main/sales-lift-studies/editor/:salesliftstudyid?">
                  <SalesLiftEditor />
                </Route>
                <Route path="/main/sales-lift-studies/results">
                  <div />
                </Route>
                <Route path="/main/forecasting">
                  <Forecasting />
                </Route>
                <Route path="/main/insights">
                  <Insights />
                </Route>
                <Route path="/main/help">
                  <Help />
                </Route>
                <Route path="/main/notifications">
                  <Notifications />
                </Route>
                {/* Default route  */}
                <Route>
                  <AudienceManager />
                </Route>
              </Switch>
            </Box>
          </Grid>
        </Grid>
        {audienceManagerNotifications.map(({ state, message }, i) => (
          <CustomSnackBar
            index={i}
            trigger={true}
            severity={state === 'done' ? 'success' : 'error'}
            message={message || ''}
            key={i}
            removeNotificationFunction={removeAudienceNotification}
          />
        ))}
        {offerWallManagerNotifications.map(({ state, message }, i) => (
          <CustomSnackBar
            index={i}
            trigger={true}
            severity={state === 'done' ? 'success' : 'error'}
            message={message || ''}
            key={i}
            removeNotificationFunction={removeOfferNotification}
          />
        ))}
        {salesLiftStudyManagerNotifications.map(({ state, message }, i) => (
          <CustomSnackBar
            index={i}
            trigger={true}
            severity={state === 'done' ? 'success' : 'error'}
            message={message || ''}
            key={i}
            removeNotificationFunction={removeSalesLiftStudyNotification}
          />
        ))}
        {dealsManagerNotifications.map(({ state, message }, i) => (
          <CustomSnackBar
            index={i}
            trigger={true}
            severity={state === 'done' ? 'success' : 'error'}
            message={message || ''}
            key={i}
            removeNotificationFunction={removeDealsNotification}
          />
        ))}
      </div>
    );
  } else {
    return <Login />;
  }
};

export default Main;
