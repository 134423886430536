import styled from 'styled-components';
import tokens from 'tokens';

export const RadioSelectionSpace = styled.div`
  margin: ${tokens.spacing.md} -${tokens.spacing.xs};
  padding: 0px ${tokens.spacing.xs};
  overflow-y: scroll;
  height: 100px;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }
  ::-webkit-scrollbar:horizontal {
    height: 11px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid ${tokens.colorDefault}; /* should match background, can't be transparent */
    background-color: rgba(0, 0, 0, 0.5);
  }
`;
