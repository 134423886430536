import CircularProgress from '@material-ui/core/CircularProgress';
import SaveIcon from '@material-ui/icons/Save';

interface GroupNameSaveProps {
  isDisabled: boolean;
}

const GroupNameSaveIcon = ({ isDisabled }: GroupNameSaveProps) => {
  return (
    <>
      {isDisabled ? (
        <CircularProgress color="primary" size={20} />
      ) : (
        <SaveIcon />
      )}
    </>
  );
};

export default GroupNameSaveIcon;
