import Field from 'components/Field';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import TextField from '@mui/material/TextField';
import _debounce from 'lodash/debounce';
import nanoid from 'utils/nanoid';
import {
  Audience,
  AudienceGroup,
  ToggleGroupNameDialogAction,
  addAudience,
  fetchAudience,
  fetchGroups,
  loadOriginalAudience,
  searchAudiences,
  setGroupDeletingState,
  setGroupSavingState,
  toggleGroupNameDialog,
  updateCurrentAudience,
  updateCurrentGroup,
} from './slice';
import { Button, IconButton } from '@klover/attain-design-system';
import { Formik } from 'formik';
import { getAudeinceEmails } from '../../helper/helper';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { useHistory, useLocation } from 'react-router-dom';
import * as Styled from './index.styles';

// Material UI Components
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import GroupIcon from '@material-ui/icons/Group';
import HistoryIcon from '@mui/icons-material/History';
import Typography from '@material-ui/core/Typography';
import { CaretDown, Plus } from '@phosphor-icons/react';
import {
  Theme,
  ThemeProvider,
  createStyles,
  createTheme,
  makeStyles,
} from '@material-ui/core/styles';

// Page Components
import AudienceStatusHeader from './audienceStatus';
import CircleIcon from '@mui/icons-material/Circle';
import DataTable from 'components/DataTable/DataTable';
import ExportHistroryDialog from './exportHistoryDialog';
import GroupDeleteDialog from './groupDeleteDialog';
import GroupName from './groupName';
import GroupNameDialog from './groupNameDialog';
import Header from 'components/Header';
import Link from 'components/Link';
import Pagination, {
  PaginationLoading,
} from 'components/Pagination/Pagination';
import Table from 'components/Table';
import TextFieldSearch from 'components/TextFieldSearch/TextFieldSearch';
import tokens from 'tokens';
import { AUDIENCE_SELECT_EMAIL_AUTO_COMPLETE } from '../../constants';
import {
  ENUM_AUDIENCE_EXPORT_STATUS,
  getAudienceAndPlatformTypeStatus,
} from './exportHistoryDialog/exportHistoryUtils';
import { RowingOutlined } from '@mui/icons-material';
import { getAudienceExportStatus } from 'utils/audienceExportStatus';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    createGroup: {
      backgroundColor: tokens.colorButtonLight,
      bottom: 0,
      height: 68,
      padding: 16,
      position: 'fixed',
      width: 248,
    },
    divider: {
      height: 2,
    },
    groupList: {
      marginBottom: 'auto',
      maxHeight: '500px',
      minHeight: '400px',
      overflow: 'scroll',
    },
    header: {
      display: 'flex',
      height: '80px',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      width: '100%',
    },
    noAudiences: {
      minHeight: '100%',
      display: 'flex',
      flexGrow: 1,
      alignItems: 'center',
      justifyContent: 'center',
      height: '80vh',
    },
    searchAudience: {
      margin: 16,
    },
    searchGroup: {
      margin: 8,
      marginTop: 16,
    },
    verticalDivider: {
      borderRight: '2px solid rgb(232 232 232)',
    },
    audienceName: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: 'inline-block',
      cursor: 'pointer',
    },
    audienceStatusCol: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      minWidth: 50,
      display: 'inline-block',
      fontSize: 16,
      fontFamily: 'Calibre-Regular, sans-serif',
    },
    createdBy: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: 200,
      display: 'inline-block',
      fontSize: 16,
    },
    audienceStatus: {
      display: 'flex',
      gap: '5px',
      alignItems: 'center',
      top: '1px',
      position: 'relative',
    },
    tableCellText: {
      fontSize: '14px',
    },
    createAudienceButton: {
      fontSize: '16px !important',
    },
    audienceType: {
      fontSize: 16,
      color: '#000000d9',
      lineHeight: '20px',
      fontFamily: 'Calibre-Regular, sans-serif',
    },
    platformType: {
      fontSize: 14,
      color: '#0000008c',
      fontFamily: 'Calibre-Regular, sans-serif',
    },
    audienceSizeStatus: {
      fontSize: 14,
      color: '#0000008c',
      lineHeight: '20px',
      fontWeight: 500,
    },
    audienceSizeIcon: {
      color: 'gray',
      padding: '2px',
      marginTop: '-1px',
    },
    totalSize: {
      fontWeight: 500,
      lineHeight: '20px',
    },
  })
);

const inputs = createTheme({
  overrides: {
    MuiFormControl: {
      root: {
        maxHeight: 48,
      },
    },
    MuiFormLabel: {
      root: {
        maxHeight: 48,
      },
    },
    MuiInputLabel: {
      root: {
        marginTop: -5,
        '&$focused': {
          marginTop: 0,
        },
      },
    },
    MuiInputBase: {
      root: {
        maxHeight: 48,
      },
      input: {
        borderRadius: 10,
        alignSelf: 'center',
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 10,
      },
      input: {
        padding: '12px 14px',
      },
    },
    MuiButton: {
      root: {
        borderRadius: 10,
      },
      outlinedPrimary: {
        color: tokens.colorButtonPrimary,
      },
      containedPrimary: {
        backgroundColor: tokens.colorButtonPrimary,
        '&:hover': {
          backgroundColor: tokens.colorButtonPrimary,
        },
      },
    },
    MuiButtonBase: {
      root: {
        borderRadius: 10,
      },
    },
  },
});

const AudienceManager = () => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [search, setSearch] = useState('');
  const [allGroups, setAllGroups] = useState(false);
  const [selectedEmail, setSelectedEmail] = useState(
    searchParams.get('email') || 'all'
  );
  const [isEmailLoading, setIsEmailLoading] = useState(false);
  const [oprations, setOprations] = useState({
    page: 0,
    rowsPerPage: 25,
    sortBy: 'updatedAt',
    sortOrder: 'DESC',
    email: selectedEmail,
    groupId: searchParams.get('groupId') || 'all',
  });
  const [emails, setEmails] = useState([]);
  const { groupId } = oprations;

  useEffect(() => {
    async function fetchMyAPI() {
      setIsEmailLoading(true);
      const response = await getAudeinceEmails(false);
      const allUsers = [];
      response.data.map((user: any) => {
        allUsers.push({
          label: user.created_by_email,
          id: user.created_by_email,
        });
      });
      setEmails(allUsers);
      setIsEmailLoading(false);
    }
    fetchMyAPI();
  }, []);

  const emailOptions = useMemo(() => {
    const data = [{ label: 'All Emails', id: 'all' }, ...emails];
    return data;
  }, [emails]);

  const audienceReducer = useAppSelector((state) => {
    return state.audienceReducer;
  });

  const currentGroup = useAppSelector(
    (state) => state.audienceReducer.currentGroup
  );
  const openGroupDeleteDialog = useAppSelector(
    (state) => state.audienceReducer.ui.openGroupDeleteDialog
  );
  const openGroupNameDialog = useAppSelector(
    (state) => state.audienceReducer.ui.openGroupNameDialog
  );

  useEffect(() => {
    dispatch(fetchGroups());
  }, []);

  useEffect(() => {
    dispatch(searchAudiences({ ...oprations, search, email: selectedEmail }));
  }, [oprations]);

  useEffect(() => {
    if (openGroupNameDialog) return;
    const searchParams = new URLSearchParams(window.location.search);
    const id = searchParams.get('groupId');
    const currGroup = audienceReducer.entities.groups.byId[id];
    if (currGroup) {
      dispatch(updateCurrentGroup(currGroup));
    } else {
      setAllGroups(true);
    }
  }, [window.location.search, openGroupNameDialog]);

  useEffect(() => {
    if (groupId && groupId !== 'all') {
      const currentGroup = audienceReducer.entities.groups.byId[groupId];
      if (currentGroup) {
        setAllGroups(false);
      }
    } else {
      setAllGroups(true);
      dispatch(updateCurrentGroup(null));
    }

    window.history.replaceState(
      null,
      '',
      `${location.pathname}?groupId=${groupId}&email=${selectedEmail}`
    );
  }, [groupId]);

  const audiences = useAppSelector((state) => {
    return state.audienceReducer.entities.audiences.allIds.map((id) => {
      return state.audienceReducer.entities.audiences.byId[id];
    });
  });

  const groups = useAppSelector((state) => {
    return state.audienceReducer.entities.groups.allIds
      .map((id) => state.audienceReducer.entities.groups.byId[id])
      .filter((group) => {
        if (name !== '') {
          return group.name.toLowerCase().includes(name);
        } else {
          return true;
        }
      });
  });

  const groupLoading = useAppSelector(
    (state) => state.audienceReducer.ui.groupLoading
  );

  const groupsLoading = useAppSelector(
    (state) => state.audienceReducer.ui.groupsLoading
  );

  const addAudienceHandler = () => {
    dispatch(loadOriginalAudience(null));
    dispatch(setGroupDeletingState({ state: 'idle' }));
    dispatch(setGroupSavingState({ state: 'idle' }));
    dispatch(addAudience(newAudience));
  };

  const handleCreateGroup = () => {
    const actionProps: ToggleGroupNameDialogAction = {
      open: true,
    };
    dispatch(toggleGroupNameDialog(actionProps));
  };

  const handleShowGroup = (id) => {
    setOprations({ ...oprations, groupId: id, page: 0 });
    setAllGroups(id ? false : true);
  };

  const isAudiencesLoading = groupLoading.state === 'loading';
  const isGroupsLoading = groupsLoading.state === 'loading';

  const newAudience: Audience = {
    id: nanoid(),
    name: '',
    totalSize: 0,
    dimensions: [],
    dimensionGroups: [],
    createdAt: 0,
    updatedAt: 0,
  };

  const groupsOptions = useMemo(() => {
    const data = [
      { label: 'All Groups', id: 'all' },
      ...groups
        ?.sort((a, b) => b.updated_at - a.updated_at)
        ?.map((a) => {
          return { label: a.name, id: `${a.id}` };
        }),
    ];
    return data;
  }, [groups]);

  const handleChangePage = (newPage: number) => {
    setOprations({
      ...oprations,
      page: newPage,
    });
  };

  const handleChangeRowsPerPage = (value: number) => {
    const newPageSize = parseInt(value, 10);
    setOprations({
      ...oprations,
      page: 0,
      rowsPerPage: newPageSize,
    });
  };

  const handleOnSortChange = (sortInfo) => {
    let sortOrder = 'DESC';
    if (oprations.sortBy === sortInfo.column) {
      sortOrder = oprations.sortOrder == 'ASC' ? 'DESC' : 'ASC';
    }

    setOprations({
      ...oprations,
      sortBy: sortInfo.column,
      sortOrder: sortOrder,
    });
  };

  const debounceFn = useCallback(
    _debounce((value) => {
      setOprations({
        ...oprations,
        page: 0,
      });
    }, 1000)
  );

  return (
    <>
      <Box className={classes.root}>
        <Header
          title="Audience Manager"
          centerBorder={true}
          center={!allGroups ? currentGroup && <GroupName /> : null}
          action={
            <>
              {currentGroup && !allGroups ? (
                <Button
                  variant="contained"
                  onClick={() => {
                    history.push(`/main/audience-manager/editor`);
                    addAudienceHandler();
                  }}
                  className={classes.createAudienceButton}
                  startIcon={<Plus />}
                >
                  Create Audience
                </Button>
              ) : null}
            </>
          }
        />

        <Box height="100%" padding={2}>
          <Styled.SearchArea>
            <>
              <ThemeProvider theme={inputs}>
                <Button
                  variant="contained"
                  onClick={handleCreateGroup}
                  startIcon={<Plus />}
                  style={{ fontSize: '16px' }}
                >
                  Create Group
                </Button>
              </ThemeProvider>

              <Formik>
                {() => (
                  <Styled.Form onSubmit={(e) => e.preventDefault()}>
                    <Field
                      name="searchAudiences"
                      placeholder="Search Audiences"
                      value={search}
                      style={{ height: 44, width: 415 }}
                      onChange={(e) => {
                        setSearch(e.target.value);
                        debounceFn(e.target.value);
                      }}
                    />
                  </Styled.Form>
                )}
              </Formik>
            </>

            <Styled.SelectAutoCompleteGroup
              disablePortal
              id="select-audience-group"
              options={groupsOptions}
              disabled={isGroupsLoading}
              popupIcon={<CaretDown />}
              value={
                groupsOptions.find((a) => `${a?.id}` === `${groupId}`)?.label
              }
              renderInput={(params) => (
                <TextFieldSearch
                  {...params}
                  placeholder="Select group"
                  variant="outlined"
                />
              )}
              onChange={(e, newValue) => {
                if (newValue?.id) {
                  setOprations({
                    ...oprations,
                    page: 0,
                    groupId: newValue?.id?.toString(),
                  });
                  setAllGroups(newValue?.id ? false : true);
                }
              }}
              renderOption={(props, option) => (
                <Styled.SelectAutoCompleteGroupOptions>
                  <li {...props} key={option.id}>
                    {option.label}
                  </li>
                </Styled.SelectAutoCompleteGroupOptions>
              )}
            />
            <Styled.SelectAutoCompleteEmail
              disablePortal
              id="select-audience-email"
              options={emailOptions}
              disabled={isEmailLoading}
              popupIcon={<CaretDown />}
              value={
                emailOptions.find((a) => a?.id === `${selectedEmail}`)?.label
              }
              renderInput={(params) => (
                <TextFieldSearch
                  {...params}
                  placeholder={AUDIENCE_SELECT_EMAIL_AUTO_COMPLETE}
                  variant="outlined"
                />
              )}
              onChange={(e, newValue: any) => {
                if (newValue?.id) {
                  setSelectedEmail(newValue?.id);
                } else {
                  return;
                }
                setOprations({
                  ...oprations,
                  page: 0,
                });
                if (newValue?.id !== selectedEmail) {
                  window.history.replaceState(
                    null,
                    '',
                    groupId
                      ? `${location.pathname}?groupId=${groupId}&email=${newValue?.id}`
                      : `${location.pathname}?email=${newValue?.id}`
                  );
                }
              }}
              renderOption={(props, option) => (
                <Styled.SelectAutoCompleteGroupOptions>
                  <li {...props} key={option.id}>
                    {option.label}
                  </li>
                </Styled.SelectAutoCompleteGroupOptions>
              )}
            />
          </Styled.SearchArea>
          {audiences.length > 0 ? (
            <AudienceTable
              loading={isAudiencesLoading}
              audiences={audiences}
              selectedGroupId={groupId}
              groups={groups}
              handleShowGroup={handleShowGroup}
              oprations={oprations}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              handleOnSortChange={handleOnSortChange}
            />
          ) : (
            <>
              {currentGroup ? (
                <NoAudiences search={search} />
              ) : (
                <NoCurrentGroup />
              )}
            </>
          )}
        </Box>
      </Box>

      <GroupDeleteDialog open={openGroupDeleteDialog} />
      <GroupNameDialog open={openGroupNameDialog} />
    </>
  );
};

interface Props {
  audiences: Audience[];
  groups: any;
  handleShowGroup: any;
  selectedGroupId: any;
  loading: Boolean;
  oprations: {
    page: number;
    rowsPerPage: number;
    sortBy: string;
    sortOrder: string;
  };
  handleChangePage: () => {};
  handleChangeRowsPerPage: () => {};
  handleOnSortChange: () => {};
}

const AudienceTable = ({
  audiences,
  handleShowGroup,
  oprations,
  handleChangePage,
  handleChangeRowsPerPage,
  handleOnSortChange,
  loading = true,
}: Props) => {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const { sortBy, sortOrder } = oprations;
  const [openExportHistoryDialog, setOpenExportHistoryDialog] = useState(false);
  const [currentAudienceHistory, setCurrentAudienceHistory] = useState([]);
  const [currentAudience, setCurrentAudience] = useState([]);

  const states = useAppSelector((state) => {
    return state.audienceReducer.ui.states;
  });

  const handleShowAudience = (audience: Audience) => {
    dispatch(updateCurrentAudience(audience));
    dispatch(setGroupDeletingState({ state: 'idle' }));
    dispatch(setGroupSavingState({ state: 'idle' }));
  };

  const data = useMemo(
    () => audiences && audiences.filter((x) => x),
    [audiences]
  );

  const count =
    data.length > 0
      ? typeof data[0].totalcount === 'string'
        ? Number(data[0].totalcount)
        : data[0].totalcount
      : 1;

  const columns = [
    {
      title: 'Group',
      column: 'groupId',
      isSorted: sortBy === 'groupId',
      sortOrder: sortOrder,
    },
    {
      title: 'Name',
      column: 'name',
      isSorted: sortBy === 'name',
      sortOrder: sortOrder,
      width: '550px',
    },
    {
      title: <AudienceStatusHeader />,
      column: 'exportStatus',
      width: '15%',
      isSortDisabled: true,
    },
    {
      title: 'Type',
      column: 'exportHistory',
      width: '12%',
      isSortDisabled: true,
    },
    {
      title: 'Est. Size',
      column: 'totalSize',
      isSorted: sortBy === 'totalSize',
      sortOrder: sortOrder,
      width: '8%',
    },
    {
      title: 'Last Modified',
      column: 'updatedAt',
      isSorted: sortBy === 'updatedAt',
      sortOrder: sortOrder,
      width: '9%',
    },
  ];

  const formattedRows = React.useMemo(() => {
    return data?.map((x) => {
      const { audience_type, platform_type } = getAudienceAndPlatformTypeStatus(
        x?.exportHistory || []
      );
      return (
        <>
          <td>
            <Link
              to={`/main/audience-manager?groupId=${x.groupId}&email=all`}
              onClick={() => handleShowGroup(x.groupId)}
              disabled={
                states &&
                RowingOutlined &&
                RowingOutlined.id &&
                states[RowingOutlined.id]
                  ? true
                  : false
              }
            >
              {x.groupName}
            </Link>
          </td>
          <td>
            <Styled.AudienceName>
              <Link
                to={`/main/audience-manager/editor/${x?.id}`}
                onClick={() => handleShowAudience(x)}
              >
                {x?.name || null}
              </Link>
            </Styled.AudienceName>
          </td>
          <td>
            <Styled.AudienceStatus className={classes.audienceStatusCol}>
              <AudienceExportStatus value={x?.exportStatus} />{' '}
              <IconButton
                onClick={() => {
                  setCurrentAudienceHistory(x?.exportHistory || []);
                  setCurrentAudience(x || {});
                  setOpenExportHistoryDialog(true);
                }}
              >
                <HistoryIcon style={{ fontSize: '20px', cursor: 'pointer' }} />
              </IconButton>
            </Styled.AudienceStatus>
          </td>
          <td>
            <Styled.Type>
              <div className={classes.audienceType}>{audience_type}</div>
              <div className={classes.platformType}>{platform_type}</div>
            </Styled.Type>
          </td>
          <td>
            {x?.totalSize ? (
              <Styled.ModeledSize>
                <div className={classes.totalSize}>
                  {x?.totalSize?.toLocaleString()}
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span className={classes.audienceSizeStatus}>KDS Seed</span>
                  {/* <InfoIcon
                      className={classes.audienceSizeIcon}
                      style={{ fontSize: 20 }}
                    /> */}
                </div>
              </Styled.ModeledSize>
            ) : null}
          </td>
          <td>
            <Styled.LastModified>
              {x.updatedAt !== undefined && x.updatedAt !== 0 && (
                <>{new Date(Number(x.updatedAt)).toLocaleDateString('en-US')}</>
              )}
            </Styled.LastModified>
          </td>
        </>
      );
    });
  }, [data]);

  return (
    <>
      <ExportHistroryDialog
        isOpen={openExportHistoryDialog}
        setOpenDialog={setOpenExportHistoryDialog}
        data={currentAudienceHistory}
        normalAudience={currentAudience}
      />
      <DataTable
        loading={loading}
        columns={columns}
        rows={formattedRows || []}
        showRowsPerPageDropdown
        customRowsPerPageOptions={[25, 50, 100]}
        defaultRowsPerPage={25}
        onPaginationClick={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        onSortChange={handleOnSortChange}
        totalRows={count}
      />
    </>
  );
};

const NoAudiences = ({ search }) => {
  const classes = useStyles();

  return (
    <Box className={classes.noAudiences}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>{!search ? <GroupIcon /> : null}</Grid>
        <Grid item>
          <Typography component="h3" variant="h6">
            {search ? (
              'No audiences with this query.'
            ) : (
              <>This group doesn&apos;t have any audiences yet.</>
            )}
          </Typography>
        </Grid>
        <Grid item>
          <Typography component="h4" variant="caption">
            {!search ? (
              <>Start by clicking &quot;Create Audience&quot;.</>
            ) : null}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

const NoCurrentGroup = () => {
  const classes = useStyles();

  return (
    <Box className={classes.noAudiences}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <GroupIcon />
        </Grid>
        <Grid item>
          <Typography component="h3" variant="h6">
            Create or select a group
          </Typography>
        </Grid>
        <Grid item>
          <Typography component="h4" variant="caption">
            Start by clicking &quot;Create Group&quot;.
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

const LoadingAudiences = () => {
  const classes = useStyles();

  return (
    <Box className={classes.noAudiences}>
      <CircularProgress color="primary" size={40} />
    </Box>
  );
};

export const AudienceExportStatus = (props: any) => {
  const { value } = props || {};
  const classes = useStyles();
  const { iconColor, tootipText } = getAudienceExportStatus(value);

  return iconColor ? (
    <span title={tootipText} className={classes.audienceStatus}>
      <CircleIcon style={{ fontSize: '10px', color: iconColor }} />
      {value || ENUM_AUDIENCE_EXPORT_STATUS.NOT_SENT}
    </span>
  ) : null;
};

export default AudienceManager;
