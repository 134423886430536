import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from 'components/Dialog';
import Field from 'components/Field';
import React from 'react';
import _ from 'lodash';
import axios from 'axios';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { FormikProvider, useFormik } from 'formik';
import { Offer, addNotification, updateOfferCreativeRotation } from '../slice';
import { OfferWallContext } from 'context';
import { Tooltip } from 'antd';
import { useAppDispatch } from 'redux/hooks';
import { useHistory } from 'react-router-dom';
import * as Grid from 'components/Grid';
import * as Styled from './index.styles';
import * as Yup from 'yup';
import { Button } from '@klover/attain-design-system';

const validWeights = () =>
  Yup.array().test({
    name: 'are-selected-offer-weights-valid',
    test: function (values) {
      if (values && values.length) {
        if (values.length >= 1) {
          if (_.every(values.map(({ weight }) => weight !== null))) {
            if (values.every(({ weight }) => _.isInteger(weight))) {
              const totalWeight = values.reduce(
                (a, { weight }) => (weight ? (a += weight) : a),
                0
              );
              if (totalWeight !== 100) {
                return this.createError({
                  message: `Rotation percentages must be integers and add up to 100%`,
                  path: 'selectedOffers',
                });
              }
            } else {
              return this.createError({
                message: `Rotation percentages must be integers and add up to 100%`,
                path: 'selectedOffers',
              });
            }
          } else {
            return this.createError({
              message: `Rotation percentages must be filled out.`,
              path: 'selectedOffers',
            });
          }
        } else {
          return this.createError({
            message: `Must add at least 1 offer(s) to the rotation.`,
            path: 'selectedOffers',
          });
        }
        return true;
      } else {
        return this.createError({
          message: `Must add at least 1 offer(s) to the rotation.`,
          path: 'selectedOffers',
        });
      }
    },
  });

const CreateOfferRotation = () => {
  const history = useHistory();
  const {
    existingCreativeRotation,
    setExistingCreativeRotation,
    setShowCreativeRotation,
  } = React.useContext(OfferWallContext);
  const dispatch = useAppDispatch();
  const validationSchema = Yup.object().shape({
    selectedOffers: validWeights(),
  });
  const formik = useFormik({
    initialValues: {
      placement: 1,
      inputField: '',
      offers: [],
      groups: [],
      selectedOffers: [],
      search: '',
      offersLoading: false,
      groupsLoading: false,
      selectedLoading: false,
      groupid: '',
    },
    validationSchema,
    onSubmit: ({ selectedOffers }) => {
      const { offerid } = selectedOffers[0];
      const body = {
        parent: offerid,
        children: selectedOffers.map(({ offerid, weight }) => ({
          offerid,
          weight,
        })),
      };
      axios
        .post(
          `${process.env.API_BASE_ADDRESS}/offer-manager/offers/creative-rotation`,
          body
        )
        .then(() => {
          dispatch(updateOfferCreativeRotation(body));
          dispatch(
            addNotification({
              state: 'done',
              message: `Successfully ${existingCreativeRotation ? 'updated' : 'created'
                } rotation`,
            })
          );
          history.push('/main/offer-wall-manager/creative-rotations');
        });
      setExistingCreativeRotation(null);
      setShowCreativeRotation(false);
      formik.resetForm();
    },
  });

  const {
    placement,
    groups,
    offers,
    search,
    offersLoading,
    groupsLoading,
    selectedLoading,
    groupid,
    selectedOffers,
  } = formik.values;

  const offerToIdMap = offers.reduce(
    (a, offer) => ({ ...a, [offer.offerid]: offer }),
    {}
  );

  React.useEffect(() => {
    formik.setFieldValue('offersLoading', true);
    axios
      .get(`${process.env.API_BASE_ADDRESS}/offer-manager/offers?eligible=true`)
      .then((results) => {
        const currentTimestamp = Date.now();
        const filteredByDate = results.data.filter(
          ({ isactive, startdate, enddate }: Offer) => {
            if (isactive && startdate && enddate) {
              if (
                !(startdate <= currentTimestamp && enddate >= currentTimestamp)
              ) {
                return false;
              }
            }
            return true;
          }
        );
        formik.setFieldValue('offers', filteredByDate);
        formik.setFieldValue('offersLoading', false);
      })
      .catch((e) => {
        console.log('Error:', e);
        formik.setFieldValue('offers', []);
        formik.setFieldValue('offersLoading', false);
        dispatch(
          addNotification({
            state: 'error',
            message: `Error fetching Offers`,
          })
        );
      });
    axios
      .get(`${process.env.API_BASE_ADDRESS}/offer-manager/offer-groups`)
      .then((results) => {
        formik.setFieldValue('groups', results.data);
        formik.setFieldValue('groupsLoading', false);
      })
      .catch((e) => {
        console.log('Error:', e);
        formik.setFieldValue('groups', []);
        formik.setFieldValue('groupsLoading', false);
        dispatch(
          addNotification({
            state: 'error',
            message: `Error fetching Groups`,
          })
        );
      });
    if (existingCreativeRotation) {
      formik.setFieldValue('selectedLoading', true);
      axios
        .get(
          `${process.env.API_BASE_ADDRESS}/offer-manager/offers/creative-rotation/${existingCreativeRotation.offerid}`
        )
        .then(({ data }) => {
          formik.setFieldValue('selectedOffers', data);
          formik.setFieldValue('selectedLoading', false);
        })
        .catch((e) => {
          console.log('Error:', e);
          formik.setFieldValue('selectedOffers', []);
          formik.setFieldValue('selectedLoading', false);
          dispatch(
            addNotification({
              state: 'error',
              message: `Error fetching creative offers`,
            })
          );
        });
    }
  }, []);
  const selectedMap = selectedOffers.reduce(
    (a, { offerid }) => ({ ...a, [offerid]: true }),
    {}
  );
  const noCreativeRotationOffers = offers.filter(
    ({ creativerotationenabled }) => !creativerotationenabled
  );
  const filteredOffers = React.useMemo(() => {
    let targetPlacement = placement;
    if (existingCreativeRotation) {
      if (selectedOffers && selectedOffers.length) {
        const target = selectedOffers[0].offerid;
        const filteredOffers = offers.filter(
          ({ offerid }) => target == offerid
        );
        if (filteredOffers.length) {
          const { placementid } = filteredOffers[0];
          targetPlacement = placementid;
        }
      }
    }
    let searchFiltered = offers.filter(
      ({ offerid, label, creativerotationenabled, placementid }) =>
        label &&
        label.toUpperCase().includes(search.toUpperCase()) &&
        placementid == targetPlacement &&
        !creativerotationenabled &&
        offerid &&
        !selectedMap[offerid]
    );
    if (groupid) {
      searchFiltered = searchFiltered.filter(
        ({ offergroupid }) => offergroupid == groupid
      );
    }
    return searchFiltered.sort((a, b) => b.isactive - a.isactive);
  }, [search, offers, groupid, placement, selectedMap]);

  const disableSelection =
    noCreativeRotationOffers.length + selectedOffers.length < 1;

  const onDragEnd = (result) => {
    const { destination, source } = result;
    if (!destination) return;
    const sourceIndex = source.index;
    const destinationIndex = destination ? destination.index : sourceIndex;
    const newSelection = _.clone(selectedOffers);
    if (source.droppableId == destination.droppableId) {
      if (existingCreativeRotation) {
        if (
          destination.droppableId === 'selectedOffers' &&
          destinationIndex == 0
        ) {
          const modifiedDestinationIndex = destinationIndex + 1;
          const [removed] = newSelection.splice(sourceIndex, 1);
          newSelection.splice(modifiedDestinationIndex, 0, removed);
        } else {
          const [removed] = newSelection.splice(sourceIndex, 1);
          newSelection.splice(destinationIndex, 0, removed);
        }
      } else {
        if (destination.droppableId === 'selectedOffers') {
          const next = newSelection[sourceIndex + 1];
          const [removed] = newSelection.splice(sourceIndex, 1);
          const nextOffer = next ? offerToIdMap[next.offerid] : null;
          const removedOffer = offerToIdMap[removed.offerid];
          let modifiedDestinationIndex = destinationIndex;
          if (!removedOffer.isactive && destinationIndex == 0) {
            modifiedDestinationIndex += 1;
          }
          if (sourceIndex === 0 && nextOffer && !nextOffer.isactive) {
            return;
          }
          newSelection.splice(modifiedDestinationIndex, 0, removed);
        }
      }
    } else {
      if (existingCreativeRotation) {
        if (destination.droppableId === 'selectedOffers') {
          let modifiedDestinationIndex = destinationIndex;
          if (destinationIndex == 0) {
            modifiedDestinationIndex += 1;
          }
          const [removed] = filteredOffers.splice(sourceIndex, 1);
          newSelection.splice(modifiedDestinationIndex, 0, removed);
        } else if (destination.droppableId === 'availableOffers') {
          const [removed] = newSelection.splice(sourceIndex, 1);
          const { offerid }: Offer = removed;
          const modifiedOffers = offers.map((offer: Offer) =>
            offer.offerid == offerid
              ? {
                ...offer,
                creativerotationenabled: null,
                parentofferid: null,
                weight: null,
              }
              : offer
          );
          formik.setFieldValue('offers', modifiedOffers);
        }
      } else {
        if (destination.droppableId === 'selectedOffers') {
          const [removed] = filteredOffers.splice(sourceIndex, 1);
          let modifiedDestinationIndex = destinationIndex;
          if (!removed.isactive && destinationIndex == 0) {
            modifiedDestinationIndex += 1;
          }
          newSelection.splice(modifiedDestinationIndex, 0, removed);
        } else if (destination.droppableId === 'availableOffers') {
          const next = newSelection[sourceIndex + 1];
          const nextOffer = next ? offerToIdMap[next.offerid] : null;
          if (sourceIndex === 0 && nextOffer && !nextOffer.isactive) return;
          newSelection.splice(sourceIndex, 1);
        }
      }
    }
    formik.setFieldValue('selectedOffers', newSelection);
  };

  const errors = Object.values(formik.errors);

  return (
    <Dialog
      onDismiss={() => {
        formik.resetForm();
        setExistingCreativeRotation(null);
        setShowCreativeRotation(false);
      }}
      showClose={true}
      onCloseClick={() => {
        formik.resetForm();
        setExistingCreativeRotation(null);
        setShowCreativeRotation(false);
      }}
      title={`${existingCreativeRotation ? 'Edit' : 'Create'} Offer Rotation`}
      buttons={
        <>
          <Tooltip
            zIndex={999999999999999}
            title={
              errors.length
                ? errors.map((message, i) => {
                  return <div key={i}>{message}</div>;
                })
                : ''
            }
          >
            <span>
              <Button
                variant="contained"
                type="submit"
                onClick={() => {
                  formik.handleSubmit();
                  setExistingCreativeRotation(null);
                  setShowCreativeRotation(false);
                }}
                disabled={
                  !formik.isValid || formik.isSubmitting || !formik.touched
                }
              >
                Save
              </Button>
            </span>
          </Tooltip>
          <Button
            variant="outlined"
            onClick={() => {
              formik.resetForm();
              setExistingCreativeRotation(null);
              setShowCreativeRotation(false);
            }}
          >
            Close
          </Button>
        </>
      }
    >
      <DragDropContext onDragEnd={onDragEnd}>
        <FormikProvider value={formik}>
          <Styled.Wrapper>
            {!existingCreativeRotation ? (
              <Grid.Row>
                <Grid.Col xs={6}>
                  <Styled.SubHeaderClean>Step 1</Styled.SubHeaderClean>
                  <Field
                    as="select"
                    label="Select Placement"
                    name="placement"
                    value={placement}
                    onChange={formik.handleChange}
                    fullWidth
                    disabled={selectedOffers.length ? true : false}
                  >
                    <option value="1">Offer Wall</option>
                    <option value="2">Dashboard</option>
                    <option value="3">Klover+</option>
                    <option value="4">Onboarding</option>
                  </Field>
                  <Styled.SubHeader>Step 2</Styled.SubHeader>
                </Grid.Col>
              </Grid.Row>
            ) : null}
            <Grid.Row>
              <Grid.Col xs={6}>
                <Field
                  as="select"
                  label="Select Offer Group (optional)"
                  name="groupid"
                  value={groupid}
                  onChange={formik.handleChange}
                  fullWidth
                >
                  <option disabled={offersLoading} value="">
                    All Offers
                  </option>
                  {groupsLoading ? (
                    <option disabled>
                      <CircularProgress color="primary" size={20} />
                    </option>
                  ) : (
                    <>
                      {groups.length
                        ? groups.map(({ name, id }, i) => (
                          <option value={id} key={i}>
                            {name}
                          </option>
                        ))
                        : null}
                    </>
                  )}
                </Field>
              </Grid.Col>
              <Styled.SearchOffers xs={6}>
                <Styled.SearchField onSubmit={(e) => e.preventDefault()}>
                  <Field
                    label="Search Offers"
                    name="search"
                    placeholder="e.g: Calypso Beauty"
                    fullWidth
                  />
                </Styled.SearchField>
                {/* <Styled.SearchButton>Search</Styled.SearchButton> */}
              </Styled.SearchOffers>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col xs={6}>
                <Styled.SubHeader>Available Offers</Styled.SubHeader>
                <Styled.Note>
                  {!offersLoading && !groupsLoading && disableSelection
                    ? `There doesn't seem to be enough offers.`
                    : 'Please drag at least 2 offers to add to a rotation.'}
                </Styled.Note>
                <Droppable droppableId="availableOffers">
                  {(droppableProvided) => (
                    <Styled.OfferList ref={droppableProvided.innerRef}>
                      {offersLoading ? (
                        <CircularProgress color="primary" size={40} />
                      ) : (
                        <>
                          {filteredOffers && filteredOffers.length > 0 ? (
                            <>
                              {filteredOffers.map((offer: Offer, index) => {
                                const selectedOfferLength =
                                  selectedOffers && !selectedOffers.length;
                                const disabledOffer =
                                  !offer.isactive && selectedOfferLength;
                                const possibleParent =
                                  offer.isactive && selectedOfferLength;
                                return (
                                  <Draggable
                                    key={offer.offerid}
                                    draggableId={offer.offerid}
                                    index={index}
                                    isDragDisabled={
                                      disableSelection || disabledOffer
                                    }
                                  >
                                    {(draggableProvided) => (
                                      <Styled.OfferListItem
                                        disabled={
                                          disableSelection || disabledOffer
                                        }
                                        selected={possibleParent}
                                        key={index}
                                        ref={draggableProvided.innerRef}
                                        {...draggableProvided.draggableProps}
                                        {...draggableProvided.dragHandleProps}
                                      >
                                        {offer.offerid
                                          ? `(ID: ${offer.offerid}) `
                                          : ''}
                                        {offer.label ? offer.label : ''}
                                      </Styled.OfferListItem>
                                    )}
                                  </Draggable>
                                );
                              })}
                            </>
                          ) : (
                            <>No offers available.</>
                          )}
                        </>
                      )}
                    </Styled.OfferList>
                  )}
                </Droppable>
              </Grid.Col>
              {/* <img src={LeftRightArrow} width={10} /> */}
              <Grid.Col xs={6}>
                <Styled.SubHeader>Selected Offers</Styled.SubHeader>
                <Styled.Note>
                  Rotation group will take on all targeting/tracking of top item
                  in the list.
                </Styled.Note>
                <Droppable droppableId="selectedOffers">
                  {(droppableProvided) => (
                    <Styled.OfferList ref={droppableProvided.innerRef}>
                      {selectedLoading ? (
                        <CircularProgress color="primary" size={40} />
                      ) : selectedOffers && selectedOffers.length > 0 ? (
                        <>
                          {selectedOffers.map((offer, index) => (
                            <Draggable
                              key={offer.offerid}
                              draggableId={offer.offerid}
                              index={index}
                              isDragDisabled={
                                existingCreativeRotation && index == 0
                                  ? true
                                  : false
                              }
                            >
                              {(draggableProvided) => (
                                <Styled.OfferListItem
                                  key={index}
                                  selected={index == 0}
                                  ref={draggableProvided.innerRef}
                                  {...draggableProvided.draggableProps}
                                  {...draggableProvided.dragHandleProps}
                                >
                                  <Styled.OfferListItemName>
                                    {offer.offerid
                                      ? `(ID: ${offer.offerid}) `
                                      : ''}
                                    {offer.label ? offer.label : ''}
                                  </Styled.OfferListItemName>

                                  <Styled.OfferListItemPercentage
                                    name={`selectedOffers.${index}.weight`}
                                    min={0}
                                    max={100}
                                    onChange={(e) => {
                                      e.preventDefault();
                                      const { value } = e.target;
                                      if (!value) {
                                        formik.handleChange(e);
                                      } else {
                                        if (value >= 0 && value <= 100) {
                                          formik.handleChange(e);
                                          if (value.includes('.')) {
                                            formik.setFieldValue(
                                              `selectedOffers.${index}.weight`,
                                              parseInt(value)
                                            );
                                          }
                                        }
                                      }
                                    }}
                                    type="number"
                                    placeholder="%"
                                    fullWidth
                                  />
                                </Styled.OfferListItem>
                              )}
                            </Draggable>
                          ))}
                        </>
                      ) : (
                        <>No offers selected.</>
                      )}
                    </Styled.OfferList>
                  )}
                </Droppable>
              </Grid.Col>
            </Grid.Row>
          </Styled.Wrapper>
        </FormikProvider>
      </DragDropContext>
    </Dialog>
  );
};
export default CreateOfferRotation;
