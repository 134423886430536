import { CircularProgress, Grid } from '@material-ui/core';

const UploadingSpinner = () => {
  return (
    <Grid container direction="row" spacing={2} alignItems="center">
      <Grid item>
        <CircularProgress size={24} />
      </Grid>
      <Grid item>Uploading</Grid>
    </Grid>
  );
};

export default UploadingSpinner;
