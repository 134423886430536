import TableRow from '@material-ui/core/TableRow';
import { Link } from '@mui/material';
import { StyledTableCell } from './index.styles';

import nanoid from 'utils/nanoid';
import { Grid } from '@material-ui/core';
import { KDSPageNames, NotificationStates } from 'constants/index';
import { Notification } from 'interfaces/notifications';

const buildLink = (notification: Notification) => {
  const page = notification.page;
  let link = '#';
  let subRoute;
  let id;
  switch (page) {
    case KDSPageNames.OFFERWALLMANAGER:
      id = notification.refObj?.offerId;
      subRoute = '/editor/';
      break;
    case KDSPageNames.AUDIENCEMANAGER:
      id = notification.refObj?.audienceId;
      subRoute = '/editor/';
      break;
    case KDSPageNames.QUICKQUESTIONS:
      id = notification.refObj?.questionId;
      subRoute = '/questions/';
      break;
    case KDSPageNames.PMP:
      break;
    case KDSPageNames.SALESLIFT:
      break;
    default:
      return;
  }
  link = '/main/' + page + (id ? subRoute + id : '');
  return link;
};

interface NotificationProps {
  notification: Notification;
}
const NotificationRow = (props: NotificationProps) => {
  const { date, message, email, state, read } = props.notification;
  const link = buildLink(props.notification);

  const getDateString = (dateNum: number) => {
    const date = new Date(dateNum).toLocaleDateString('en-US', {
      day: '2-digit',
      month: '2-digit',
      year: '2-digit',
    });
    const today = new Date().toLocaleDateString('en-US', {
      day: '2-digit',
      month: '2-digit',
      year: '2-digit',
    });
    return date === today ? 'Today' : date;
  };

  return (
    <TableRow key={nanoid()}>
      <StyledTableCell>
        <Grid container direction="column">
          <Grid item>{getDateString(date)}</Grid>
          <Grid item>
            {new Date(date).toLocaleTimeString('en-US', {
              hour: '2-digit',
              minute: '2-digit',
            })}
          </Grid>
        </Grid>
      </StyledTableCell>
      <StyledTableCell>
        <Link
          href={link || '#'}
          underline="none"
          color={state === NotificationStates.DONE ? 'black' : 'error'}
          sx={{
            fontWeight: read ? 'normal' : 'bold',
          }}
        >
          {message}
        </Link>
      </StyledTableCell>
      <StyledTableCell>{email}</StyledTableCell>
    </TableRow>
  );
};

export default NotificationRow;
