import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Offer, OfferPlacementOptions, moveOffer } from '../slice';
import { nanoid } from 'nanoid';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import * as Styled from './index.styles';
import * as StyledTable from 'components/Table/index.styles';
import { useHistory } from 'react-router-dom';

// Material UI Components
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import OfferIcon from '@material-ui/icons/LocalOfferRounded';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Typography from '@material-ui/core/Typography';
import {
  Theme,
  ThemeProvider,
  createStyles,
  createTheme,
  makeStyles,
} from '@material-ui/core/styles';
import Search from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';

// Page Components
import Header from 'components/Header';
import TargetingFilters from '../targetingFilters';
import tokens from 'tokens';
import { InputAdornment } from '@material-ui/core';

import { updateSearchQuery } from '../slice';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    offerPreviewsContainer: {
      width: '100%',
      height: '100%',
    },
    mainContainer: {
      height: '100%',
    },
    contentContainer: {
      overflowY: 'scroll',
      width: '100%',
      height: '100%',
      display: 'flex',
    },
    offersContainer: {
      overflowY: 'scroll',
      height: '100%',
      width: '100%',
    },
    centeredContent: {
      minHeight: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    header: {
      alignItems: 'center',
      display: 'flex',
      height: '80px',
      justifyContent: 'space-between',
      padding: theme.spacing(2),
      width: '100%',
    },
    offerListItem: {
      borderBottom: '1px solid rgb(232 232 232)',
      height: '100%',
      marginLeft: theme.spacing(8),
      padding: theme.spacing(2),
    },
    offerTitleAndGroup: {
      margin: '1em',
      wordBreak: 'break-word',
    },
    title: {
      fontSize: '1.3rem',
      fontWeight: 400,
    },
    toggleButtonSelected: {
      color: tokens.colorButtonDefault,
    },
    topDivider: {
      height: 2,
    },
    searchOffers: {
      margin: '1rem 1rem 1rem 0rem',
      textAlign: 'right'
    }
  })
);

const button = createTheme({
  overrides: {
    MuiToggleButton: {
      root: {
        border: '2px solid rgba(0, 0, 0, 0.12)',
        borderColor: tokens.colorPrimary,
        borderRadius: 10,

        '&:hover': {
          borderColor: tokens.colorButtonPrimary,
        },
        '&$selected': {
          backgroundColor: tokens.colorButtonPrimary,
          '&:hover': {
            backgroundColor: tokens.colorButtonPrimary,
          },
        },
      },
      label: {
        color: tokens.colorTextPrimary,
        textTransform: 'none',
        '&:hover': {
          color: tokens.colorTextPrimary,
        },
      },
    },
  },
});

const Preview: React.FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [placement, setPlacement] = useState('2');
  const [currentOffers, setCurrentOffers] = useState([]);
  const currentTargeting = useAppSelector(
    (state) => state.offerWallReducer.currentTargeting
  );
  const offerEntitiesById = useAppSelector(
    (state) => state.offerWallReducer.entities.offers.byId
  );
  const currentActiveOffers = useAppSelector(
    (state) => state.offerWallReducer.currentActiveOffers
  );

  const activeOffers = currentActiveOffers.map(
    (offerid) => offerEntitiesById[offerid]
  );
  const offersLoading = useAppSelector(
    (state) => state.offerWallReducer.ui.offersLoading
  );

  const searchQuery = useAppSelector((state) =>
    state.offerWallReducer &&
    state.offerWallReducer.ui &&
    state.offerWallReducer.ui.searchQuery
      ? state.offerWallReducer.ui.searchQuery
      : ''
  );

  const isOffersLoading = offersLoading.state === 'loading';
  const offersFilteredByPlacement =
    activeOffers && activeOffers.length
      ? activeOffers.filter(
          (offer) =>
            offer && offer.placementid && offer.placementid == placement
        )
      : [];

  useEffect(() => {
    const offers =
      offersFilteredByPlacement && offersFilteredByPlacement.length
        ? offersFilteredByPlacement
            .filter(
              (offer) =>
                offer &&
                ((offer.offername &&
                  offer.offername
                    .toUpperCase()
                    .includes(searchQuery.toUpperCase())) ||
                  (offer.label &&
                    offer.label
                      .toUpperCase()
                      .includes(searchQuery.toUpperCase())) ||
                  (offer.offergroupname &&
                    offer.offergroupname
                      .toUpperCase()
                      .includes(searchQuery.toUpperCase())))
            )
            .sort((a, b) => a.zindex - b.zindex)
        : [];
    setCurrentOffers(offers);
  }, [placement, searchQuery, offersLoading]);

  const onDragEnd = (result) => {
    const { destination, source } = result;
    if (!destination) return;
    const sourceIndex = source.index;
    const destinationIndex = destination ? destination.index : sourceIndex;
    const newItems = [...currentOffers];
    const [removed] = newItems.splice(sourceIndex, 1);
    newItems.splice(destinationIndex, 0, removed);
    const offerIds = newItems.map((x) => x.offerid);
    dispatch(moveOffer(offerIds));
    setCurrentOffers(newItems);
  };

  const disableDragging =
    (searchQuery && searchQuery.length) || !_.isEmpty(currentTargeting)
      ? true
      : false;

  return (
    <>
      <div className={classes.mainContainer}>
        {/* Header */}
        <Header
          title="Placements"
          action={
            <>
              <ThemeProvider theme={button}>
                <ToggleButtonGroup
                  color="primary"
                  exclusive
                  size="small"
                  value={placement}
                >
                  {Object.keys(OfferPlacementOptions).map((key) => {
                    const value = OfferPlacementOptions[key];
                    const selected = value == parseInt(placement);
                    return (
                      <ToggleButton
                        key={nanoid()}
                        selected={selected}
                        onClick={() => setPlacement(value)}
                      >
                        <span
                          className={
                            selected ? classes.toggleButtonSelected : ''
                          }
                        >
                          {key.replace(/\w+/g, _.startCase)}
                        </span>
                      </ToggleButton>
                    );
                  })}
                </ToggleButtonGroup>
              </ThemeProvider>
            </>
          }
        />

        <div className={classes.contentContainer}>
          <TargetingFilters />

          <div className={classes.offerPreviewsContainer}>
            <div className={classes.offersContainer}>
              {/* When offers loading show spinner */}
              {isOffersLoading ? <LoadingOffers /> : null}

              <div className={classes.searchOffers}>
                <TextField
                  id="search-offer"
                  label="Search Offers"
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Search color="disabled" />
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) => dispatch(updateSearchQuery(e.target.value))}
                  value={searchQuery}
                />
              </div>

              {/* When offers done loading check if searchResults are true */}
              {!isOffersLoading && currentOffers && currentOffers.length ? (
                <Styled.TableWrapper>
                  <Styled.HideStuff />
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                      {(droppableProvided) => (
                        <StyledTable.Table ref={droppableProvided.innerRef}>
                          <thead>
                            <tr>
                              <Styled.TableHeader>Name</Styled.TableHeader>
                              <Styled.TableHeader>ID</Styled.TableHeader>
                              <Styled.TableHeader>Group</Styled.TableHeader>
                              <Styled.TableHeader>
                                Start Date
                              </Styled.TableHeader>
                              <Styled.TableHeader>End Date</Styled.TableHeader>
                            </tr>
                          </thead>

                          <tbody>
                            {currentOffers.map((offer, index) => {
                              return (
                                <Draggable
                                  key={offer.offerid}
                                  draggableId={offer.offerid}
                                  index={index}
                                  isDragDisabled={disableDragging}
                                >
                                  {(draggableProvided, draggableSnapshot) => (
                                    <OfferItemDisplay
                                      offer={offer}
                                      provided={draggableProvided}
                                      snapshot={draggableSnapshot}
                                    />
                                  )}
                                </Draggable>
                              );
                            })}
                            {droppableProvided.placeholder}
                          </tbody>
                        </StyledTable.Table>
                      )}
                    </Droppable>
                  </DragDropContext>
                </Styled.TableWrapper>
              ) : null}

              {!isOffersLoading && currentOffers && !currentOffers.length ? (
                <NoOffers />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

interface OfferItemDisplayProps {
  offer: Offer;
  provided: any;
  snapshot: any;
}

const OfferItemDisplay = ({
  offer,
  provided,
  snapshot,
}: OfferItemDisplayProps) => {
  const history = useHistory();
  const { isDragging } = snapshot;
  const getItemStyle = (draggableStyle) => ({
    ...draggableStyle,
    userSelect: 'none',
    background: isDragging ? '#fff' : '',
    borderRadius: isDragging ? 4 : 0,
    boxShadow: isDragging ? '0 4px 8px rgba(0, 0, 0, 0.25)' : '',
  });

  return (
    <Styled.TableBodyRow
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      style={getItemStyle(provided.draggableProps.style)}
    >
      <StyledTable.TableCell>
        <>
          <Styled.DragIcon />
          <a onClick={() => history.push(`/main/offer-wall-manager/editor/${offer.offerid}`) }>{offer && offer.label ? offer.label : 'N/A'}</a>
        </>
      </StyledTable.TableCell>
      {!isDragging ? (
        <>
          <StyledTable.TableCell>
            {offer.offerid ? offer.offerid : 0}
          </StyledTable.TableCell>
          <StyledTable.TableCell>
            {offer && offer.offergroupname ? offer.offergroupname : 'N/A'}
          </StyledTable.TableCell>
          <StyledTable.TableCell>
            {offer.startdate
              ? new Date(offer.startdate).toLocaleDateString()
              : 'N/A'}
          </StyledTable.TableCell>
          <StyledTable.TableCell>
            {offer.enddate
              ? new Date(offer.enddate).toLocaleDateString()
              : 'N/A'}
          </StyledTable.TableCell>
        </>
      ) : null}
    </Styled.TableBodyRow>
  );
};

const LoadingOffers = () => {
  const classes = useStyles();

  return (
    <Box className={classes.centeredContent}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <CircularProgress color="primary" size={40} />
        </Grid>
      </Grid>
    </Box>
  );
};

const NoOffers = () => {
  const classes = useStyles();

  return (
    <Box className={classes.centeredContent}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <OfferIcon />
        </Grid>
        <Grid item>
          <Typography component="h3" variant="h6">
            There are no active offers right now.
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Preview;
