import React, { useEffect, useState } from 'react';
import { useAppDispatch } from 'redux/hooks';
import { Button } from '@klover/attain-design-system';
import { SnackBarOptions, defaultSnackBarOptions } from 'interfaces/ui';
import * as Styled from '../index.styles';

// Material UI Components
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import Typography from '@material-ui/core/Typography';
import tokens from 'tokens';
import {
  Theme,
  ThemeProvider,
  createStyles,
  createTheme,
  makeStyles,
} from '@material-ui/core/styles';
import {
  CustomAudienceGroup,
  deleteGropProps,
  toggleGroupDeleteProps,
} from '../groupName';
import axios from 'axios';
import { auth } from 'utils/firebase';
import { API_BASE_ADDRESS } from '../variables';
import { AnyKindOfDictionary } from 'lodash';
import getAuthorizedAxiosInstance from 'utils/getAuthorizedAxiosInstance';

const API_ENDPOINT = `${API_BASE_ADDRESS}/custom-audience-manager/groups`;

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: theme.spacing(3),
    },
    title: {
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 23,
      paddingLeft: 16,
      paddingTop: 16,
    },
  })
);

const dialog = createTheme({
  overrides: {
    MuiDialog: {
      paper: {
        borderRadius: 10,
      },
      paperWidthMd: {
        maxWidth: 1100,
      },
    },
    MuiMenu: {
      list: {
        padding: 0,
      },
    },
    MuiInputBase: {
      input: {
        borderRadius: 10,
        padding: '12px 14px',
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 10,
      },
    },
    MuiButton: {
      root: {
        borderRadius: 8,
      },
      outlinedPrimary: {
        color: tokens.colorButtonPrimary,
      },
      containedPrimary: {
        backgroundColor: tokens.colorButtonPrimary,
        '&:hover': {
          backgroundColor: tokens.colorButtonPrimary,
        },
      },
    },
  },
});

interface Props {
  currentGroup: CustomAudienceGroup;
  groupDeleting: deleteGropProps;
  toggleGroupDeleteDialog: toggleGroupDeleteProps;
  setGroupDeletingState: () => void;
  settoggleGroupDeleteDialog: () => void;
  handleSelectGroup: AnyKindOfDictionary;
  reFetchGroups: () => void;
}

const GroupDeleteDialog = ({
  currentGroup,
  groupDeleting,
  toggleGroupDeleteDialog,
  setGroupDeletingState,
  settoggleGroupDeleteDialog,
  handleSelectGroup,
  reFetchGroups,
}: Props) => {
  const classes = useStyles();

  const [snackBarOptions, setSnackBarOptions] = useState<SnackBarOptions>(
    defaultSnackBarOptions
  );

  useEffect(() => {
    let snackBarOptions: SnackBarOptions;
    switch (groupDeleting.state) {
      case 'done':
        dispatchClose();

        snackBarOptions = {
          open: true,
          severity: 'success',
          message: groupDeleting.message || '',
        };
        break;
      case 'failed':
        snackBarOptions = {
          open: true,
          severity: 'error',
          message: groupDeleting.message || '',
        };
        break;
      default:
        snackBarOptions = {
          open: false,
          severity: 'success',
          message: '',
        };
        break;
    }
    setSnackBarOptions(snackBarOptions);
  }, [groupDeleting]);

  const closeSnackBar = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') return;
    const updatedSnackBarOptions = {
      ...snackBarOptions,
      open: false,
    };
    setSnackBarOptions(updatedSnackBarOptions);
    setGroupDeletingState({ state: 'idle' });
  };

  const deleting = groupDeleting.state === 'loading';

  const dispatchClose = () => {
    setSnackBarOptions(defaultSnackBarOptions);
    settoggleGroupDeleteDialog({ open: false });
  };

  const handleDeleteGroup = async () => {
    if (currentGroup && auth.currentUser) {
      auth.currentUser?.getIdTokenResult().then(async (res) => {
        setGroupDeletingState({ state: 'loading' });

        try {
          const authorizedAxios = await getAuthorizedAxiosInstance();
          if (authorizedAxios) {
            const res = await authorizedAxios.delete(
              `${API_ENDPOINT}/${currentGroup.id}`
            );
            if (res?.status === 200) {
              setGroupDeletingState({
                state: 'done',
                message: `${currentGroup.name} and its audiences have been deleted`,
              });
              reFetchGroups();
              setTimeout(() => handleSelectGroup('all'), 2000);
            } else {
              throw `Failed to delete group ${currentGroup.name}`;
            }
          }
        } catch (e) {
          setGroupDeletingState({ state: 'failed', message: e });
        }
      });
    }
  };

  const handleClose = () => {
    dispatchClose();
  };

  return (
    <>
      <Styled.RootContainer>
        <Dialog
          fullWidth={true}
          onClose={handleClose}
          open={toggleGroupDeleteDialog.open}
        >
          <Box m={1}>
            <Grid container alignItems="center">
              <Grid item xs={10}>
                <Typography className={classes.title}>
                  Delete {currentGroup?.name}
                </Typography>
              </Grid>
              <Grid item container justifyContent="flex-end" xs={2}>
                <IconButton onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Box>
          <Box m={1}>
            <DialogContent>
              <Styled.DialogText>
                Are you sure you want to permanently delete{' '}
                <Styled.BoldText>{currentGroup?.name}</Styled.BoldText> and all
                its audiences?
              </Styled.DialogText>
            </DialogContent>
          </Box>
          <Grid container spacing={0} style={{ marginTop: 150 }}>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Grid>
          <Box m={1}>
            <DialogActions>
              <Button color="primary" onClick={handleClose} variant="outlined">
                Cancel
              </Button>

              {deleting ? (
                <Styled.ProgressBox>
                  <CircularProgress color="primary" size={20} />
                </Styled.ProgressBox>
              ) : (
                <Button
                  color="primary"
                  disableElevation
                  onClick={handleDeleteGroup}
                  variant="contained"
                >
                  Delete Group
                </Button>
              )}
            </DialogActions>
          </Box>
        </Dialog>
      </Styled.RootContainer>
      <Snackbar
        open={snackBarOptions.open}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={2000}
        onClose={closeSnackBar}
      >
        <Alert onClose={closeSnackBar} severity={snackBarOptions.severity}>
          {snackBarOptions.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default GroupDeleteDialog;
