import styled from 'styled-components';
import tokens from 'tokens';

export const Header = styled.header`
  align-items: center;
  border-bottom: 1px solid ${tokens.colorGray40};
  display: flex;
  line-height: 40px;
  padding: ${tokens.spacing.md};
`;

export const Title = styled.h1`
  ${tokens.typography.subheaderLg};
  line-height: 40px;
  margin: 0;
`;

export const Center = styled.div`
  ${tokens.typography.subheaderSm};
  flex-grow: 1;
  line-height: 40px;
  padding-left: ${tokens.spacing.lg};
  margin-left: ${tokens.spacing.lg};
`;

export const CenterBorder = styled.div`
  ${tokens.typography.subheaderSm};
  border-left: 1px solid ${tokens.colorGray40};
  flex-grow: 1;
  line-height: 40px;
  padding-left: ${tokens.spacing.lg};
  margin-left: ${tokens.spacing.lg};
`;

export const Action = styled.div``;
