import React from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { Button } from '@klover/attain-design-system';
import {
  ToggleDuplicateOfferDialogAction,
  duplicateOffer,
  fetchGroups,
  toggleDuplicateOfferDialog,
} from '../slice';

import { Formik, useFormik } from 'formik';

import Dialog from 'components/Dialog';
import Field from 'components/Field';

import * as Styled from './index.styles';

interface Props {
  open: boolean;
}

const DuplicateOfferDialog = ({ open }: Props) => {
  const dispatch = useAppDispatch();
  const offerToDuplicate = useAppSelector(
    (state) => state.offerWallReducer.currentOffer
  );
  const formik = useFormik({
    initialValues: {
      offerGroupId: '',
      groupName: '',
    },
    onSubmit: (values) => {
      dispatch(
        duplicateOffer({
          ...values,
          offerToDuplicate,
        })
      );
    },
  });

  const groupsEntities = useAppSelector((state) => {
    if (
      state.offerWallReducer &&
      state.offerWallReducer.entities &&
      state.offerWallReducer.entities.groups
    ) {
      return state.offerWallReducer.entities.groups;
    } else {
      return null;
    }
  });

  const groupIds = groupsEntities ? groupsEntities.allIds : null;

  const dispatchClose = () => {
    const actionProps: ToggleDuplicateOfferDialogAction = {
      open: false,
    };
    dispatch(toggleDuplicateOfferDialog(actionProps));
  };

  const offerLoading = useAppSelector(
    (state) => state.offerWallReducer.ui.offerLoading
  );

  const isOfferLoading = offerLoading.state === 'loading';

  const handleClose = () => {
    dispatchClose();
  };

  React.useEffect(() => {
    dispatch(fetchGroups());
  }, [dispatch]);

  return (
    <>
      <Formik
        initialValues={{ alignment: 'oldGroups' }}
        onSubmit={formik.handleSubmit}
      >
        {({ values }) => (
          <Dialog
            isOpen={open}
            onDismiss={handleClose}
            showClose={true}
            onCloseClick={handleClose}
            title="Duplicate Offer"
            buttons={
              <>
                <Button
                  variant="contained"
                  disabled={
                    values.alignment == 'newGroup' && !formik.values.groupName
                  }
                  onClick={formik.handleSubmit}
                >
                  Duplicate Offer
                </Button>
                <Button
                  variant="outlined"
                  disableElevation
                  disabled={isOfferLoading}
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </>
            }
          >
            <p>
              Are you sure you want to duplicate the offer? Choose an offer
              group or select a new one.
            </p>
            <Styled.RadioGroup role="group" aria-labelledby="my-radio-group">
              <div>
                <label htmlFor="oldGroups">
                  <Field
                    id="oldGroups"
                    type="radio"
                    name="alignment"
                    value="oldGroups"
                  />
                  <span>Select Group</span>
                </label>
              </div>
              <div>
                <label htmlFor="newGroup">
                  <Field
                    id="newGroup"
                    type="radio"
                    name="alignment"
                    value="newGroup"
                  />
                  <span>Create New Group</span>
                </label>
              </div>
            </Styled.RadioGroup>
            {values.alignment == 'oldGroups' ? (
              <Field
                as="select"
                id="offerGroupId"
                name="offerGroupId"
                onChange={formik.handleChange}
                value={formik.values.offerGroupId}
              >
                <option value="" key="default">
                  Default
                </option>
                {groupIds
                  ? groupIds.map((groupId) => {
                    return (
                      <option key={groupId} value={groupId}>
                        {groupsEntities &&
                          groupsEntities.byId &&
                          groupsEntities.byId[groupId] &&
                          groupsEntities.byId[groupId].name
                          ? groupsEntities.byId[groupId].name
                          : null}
                      </option>
                    );
                  })
                  : null}
              </Field>
            ) : (
              <Field
                type="text"
                value={formik.values.groupName}
                id="groupName"
                name="groupName"
                placeholder="e.g. Walmart Offers..."
                onChange={formik.handleChange}
              />
            )}
          </Dialog>
        )}
      </Formik>
    </>
  );
};

export default DuplicateOfferDialog;
