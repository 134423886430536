import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { Button, IconButton } from '@klover/attain-design-system';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { useHistory } from 'react-router-dom';

// Material UI Components
import ArrowBack from '@material-ui/icons/ArrowBack';
import CircularProgress from '@material-ui/core/CircularProgress';
import CloseIcon from '@material-ui/icons/Close';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import InputAdornment from '@material-ui/core/InputAdornment';
import SaveIcon from '@material-ui/icons/Save';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import {
  Theme,
  ThemeProvider,
  createStyles,
  createTheme,
  makeStyles,
} from '@material-ui/core/styles';

//components
import BootstrapTooltip from 'components/bootstrapTooltip';
import LaunchDealsDisabledButtonMessage from '../launchDealDisabledMessage';
import tokens from 'tokens';
import {
  ADMIN_USERS,
  DealJob,
  launchDeal,
  saveDeal,
  setCurrentDeal,
  setDealDeleteDialogOpen,
  setOriginalDeal,
} from '../slice';
import { Divider } from '@material-ui/core';
import { ErrorOutline } from '@material-ui/icons';
import { auth } from 'utils/firebase';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    lastModifiedContainer: {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: 'auto',
    },
    creationDateContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    exportMessageTextAlignment: {
      textAlign: 'left',
      padding: 10,
    },
    exportSpinner: {
      width: 50,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    divider: {
      borderRight: '2px solid rgb(232, 232, 232)',
      height: 50,
      width: 2,
      marginLeft: '16px',
      marginRight: '16px',
    },
    button: {
      margin: theme.spacing(0, 1),
    },
    backButton: {
      paddingRight: '1em',
    },
    topBarContainer: {
      display: 'flex',
      alignItems: 'center',
      padding: '12px',
      backgroundColor: tokens.colorTextDefault,
      borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
    topNameContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    subtitle: {
      fontStyle: 'normal',
      fontWeight: 300,
      fontSize: 11,
    },
    editButton: {
      marginLeft: theme.spacing(4),
    },
    launchDealContainer: {
      display: 'flex',
      alignItems: 'center',
    },
  })
);

const inputs = createTheme({
  overrides: {
    MuiFormControl: {
      root: {
        maxHeight: 48,
      },
    },
    MuiFormLabel: {
      root: {
        maxHeight: 48,
      },
    },
    MuiInputLabel: {
      root: {
        marginTop: -5,

        '&$focused': {
          marginTop: 0,
        },
      },
    },
    MuiInputBase: {
      root: {
        maxHeight: 48,
      },
      input: {
        borderRadius: 10,
        alignSelf: 'center',
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 10,
      },
      input: {
        padding: '12px 14px',
      },
    },
    MuiButton: {
      root: {
        borderRadius: 10,
      },
      outlinedPrimary: {
        color: tokens.colorPrimary,
      },
      containedPrimary: {
        backgroundColor: tokens.colorPrimary,
        '&:hover': {
          backgroundColor: tokens.colorPrimary,
        },
      },
    },
    MuiButtonBase: {
      root: {
        borderRadius: 10,
      },
    },
  },
});

const DealName = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [editMode, setEditMode] = useState(false);

  const currentDeal = useAppSelector((state) => state.dealsReducer.currentDeal);

  const originalDeal = useAppSelector(
    (state) => state.dealsReducer.originalDeal
  );

  useEffect(() => {
    if (currentDeal && !currentDeal.dealname) {
      setEditMode(true);
    }
  }, [currentDeal]);

  const handleCancel = () => {
    if (originalDeal) {
      const updatedDeal = {
        ...currentDeal,
        dealname: originalDeal.dealname,
      };
      dispatch(setCurrentDeal(updatedDeal));
      setEditMode(false);
    } else {
      const updatedDeal = {
        ...currentDeal,
        dealname: '',
      };
      dispatch(setCurrentDeal(updatedDeal));
    }
  };

  // const handleEditName = () => {
  //   setEditMode(true)
  // }

  const handleNameChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    const updatedDeal = {
      ...currentDeal,
      dealname: e.target.value,
    };
    dispatch(setCurrentDeal(updatedDeal));
  };

  const handleLaunchDeal = () => {
    dispatch(launchDeal(originalDeal));
  };

  const isValidDeal = () => {
    return (
      !originalDeal?.launchedat &&
      originalDeal?.confirmed &&
      originalDeal?.bidderid &&
      originalDeal?.biddername &&
      originalDeal?.buyername &&
      originalDeal?.buyercode
    );
  };

  const handleSave = () => {
    dispatch(setOriginalDeal(_.cloneDeep(currentDeal)));
    dispatch(saveDeal(currentDeal));
    // if (originalDeal) {
    //   //dispatch(saveSalesLiftStudy(currentSalesLiftStudy))
    //   setEditMode(false)
    // } else {
    //   setEditMode(false)
    // }
  };

  const handleDelete = () => {
    dispatch(setDealDeleteDialogOpen(true));
  };

  //const title = !currentDeal?.dealname ? 'Deal needs a name to save.' : ''

  return (
    <>
      <ThemeProvider theme={inputs}>
        <div className={classes.topBarContainer}>
          <div className={classes.backButton}>
            <IconButton
              color="primary"
              onClick={() => history.push('/main/pmp-deals')}
            >
              <ArrowBack />
            </IconButton>
          </div>
          <div>
            {editMode ? (
              <div className={classes.topNameContainer}>
                <div>
                  <TextField
                    id="deal-name"
                    label="Deal Name"
                    onChange={handleNameChange}
                    value={currentDeal?.dealname}
                    variant="outlined"
                    style={{ marginRight: 8 }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            color="default"
                            onClick={handleSave}
                            size="small"
                            //disabled={currentIsDisabled}
                          >
                            <DealSaveIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div>
                  <IconButton onClick={handleCancel}>
                    <CloseIcon />
                  </IconButton>
                </div>
              </div>
            ) : (
              <div className={classes.topNameContainer}>
                <div>
                  <Typography component="h2" variant="h6">
                    {currentDeal?.dealname}
                  </Typography>
                  {!editMode && (
                    <Typography component="h3" variant="body2">
                      ID: {currentDeal?.xandrdealid || 'N/A'}
                    </Typography>
                  )}
                </div>
                {/* <div>
                  <IconButton
                    className={classes.editButton}
                    color="default"
                    disabled
                    onClick={handleEditName}
                  >
                    <CreateIcon />
                  </IconButton>
                </div> */}
                {ADMIN_USERS.has(auth.currentUser?.email) && (
                  <IconButton color="default" onClick={handleDelete}>
                    <DeleteForeverIcon />
                  </IconButton>
                )}
              </div>
            )}
          </div>
          <div className={classes.launchDealContainer}>
            {/* <BootstrapTooltip title={title}>
              <span>
                <Button
                  className={classes.button}
                  disableElevation
                  variant="contained"
                  color="primary"
                  onClick={handleSave}
                  // disabled={isSaveDisabled}
                >
                  Save
                </Button>
              </span>
            </BootstrapTooltip> */}

            <span>
              <Button
                className={classes.button}
                disableElevation
                variant="contained"
                color="primary"
                onClick={handleLaunchDeal}
                disabled={
                  !isValidDeal() ||
                  !!originalDeal?.history?.filter(
                    (job: DealJob) => job.jobstatus !== 'ERROR'
                  ).length
                }
              >
                Launch Deal
              </Button>
            </span>
            {!isValidDeal() && !originalDeal?.launchedat && (
              <BootstrapTooltip title={<LaunchDealsDisabledButtonMessage />}>
                <ErrorOutline color="disabled" />
              </BootstrapTooltip>
            )}
          </div>
          <div className={classes.lastModifiedContainer}>
            <Typography className={classes.subtitle}>Last modified</Typography>
            <Typography component="p" variant="body2">
              {new Date(Number(currentDeal?.updatedat))
                .toLocaleString()
                .replace(', ', '・')}
            </Typography>
          </div>
          <Divider orientation="vertical" className={classes.divider} />
          <div className={classes.creationDateContainer}>
            <Typography className={classes.subtitle}>Created</Typography>
            <Typography component="p" variant="body2">
              {currentDeal?.submittedby}
            </Typography>
            <Typography component="p" variant="body2">
              {new Date(Number(currentDeal?.createdat))
                .toLocaleString()
                .replace(', ', '・')}
            </Typography>
          </div>
        </div>
      </ThemeProvider>
    </>
  );
};

const DealSaveIcon = () => {
  return (
    <>{false ? <CircularProgress color="primary" size={20} /> : <SaveIcon />}</>
  );
};

export default DealName;
