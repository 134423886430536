import CreateAnswerResponses from '../createAnswerResponse';
import Dialog from 'components/Dialog';
import ErrorMsg from 'components/ErrorMsg';
import Field from 'components/Field';
import Picker from 'emoji-picker-react';
import React, { useState } from 'react';
import SelectFreeFormAnswerResponseType from '../SelectFreeFormAnswerResponseType';
import Stack from 'components/Stack';
import Toggle from 'components/Toggle';
import _ from 'lodash';
import getAuthorizedAxiosInstance from 'utils/getAuthorizedAxiosInstance';
import tokens from 'tokens';
import { API_BASE_ADDRESS } from 'pages/audienceManager/variables';
import { Audience } from 'interfaces';
import { AudienceTypes, MULTIPLIER, SaveStatus } from 'pages/variables';
import { Button } from '@klover/attain-design-system';
import { CircularProgress } from '@material-ui/core';
import { FormikProvider, useFormik } from 'formik';
import { LoadingStates } from 'constants/index';
import {
  QQ_ANSWERTYPE_FREE_FORM,
  QQ_ANSWERTYPE_MULTIPLE,
  QQ_ANSWERTYPE_SINGLE,
} from '../variables';
import { Question } from 'interfaces/quickQuestionsManager';
import { QuickQuestionContext } from 'context';
import { Tooltip } from 'antd';
import { addNotification } from 'pages/audienceManager/slice';
import { auth } from 'utils/firebase';
import { nanoid } from 'nanoid';
import { useAppDispatch } from 'redux/hooks';
import { useUser } from 'reactfire';
import * as Grid from 'components/Grid';
import * as Styled from './index.styles';
import * as Yup from 'yup';

const CreateQuickQuestion = () => {
  const { setShowCreateQuickQuestion, setQuestions, questions } =
    React.useContext(QuickQuestionContext);
  const { AUDIENCE, CUSTOM } = AudienceTypes;
  const { NEW } = SaveStatus;

  const [searchAudiences, setSearchAudiences] = React.useState('');
  const [audiences, setAudiences] = React.useState<Audience[]>([]);
  const [fetchingAudiences, setFetchingAudiencesFalse] = React.useState(false);
  const filteredAudiences = audiences.filter(({ name }) =>
    name.toUpperCase().includes(searchAudiences.toUpperCase())
  );
  const [selectedAudiences, setSelectedAudiences] = React.useState({});
  const [audienceType, setAudienceType] = useState(AUDIENCE);
  const { data } = useUser();
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    const apiEndPoint =
      audienceType === AUDIENCE
        ? 'audience-manager'
        : 'custom-audience-manager';
    const getAudiences = async () => {
      try {
        setFetchingAudiencesFalse(true);
        const axiosInstance = await getAuthorizedAxiosInstance();
        if (axiosInstance) {
          const res = await axiosInstance.get(
            `${API_BASE_ADDRESS}/${apiEndPoint}/audiences`
          );
          setAudiences(res.data);
          setFetchingAudiencesFalse(false);
        } else {
          throw new Error('Failed to authorize request');
        }
      } catch (e) {
        console.log('Error', e);
        setFetchingAudiencesFalse(false);
      }
    };
    getAudiences();
  }, [audienceType]);

  const validationSchema = Yup.object().shape({
    questiontext: Yup.string().required('Question is required.'),
    answers: Yup.array().when(['answertype'], {
      is: (answertype: string) =>
        answertype === QQ_ANSWERTYPE_SINGLE ||
        answertype === QQ_ANSWERTYPE_MULTIPLE,
      then: Yup.array()
        .of(
          Yup.object().shape({
            answertext: Yup.string().required('An answer is required.'),
          })
        )
        .min(2, 'Needs at least two answers.'),
    }),
    points: Yup.number()
      .integer('Points need to be an integer.')
      .max(500, 'Points cannot be greater than 500.')
      .min(0, 'Points must be positive.')
      .required('Points are required.'),
    zindex: Yup.number()
      .integer('Z-Index needs to be an integer.')
      .min(0, 'Z-Index must be positive.')
      .required('Z-Index is required.'),
  });
  const formik = useFormik({
    initialValues: {
      createdby: auth.currentUser?.email,
      questiontext: '',
      answertype: QQ_ANSWERTYPE_SINGLE,
      freeformanswertype: null,
      changeEmoji: false,
      description: 'Answers ',
      questionemoji: {
        unified: '1F600',
        emoji: '😀',
        originalUnified: '1F600',
        names: ['grinning face', 'grinning'],
      },
      isrepeatable: 0,
      isenabled: 1,
      isactive: false,
      points: process.env.QUICK_QUESTION_POINT || 1,
      answers: [{ answertext: '' }, { answertext: '' }],
      questionSaving: false,
      zindex: 1000,
    },
    validationSchema,
    onSubmit: async (values) => {
      formik.setFieldValue('questionSaving', true);
      try {
        const modifiedPayload: Question = _.omit(_.clone(values), [
          'changeEmoji',
          'questionSaving',
        ]);
        modifiedPayload.questionemoji =
          modifiedPayload.questionemoji.unified.toUpperCase();
        modifiedPayload.description += modifiedPayload.questiontext;
        modifiedPayload.answers = modifiedPayload.answers.map(
          (answer, index) => ({
            ...answer,
            answerid: index + 1 < 10 ? `0${index + 1}` : `${index + 1}`,
          })
        );
        if (!_.isEmpty(selectedAudiences)) {
          modifiedPayload.audienceid = parseInt(
            Object.keys(selectedAudiences)[0]
          );
          modifiedPayload.audiencetype =
            audienceType === AUDIENCE ? AUDIENCE : CUSTOM;
        }

        const axiosInstance = await getAuthorizedAxiosInstance();
        const questionRes: { data: { questionid: string } } =
          await axiosInstance?.post(
            `${API_BASE_ADDRESS}/questions`,
            modifiedPayload
          );
        const { questionid } = questionRes.data;
        setQuestions([questionRes.data, ...questions]);
        if (!_.isEmpty(selectedAudiences)) {
          await axiosInstance?.put(
            `${API_BASE_ADDRESS}/questions/${questionid}/targeting`,
            {
              audiences: Object.keys(selectedAudiences).map((key) =>
                parseInt(key)
              ),
              user: data?.email,
              audienceName: audiences.find(
                (audience) =>
                  audience.id === parseInt(Object.keys(selectedAudiences)[0])
              )?.name,
              type: audienceType === AUDIENCE ? AUDIENCE : CUSTOM,
              audienceMapStatus:
                Object.keys(selectedAudiences).length === 0 ? '' : NEW,
              isNewRecord: true,
            }
          );
        }
        setShowCreateQuickQuestion(false);
        formik.setFieldValue('questionSaving', false);
        dispatch(
          addNotification({
            state: LoadingStates.DONE,
            message: 'Quick Question Created',
          })
        );
      } catch (e) {
        console.log('the error', e);
        setShowCreateQuickQuestion(false);
        formik.setFieldValue('questionSaving', false);
        dispatch(
          addNotification({
            state: LoadingStates.ERROR,
            message: 'Error Saving Quick Question.',
          })
        );
      }
    },
  });

  const {
    isactive,
    answertype,
    answers,
    freeformanswertype,
    changeEmoji,
    questionemoji,
    questionSaving,
  } = formik.values;
  const errors = Object.values(formik.errors);

  return (
    <Dialog
      onDismiss={() => {
        formik.resetForm();
        setShowCreateQuickQuestion(false);
      }}
      showClose={true}
      onCloseClick={() => {
        formik.resetForm();
        setShowCreateQuickQuestion(false);
      }}
      title={`Create Quick Question`}
      buttons={
        <>
          <Tooltip
            zIndex={999999999999999}
            title={
              errors && errors.length
                ? errors.map((error) => {
                    if (typeof error == 'string') return <div>{error}</div>;
                    if (error.length && _.isArray(error))
                      return <div>Please fill out all possible responses.</div>;
                    return null;
                  })
                : null
            }
          >
            <span>
              <Button
                variant="contained"
                type="submit"
                onClick={formik.handleSubmit}
                disabled={
                  !formik.isValid ||
                  formik.isSubmitting ||
                  !Object.keys(formik.touched).length
                    ? true
                    : false
                }
              >
                {questionSaving ? (
                  <CircularProgress color="inherit" size={20} />
                ) : (
                  'Save'
                )}
              </Button>
            </span>
          </Tooltip>
          <Button
            variant="outlined"
            onClick={() => {
              formik.resetForm();
              setShowCreateQuickQuestion(false);
            }}
          >
            Cancel
          </Button>
        </>
      }
    >
      <FormikProvider value={formik}>
        <Styled.GridContainer>
          <Stack spacing={tokens.spacing.md}>
            <Grid.Row>
              <Grid.Col>
                <Field
                  fullWidth
                  name="questiontext"
                  label="Text"
                  placeholder="e.g How many children in your household?"
                />

                <ErrorMsg name="questiontext" />
              </Grid.Col>
            </Grid.Row>

            <Grid.Row>
              <Grid.Col>
                <Field
                  fullWidth
                  name="points"
                  label="Points"
                  type="number"
                  placeholder="e.g 1"
                />

                <ErrorMsg name="points" />
              </Grid.Col>

              {/* <Grid.Col>
                <Field
                  fullWidth
                  name="zindex"
                  type="number"
                  label="Z-Index"
                  placeholder="e.g 100"
                />

                <ErrorMsg name="zindex" />
              </Grid.Col> */}
            </Grid.Row>

            <Grid.Row>
              <Grid.Col>
                <Styled.Label>Emoji</Styled.Label>

                <Styled.PickerSpace
                  changeEmoji={changeEmoji}
                  onMouseLeave={() =>
                    formik.setFieldValue('changeEmoji', false)
                  }
                  onMouseEnter={() => formik.setFieldValue('changeEmoji', true)}
                >
                  <Button type="button" variant="outlined">
                    Choose Emoji {questionemoji.emoji}
                  </Button>

                  {changeEmoji ? (
                    <>
                      <label htmlFor="questionemoji" hidden>
                        Emoji
                      </label>

                      <Picker
                        onEmojiClick={(_, emojiObject) =>
                          formik.setFieldValue('questionemoji', emojiObject)
                        }
                        disableAutoFocus={true}
                        disableSkinTonePicker
                        groupNames={{ smileys_people: 'PEOPLE' }}
                        native
                        groupVisibility={{
                          flags: false,
                          food_drink: false,
                          travel_places: false,
                          activities: false,
                          objects: false,
                          recently_used: false,
                        }}
                      />
                    </>
                  ) : null}
                </Styled.PickerSpace>
              </Grid.Col>
              <Grid.Col>
                <label htmlFor="answertype" hidden>
                  Answer Type
                </label>

                <Styled.Label>Answer Type</Styled.Label>
                <Field
                  as="select"
                  name="answertype"
                  value={answertype}
                  onChange={formik.handleChange}
                >
                  <option value={QQ_ANSWERTYPE_SINGLE}>Single</option>
                  <option value={QQ_ANSWERTYPE_MULTIPLE}>Multiple</option>
                  {/* <option value={QQ_ANSWERTYPE_FREE_FORM}>Free Form</option> */}
                </Field>
              </Grid.Col>

              <Grid.Col>
                <Styled.Label>Status</Styled.Label>

                <Toggle
                  name="is-active-toggle"
                  checked={isactive ? true : false}
                  onClick={() => {
                    formik.setFieldValue('isactive', isactive ? 0 : 1);
                  }}
                />
              </Grid.Col>
            </Grid.Row>

            <Grid.Row>
              <Grid.Col>
                {(answertype === QQ_ANSWERTYPE_SINGLE ||
                  answertype === QQ_ANSWERTYPE_MULTIPLE) && (
                  <CreateAnswerResponses answers={answers} />
                )}
                {answertype === QQ_ANSWERTYPE_FREE_FORM && (
                  <SelectFreeFormAnswerResponseType
                    freeformanswertype={freeformanswertype}
                    handleChange={formik.handleChange}
                  />
                )}
              </Grid.Col>
            </Grid.Row>

            <Grid.Row>
              <Grid.Col>
                <label htmlFor="audienceType" hidden>
                  Audience Type
                </label>

                <Styled.Label>Audience Type</Styled.Label>
                <Field
                  as="select"
                  name="audienceType"
                  value={audienceType}
                  onChange={(e: any) => {
                    setAudienceType(e.target.value);
                    setSelectedAudiences({});
                  }}
                >
                  <option value="AUDIENCE">Audience Manager</option>
                  <option value="CUSTOM">Custom Audience</option>
                </Field>
              </Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col xs={12}>
                <label htmlFor="audiences" hidden>
                  Audiences
                </label>
                <Field
                  fullWidth
                  name="searchAudiences"
                  label="Attach Audiences"
                  placeholder="e.g Audience 1"
                  value={searchAudiences}
                  onChange={(e) => setSearchAudiences(e.target.value)}
                />
                <Styled.RadioSelectionSpace>
                  {!fetchingAudiences ? (
                    <Stack spacing={tokens.spacing.sm}>
                      <AudienceList
                        audienceType={audienceType}
                        filteredAudiences={filteredAudiences}
                        selectedAudiences={selectedAudiences}
                        setSelectedAudiences={setSelectedAudiences}
                      />
                    </Stack>
                  ) : (
                    <div>
                      <CircularProgress size={20} />
                    </div>
                  )}
                </Styled.RadioSelectionSpace>
              </Grid.Col>
            </Grid.Row>
          </Stack>
        </Styled.GridContainer>
      </FormikProvider>
    </Dialog>
  );
};

export const AudienceList = ({
  filteredAudiences,
  audienceType,
  selectedAudiences,
  setSelectedAudiences,
}) => {
  if (audienceType === 'AUDIENCE') {
    return filteredAudiences
      .sort((a, b) => b.total_size - a.total_size)
      .map(({ id, name, total_size }) => (
        <div key={nanoid()}>
          <Field
            name={`${id}-${name}`}
            type="radio"
            value={id}
            multiple
            checked={selectedAudiences[id]}
            onClick={(e) => {
              e.preventDefault();
              if (selectedAudiences[id]) {
                setSelectedAudiences({});
              } else {
                setSelectedAudiences({
                  [id]: 1,
                });
              }
            }}
            onChange={(e) => e.preventDefault()}
            style={{
              cursor: 'pointer',
            }}
          />
          <span>
            {name} -{' '}
            {total_size
              ? `(id: ${id}/ users: ${total_size.toLocaleString()})`
              : ''}
          </span>
        </div>
      ));
  } else {
    return filteredAudiences
      .sort((a, b) => b.size - a.size)
      .map(({ id, name, size }) => (
        <div key={nanoid()}>
          <Field
            name={`${id}-${name}`}
            type="radio"
            value={id}
            multiple
            checked={selectedAudiences[id]}
            onClick={(e) => {
              e.preventDefault();
              if (selectedAudiences[id]) {
                setSelectedAudiences({});
              } else {
                setSelectedAudiences({
                  [id]: 1,
                });
              }
            }}
            onChange={(e) => e.preventDefault()}
            style={{
              cursor: 'pointer',
            }}
          />
          <span>
            {name} -{' '}
            {size ? `(id: ${id}/ users: ${size.toLocaleString()})` : ''}
          </span>
        </div>
      ));
  }
};

export default CreateQuickQuestion;
