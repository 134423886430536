import Dialog from 'components/Dialog';
import Field from 'components/Field';
import Stack from 'components/Stack';
import _ from 'lodash';
import getAuthorizedAxiosInstance from 'utils/getAuthorizedAxiosInstance';
import tokens from 'tokens';
import { API_BASE_ADDRESS } from 'pages/audienceManager/variables';
import { CircularProgress } from '@material-ui/core';
import { FormikProvider, useFormik } from 'formik';
import { AudienceTypes, MULTIPLIER, SaveStatus } from 'pages/variables';
import { QuickQuestionContext } from 'context';
import { UseState } from 'interfaces';
import { nanoid } from 'nanoid';
import { useContext, useEffect, useState } from 'react';
import { useUser } from 'reactfire';
import * as Grid from 'components/Grid';
import * as Styled from './index.styles';
import { Button } from '@klover/attain-design-system';
import { AudienceList } from '../createQuickQuestion';

const EditTargeting = ({
  questionid,
  setOriginalTargeting,
  type,
  audiencesList,
}: {
  questionid: string;
  setOriginalTargeting?: UseState;
}) => {
  const { setShowEditTargeting } = useContext(QuickQuestionContext);
  const { AUDIENCE } = AudienceTypes;
  const { NEW, UPDATE } = SaveStatus;

  const [audiences, setAudiences] = useState([]);
  const [audienceType, setAudienceType] = useState(type || AUDIENCE);
  const [searchAudiences, setSearchAudiences] = useState('');
  const filteredAudiences = audiences.filter(({ name }) =>
    name.toUpperCase().includes(searchAudiences.toUpperCase())
  );
  const [saving, setSaving] = useState(false);
  const [audiencesLoading, setAudiencesLoading] = useState(true);
  const [selectedAudiences, setSelectedAudiences] = useState({});
  const [targetingLoading, setTargetingLoading] = useState(true);

  const { data } = useUser();
  useEffect(() => {
    const apiEndPoint =
      audienceType === AUDIENCE
        ? 'audience-manager'
        : 'custom-audience-manager';
    const getAudiences = async () => {
      try {
        setAudiencesLoading(true);

        const axiosInstance = await getAuthorizedAxiosInstance();
        if (axiosInstance) {
          const res = await axiosInstance.get(
            `${API_BASE_ADDRESS}/${apiEndPoint}/audiences`
          );
          setAudiences(res.data);
          setAudiencesLoading(false);
        } else {
          throw new Error('Failed to authorize request');
        }
      } catch (e) {
        console.log('Error', e);
        setAudiencesLoading(false);
      }
    };
    getAudiences();
  }, [audienceType]);

  useEffect(() => {
    const getQuestion = async () => {
      try {
        const axiosInstance = await getAuthorizedAxiosInstance();
        if (axiosInstance) {
          const res = await axiosInstance.get(
            `${API_BASE_ADDRESS}/questions/${questionid}`
          );
          const { audiences } = res.data;
          if (audiences) {
            console.log('the audiences', audiences);
            setSelectedAudiences(
              audiences.reduce((a, x) => ({ ...a, [x.id]: 1 }), {})
            );
          }
          setTargetingLoading(false);
        } else {
          throw new Error('Failed to authorize request');
        }
      } catch (err) {
        console.log(err);
        setTargetingLoading(false);
      }
    };
    getQuestion();
  }, []);

  const formik = useFormik({
    initialValues: {},
    onSubmit: async () => {
      setSaving(true);
      const audienceMapStatus =
        audiencesList.length === 0 && audiences?.length >= 0 ? NEW : UPDATE;
      try {
        const axiosInstance = await getAuthorizedAxiosInstance();
        if (axiosInstance) {
          await axiosInstance.put(
            `${API_BASE_ADDRESS}/questions/${questionid}/targeting`,
            {
              audiences: Object.keys(selectedAudiences).map((key) =>
                parseInt(key)
              ),
              user: data?.email,
              audienceName: audiences.find(
                (audience) =>
                  audience.id === parseInt(Object.keys(selectedAudiences)[0])
              )?.name,
              type: audienceType,
              audienceMapStatus,
            }
          );
          if (setOriginalTargeting) {
            setOriginalTargeting(
              audiences.filter(({ id }) => selectedAudiences[id])
            );
          }
        } else {
          throw new Error('Failed to authorize request');
        }
      } catch (e) {
        console.log('Error from Editing Targeting', e);
      }
      setSaving(false);
      setShowEditTargeting(false);
    },
  });

  return (
    <Dialog
      onDismiss={() => {
        formik.resetForm();
        setShowEditTargeting(false);
      }}
      showClose={true}
      onCloseClick={() => {
        formik.resetForm();
        setShowEditTargeting(false);
      }}
      title={`Edit Quick Question Targeting`}
      buttons={
        <>
          <span>
            <Button
              variant="contained"
              type="submit"
              onClick={formik.handleSubmit}
            >
              {saving ? <CircularProgress size={20} /> : 'Save'}
            </Button>
          </span>
          <Button
            variant="outlined"
            onClick={() => {
              formik.resetForm();
              setShowEditTargeting(false);
            }}
          >
            Cancel
          </Button>
        </>
      }
    >
      <FormikProvider value={formik}>
        <Grid.Container>
          <Stack spacing={tokens.spacing.md}>
            <Grid.Row>
              <Grid.Col>
                <label htmlFor="audienceType" hidden>
                  Audience Type
                </label>
                <Field
                  as="select"
                  name="audienceType"
                  label="Audience Type"
                  value={audienceType}
                  onChange={(e: any) => {
                    setAudienceType(e.target.value);
                    setSelectedAudiences({});
                  }}
                >
                  <option value="AUDIENCE">Audience Manager</option>
                  <option value="CUSTOM">Custom Audience</option>
                </Field>
              </Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col>
                {targetingLoading && audiencesLoading ? (
                  <CircularProgress size={20} />
                ) : (
                  <>
                    <label htmlFor="audiences" hidden>
                      Audiences
                    </label>
                    <Field
                      fullWidth
                      name="searchAudiences"
                      label="Attach Audiences"
                      placeholder="e.g Audience 1"
                      value={searchAudiences}
                      onChange={(e) => setSearchAudiences(e.target.value)}
                    />
                    <Styled.RadioSelectionSpace>
                      {!audiencesLoading ? (
                        <Stack spacing={tokens.spacing.sm}>
                          <AudienceList
                            audienceType={audienceType}
                            filteredAudiences={filteredAudiences}
                            selectedAudiences={selectedAudiences}
                            setSelectedAudiences={setSelectedAudiences}
                          />
                        </Stack>
                      ) : (
                        <div>
                          <CircularProgress size={20} />
                        </div>
                      )}
                    </Styled.RadioSelectionSpace>
                  </>
                )}
              </Grid.Col>
            </Grid.Row>
          </Stack>
        </Grid.Container>
      </FormikProvider>
    </Dialog>
  );
};

export default EditTargeting;
