import React, { useMemo } from 'react';
import { IconButton } from '@klover/attain-design-system';

// Material UI Components
import Box from '@material-ui/core/Box';
import CloseIcon from '@material-ui/icons/Close';
import HistoryIcon from '@mui/icons-material/History';
import CircleIcon from '@mui/icons-material/Circle';
import CloudDoneIcon from '@mui/icons-material/CloudDone';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Dialog from '@material-ui/core/Dialog';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import tokens from 'tokens';
import {
  ENUM_EXPORT_STATUS,
  ENUM_EXPORT_TYPE,
  convertToDateTime,
  getExportLatestStatus,
  getFileUploadingStatus,
  getStatusDetails,
  modifyProps,
  segmentDetails,
} from './exportHistoryUtils';
import {
  Theme,
  ThemeProvider,
  createStyles,
  createTheme,
  makeStyles,
} from '@material-ui/core/styles';
import { UploadStates } from '../../../constants/index';
import CopyPopover from 'components/CopyPopover';
import { getAudienceExportStatus } from 'utils/audienceExportStatus';

/**
 * Define custom styles using makeStyles
 */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    close: {
      height: 24,
      width: 24,
    },
    columnTitles: {
      color: 'grey',
      display: 'flex',
      fontSize: 15,
      padding: 5,
      paddingBottom: 0,
    },
    header: {
      padding: '10px 12px 10px 24px',
    },
    noLogs: {
      display: 'flex',
      justifyContent: 'center',
      color: '#413762',
      fontSize: '22px',
      fontWeight: 500,
    },
    icon: {
      height: 24,
      width: 24,
    },
    root: {
      margin: theme.spacing(3),
    },
    lineDivider: {
      margin: '2px 0px',
    },
    accordion: {
      height: 55,
    },
    title: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '10px',
    },
    titleDate: {
      paddingLeft: 33,
    },
    titleStatus: {
      paddingLeft: 29,
    },
    titleExportedBy: {
      paddingLeft: 24,
    },
    histroryTitleContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: 'grey',
      fontSize: 15,
      padding: '5px 24px 5px 0px',
      paddingBottom: 0,
    },
    historyTitle: {
      color: '#413762',
      fontSize: '20px',
      fontWeight: 500,
    },
    histroryDetailContainer: {
      marginTop: '10px',
      display: 'flex',
    },
    tableContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    tableTitle: {
      color: '#413762',
      fontSize: '20px',
      fontWeight: 500,
      float: 'left',
      paddingBottom: '5px',
    },
    rowContainer: {
      color: 'gray',
      fontSize: '15px',
    },
    historyMainContainer: {
      display: 'flex',
      padding: '18px',
      border: '1px solid gray',
      borderRadius: '25px',
    },
    fileStatusIcon: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      gap: '5px',
    },
    fileName: {
      wordBreak: 'break-all',
    },
    historyDateTime: {
      marginLeft: '25px',
    },
  })
);

const dialog = createTheme({
  overrides: {
    MuiDialog: {
      paper: {
        borderRadius: 10,
        overflowX: 'hidden',
      },
      paperWidthMd: {
        maxWidth: 1100,
      },
    },
    MuiMenu: {
      list: {
        padding: 0,
      },
    },
    MuiInputBase: {
      input: {
        borderRadius: 10,
        padding: '12px 14px',
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 10,
      },
    },
    MuiButton: {
      root: {
        borderRadius: 8,
      },
      outlinedPrimary: {
        color: tokens.colorButtonPrimary,
      },
      containedPrimary: {
        backgroundColor: tokens.colorButtonPrimary,
        '&:hover': {
          backgroundColor: tokens.colorButtonPrimary,
        },
      },
    },
  },
});

/**
 * ExportHistoryDialog component definition
 */
function ExportHistroryDialog(props: any) {
  const {
    isOpen = false,
    setOpenDialog,
    data = [],
    customAudience = null,
    normalAudience = null,
  } = props || {};
  const classes = useStyles();
  const historyData = useMemo(() => {
    const filteredItem = data.filter(
      (x: any) => !['klover_ids', 'transunion_hems'].includes(x.exportType)
    );
    return modifyProps(filteredItem);
  }, [data]);

  const handleClose = () => {
    setOpenDialog(false);
  };

  return (
    <ThemeProvider theme={dialog}>
      <Dialog
        className={classes.root}
        fullWidth={true}
        maxWidth="md"
        onClose={handleClose}
        open={isOpen}
        disableEnforceFocus
      >
        <Box className={classes.header}>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={11}>
              <Title />
            </Grid>
            <Grid item container justifyContent="flex-end" xs={1}>
              <IconButton onClick={handleClose}>
                <CloseIcon className={classes.close} />
              </IconButton>
            </Grid>
          </Grid>
        </Box>

        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>

        <ExportHistroryWithDetails
          historyData={historyData}
          customAudience={customAudience}
          normalAudience={normalAudience}
        />
      </Dialog>
    </ThemeProvider>
  );
}

/**
 * Export History component defination to define individual UI of Export History
 */
const ExportHistory = (props: any) => {
  const { exportData, itemIndex } = props || {};
  const classes = useStyles();

  const statusList = getStatusDetails(exportData.exportType);
  let segmentDetailsList = segmentDetails;
  if (exportData.exportType === 'ots_segment') {
    segmentDetailsList = segmentDetailsList.filter(
      (x) => !['publisherId', 'advertiserId'].includes(x.key)
    );
  }

  return (
    <Accordion defaultExpanded={itemIndex === 0} key={`accordion_${itemIndex}`}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
        className={classes.accordion}
      >
        <Grid className={classes.histroryTitleContainer} xs={12}>
          <Grid item xs={1}>
            <HistoryStatusIcon exportItem={exportData} />
          </Grid>
          <Grid item xs={2} className={classes.historyDateTime}>
            {convertToDateTime(exportData.createdAt?.value, true)}
          </Grid>
          <Grid item xs={6} className={classes.historyTitle}>
            {getExportLatestStatus(exportData)}
          </Grid>
          <Grid item xs={3}>
            {exportData.exportBy}
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <>
          <Grid className={classes.histroryDetailContainer}>
            <Grid item xs={3} />
            <Grid item xs={8} style={{ paddingLeft: 5 }}>
              <ExportDetailTable
                details={statusList}
                exportData={exportData}
                labelXS={8}
                valueXS={4}
                type="export_details"
              />
            </Grid>
          </Grid>
          {['custom_segment', 'ots_segment'].includes(
            exportData?.exportType
          ) && (
            <Grid className={classes.histroryDetailContainer}>
              <Grid item xs={1} />
              <Grid item xs={11} className={classes.historyMainContainer}>
                <ExportDetailTable
                  details={segmentDetailsList}
                  exportData={exportData}
                  type="segment_details"
                  labelXS={3}
                  valueXS={9}
                  title={ENUM_EXPORT_TYPE[exportData?.exportType]}
                />
              </Grid>
            </Grid>
          )}
        </>
      </AccordionDetails>
    </Accordion>
  );
};

/**
 * ExportDetailTable component defination to show table in label/value pair
 */
const ExportDetailTable = (props: any) => {
  const classes = useStyles();
  const {
    details,
    exportData,
    labelXS = 6,
    valueXS = 6,
    type,
    title,
  } = props || {};

  /**
   * Retun custom label based on key value
   */
  const getLabel = (item: any, data: any) => {
    if (item.key === 'timestampStatusExportStarted') {
      item.label = `${ENUM_EXPORT_TYPE[exportData.exportType]} ${
        ENUM_EXPORT_STATUS['export_started']
      }`;
    }

    if (item.key == 'timestampStatusSentToTransunion') {
      return (
        <div style={{ wordBreak: 'break-all' }}>
          <span>{item.label}</span>
          <div style={{ paddingTop: '5px' }}>
            File Name : {data?.fileName || ''}
          </div>
        </div>
      );
    }

    return item.label;
  };

  const table = details?.map((item: any, index: number) => {
    return (
      <>
        {index !== 0 && (
          <Grid xs={12}>
            <Grid item xs={12}>
              <Divider className={classes.lineDivider} />
            </Grid>
          </Grid>
        )}
        <Grid
          container
          item
          xs={12}
          className={classes.rowContainer}
          key={`export_detail_table_${index}_${type}}`}
        >
          <Grid item xs={labelXS}>
            {getLabel(item, exportData)}
          </Grid>
          <Grid
            item
            xs={valueXS}
            style={{ paddingLeft: type === 'export_details' ? '24px' : '0px' }}
          >
            {exportData[item.key]
              ? item.type == 'datetime'
                ? convertToDateTime(exportData[item.key]?.value)
                : exportData[item.key]
              : ''}
          </Grid>
        </Grid>
      </>
    );
  });

  return (
    <Grid className={classes.tableContainer} xs={12}>
      {title && <div className={classes.tableTitle}>{title}</div>}
      {table}
    </Grid>
  );
};

/**
 * Title component defination to show Title of modal
 */
const Title = (props: any) => {
  const classes = useStyles();

  return (
    <Grid container item xs={12} alignItems="center">
      <Grid item className={classes.title}>
        <HistoryIcon style={{ fontSize: '25px' }} />
        <Typography component="h3" variant="h6">
          Audience History
        </Typography>
      </Grid>
    </Grid>
  );
};

/**
 * HistoryStatusIcon component defination to show status icon based on export status
 */
const HistoryStatusIcon = (props: any) => {
  const classes = useStyles();
  const { exportItem: audience } = props || {};
  const { iconColor } = getAudienceExportStatus(audience.exportStatus);

  return iconColor ? (
    <span className={classes.fileStatusIcon}>
      <CircleIcon style={{ fontSize: '14px', color: iconColor }} />
      {audience.exportStatus || ''}
    </span>
  ) : null;
};

/**
 * Export History Details including Header and details based on Audience Manager/Custom Audience
 */
const ExportHistroryWithDetails = (props: any) => {
  const classes = useStyles();
  const { historyData = [], customAudience } = props || {};
  const isHistoryData = historyData?.length > 0;

  const header = (
    <Grid className={classes.columnTitles}>
      <Grid item xs={1} />
      <Grid item xs={2} className={classes.titleDate}>
        Date
      </Grid>
      <Grid item xs={6} className={classes.titleStatus}>
        Status
      </Grid>
      <Grid item xs={4} className={classes.titleExportedBy}>
        Email
      </Grid>
    </Grid>
  );

  //For Audience Manager | With No History Data
  // if (!isHistoryData && !customAudience) {
  //   return (
  //     <Box style={{ margin: '24px' }}>
  //       <h3 className={classes.noLogs}>Audience is not exported yet !</h3>
  //     </Box>
  //   );
  // }

  return (
    <Box style={{ margin: '24px' }}>
      {header}
      <Divider className={classes.lineDivider} />
      {customAudience ? (
        <CustomAudienceFileUploadingStatus {...props} />
      ) : (
        <AudienceManagerStatus {...props} />
      )}
      {historyData?.map((exportItem: any, i: number) => (
        <ExportHistory
          exportData={exportItem}
          itemIndex={i}
          key={`history_${i}`}
        />
      ))}
    </Box>
  );
};

/**
 * Custom Audience | Show uploaded file status
 */
const CustomAudienceFileUploadingStatus = (props: any) => {
  const classes = useStyles();
  const {
    status: loadingStatus,
    email,
    createdat,
    updatedat,
    filename,
  } = props?.customAudience || {};
  const { status, statusIcon } = getFileUploadingStatus(loadingStatus);
  const isFileLoading = loadingStatus === UploadStates.LOADING;

  const statusTime = convertToDateTime(
    isFileLoading ? Number(createdat) : Number(updatedat),
    true
  );

  return (
    <Box style={{ marginBottom: '10px' }}>
      <Grid className={classes.columnTitles}>
        <Grid item xs={1} className={classes.fileStatusIcon}>
          {statusIcon}
        </Grid>
        <Grid item xs={2} className={classes.titleDate}>
          {statusTime}
        </Grid>
        <Grid item xs={6} className={classes.titleStatus}>
          {status}
          <div className={classes.fileName}>
            <b>File Name</b>: <CopyPopover value={filename} />{' '}
          </div>
        </Grid>
        <Grid item xs={4} className={classes.titleExportedBy}>
          <CopyPopover value={email} />
        </Grid>
      </Grid>
    </Box>
  );
};

/**
 * Audience Manager | Show audience created status
 */
const AudienceManagerStatus = (props: any) => {
  const classes = useStyles();
  const { createdAt, createdByEmail } = props?.normalAudience || {};
  const statusTime = convertToDateTime(Number(createdAt), true);
  return (
    <Box style={{ marginBottom: '10px' }}>
      <Grid className={classes.columnTitles}>
        <Grid item xs={1} className={classes.fileStatusIcon}>
          <CloudDoneIcon
            style={{ color: '#413762', marginLeft: '16px', fontSize: '26px' }}
          />
        </Grid>
        <Grid item xs={2} className={classes.titleDate}>
          {statusTime}
        </Grid>
        <Grid item xs={6} className={classes.titleStatus}>
          Audience Created
        </Grid>
        <Grid item xs={4} className={classes.titleExportedBy}>
          <CopyPopover value={createdByEmail} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ExportHistroryDialog;
