import React from 'react';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import { useAppDispatch, useAppSelector } from 'redux/hooks';

import { UploadStates } from 'constants/index';
import { applyOfferDimension, toggleOfferDimensionDialog } from '../slice';
//material ui
import { Button, Divider, Grid, Paper, Typography } from '@material-ui/core';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { makeStyles } from '@material-ui/core/styles';

//components
import OfferDimensionDialog, {
  OfferStartEndValidationMessage,
} from '../offerDimensionDialog';
import UploadingSpinner from './UploadingSpinner';

import DashboardDynamicPreviewCard from '../dashboardPreviewDynamic';
import OnBoardingPreview from '../onBoardingPreview';
// import DashboardStaticPreviewCard from '../dashboardPreviewStatic';
import KloverPlusDynamicPreviewCard from '../kloverPlusPreviewDynamic';
import KloverPlusStaticPreviewCard from '../kloverPlusPreviewStatic';
import OfferPreview, { OfferPlacements } from 'components/OfferPreview';
// import OfferPreviewCard from '../offerWallPreview';
import allOptions from '../offerOptions';
import colormap from 'colormap';
import creativeDetailsDashboard from '../configurations/creativeDetailsDashboard';
import creativeDetailsKloverPlus from '../configurations/creativeDetailsKloverPlus';
import creativeDetailsOfferWall from '../configurations/creativeDetailsOfferWall';
import creativeDetailsOnBoarding from '../configurations/creativeDetailsOnBoarding';

import tokens from 'tokens';
import { StringMap } from 'interfaces';
import CapFullFilledIcon from '../../../assets/images/trophy.png';
import { validateStartEndDate } from '../utils/validateOfferInputs';

const paperWidth = '100%';
const useStyles = makeStyles(() => ({
  active: {
    color: 'green',
  },
  baseBar: {
    justifySelf: 'flex-end',
    padding: 2,
    backgroundColor: tokens.colorFlesh,
  },
  centerColumn: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    maxWidth: 1100,
    width: '100%',
  },
  columnDetailsBody: {
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: 10,
  },
  defaultValueSettings: {
    color: 'black',
    display: 'flex',
    wordWrap: 'break-word',
  },
  dimensionPaper: {
    overflow: 'hidden',
    width: paperWidth,
    display: 'flex',
    wordWrap: 'break-word',
    flexDirection: 'column',
    margin: '1em 0em',
    justifyContent: 'space-between',
  },
  fullHeightDimensionPaper: {
    justifyContent: 'space-between',
    overflow: 'hidden',
    width: paperWidth,
    height: '100%',
    display: 'flex',
    wordWrap: 'break-word',
    flexDirection: 'column',
  },
  inactive: {
    color: 'red',
  },
  inputBodies: {
    color: 'rgba(0, 0, 0, 0.54);',
    width: '100%',
    fontSize: 17,
    padding: 15,
  },
  mediaCardContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
  },
  offerDetails: {
    width: '100%',
    paddingRight: '2em',
  },
  offerDetailsAndMediaCard: {
    display: 'flex',
    padding: '1em 0',
  },
  title: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 18,
    lineHeight: '20px',
    color: 'white',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  divider: {
    color: 'black',
    display: 'flex',
    wordWrap: 'break-word',
    borderTop: '2px solid #dadada',
    padding: '15px 0px 0px 0px',
  },
  dividerWithText: {
    display: 'flex',
    wordWrap: 'break-word',
    borderTop: '2px solid #dadada',
    padding: '15px 0px 0px 0px',
  },
}));

const offerDetailsMap: StringMap = {
  offerId: 'offerid',
  status: 'isactive',
  placement: 'placementid',
  flightDates: 'flightdates',
  advertiserGID: 'gid',
  payout: 'mediarate',
  revenueConversionEvent: 'revenueconversionevent',
  pricingModel: 'pricingmodel',
  // conversionCapping: 'cap', // Reason to comment : https://klover.atlassian.net/browse/ACT-1882?focusedCommentId=28472
};

const creativeDetailsMap: StringMap = {
  offerName: 'offername',
  imageUrl: 'logourl',
  backgroundImageUrl: 'backgroundimgurl',
  headline: 'headline',
  subline: 'subline',
  body: 'description',
  comparisonEmoji: 'comparisonemoji',
  comparisonText: 'comparisontext',
  callToAction: 'ctatext',
  clickthroughUrl: 'ctaurl',
  zIndex: 'zindex',
};

const kloverPointsMap: StringMap = {
  points: 'points',
  kloverPointsConversionEvent: 'actionforpoints',
  successUrls: 'successurl',
  multipleConversionEventOverride: 'multipleconversionsenabled',
  offertunegoals: 'offertunegoals',
};

const trackingMap: StringMap = {
  impressionEvents: 'onofferseenurltofire',
};

const targetingMap: StringMap = {
  zipCodes: 'zips',
  totalZipCodes: 'zipCount',
  states: 'states',
  devices: 'devices',
  audiences: 'audiences',
};

const disclosureMap: StringMap = {
  disclosure_required: 'disclosure_required',
  header: 'disclosure_header',
  body: 'disclosure_body',
};

const SingleDimensionBody = ({
  mapping,
  title,
  titleColor,
  className,
}: any) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const currentOffer = useAppSelector(
    (state) => state.offerWallReducer.currentOffer
  );
  const zipsFileUploadingState = useAppSelector(
    (state) => state.offerWallReducer.currentOffer?.zip_upload_status
  );
  const handleOpenDimensionDialog = (dimensionDialogName) => {
    dispatch(applyOfferDimension(dimensionDialogName));
    dispatch(toggleOfferDimensionDialog(true));
  };

  const InnerComponent = ({ keyName }: { keyName: string }) => {
    switch (keyName) {
      case 'audiences':
        return (
          <Grid item xs={9} className={classes.defaultValueSettings}>
            {currentOffer &&
            currentOffer.audiences &&
            currentOffer.audiences.length ? (
              <div>
                {currentOffer.audiences.map((audience) => (
                  <div key={nanoid()}>{audience.audiencename}</div>
                ))}
              </div>
            ) : (
              'N/A'
            )}
          </Grid>
        );
      case 'isactive':
        return (
          <Grid
            item
            xs={9}
            className={
              currentOffer && currentOffer.isactive
                ? classes.active
                : classes.inactive
            }
          >
            {currentOffer && currentOffer.isactive ? 'Active' : 'Inactive'}
          </Grid>
        );
      case 'revenueconversionevent':
        return (
          <Grid item xs={9} className={classes.defaultValueSettings}>
            {currentOffer && currentOffer[keyName]
              ? _.startCase(
                  _.toLower(
                    allOptions.revenueconversionevent[currentOffer[keyName]]
                  )
                )
              : 'N/A'}
          </Grid>
        );
      case 'pricingmodel':
        return (
          <Grid item xs={9} className={classes.defaultValueSettings}>
            {currentOffer && currentOffer[keyName]
              ? _.startCase(allOptions.pricingmodel[currentOffer[keyName]])
              : 'N/A'}
          </Grid>
        );
      case 'comparisonemoji':
        const getDisplayComparisonEmoji = () => {
          try {
            return currentOffer && currentOffer[keyName]
              ? String.fromCodePoint(currentOffer[keyName])
              : 'N/A';
          } catch (e) {
            return 'N/A';
          }
        };
        return (
          <Grid item xs={9} className={classes.defaultValueSettings}>
            {currentOffer && currentOffer[keyName]
              ? getDisplayComparisonEmoji()
              : 'N/A'}
          </Grid>
        );
      case 'actionforpoints':
        return (
          <Grid item xs={9} className={classes.defaultValueSettings}>
            {currentOffer && currentOffer[keyName]
              ? _.startCase(allOptions.actionforpoints[currentOffer[keyName]])
              : 'N/A'}
          </Grid>
        );
      case 'placementid':
        return (
          <Grid item xs={9} className={classes.defaultValueSettings}>
            {currentOffer && currentOffer[keyName]
              ? _.startCase(allOptions.placementid[currentOffer[keyName]])
              : 'N/A'}
          </Grid>
        );
      case 'zips':
        const zips =
          currentOffer && currentOffer[keyName] ? currentOffer[keyName] : '';
        const zipsArray = zips ? zips.split('\n') : [];
        return (
          <Grid item xs={9} className={classes.defaultValueSettings}>
            {zipsArray.filter((x) => x).length
              ? zipsArray.filter((x) => x).join(', ')
              : 'N/A'}
          </Grid>
        );
      case 'zipCount':
        const zipCount =
          currentOffer && currentOffer[keyName]
            ? currentOffer[keyName].toLocaleString()
            : 'N/A';
        const zipsDisplay = (loadingState: UploadStates | undefined) => {
          switch (loadingState) {
            case UploadStates.DONE || undefined:
              return zipCount;
            case UploadStates.LOADING:
              return <UploadingSpinner />;
            case UploadStates.ERROR:
              return 'An Error Occured';
            default:
              return '';
          }
        };
        return (
          <Grid item xs={9} className={classes.defaultValueSettings}>
            <Grid container direction="row" spacing={3} alignItems="center">
              <Grid item>{zipsDisplay(zipsFileUploadingState)}</Grid>
            </Grid>
          </Grid>
        );
      case 'states':
        const states =
          currentOffer && currentOffer[keyName] ? currentOffer[keyName] : '';
        const statesArray = states ? states.split('\n') : [];
        return (
          <Grid item xs={9} className={classes.defaultValueSettings}>
            {statesArray.filter((x) => x).length
              ? statesArray.filter((x) => x).join(', ')
              : 'N/A'}
          </Grid>
        );
      case 'devices':
        return (
          <Grid item xs={9} className={classes.defaultValueSettings}>
            {currentOffer && currentOffer[keyName]
              ? _.startCase(allOptions.devices[currentOffer[keyName]])
              : 'N/A'}
          </Grid>
        );
      case 'successurl':
        const successUrls =
          currentOffer && currentOffer[keyName] ? currentOffer[keyName] : [];
        return (
          <Grid item xs={9} className={classes.defaultValueSettings}>
            {successUrls.filter((x) => x).length
              ? successUrls.filter((x) => x).join(', ')
              : 'N/A'}
          </Grid>
        );
      case 'startdate':
        return (
          <Grid item xs={9} className={classes.defaultValueSettings}>
            {currentOffer && currentOffer[keyName]
              ? new Date(currentOffer[keyName]).toLocaleDateString()
              : 'N/A'}
          </Grid>
        );
      case 'enddate':
        return (
          <Grid item xs={9} className={classes.defaultValueSettings}>
            {currentOffer && currentOffer[keyName]
              ? new Date(currentOffer[keyName]).toLocaleDateString()
              : 'N/A'}
          </Grid>
        );
      case 'flightdates': {
        const isMessageShow =
          !currentOffer?.isactive && validateStartEndDate(currentOffer);

        return (
          <Grid item xs={9} className={classes.defaultValueSettings}>
            {currentOffer && currentOffer['startdate']
              ? new Date(currentOffer['startdate']).toLocaleDateString()
              : 'N/A'}
            <span>&nbsp;</span>-<span>&nbsp;</span>
            {currentOffer && currentOffer['enddate']
              ? new Date(currentOffer['enddate']).toLocaleDateString()
              : 'N/A'}
            {isMessageShow ? (
              <>
                <span>&nbsp;</span>-<span>&nbsp;</span>
                <OfferStartEndValidationMessage currentOffer={currentOffer} />
              </>
            ) : (
              ''
            )}
          </Grid>
        );
      }

      case 'onofferseenurltofire':
        const offerSeenUrlToFire =
          currentOffer && currentOffer[keyName] ? currentOffer[keyName] : [];
        return (
          <Grid item xs={9} className={classes.defaultValueSettings}>
            {offerSeenUrlToFire.filter((x) => x).length
              ? offerSeenUrlToFire.filter((x) => x).join(', ')
              : 'N/A'}
          </Grid>
        );
      case 'disclosure_required':
        return (
          <Grid item xs={9} className={classes.defaultValueSettings}>
            {currentOffer && currentOffer.disclosure_required ? 'Yes' : 'No'}
          </Grid>
        );
      case 'multipleconversionsenabled':
        return (
          <Grid item xs={9} className={classes.divider}>
            {currentOffer && currentOffer?.multipleconversionsenabled
              ? 'Yes'
              : currentOffer?.multipleconversionsenabled === false
              ? 'No'
              : 'N/A'}
          </Grid>
        );
      default:
        return (
          <Grid item xs={9} className={classes.defaultValueSettings}>
            {currentOffer && currentOffer[keyName]
              ? currentOffer[keyName]
              : 'N/A'}
          </Grid>
        );
    }
  };

  const TuneGoals = () => {
    const tuneGoals = currentOffer && currentOffer['offertunegoals'];
    if (!tuneGoals || !currentOffer?.multipleconversionsenabled) return null;

    return tuneGoals
      ?.map((goal, i: number) => {
        if (goal.status === 'delete') return null;

        //Check if conversion ratio is >= 1 represents current conversion cap is fullfilled successfully.
        const conversionRatio =
          (goal?.currentconversionvalue || 0) / (goal?.conversioncapvalue || 0);
        const isConversionCapFullFilled = conversionRatio >= 1;
        return (
          <>
            <Grid item xs={14} className={classes.divider} />
            <Grid
              className={classes.columnDetailsBody}
              key={`tune_${i}_${goal.goalid}`}
            >
              <Grid item xs={5}>
                {'Tune Goal ID ' + (i + 1) + ': '}
              </Grid>
              <Grid item xs={9} className={classes.defaultValueSettings}>
                {![undefined, ''].includes(goal?.goalid) ? goal.goalid : 'N/A'}
              </Grid>
            </Grid>
            <Grid
              className={classes.columnDetailsBody}
              key={`tune_point_${i}_${goal?.pointsawarded}`}
            >
              <Grid item xs={5}>
                {'Klover Points Award for Goal ID ' + (i + 1) + ': '}
              </Grid>
              <Grid item xs={9} className={classes.defaultValueSettings}>
                {![undefined, ''].includes(goal?.pointsawarded)
                  ? goal.pointsawarded
                  : 'N/A'}
              </Grid>
            </Grid>
            <Grid
              className={classes.columnDetailsBody}
              key={`tune_point_${i}_${goal?.isconversioncapactive}`}
            >
              <Grid item xs={5}>
                {`Conversion Cap for Goal ID ${i + 1}: `}
              </Grid>
              <Grid item xs={9} className={classes.defaultValueSettings}>
                {goal?.isconversioncapactive
                  ? 'Yes'
                  : goal?.isconversioncapactive == false
                  ? 'No'
                  : 'N/A'}
                {isConversionCapFullFilled &&
                  goal?.isconversioncapactive == 1 && (
                    <img
                      src={CapFullFilledIcon}
                      style={{ height: 20, width: 20, marginLeft: 10 }}
                    />
                  )}
              </Grid>
            </Grid>
            {goal?.isconversioncapactive ? (
              <>
                <Grid
                  className={classes.columnDetailsBody}
                  key={`tune_point_${i}_${goal?.conversioncapvalue}`}
                >
                  <Grid item xs={5}>
                    {`Conversion Limit for Goal ID ${i + 1}: `}
                  </Grid>
                  <Grid item xs={9} className={classes.defaultValueSettings}>
                    {goal?.conversioncapvalue != null
                      ? goal.conversioncapvalue
                      : 'N/A'}
                  </Grid>
                </Grid>
                <Grid
                  className={classes.columnDetailsBody}
                  key={`tune_point_${i}_${goal?.conversiontimerange}`}
                >
                  <Grid item xs={5}>
                    {`Cap Date Range for Goal ID ${i + 1}:`}
                  </Grid>
                  <Grid item xs={9} className={classes.defaultValueSettings}>
                    {goal && goal?.conversiontimerange
                      ? _.startCase(
                          allOptions.conversiontimerange[
                            goal.conversiontimerange
                          ]
                        )
                      : 'N/A'}
                  </Grid>
                </Grid>
                <Grid
                  className={classes.columnDetailsBody}
                  key={`tune_point_${i}_${goal?.currentconversionvalue}`}
                >
                  <Grid item xs={5}>
                    {`Current Conversion Value for Goal ID ${i + 1}:`}
                  </Grid>
                  <Grid item xs={9} className={classes.defaultValueSettings}>
                    {goal?.currentconversionvalue != null
                      ? goal.currentconversionvalue
                      : 'N/A'}
                  </Grid>
                </Grid>
              </>
            ) : (
              ''
            )}
          </>
        );
      })
      .filter((goal) => goal);
  };

  return (
    <>
      <Paper className={classes[className]}>
        <Grid>
          <Grid style={{ backgroundColor: titleColor, padding: 10 }}>
            <Typography className={classes.title}>
              {_.startCase(title)}
            </Typography>
          </Grid>
          <Grid className={classes.inputBodies}>
            {Object.keys(mapping).map((mappingName) => {
              const key: string = mapping[mappingName];
              if (
                key === 'successurl' &&
                currentOffer &&
                currentOffer.actionforpoints !== 'SUCCESS_URL'
              )
                return null;
              if (key === 'zips') {
                return null;
              }
              if (key === 'multipleconversionsenabled') {
                return (
                  <Grid className={classes.columnDetailsBody} key={key}>
                    <Grid item xs={5} className={classes.dividerWithText}>
                      {_.startCase(mappingName)}:
                    </Grid>
                    <InnerComponent keyName={key} />
                  </Grid>
                );
              }
              if (key === 'offertunegoals') {
                return <TuneGoals />;
              }
              return (
                <Grid className={classes.columnDetailsBody} key={nanoid()}>
                  <Grid item xs={5}>
                    {_.startCase(mappingName)}
                    {mappingName === 'disclosure_required' ? '?' : ''}:
                  </Grid>
                  <InnerComponent keyName={key} />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
        <Grid>
          <Grid className={classes.baseBar}>
            <Button
              color={'primary'}
              onClick={() => handleOpenDimensionDialog(title)}
            >
              Edit
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

const colors = colormap({
  colormap: 'plasma',
  nshades: 20,
  format: 'hex',
  alpha: 1,
});

let offerDimensions = [
  { title: 'offerDetails', mapping: offerDetailsMap },
  { title: 'creativeDetails', mapping: creativeDetailsMap },
  { title: 'kloverPoints', mapping: kloverPointsMap },
  { title: 'tracking', mapping: trackingMap },
  { title: 'targeting', mapping: targetingMap },
  { title: 'addDisclosure', mapping: disclosureMap },
];

const OfferDimensions = () => {
  const classes = useStyles();
  const currentOffer = useAppSelector(
    (state) => state.offerWallReducer.currentOffer
  );
  if (currentOffer?.placementid === '1') {
    offerDimensions = [
      { title: 'offerDetails', mapping: offerDetailsMap },
      { title: 'creativeDetails', mapping: creativeDetailsOfferWall },
      { title: 'kloverPoints', mapping: kloverPointsMap },
      { title: 'tracking', mapping: trackingMap },
      { title: 'targeting', mapping: targetingMap },
      { title: 'addDisclosure', mapping: disclosureMap },
    ];
  }
  if (currentOffer?.placementid === '2') {
    offerDimensions = [
      { title: 'offerDetails', mapping: offerDetailsMap },
      { title: 'creativeDetails', mapping: creativeDetailsDashboard },
      { title: 'kloverPoints', mapping: kloverPointsMap },
      { title: 'tracking', mapping: trackingMap },
      { title: 'targeting', mapping: targetingMap },
      { title: 'addDisclosure', mapping: disclosureMap },
    ];
  }

  if (currentOffer?.placementid === '3') {
    offerDimensions = [
      { title: 'offerDetails', mapping: offerDetailsMap },
      { title: 'creativeDetails', mapping: creativeDetailsKloverPlus },
      { title: 'kloverPoints', mapping: kloverPointsMap },
      { title: 'tracking', mapping: trackingMap },
      { title: 'targeting', mapping: targetingMap },
      { title: 'addDisclosure', mapping: disclosureMap },
    ];
  }

  if (currentOffer?.placementid === '4') {
    offerDimensions = [
      { title: 'offerDetails', mapping: offerDetailsMap },
      { title: 'creativeDetails', mapping: creativeDetailsOnBoarding },
      { title: 'kloverPoints', mapping: kloverPointsMap },
      { title: 'tracking', mapping: trackingMap },
      { title: 'targeting', mapping: targetingMap },
      { title: 'addDisclosure', mapping: disclosureMap },
    ];
  }

  return (
    <div className={classes.centerColumn}>
      <div className={classes.column}>
        <Grid className={classes.offerDetailsAndMediaCard}>
          <Grid className={classes.offerDetails}>
            {offerDimensions.slice(0, 1).map((dimension, i) => {
              return (
                <SingleDimensionBody
                  key={nanoid()}
                  title={dimension.title}
                  mapping={dimension.mapping}
                  className={'fullHeightDimensionPaper'}
                  titleColor={fade(colors[i], 0.8 - 0.15 * i)}
                />
              );
            })}
          </Grid>
          <Grid className={classes.mediaCardContainer}>
            {currentOffer &&
            currentOffer.placementid &&
            currentOffer.placementid == 1 ? (
              <OfferPreview
                ctaText={currentOffer.ctatext}
                description={currentOffer.description}
                headline={currentOffer.headline}
                logoUrl={currentOffer.logourl}
                placement={OfferPlacements.OfferWall}
                points={currentOffer.points}
                disclosure_required={currentOffer.disclosure_required}
                ctaurl={currentOffer.ctaurl}
              />
            ) : null}
            {currentOffer &&
              currentOffer.comparisonemoji &&
              currentOffer.placementid == 2 && (
                <DashboardDynamicPreviewCard
                  backgroundimgurl={currentOffer.backgroundimgurl}
                  logourl={currentOffer.logourl}
                  headline={currentOffer.headline}
                  ctatext={currentOffer.ctatext}
                  offername={currentOffer.offername}
                  points={currentOffer.points}
                  comparisontext={currentOffer.comparisontext}
                  comparisonemoji={currentOffer.comparisonemoji}
                  price={currentOffer.price}
                  disclosure_required={currentOffer.disclosure_required}
                />
              )}
            {currentOffer &&
              !currentOffer.comparisonemoji &&
              currentOffer.placementid == 2 && (
                <OfferPreview
                  comparisonText={currentOffer.comparisontext}
                  backgroundImgUrl={currentOffer.backgroundimgurl}
                  ctaText={currentOffer.ctatext}
                  description={currentOffer.description}
                  headline={currentOffer.headline}
                  logoUrl={currentOffer.logourl}
                  offerName={currentOffer.offername}
                  placement={OfferPlacements.Dashboard}
                  points={currentOffer.points}
                  disclosure_required={currentOffer.disclosure_required}
                />
              )}
            {currentOffer &&
              currentOffer.comparisonemoji &&
              currentOffer.placementid == 3 && (
                <KloverPlusDynamicPreviewCard
                  comparisonemoji={currentOffer.comparisonemoji}
                  ctatext={currentOffer.ctatext}
                  points={currentOffer.points}
                  offername={currentOffer.offername}
                  headline={currentOffer.headline}
                  logourl={currentOffer.logourl}
                  comparisontext={currentOffer.comparisontext}
                  description={currentOffer.description}
                  disclosure_required={currentOffer.disclosure_required}
                />
              )}
            {currentOffer &&
              !currentOffer.comparisonemoji &&
              currentOffer.placementid == 3 && (
                <OfferPreview
                  comparisonText={currentOffer.comparisontext}
                  ctaText={currentOffer.ctatext}
                  description={currentOffer.description}
                  headline={currentOffer.headline}
                  logoUrl={currentOffer.logourl}
                  placement={OfferPlacements.KloverPlus}
                  points={currentOffer.points}
                  offerName={currentOffer.offername}
                  disclosure_required={currentOffer.disclosure_required}
                />
              )}

            {currentOffer &&
              !currentOffer.comparisonemoji &&
              currentOffer.placementid == 4 && (
                <OfferPreview
                  backgroundImgUrl={currentOffer.backgroundimgurl}
                  logoUrl={currentOffer.logourl}
                  headline={currentOffer.offername}
                  subLine={currentOffer.headline}
                  body={currentOffer.description}
                  ctaText={currentOffer.ctatext}
                  placement={OfferPlacements.Onboarding}
                  points={currentOffer.points}
                />
              )}
          </Grid>
        </Grid>
        <Grid>
          {offerDimensions.slice(1).map((dimension, i) => {
            return (
              <SingleDimensionBody
                key={nanoid()}
                title={dimension.title}
                mapping={dimension.mapping}
                className={'dimensionPaper'}
                titleColor={fade(colors[i + 1], 0.99 - 0.1 * (i + 1))}
              />
            );
          })}
        </Grid>
        <OfferDimensionDialog />
      </div>
    </div>
  );
};

export default OfferDimensions;
