import _ from 'lodash';
import axios, { AxiosResponse } from 'axios';
import { PayloadAction } from '@reduxjs/toolkit';
import { auth } from 'utils/firebase';
import { call, put, takeLatest } from 'redux-saga/effects';

import {
  Deal,
  DealJob,
  addNotification,
  clearBuyers,
  createDeal,
  deleteDeal,
  fetchBidders,
  fetchBuyers,
  fetchDeal,
  fetchDeals,
  fetchLineItems,
  launchDeal,
  removeDeal,
  saveDeal,
  sendAgreement,
  setBidder,
  setBuyers,
  setCurrentDeal,
  setDeal,
  setDealLoadingState,
  setDeals,
  setDealsLoadingState,
  setLineItems,
  setOriginalDeal,
  setUIState,
} from '../slice';

const PMP_ENDPOINT = `${process.env.API_BASE_ADDRESS}/xandr`;
//const PMP_ENDPOINT = `http://localhost:4201/xandr`

function reformatDealForAPI(dealObject: any) {
  const cloneOfDealObject = _.clone(dealObject);
  cloneOfDealObject.dealname = cloneOfDealObject.dealname.trim();
  cloneOfDealObject.partnerbuyercontact =
    cloneOfDealObject.partnerbuyercontact.trim();
  if (!cloneOfDealObject.buyername) cloneOfDealObject.buyername = null;
  if (!cloneOfDealObject.buyercode) cloneOfDealObject.buyercode = null;
  if (!cloneOfDealObject.biddername) cloneOfDealObject.biddername = null;
  if (!cloneOfDealObject.bidderid) cloneOfDealObject.bidderid = null;
  return cloneOfDealObject;
}

function isInvalidDeal(dealObject: any) {
  return (
    _.isEmpty(dealObject.dealname) || _.isEmpty(dealObject.partnerbuyercontact)
  );
}

//unused for now
function handleFetchDeal(dealid: string): Promise<AxiosResponse<Deal>> {
  return auth.currentUser.getIdTokenResult().then((res) => {
    const authorizedAxiosInstance = axios.create({
      headers: {
        Authorization: `Bearer ${res.token}`,
        'Cache-Control': 'no-cache',
      },
    });
    return authorizedAxiosInstance.get(`${PMP_ENDPOINT}/deals/${dealid}`);
  });
}

function handleFetchDeals(): Promise<AxiosResponse<Array<Deal>>> {
  return auth.currentUser.getIdTokenResult().then((res) => {
    const authorizedAxiosInstance = axios.create({
      headers: {
        Authorization: `Bearer ${res.token}`,
        'Cache-Control': 'no-cache',
      },
    });
    return authorizedAxiosInstance.get(`${PMP_ENDPOINT}/deals`);
  });
}

function handleFetchBidders() {
  return auth.currentUser.getIdTokenResult().then((res) => {
    const authorizedAxiosInstance = axios.create({
      headers: {
        Authorization: `Bearer ${res.token}`,
        'Cache-Control': 'no-cache',
      },
    });
    return authorizedAxiosInstance.get(`${PMP_ENDPOINT}/bidders`);
  });
}

function handleFetchBuyers(bidderid: number, queryString: string) {
  return auth.currentUser.getIdTokenResult().then((res) => {
    const authorizedAxiosInstance = axios.create({
      headers: {
        Authorization: `Bearer ${res.token}`,
        'Cache-Control': 'no-cache',
      },
    });
    return authorizedAxiosInstance.get(
      `${PMP_ENDPOINT}/buyers?bidderid=${bidderid}&q=${queryString}`
    );
  });
}

function handleFetchLineItems() {
  return auth.currentUser.getIdTokenResult().then((res) => {
    const authorizedAxiosInstance = axios.create({
      headers: {
        Authorization: `Bearer ${res.token}`,
        'Cache-Control': 'no-cache',
      },
    });
    return authorizedAxiosInstance.get(`${PMP_ENDPOINT}/line-items`);
  });
}

function handleCreateDeal(deal: any): Promise<AxiosResponse<Deal>> {
  return auth.currentUser.getIdTokenResult().then((res) => {
    const authorizedAxiosInstance = axios.create({
      headers: {
        Authorization: `Bearer ${res.token}`,
      },
    });
    return authorizedAxiosInstance.post(`${PMP_ENDPOINT}/deals`, deal);
  });
}

function handleSaveDeal(updatedDeal: Deal): Promise<AxiosResponse<Deal>> {
  return auth.currentUser.getIdTokenResult().then((res) => {
    const authorizedAxiosInstance = axios.create({
      headers: {
        Authorization: `Bearer ${res.token}`,
      },
    });
    return authorizedAxiosInstance.put(
      `${PMP_ENDPOINT}/deals/${updatedDeal.dealid}`,
      updatedDeal
    );
  });
}

function handleDeleteDeal(dealid: string): Promise<AxiosResponse<any>> {
  return auth.currentUser.getIdTokenResult().then((res) => {
    const authorizedAxiosInstance = axios.create({
      headers: {
        Authorization: `Bearer ${res.token}`,
      },
    });
    return authorizedAxiosInstance.delete(`${PMP_ENDPOINT}/deals/${dealid}`);
  });
}

function handleSendEmail(
  dealid: string
): Promise<AxiosResponse<boolean>> | boolean {
  if (dealid) {
    return auth.currentUser.getIdTokenResult().then((res) => {
      const authorizedAxiosInstance = axios.create({
        headers: {
          Authorization: `Bearer ${res.token}`,
        },
      });
      return authorizedAxiosInstance.post(
        `${PMP_ENDPOINT}/resend-email/${dealid}`
      );
    });
  } else {
    return false;
  }
}

function handleLaunchDeal(
  dealid: string
): Promise<AxiosResponse<boolean>> | boolean {
  return auth.currentUser.getIdTokenResult().then((res) => {
    const authorizedAxiosInstance = axios.create({
      headers: {
        Authorization: `Bearer ${res.token}`,
      },
    });
    return authorizedAxiosInstance.post(`${PMP_ENDPOINT}/launch-deal`, {
      dealid,
    });
  });
}

function handleFetchDealJobs(
  dealid: string
): Promise<AxiosResponse<boolean>> | boolean {
  return auth.currentUser.getIdTokenResult().then((res) => {
    const authorizedAxiosInstance = axios.create({
      headers: {
        Authorization: `Bearer ${res.token}`,
        'Cache-Control': 'no-cache',
      },
    });
    return authorizedAxiosInstance.get(`${PMP_ENDPOINT}/dealjobs/${dealid}`);
  });
}

function* watchFetchDeal(action: PayloadAction<any>): Generator<any> {
  const { dealid } = action.payload;
  try {
    yield put(setDealLoadingState(true));
    const result: any = yield call(handleFetchDeal, dealid);
    const jobResult: any = yield call(handleFetchDealJobs, dealid);
    //add jobresult to result payload
    result.data.history = jobResult.data;
    yield put(setCurrentDeal(result.data));
    yield put(setOriginalDeal(result.data));
    yield put(setDealLoadingState(false));
  } catch (e) {
    console.log('error:', e);
    yield put(
      setUIState({
        value: true,
        uiPropertyName: 'errorFetching',
      })
    );
    yield put(setDealLoadingState(false));
    yield put(
      addNotification({
        state: 'error',
        message: `Failed to fetch deal`,
      })
    );
  }
}

function* watchFetchDeals(): Generator<any> {
  try {
    yield put(setDealsLoadingState(true));
    const result: any = yield call(handleFetchDeals);
    yield put(setDeals(result.data));
    yield put(setDealsLoadingState(false));
  } catch (e) {
    console.log('error:', e);
    yield put(setDealsLoadingState(false));
    yield put(
      addNotification({
        state: 'error',
        message: `Failed to fetch deals`,
      })
    );
  }
}

function* watchFetchBidder(): Generator<any> {
  try {
    const result: any = yield call(handleFetchBidders);
    yield put(setBidder(result.data));
  } catch (e) {
    console.log('error:', e);
    yield put(
      addNotification({
        state: 'error',
        message: `Failed to fetch bidders`,
      })
    );
  }
}

function* watchFetchBuyer(action: PayloadAction<any>): Generator<any> {
  try {
    const { bidderid, queryString } = action.payload;
    yield put(clearBuyers(null));
    const result: any = yield call(handleFetchBuyers, bidderid, queryString);
    yield put(setBuyers(result.data));
  } catch (e) {
    console.log('error:', e);
    yield put(
      addNotification({
        state: 'error',
        message: `Failed to fetch buyers`,
      })
    );
  }
}

function* watchFetchLineItems(): Generator<any> {
  try {
    const result: any = yield call(handleFetchLineItems);
    yield put(setLineItems(result.data));
  } catch (e) {
    console.log('error:', e);
    yield put(
      addNotification({
        state: 'error',
        message: `Failed to fetch line items`,
      })
    );
  }
}

function* watchCreateDeal(action: PayloadAction<any>): Generator<any> {
  const { deal } = action.payload;
  try {
    yield put(setDealLoadingState(true));
    const newDeal = reformatDealForAPI(deal);
    if (isInvalidDeal(newDeal)) throw 'Insufficient parameters for create deal';
    if (auth.currentUser?.email) {
      newDeal.submittedby = auth.currentUser?.email;
      const result: any = yield call(handleCreateDeal, newDeal);
      yield put(setCurrentDeal(result.data));
      yield put(setOriginalDeal(result.data));
      yield put(setDeal(result.data));
      yield put(setDealLoadingState(false));
      yield put(
        addNotification({
          state: 'done',
          message:
            'Sucessfully created deal. An email to confirm the deal has been sent to the partner buyer',
        })
      );
    } else {
      throw 'User is not authorized to create new deal';
    }
  } catch (e) {
    console.log('error:', e);
    yield put(setDealLoadingState(false));
    yield put(
      addNotification({
        state: 'error',
        message: `Failed to create deal`,
      })
    );
  }
}

function* watchSaveDeal(action: PayloadAction<Deal>): Generator<any> {
  try {
    yield put(setDealLoadingState(true));
    const updatedDeal = reformatDealForAPI(action.payload);
    if (isInvalidDeal(updatedDeal))
      throw 'Insufficient parameters for update deal';
    const result: any = yield call(handleSaveDeal, updatedDeal);
    const jobResult: any = yield call(handleFetchDealJobs, updatedDeal.dealid);
    //add jobresult to result payload
    result.data.history = jobResult.data;
    if (result && result.data) {
      yield put(
        addNotification({
          state: 'done',
          message: 'Sucessfully saved the deal',
        })
      );
      yield put(setCurrentDeal(result.data));
      yield put(setOriginalDeal(result.data));
    } else {
      throw 'Failed to save deal';
    }
    yield put(setDealLoadingState(false));
  } catch (e) {
    console.log('error:', e);
    yield put(setDealLoadingState(false));
    yield put(
      addNotification({
        state: 'error',
        message: `Failed to save deal`,
      })
    );
  }
}

function* watchSendAgreement(action: PayloadAction<string>): Generator<any> {
  try {
    const dealid = action.payload;
    const result: any = yield call(handleSendEmail, dealid);
    if (result && result.data) {
      yield put(
        addNotification({
          state: 'done',
          message: 'Sucessfully sent a deal agreement to the partner buyer',
        })
      );
    } else {
      throw 'Failed to send email';
    }
  } catch (e) {
    console.log('error:', e);
    yield put(setDealLoadingState(false));
    yield put(
      addNotification({
        state: 'error',
        message: `Failed to resend email`,
      })
    );
  }
}

function* watchDeleteDeal(action: PayloadAction<Deal>): Generator<any> {
  try {
    const deal = action.payload;
    yield call(handleDeleteDeal, deal.dealid);
    //safety
    yield put(removeDeal(deal.dealid));
    yield put(setCurrentDeal(null));
    yield put(setOriginalDeal(null));
    yield put(
      addNotification({
        state: 'done',
        message: `Sucessfully deleted deal: ${deal.dealname}`,
      })
    );
  } catch (e) {
    console.log('error:', e);
    yield put(setDealLoadingState(false));
    yield put(
      addNotification({
        state: 'error',
        message: `Failed to delete deal`,
      })
    );
  }
}

function* watchLaunchDeal(action: PayloadAction<Deal>): Generator<any> {
  try {
    const deal = action.payload;
    if (isInvalidDeal(deal)) throw 'Insufficient parameters for update deal';
    yield call(handleLaunchDeal, deal.dealid);
    //Attaching mock history data to deal
    let history: Array<DealJob>;
    if (!deal.history) {
      history = [];
    } else {
      history = [
        {
          jobid: NaN,
          jobstatus: 'QUEUED',
          dealid: deal.dealid,
          type: 'CREATE',
          createdate: Date.now(),
          updatedate: Date.now(),
          error: '',
        },
        ...deal.history,
      ];
    }
    const updatedDeal = {
      ...deal,
      history,
    };
    yield put(setCurrentDeal(updatedDeal));
    yield put(setOriginalDeal(updatedDeal));
    yield put(
      addNotification({
        state: 'done',
        message: `Sucessfully launched deal: ${deal.dealname}`,
      })
    );
  } catch (e) {
    console.log('error:', e);
    yield put(
      addNotification({
        state: 'error',
        message: `Failed to launch deal`,
      })
    );
  }
}

export default function* watchAll() {
  yield takeLatest<any>(fetchDeal.type, watchFetchDeal);
  yield takeLatest<any>(fetchDeals.type, watchFetchDeals);
  yield takeLatest<any>(fetchLineItems.type, watchFetchLineItems);
  yield takeLatest<any>(createDeal.type, watchCreateDeal);
  yield takeLatest<any>(sendAgreement.type, watchSendAgreement);
  yield takeLatest<any>(saveDeal.type, watchSaveDeal);
  yield takeLatest<any>(deleteDeal.type, watchDeleteDeal);
  yield takeLatest<any>(launchDeal.type, watchLaunchDeal);
  yield takeLatest<any>(fetchBidders.type, watchFetchBidder);
  yield takeLatest<any>(fetchBuyers.type, watchFetchBuyer);
}
