import {
  CUSTOM_SEGMENT_INFORMATION,
  OTS_SEGMENT_INFORMATION,
  TRADE_DESK_SEGMENT_DETAILS,
  TRADE_DESK_SEGMENT_FIELD_NAME,
  ValidationErrorMessages,
} from '../../constants';
// Material UI Components
import tokens from 'tokens';
import { Grid, TextField } from '@klover/attain-design-system';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    informationText: {
      marginTop: '1rem',
      fontFamily: 'Calibre-Regular,sans-serif',
      fontSize: 16,
    },
    segmentDivider: {
      background: tokens.colorDividerLgBorder,
      height: '1px',
      border: 'none',
    },
    segmentInputFieldsContainer: {
      display: 'flex',
      padding: '1rem 1rem 0rem 1rem',
    },
    segmentInputFieldsLeft: {
      flex: 1,
      borderRight: '1px solid' + tokens.colorDividerLgBorder,
      paddingRight: '1rem',
    },
    segmentInputFieldsRight: {
      flex: 1,
      marginTop: 53,
      paddingLeft: '1rem',
    },
    tradeDeskDataSegmentLabel: {
      paddingBottom: '1.5rem',
      fontSize: 20,
      fontWeight: 500,
      fontFamily: 'Calibre-Regular,sans-serif',
    },
    OrLabel: {
      fontSize: 18,
      fontWeight: 500,
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontFamily: 'Calibre-Regular,sans-serif',
    },
  })
);

const AudienceExport = (props: any) => {
  const classes = useStyles();
  const {
    selectedExportType,
    handleSegmentName,
    segmentName,
    handleDescription,
    description,
    handleCpm,
    cpm,
    handlePercentageOfMedia,
    percentageOfMedia,
    handlePartnerID,
    partnerID,
    advertiserID,
    handleAdvertiserID,
    isSegmentNameValid,
    inValidSegmentNameErrorMessage,
  } = props;

  return (
    <>
      <Grid item xs={12}>
        <ul className={classes.informationText}>
          {selectedExportType === 'custom_segment' &&
            CUSTOM_SEGMENT_INFORMATION.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          {selectedExportType === 'ots_segment' &&
            OTS_SEGMENT_INFORMATION.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
        </ul>
      </Grid>
      <hr className={classes.segmentDivider} />
      <Grid container spacing={0} display="flex">
        <Grid container xs={6} className={classes.segmentInputFieldsLeft}>
          <Grid item xs={12} className={classes.tradeDeskDataSegmentLabel}>
            {TRADE_DESK_SEGMENT_DETAILS}
          </Grid>
          <Grid item xs={12}>
            <TextField
              onChange={handleSegmentName}
              value={segmentName}
              fullWidth
              id="segmentName"
              label={
                selectedExportType === 'custom_segment'
                  ? TRADE_DESK_SEGMENT_FIELD_NAME.CUSTOM_SEGMENT_NAME
                  : TRADE_DESK_SEGMENT_FIELD_NAME.OTS_SEGMENT_NAME
              }
              type="text"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              error={!isSegmentNameValid}
              helperText={inValidSegmentNameErrorMessage}
              required
            />
          </Grid>
          <Grid item xs={12} style={{ marginTop: 36 }}>
            <TextField
              onChange={handleDescription}
              value={description}
              fullWidth
              id="description"
              label={TRADE_DESK_SEGMENT_FIELD_NAME.DESCRIPTION}
              type="text"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Grid>
        <Grid
          container
          xs={6}
          display="flex"
          flexDirection="column"
          className={classes.segmentInputFieldsRight}
        >
          <Grid container spacing={0}>
            <Grid item xs={5}>
              <TextField
                onChange={handleCpm}
                value={cpm}
                fullWidth
                id="cpm"
                label={TRADE_DESK_SEGMENT_FIELD_NAME.CPM}
                type="text"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                required
              />
            </Grid>
            <Grid item xs={2} />
            <Grid item xs={5}>
              <TextField
                onChange={handlePercentageOfMedia}
                value={percentageOfMedia}
                fullWidth
                id="percentageOfMedia"
                label={TRADE_DESK_SEGMENT_FIELD_NAME.PERCENT_OF_MEDIA}
                type="text"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                required
              />
            </Grid>
          </Grid>
          {selectedExportType === 'custom_segment' && (
            <Grid container spacing={0} style={{ marginTop: 36 }}>
              <Grid item xs={5}>
                <TextField
                  onChange={handlePartnerID}
                  value={partnerID}
                  fullWidth
                  id="partnerID"
                  label={TRADE_DESK_SEGMENT_FIELD_NAME.PARTNER_ID}
                  type="text"
                  variant="outlined"
                  disabled={advertiserID !== null && advertiserID !== ''}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  required
                />
              </Grid>
              <Grid item xs={2}>
                <div className={classes.OrLabel}>Or</div>
              </Grid>
              <Grid item xs={5}>
                <TextField
                  onChange={handleAdvertiserID}
                  value={advertiserID}
                  fullWidth
                  id="advertiserID"
                  label={TRADE_DESK_SEGMENT_FIELD_NAME.ADVERTISER_ID}
                  type="text"
                  variant="outlined"
                  disabled={partnerID !== null && partnerID !== ''}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  required
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
};
export default AudienceExport;
