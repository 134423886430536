import Button from 'components/Button';
import styled from 'styled-components';
import tokens from 'tokens';

export const SearchWrapper = styled.div`
  margin-bottom: ${tokens.spacing.md};
`;

export const TableWrapper = styled.div`
  padding: ${tokens.spacing.md};
`;

export const DealNameWrapper = styled.div`
  align-items: center;
  display: flex;
`;

export const ButtonComponent = styled(Button)``;

export const ButtonSpacing = styled.div`
  display: flex;

  & > * + * {
    margin-left: ${tokens.spacing.sm};
  }
`;
