import styled from 'styled-components';

export const AudienceName = styled.div`
  width: 190px;
`;

export const Taxonomy = styled.div`
  width: 180px;
`;

export const FileName = styled.div``;

export const CreatedBy = styled.div`
  width: 150px;
`;

export const AudienceStatus = styled.div`
  display: flex;
  font-size: 14px;
`;

export const AudienceSize = styled.div`
  font-size: 14px;
`;
