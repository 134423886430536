import CloseIcon from '@mui/icons-material/Close';
import ErrorMsg from 'components/ErrorMsg';
import Field from 'components/Field';
import Stack from 'components/Stack';
import tokens from 'tokens';
import { Answer } from 'interfaces/quickQuestionsManager';
import { Button, IconButton } from '@klover/attain-design-system';
import { FieldArray } from 'formik';
import * as Styled from './index.styles';

type createProps = {
  answers: Answer[] | undefined | { answertext: string }[];
};

const CreateAnswerResponses = ({ answers }: createProps) => {
  return (
    <>
      <Styled.ResponseLabel>Responses</Styled.ResponseLabel>

      <label htmlFor="answers" hidden>
        Responses
      </label>
      <FieldArray name="answers">
        {({ remove, push }) => (
          <Stack spacing={tokens.spacing.sm}>
            {answers &&
              answers.length > 0 &&
              answers.map((answer: { answertext: string }, index: number) => (
                <div key={index}>
                  <Styled.Row>
                    <label htmlFor={`answers.${index}.answertext`} hidden>
                      answertext-{answer.answertext}
                    </label>
                    <Field
                      fullWidth
                      name={`answers.${index}.answertext`}
                      placeholder="e.g 10 household children"
                    />
                    <IconButton
                      type="button"
                      onClick={() => remove(index)}
                      color="error"
                    >
                      <CloseIcon />
                    </IconButton>
                  </Styled.Row>

                  <ErrorMsg name={`answers.${index}.answertext`} />
                </div>
              ))}
            <Button
              variant="contained"
              type="button"
              onClick={() => push({ answertext: '' })}
              fullWidth
            >
              Add Response
            </Button>
          </Stack>
        )}
      </FieldArray>
    </>
  );
};

export default CreateAnswerResponses;
