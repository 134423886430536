import React, { useCallback } from 'react';
import colormap from 'colormap';
import { nanoid } from 'nanoid';
import {
  setCurrentDealDimension,
  setDealSendDialogOpen,
  setDealsDimensionDialogOpen,
} from '../slice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';

//material ui
import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { Check, Clear, Email } from '@material-ui/icons';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { makeStyles } from '@material-ui/core/styles';
// configs
import dealDimensionConfig from '../configs/dealDimensionsConfig';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import tokens from 'tokens';

const PAPER_WIDTH = '100%';

const useStyles = makeStyles(() => ({
  agreementButton: {
    marginLeft: 20,
  },
  baseBar: {
    justifySelf: 'flex-end',
    padding: 2,
    backgroundColor: tokens.colorFlesh,
    display: 'flex',
    justifyContent: 'space-between',
  },
  checkIcon: {
    color: 'lightgreen',
    marginLeft: '5px',
  },
  clearIcon: {
    color: 'red',
    marginLeft: '5px',
  },
  columnName: {
    width: '30%',
  },
  columnValue: {
    width: '70%',
    color: 'black',
  },
  columnDetailsBody: {
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: 10,
    margin: 10,
  },
  dimensionsContainer: {
    padding: '0em 4em',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  defaultValueSettings: {
    color: 'black',
    display: 'flex',
    wordWrap: 'break-word',
  },
  dimensionPaper: {
    overflow: 'hidden',
    width: PAPER_WIDTH,
    display: 'flex',
    wordWrap: 'break-word',
    flexDirection: 'column',
    margin: '1em 0em',
    justifyContent: 'space-between',
  },
  formControl: {
    minWidth: 450,
  },
  inputBodies: {
    color: 'rgba(0, 0, 0, 0.54);',
    width: '100%',
    fontSize: 17,
    padding: 15,
  },
  lineitemHeaderCell: {
    paddingTop: 0,
  },
  textInput: {
    paddingBottom: 15,
    width: 450,
  },
  title: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 18,
    lineHeight: '20px',
    color: tokens.colorTextDefault,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

const SendAgreementButton = ({
  buttonText,
  disabled,
}: {
  buttonText: string;
  disabled: boolean;
}) => {
  const dispatch = useAppDispatch();
  const classes = useStyles();

  const onButtonClick = () => {
    dispatch(setDealSendDialogOpen(true));
  };

  return (
    <Button
      className={classes.agreementButton}
      color="primary"
      disableElevation
      variant="contained"
      size="small"
      onClick={onButtonClick}
      endIcon={<Email />}
      disabled={disabled}
    >
      {buttonText || ''}
    </Button>
  );
};

const InputDisplay = ({ keyName }: { keyName: string }) => {
  const classes = useStyles();
  const originalDeal = useAppSelector(
    (state) => state.dealsReducer.originalDeal
  );
  const getHistoryMessage = useCallback(
    (date: number, status: string, type: string, error: string) => {
      const formattedDate = new Date(Number(date))
        .toLocaleString('en-US')
        .replace(', ', '・');
      const formattedType = type === 'CREATE' ? 'creation' : type.toLowerCase();
      switch (status) {
        case 'QUEUED':
          return `${formattedDate} - Deal has been queued for ${formattedType} on Xandr`;
        case 'STARTED':
          return `${formattedDate} - Deal is in process for ${formattedType} on Xandr`;
        case 'COMPLETE':
          return `${formattedDate} - Deal has completed ${formattedType} process on Xandr`;
        case 'ERROR':
          return `${formattedDate} - Error during the ${formattedType} process: ${error}`;
        default:
          'Error';
      }
    },
    []
  );

  switch (keyName) {
    case 'dealname':
      return (
        <Grid item xs={10}>
          {originalDeal?.dealname} (ID: {originalDeal?.xandrdealid})
        </Grid>
      );
    case 'bidder':
      return (
        <Grid item xs={10}>
          {originalDeal?.bidderid && originalDeal.biddername
            ? `${originalDeal?.biddername} (ID: ${originalDeal?.bidderid})`
            : 'N/A'}
        </Grid>
      );
    case 'buyer':
      return (
        <Grid item xs={10}>
          {originalDeal?.buyername && `${originalDeal?.buyername} `}
          {originalDeal?.buyercode ? `(ID: ${originalDeal?.buyercode})` : 'N/A'}
        </Grid>
      );
    case 'partnerbuyercontact':
      return (
        <Grid item xs={10}>
          {originalDeal?.partnerbuyercontact}
          <SendAgreementButton
            buttonText="Resend Agreement"
            disabled={originalDeal?.confirmed || false}
          />
        </Grid>
      );
    case 'emailedat':
      return (
        <Grid item xs={10}>
          {originalDeal && originalDeal.emailedat
            ? new Date(Number(originalDeal.emailedat))
                .toLocaleString('en-US')
                .replace(', ', '・')
            : 'N/A'}
        </Grid>
      );
    case 'confirmed':
      return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          {originalDeal?.confirmed ? (
            <>
              <Typography noWrap>Yes</Typography>
              <Check className={classes.checkIcon} />
            </>
          ) : (
            <>
              <Typography noWrap>No</Typography>
              <Clear className={classes.clearIcon} />
            </>
          )}
        </div>
      );
    case 'confirmedat':
      return (
        <Grid item xs={10}>
          {originalDeal && originalDeal.confirmedat
            ? new Date(Number(originalDeal.confirmedat))
                .toLocaleString('en-US')
                .replace(', ', '・')
            : 'N/A'}
        </Grid>
      );
    case 'launchedat':
      return (
        <Grid item xs={10}>
          {originalDeal && originalDeal.launchedat
            ? new Date(Number(originalDeal.launchedat))
                .toLocaleString('en-US')
                .replace(', ', '・')
            : 'N/A'}
        </Grid>
      );
    case 'active':
      return (
        <Grid item xs={10}>
          {originalDeal?.active ? 'Yes' : 'No'}
        </Grid>
      );
    case 'lineitems':
      return (
        <Grid item xs={10}>
          <Table>
            {!originalDeal?.lineitems.length && (
              <caption>No line items are associated with this deal</caption>
            )}
            <TableHead>
              <TableRow>
                <TableCell className={classes.lineitemHeaderCell}>
                  Name
                </TableCell>
                <TableCell className={classes.lineitemHeaderCell} align="right">
                  ID
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {originalDeal?.lineitems.map((lineitem) => (
                <TableRow key={nanoid()}>
                  <TableCell>{lineitem.lineitemname}</TableCell>
                  <TableCell align="right">{lineitem.lineitemid}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>
      );
    case 'history':
      return (
        <Grid item>
          {!originalDeal?.history?.length ? (
            <Typography key={nanoid()} style={{ marginBottom: 4 }}>
              No jobs have been launched with this deal yet
            </Typography>
          ) : (
            originalDeal.history.map((dealjob) => {
              return (
                <Typography key={nanoid()} style={{ marginBottom: 4 }}>
                  ・
                  {getHistoryMessage(
                    dealjob.updatedate,
                    dealjob.jobstatus,
                    dealjob.type,
                    dealjob.error
                  )}
                </Typography>
              );
            })
          )}
        </Grid>
      );
    default:
      return (
        <Grid item xs={10} className={classes.defaultValueSettings}>
          N/A
        </Grid>
      );
  }
};

const SingleDealDimension = ({
  className,
  titleColor,
  dimensionName,
  inputs,
}: {
  className: string;
  titleColor: string;
  dimensionName: string;
  inputs: Array<any>;
  customComponentName?: string | undefined;
}) => {
  const classes = useStyles() as ClassNameMap<any>;
  const dispatch = useAppDispatch();
  const handleOpenDimensionDialog = (dimensionName: string) => {
    dispatch(
      setCurrentDealDimension(
        dealDimensionConfig.find(
          (dimension) => dimension.dimensionName === dimensionName
        )
      )
    );
    dispatch(setDealsDimensionDialogOpen(true));
  };

  return (
    <>
      <Paper className={classes[className]}>
        <Grid>
          <Grid style={{ backgroundColor: titleColor, padding: 10 }}>
            <Typography className={classes.title}>{dimensionName}</Typography>
          </Grid>
          <Grid className={classes.inputBodies}>
            {inputs.length
              ? inputs.map((input) => {
                  return (
                    <div className={classes.columnDetailsBody} key={nanoid()}>
                      {dimensionName !== 'Job Status' && (
                        <div className={classes.columnName}>
                          {input.stepperInputLabel}
                        </div>
                      )}
                      <div className={classes.columnValue}>
                        <InputDisplay keyName={input.stepperKeyName} />
                      </div>
                    </div>
                  );
                })
              : null}
          </Grid>
        </Grid>
        {dimensionName !== 'Job Status' && (
          <Grid>
            <Grid className={classes.baseBar}>
              <div>
                <Button
                  color={'primary'}
                  onClick={() => {
                    handleOpenDimensionDialog(dimensionName);
                  }}
                >
                  Edit
                </Button>
              </div>
            </Grid>
          </Grid>
        )}
      </Paper>
    </>
  );
};

const colors = colormap({
  colormap: 'plasma',
  nshades: 20,
  format: 'hex',
  alpha: 1,
});

const DealDimensions = () => {
  const classes = useStyles();
  const dimensions = dealDimensionConfig;

  return (
    <div className={classes.dimensionsContainer}>
      {dimensions.map((dimension, i) => {
        if (dimension.showInEditor) {
          return (
            <SingleDealDimension
              key={nanoid()}
              className={'dimensionPaper'}
              titleColor={fade(colors[i], 0.8 - 0.15 * i)}
              dimensionName={dimension.dimensionName}
              inputs={dimension.inputs}
            />
          );
        } else {
          return null;
        }
      })}
    </div>
  );
};

export default DealDimensions;
