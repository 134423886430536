import styled from 'styled-components';

export const AudienceContainer = styled.div`
  height: 100vh;
`;

export const AudienceUnknown = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const AudienceUnknownText = styled.div`
  font-size: 20px;
  text-align: center;
`;
