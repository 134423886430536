import CustomStepper from 'components/customStepper';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

// Material UI
import ArrowBack from '@material-ui/icons/ArrowBack';
import { Button, IconButton, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// configs
import dealDimensionConfig from '../configs/dealDimensionsConfig';

// Components
import tokens from 'tokens';
import { Email } from '@material-ui/icons';
import { InputType } from '../dealsDimensionDialog';
import {
  clearBidders,
  clearBuyers,
  clearLineItems,
  setCurrentDeal,
  setCurrentDealDimension,
  setUIState,
} from '../slice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';

const useStyles = makeStyles(() => ({
  arrowButtonContainer: {
    paddingRight: 10,
  },
  dimensionContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  dimensionPaper: {
    width: 1080,
    overflow: 'hidden',
    borderRadius: 10,
  },
  newDealBar: {
    height: 60,
    fontSize: 18,
    padding: '1em',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: tokens.colorTextDefault,
  },
  titleBar: {
    height: 50,
    color: tokens.colorTextDefault,
    fontSize: 17,
    backgroundColor: tokens.colorTextUnique,
    display: 'flex',
    alignItems: 'center',
  },
  titleBarTextContainer: {
    paddingLeft: '1em',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: 1000,
  },
  buttonContainerPaddingAndAlignment: {
    paddingTop: 20,
    display: 'flex',
    justifyContent: 'center',
  },
  inputRow: {
    display: 'flex',
    alignItems: 'center',
    padding: '1em',
    fontSize: 15,
  },
  inputRowContainer: {
    minHeight: '100%',
    padding: '2em',
  },
  inputLabel: {
    width: 500,
  },
  inputType: {
    width: 500,
  },
}));

const DealsCreator = () => {
  const [index, setIndex] = useState(0);
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const defaultDeal = useMemo(
    () => ({
      dealname: '',
      partnerbuyercontact: '',
      lineitems: [],
    }),
    []
  );

  const currentDimension = useAppSelector(
    (state) => state.dealsReducer.currentDimension
  );

  const currentDeal = useAppSelector((state) => state.dealsReducer.currentDeal);

  useEffect(() => {
    dispatch(setCurrentDeal(defaultDeal));
    dispatch(clearBuyers(null));
    dispatch(clearBidders(null));
    dispatch(clearLineItems(null));
    dispatch(setUIState({ value: false, uiPropertyName: 'manual' }));
    dispatch(setUIState({ value: '', uiPropertyName: 'buyerLabel' }));
    dispatch(setUIState({ value: '', uiPropertyName: 'bidderLabel' }));
    dispatch(setUIState({ value: '', uiPropertyName: 'lineitemFilter' }));
    dispatch(setUIState({ value: {}, uiPropertyName: 'validationMap' }));
    dispatch(
      setUIState({
        value: 0,
        uiPropertyName: 'lineitemScrollPosition',
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultDeal]);

  const dimensions = dealDimensionConfig
    .filter((dimension) => dimension.showInCreation)
    .map((dimension) => ({
      stepperDimensionName: dimension.dimensionName,
    }));

  const isInvalid = () => {
    const currentInputs = currentDimension.inputs;
    for (let i = 0; i < currentInputs.length; i++) {
      if (
        currentInputs[i].required &&
        currentDeal &&
        _.isEmpty(currentDeal[currentInputs[i].stepperKeyName])
      ) {
        return true;
      }
    }
    return false;
  };

  const handlePrevious = () => {
    setIndex(index - 1);

    if (index !== 0) {
      dispatch(
        setUIState({
          value: 0,
          uiPropertyName: 'lineitemScrollPosition',
        })
      );
      dispatch(setCurrentDealDimension(dealDimensionConfig[index - 1]));
    }
  };

  const handleNext = () => {
    setIndex(index + 1);
    if (index !== dimensions.length - 1) {
      dispatch(setCurrentDealDimension(dealDimensionConfig[index + 1]));
    }
  };

  const handleSubmit = () => {
    dispatch(setUIState({ value: {}, uiPropertyName: 'validationMap' }));
    dispatch(
      setUIState({
        value: 0,
        uiPropertyName: 'lineitemScrollPosition',
      })
    );
  };

  const handleCancel = () => {
    dispatch(setCurrentDeal(defaultDeal));
    dispatch(clearBuyers(null));
    dispatch(setUIState({ value: '', uiPropertyName: 'lineitemFilter' }));
    dispatch(setUIState({ value: '', uiPropertyName: 'buyerLabel' }));
    dispatch(setUIState({ value: {}, uiPropertyName: 'validationMap' }));
    dispatch(
      setUIState({
        value: 0,
        uiPropertyName: 'lineitemScrollPosition',
      })
    );
    history.push('/main/pmp-deals');
  };

  return (
    <div>
      <div className={classes.newDealBar}>
        <div className={classes.arrowButtonContainer}>
          <IconButton
            onClick={() => history.push('/main/pmp-deals')}
            color="primary"
          >
            <ArrowBack />
          </IconButton>
        </div>
        <div>New PMP Deal</div>
      </div>
      <CustomStepper activeStep={index} dimensions={dimensions} />
      <div className={classes.dimensionContainer}>
        <Paper className={classes.dimensionPaper}>
          <div className={classes.titleBar}>
            <div className={classes.titleBarTextContainer}>
              {dimensions[index].stepperDimensionName}
            </div>
          </div>
          <div>
            <div className={classes.inputRowContainer}>
              {currentDimension.inputs.map((input: any) => {
                if (input.forCreation) {
                  return (
                    <div key={nanoid()} className={classes.inputRow}>
                      <div className={classes.inputLabel}>
                        {input.stepperInputLabel}
                      </div>
                      <div className={classes.inputType}>
                        <InputType
                          inputType={input.stepperInputType}
                          keyName={input.stepperKeyName}
                          options={input.options || {}}
                          required={input.required}
                          helperText={input.helperText}
                          muiProps={input.muiProps}
                          type={input.type}
                          displaymode={false}
                        />
                      </div>
                    </div>
                  );
                } else {
                  return null;
                }
              })}
            </div>
          </div>
        </Paper>
      </div>
      <div className={classes.buttonContainerPaddingAndAlignment}>
        <div className={classes.buttonContainer}>
          <div>
            {index === 0 && (
              <Button
                color="primary"
                onClick={handleCancel}
                variant="contained"
              >
                CANCEL
              </Button>
            )}
            {index !== 0 && (
              <Button color="primary" onClick={handlePrevious}>
                BACK
              </Button>
            )}
          </div>
          <div>
            {index !== dimensions.length - 1 && (
              <Button
                color="primary"
                onClick={handleNext}
                disabled={isInvalid()}
              >
                NEXT
              </Button>
            )}
            {index === dimensions.length - 1 && (
              <Button
                color="primary"
                onClick={handleSubmit}
                disabled={isInvalid()}
                endIcon={<Email />}
              >
                SAVE AND SEND AGREEMENT
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DealsCreator;
