import React, { useEffect, useState } from 'react';
import { Button, IconButton } from '@klover/attain-design-system';
import { SnackBarOptions, defaultSnackBarOptions } from 'interfaces/ui';
import {
  ToggleGroupDeleteDialogAction,
  deleteGroup,
  setGroupDeletingState,
  toggleGroupDeleteDialog,
} from '../slice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';

// Material UI Components
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import Typography from '@material-ui/core/Typography';
import tokens from 'tokens';
import {
  Theme,
  ThemeProvider,
  createStyles,
  createTheme,
  makeStyles,
} from '@material-ui/core/styles';

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: theme.spacing(3),
    },
    title: {
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 23,
      paddingLeft: 16,
      paddingTop: 16,
    },
  })
);

const dialog = createTheme({
  overrides: {
    MuiDialog: {
      paper: {
        borderRadius: 10,
      },
      paperWidthMd: {
        maxWidth: 1100,
      },
    },
    MuiMenu: {
      list: {
        padding: 0,
      },
    },
    MuiInputBase: {
      input: {
        borderRadius: 10,
        padding: '12px 14px',
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 10,
      },
    },
    MuiButton: {
      root: {
        borderRadius: 8,
      },
      outlinedPrimary: {
        color: tokens.colorButtonPrimary,
      },
      containedPrimary: {
        backgroundColor: tokens.colorButtonPrimary,
        '&:hover': {
          backgroundColor: tokens.colorButtonPrimary,
        },
      },
    },
  },
});

interface Props {
  open: boolean;
}

const GroupDeleteDialog = ({ open }: Props) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const [snackBarOptions, setSnackBarOptions] = useState<SnackBarOptions>(
    defaultSnackBarOptions
  );

  const currentGroup = useAppSelector(
    (state) => state.audienceReducer.currentGroup
  );

  const groupDeleting = useAppSelector(
    (state) => state.audienceReducer.ui.groupDeleting
  );

  useEffect(() => {
    let snackBarOptions: SnackBarOptions;
    switch (groupDeleting.state) {
      case 'done':
        dispatchClose();

        snackBarOptions = {
          open: true,
          severity: 'success',
          message: groupDeleting.message || '',
        };
        break;
      case 'failed':
        snackBarOptions = {
          open: true,
          severity: 'error',
          message: groupDeleting.message || '',
        };
        break;
      default:
        snackBarOptions = {
          open: false,
          severity: 'success',
          message: '',
        };
        break;
    }
    setSnackBarOptions(snackBarOptions);
  }, [groupDeleting]);

  const closeSnackBar = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') return;
    const updatedSnackBarOptions = {
      ...snackBarOptions,
      open: false,
    };
    setSnackBarOptions(updatedSnackBarOptions);
    dispatch(setGroupDeletingState({ state: 'idle' }));
  };

  const deleting = groupDeleting.state === 'loading';

  const dispatchClose = () => {
    const actionProps: ToggleGroupDeleteDialogAction = {
      open: false,
    };
    setSnackBarOptions(defaultSnackBarOptions);
    dispatch(toggleGroupDeleteDialog(actionProps));
  };

  const handleDeleteGroup = () => {
    if (currentGroup) {
      dispatch(deleteGroup(currentGroup));
    }
  };

  const handleClose = () => {
    dispatchClose();
  };

  return (
    <>
      <ThemeProvider theme={dialog}>
        <Dialog
          className={classes.root}
          fullWidth={true}
          onClose={handleClose}
          open={open}
        >
          <Box m={1}>
            <Grid container alignItems="center">
              <Grid item xs={10}>
                <Typography className={classes.title}>
                  Delete {currentGroup?.name}
                </Typography>
              </Grid>
              <Grid item container justifyContent="flex-end" xs={2}>
                <IconButton onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Box>

          {/* <Box m={3}> */}
          <DialogContent style={{ padding: '24px 32px 8px 32px' }}>
            <DialogContentText
              style={{
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: 16,
              }}
            >
              Are you sure you want to permanently delete{' '}
              <span style={{ fontWeight: 'bolder' }}>{currentGroup?.name}</span>{' '}
              and all its audiences?
            </DialogContentText>
          </DialogContent>
          {/* </Box> */}

          {/* Bottom Divider */}
          <Grid container spacing={0} style={{ marginTop: 150 }}>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Grid>

          {/* Actions */}
          <Box m={1}>
            <DialogActions>
              <Button color="primary" onClick={handleClose} variant="outlined">
                Cancel
              </Button>

              {deleting ? (
                <Box
                  width={142}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <CircularProgress color="primary" size={20} />
                </Box>
              ) : (
                <Button
                  color="primary"
                  disableElevation
                  onClick={handleDeleteGroup}
                  variant="contained"
                >
                  Delete Group
                </Button>
              )}
            </DialogActions>
          </Box>
        </Dialog>
      </ThemeProvider>

      <Snackbar
        open={snackBarOptions.open}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={2000}
        onClose={closeSnackBar}
      >
        <Alert onClose={closeSnackBar} severity={snackBarOptions.severity}>
          {snackBarOptions.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default GroupDeleteDialog;
