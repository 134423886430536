import styled from 'styled-components';
import { Typography } from '@material-ui/core';

export const InformationalText = styled(Typography)`
  font-size: 14px;
  font-weight: bold;
  color: red;
  margin-bottom: 8px;
`;

