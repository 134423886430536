import EditDetails from '../editDetails';
import EditTargeting from '../editTargeting';
import Stack from 'components/Stack';
import nanoid from 'utils/nanoid';
import tokens from 'tokens';
import { API_BASE_ADDRESS } from 'pages/audienceManager/variables';
import { CircularProgress, Divider } from '@material-ui/core';
import {
  QQ_ANSWERTYPE_FREE_FORM,
  QQ_ANSWERTYPE_MULTIPLE,
  QQ_ANSWERTYPE_SINGLE,
} from '../variables';
import {
  Question,
  QuickQuestionTargeting,
} from 'interfaces/quickQuestionsManager';
import { QuickQuestionContext } from 'context';
import { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import * as Grid from 'components/Grid';
import * as Styled from './index.styles';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Typography from '@material-ui/core/Typography';

import BootstrapTooltip from 'components/bootstrapTooltip';
import CreatedAndUpdatedDate from '../createdAndUpdatedDate';
import Toggle from 'components/Toggle';
import getAuthorizedAxiosInstance from 'utils/getAuthorizedAxiosInstance';
import { Button, IconButton } from '@klover/attain-design-system';
import { useUser } from 'reactfire';

const ViewQuickQuestion = () => {
  const {
    setShowEditDetails,
    showEditDetails,
    showEditTargeting,
    setShowEditTargeting,
  } = useContext(QuickQuestionContext);
  const { questionid }: { questionid: string } = useParams();
  const { state } = useLocation();
  const history = useHistory();
  const [question, setQuestion] = useState(null);
  const [questionLoading, setQuestionLoading] = useState(true);
  const [countsLoading, setCountsLoading] = useState(true);
  const [targetingLoading, setTargetingLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(null);
  const [answerIdToCountMap, setAnswerIdToCountMap] = useState(null);
  const [targeting, setTargeting] = useState({});
  const { data } = useUser();

  useEffect(() => {
    const getQuestion = async () => {
      try {
        setQuestionLoading(true);
        if (questionid) {
          const axiosInstance = await getAuthorizedAxiosInstance();
          if (axiosInstance) {
            const res = await axiosInstance?.get(
              `${API_BASE_ADDRESS}/questions/${questionid}`
            );
            const { totalCount, responses } = res.data.responses;
            setQuestion(res.data);
            setQuestionLoading(false);
            setTotalCount(totalCount);
            setAnswerIdToCountMap(responses || {});
            setCountsLoading(false);
            setTargeting(res.data.audiences);
            setTargetingLoading(false);
          } else {
            throw new Error('Failed to authorize request');
          }
        }
      } catch (err) {
        console.log(err);
        setQuestionLoading(false);
        setCountsLoading(false);
        setTargetingLoading(false);
      }
    };
    if (showEditTargeting) {
      return;
    }
    getQuestion();
  }, [showEditTargeting]);

  const handleActivate = async () => {
    try {
      const axiosInstance = await getAuthorizedAxiosInstance();
      if (axiosInstance) {
        await axiosInstance.put(
          `${API_BASE_ADDRESS}/questions/status/${questionid}`,
          {
            isactive: !question.isactive,
            user: data?.email,
          }
        );
        setQuestion({ ...question, isactive: !question.isactive });
      } else {
        throw new Error('Failed to authorize request');
      }
    } catch (e) {
      console.log(e);
    }
  };

  const QuestionView = ({
    question,
    targeting,
  }: {
    question: Question;
    targeting: QuickQuestionTargeting;
  }) => {
    const {
      questiontext,
      isactive,
      answers,
      answertype,
      points,
      questionemoji,
    } = question;

    const answerTypeToText = (answertype: string) => {
      switch (answertype) {
        case QQ_ANSWERTYPE_SINGLE:
          return 'Single Selection';
        case QQ_ANSWERTYPE_MULTIPLE:
          return 'Multiple Selection';
        case QQ_ANSWERTYPE_FREE_FORM:
          return 'Free form Answer';
      }
    };

    return (
      <>
        <Styled.QuestionDetailsAndPreviewContainer>
          <Styled.QuestionDetailsAndPreview>
            <Styled.CardContainer style={{ width: '53rem', margin: 0 }}>
              <Styled.CardTitle variant="h6">
                Quick Question - Details
              </Styled.CardTitle>
              <Stack spacing={tokens.spacing.lg}>
                <Grid.Row>
                  <Grid.Col>
                    <Styled.Label>Text</Styled.Label>

                    <Styled.Info>
                      {questiontext ? questiontext : null}
                    </Styled.Info>
                  </Grid.Col>

                  <Grid.Col>
                    <Styled.Label>Status</Styled.Label>
                    <Styled.Info>
                      {isactive ? (
                        <Styled.Active>Active</Styled.Active>
                      ) : (
                        <Styled.Inactive>Inactive</Styled.Inactive>
                      )}
                    </Styled.Info>
                  </Grid.Col>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Col>
                    <Styled.Label>Answer Type</Styled.Label>
                    <Styled.Info>{answerTypeToText(answertype)}</Styled.Info>
                  </Grid.Col>

                  <Grid.Col>
                    <Styled.Label>Emoji</Styled.Label>

                    <Styled.Info>
                      {questionemoji
                        ? questionemoji.split('-').reduce((a, s) => {
                            try {
                              return (a += String.fromCodePoint(`0x${s}`));
                            } catch (e) {
                              return '';
                            }
                          }, '')
                        : null}
                    </Styled.Info>
                  </Grid.Col>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Col>
                    <Styled.Label>Points</Styled.Label>
                    <Styled.Info>{points}</Styled.Info>
                  </Grid.Col>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Col>
                    <Button
                      variant="contained"
                      onClick={() => setShowEditDetails(true)}
                      disabled={!!isactive}
                    >
                      Edit Details
                    </Button>
                  </Grid.Col>
                </Grid.Row>
              </Stack>
            </Styled.CardContainer>

            <Styled.CardContainer
              style={{
                width: '12.5rem',
                height: '12.5rem',
                margin: 0,
              }}
            >
              <Stack spacing="">
                <Styled.EmojiContainer>
                  <Styled.Emoji>
                    {questionemoji
                      ? questionemoji.split('-').reduce((a, s) => {
                          try {
                            return (a += String.fromCodePoint(`0x${s}`));
                          } catch (e) {
                            return '';
                          }
                        }, '')
                      : null}
                  </Styled.Emoji>
                </Styled.EmojiContainer>
                <Styled.Points>
                  <Styled.Trophy>🏆</Styled.Trophy> Earn{' '}
                  {question && question.points} points
                </Styled.Points>
                <Styled.Question>
                  {question && question.questiontext}
                </Styled.Question>
              </Stack>
            </Styled.CardContainer>
          </Styled.QuestionDetailsAndPreview>
        </Styled.QuestionDetailsAndPreviewContainer>

        <div style={{ paddingTop: '1.75rem' }} />

        <Styled.CardContainer>
          <Styled.CardTitle variant="h6">Respondents</Styled.CardTitle>
          <Grid.Row>
            <Grid.Col>
              <Styled.Table>
                <tr>
                  <th align="left">Answer</th>
                  <th align="right">Responses</th>
                  <th align="right">Percent</th>
                </tr>
                {answertype === QQ_ANSWERTYPE_SINGLE ||
                answertype === QQ_ANSWERTYPE_MULTIPLE ? (
                  <>
                    {answers && answers.length
                      ? answers.map(({ answerid, answertext }) => (
                          <Styled.TableRow key={nanoid()}>
                            <td align="left">{answertext}</td>
                            <td align="right">
                              {answerIdToCountMap &&
                              answerIdToCountMap[answerid] &&
                              totalCount
                                ? `${Math.round(
                                    (answerIdToCountMap[answerid] / 100) *
                                      totalCount
                                  ).toLocaleString()}`
                                : '0'}
                            </td>
                            <td align="right">
                              {answerIdToCountMap &&
                              answerIdToCountMap[answerid] &&
                              totalCount
                                ? `${answerIdToCountMap[answerid]} %`
                                : '0 %'}
                            </td>
                          </Styled.TableRow>
                        ))
                      : null}
                    <td
                      align="left"
                      style={{ fontSize: '16px', lineHeight: '24px' }}
                    >
                      Total Respondents
                    </td>
                    <td
                      align="right"
                      style={{ fontSize: '16px', lineHeight: '24px' }}
                    >
                      {totalCount ? Number(totalCount).toLocaleString() : 0}
                    </td>
                  </>
                ) : null}
              </Styled.Table>
            </Grid.Col>
          </Grid.Row>
        </Styled.CardContainer>

        <div style={{ paddingTop: '1.75rem' }} />

        <Styled.CardContainer>
          <Styled.CardTitle variant="h6">Audience Targeting</Styled.CardTitle>
          <Stack spacing={tokens.spacing.lg}>
            {targeting ? (
              <Grid.Row>
                <Grid.Col>
                  {targeting && targeting.length ? (
                    <>
                      <Styled.Label>Audiences</Styled.Label>
                      {targeting.map(({ id, name }) => (
                        <Styled.AudienceRow key={nanoid()}>
                          <div>
                            {name} - {`(ID: ${id})`}
                          </div>
                        </Styled.AudienceRow>
                      ))}
                    </>
                  ) : null}
                </Grid.Col>
              </Grid.Row>
            ) : null}
            <Grid.Row>
              <Grid.Col>
                <Button
                  onClick={() => setShowEditTargeting(true)}
                  variant="contained"
                >
                  Edit Targeting
                </Button>
              </Grid.Col>
            </Grid.Row>
          </Stack>
          {showEditTargeting ? (
            <EditTargeting
              type={question.type}
              questionid={question.questionid}
              setOriginalTargeting={setTargeting}
              audiencesList={question.audiences || []}
            />
          ) : null}
          {showEditDetails && (
            <EditDetails
              originalQuestion={question}
              setQuestion={setQuestion}
              hasResponses={!!totalCount}
              user={data?.email}
            />
          )}
        </Styled.CardContainer>
      </>
    );
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          paddingLeft: 10,
        }}
      >
        <IconButton
          color="primary"
          onClick={() => {
            const status =
              state.isActive ||
              (question.isactive === 1 ? 'active' : 'inactive');
            history.push(`/main/quick-questions-manager?status=${status}`);
          }}
        >
          <ArrowBackIcon />
        </IconButton>
        <div style={{ marginLeft: 8 }} />
        <Styled.ViewQuestionHeader
          title={
            <div>
              <BootstrapTooltip title={question && question.questiontext}>
                <Styled.QuestionText component="h2" variant="h6">
                  {question && question.questiontext}
                </Styled.QuestionText>
              </BootstrapTooltip>
              <Typography component="h3" variant="body2">
                ID: {(question && question.questionid) || 'N/A'}
              </Typography>
            </div>
          }
          centerBorder={false}
          center={
            <Styled.ButtonSpacing style={{ border: 'none' }}>
              <Toggle
                name="is-active-toggle"
                checked={question && question.isactive ? true : false}
                onClick={handleActivate}
              />
              {/* <Button
                onClick={handleActivate}
                color={
                  question && question.isactive
                    ? ButtonColors.Negative
                    : ButtonColors.Positive
                }
                size={ButtonSizes.Default}
              >
                {question && question.isactive ? 'Deactivate' : 'Activate'}
              </Button> */}
            </Styled.ButtonSpacing>
          }
          action={
            <Styled.ActionContainer>
              <CreatedAndUpdatedDate />
            </Styled.ActionContainer>
          }
        />
      </div>
      <Divider style={{ height: 2 }} />

      <Styled.ContentWrapper>
        {!questionLoading && !countsLoading && !targetingLoading ? (
          question ? (
            <QuestionView question={question} targeting={targeting} />
          ) : (
            <Styled.Nothing>No Question Exists</Styled.Nothing>
          )
        ) : (
          <Styled.Nothing>
            <CircularProgress color="primary" size={20} />
          </Styled.Nothing>
        )}
      </Styled.ContentWrapper>
    </>
  );
};

export default ViewQuickQuestion;
