import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import styled from 'styled-components';
import tokens from 'tokens';
import * as Styled from 'components/Table/index.styles';

export const JustifyCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
`;

export const DragIcon = styled(DragIndicatorIcon)`
  float: left;
  height: 24px;
  opacity: 0.25;
  margin-right: ${tokens.spacing.sm};
  padding: ${tokens.spacing.xs} 0;
  width: 16px;
`;

export const TableBodyRow = styled(Styled.TableBodyRow)`
  &:hover {
    color: ${(props) => (props.$disableHover ? '' : tokens.colorButtonPrimary)};

    ${DragIcon} {
      opacity: 1;

      path {
        fill: ${tokens.colorButtonPrimary};
      }
    }
  }
`;

export const MainBodyWithSpacing = styled.div`
  margin-bottom: ${tokens.spacing.lg};
`;
