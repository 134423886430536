import React from 'react';
import nanoid from 'utils/nanoid';
import { Button } from '@klover/attain-design-system';
import {
  Dimension,
  DimensionCategory,
  ToggleDimensionDialogAction,
  toggleDimensionDialog,
} from '../slice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';

// Material UI Components
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import PersonIcon from '@material-ui/icons/Person';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import {
  Theme,
  ThemeProvider,
  createStyles,
  createTheme,
  makeStyles,
} from '@material-ui/core/styles';
// import StoreIcon from '@material-ui/icons/Store'
import SwapHorizontalCircleIcon from '@material-ui/icons/SwapHorizontalCircle';
import Typography from '@material-ui/core/Typography';
import tokens from 'tokens';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.background.default,
      width: '100%',
      zIndex: 0,
      boxShadow: 'inset 0px -1px 0px ' + tokens.colorSuccess,
      padding: '16px 32px',
    },
    button: {
      borderRadius: 24,
      marginLeft: `4px !important`,
      marginRight: `4px !important`,
    },
    divider: {
      width: '100%',
      height: theme.spacing(0.75),
      backgroundColor: theme.palette.primary.light,
    },
  })
);

const button = createTheme({
  overrides: {
    MuiButton: {
      label: {
        color: tokens.colorButtonSecondary,
        '&:hover': {
          color: tokens.colorButtonPrimary,
        },
      },
      outlinedPrimary: {
        borderColor: tokens.colorButtonSecondary,
        '&:hover': {
          borderColor: tokens.colorButtonPrimary,
        },
      },
    },
    MuiButtonGroup: {
      root: {
        border: '1px solid yellow',
      },
    },
  },
});

interface AddDimensionCategoryProps {
  isAudienceLoading: boolean;
}

const AddDimensionCategory: React.FC<AddDimensionCategoryProps> = ({
  isAudienceLoading,
}) => {
  const audience = useAppSelector(
    (state) => state.audienceReducer.currentAudience
  );
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const addDimensionHandler = (category: DimensionCategory) => () => {
    const dimension = createDimension(category);
    const actionProps: ToggleDimensionDialogAction = {
      dimension: dimension,
      open: true,
    };
    dispatch(toggleDimensionDialog(actionProps));
    localStorage.removeItem('cacheData');
  };

  const createDimension = (category: DimensionCategory) => {
    let sort = 0;
    if (audience) {
      sort = audience.dimensions.length + 1;
    }

    //for later use
    const getOperator = () => {
      switch (category) {
        case 'survey':
          return 'or';
        default:
          return 'and';
      }
    };

    const dimension: Dimension = {
      id: nanoid(),
      sort: sort,
      category: category,
      operator: getOperator(),
      conditions: [],
      timeframe: '',
      timeframeOperator: '',
      count: '',
      countOperator: '',
      amount: '',
      amountOperator: '',
      audienceSize: 0,
    };
    return dimension;
  };

  return (
    <>
      <Box className={classes.root}>
        <ThemeProvider theme={button}>
          <Grid container alignItems="center">
            <Grid item xs={2}>
              <Typography
                style={{
                  fontStyle: 'normal',
                  fontWeight: 500,
                  fontSize: 14,
                }}
              >
                ADD DIMENSION CATEGORY
              </Typography>
            </Grid>
            <Grid item container xs={10} justifyContent="flex-end">
              <Button
                disabled={isAudienceLoading}
                className={classes.button}
                variant="outlined"
                color="primary"
                startIcon={<PersonIcon />}
                onClick={addDimensionHandler('demographics')}
              >
                Demographics
              </Button>

              <Button
                disabled={isAudienceLoading}
                className={classes.button}
                variant="outlined"
                color="primary"
                startIcon={<SwapHorizontalCircleIcon />}
                onClick={addDimensionHandler('transactions')}
              >
                Transactions
              </Button>

              <Button
                disabled={isAudienceLoading}
                className={classes.button}
                variant="outlined"
                color="primary"
                startIcon={<AccountBalanceIcon />}
                onClick={addDimensionHandler('bankData')}
              >
                Bank Data
              </Button>

              {/* <Button
              className={classes.button}
              variant="outlined"
              color="primary"
              startIcon={<StoreIcon />}
              onClick={addDimensionHandler('merchant')}
            >
              Merchant
            </Button> */}

              <Button
                disabled={isAudienceLoading}
                className={classes.button}
                variant="outlined"
                color="primary"
                startIcon={<ShoppingBasketIcon />}
                onClick={addDimensionHandler('product')}
              >
                Product
              </Button>

              <Button
                disabled={isAudienceLoading}
                className={classes.button}
                variant="outlined"
                color="primary"
                startIcon={<QuestionAnswerIcon />}
                onClick={addDimensionHandler('survey')}
              >
                Survey
              </Button>
            </Grid>
          </Grid>
        </ThemeProvider>
      </Box>

      {/* Top Divider */}
      <Grid container spacing={0} style={{ marginBottom: 40 }}>
        <Grid item xs={12}>
          <Divider />
        </Grid>
      </Grid>
    </>
  );
};

export default AddDimensionCategory;
