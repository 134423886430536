import React, { useEffect, useState } from 'react';
import {
  DimensionCategoryCondition,
  clearSearchResults,
  searchQuery,
} from '../slice';
import { StringMap, StringToBoolMap } from 'interfaces';
import { currentConditionMap } from '../currentConditionOptions';
import { nanoid } from 'nanoid';
import { useAppDispatch, useAppSelector } from 'redux/hooks';

import _ from 'lodash';

// Material UI Components
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

interface AutoCompleteProps {
  condition: DimensionCategoryCondition;
  selectedClasses: any;
  onBlurFunction: any;
  currentConditionValueString: any;
  customOption?: string;
  customLabel?: string;
}

interface CurrentConditionProps {
  [key: string]: any;
}

const usesSearch: StringToBoolMap = {
  upc: true,
  productCategory: true,
};

const ALTERNATIVE_NAMES_MAP: StringMap = {
  productMerchantName: 'Merchant Name',
};

const AutoCompleteModule = ({
  condition,
  selectedClasses,
  onBlurFunction,
  currentConditionValueString,
  customOption,
  customLabel,
}: AutoCompleteProps) => {
  const dispatch = useAppDispatch();
  const currentCondition: CurrentConditionProps = useAppSelector((state) => {
    if (state.audienceReducer.currentConditions.byId[condition.id]) {
      return state.audienceReducer.currentConditions.byId[condition.id];
    } else {
      return condition;
    }
  });
  const searchResults = useAppSelector(
    (state) => state.audienceReducer.searchResults
  );
  const [value, setValue] = useState<string>('');
  const [inputValue, setInputValue] = useState<string>('');
  const [options, setOptions] = useState<Array<string>>([]);
  useEffect(() => {
    if (customOption) {
      setOptions(currentConditionMap[customOption]);
    } else {
      if (usesSearch[currentCondition.option]) {
        if (searchResults.length) {
          const mutatedResults = searchResults.slice();
          mutatedResults.unshift('');
          setOptions(mutatedResults);
        } else {
          setOptions([]);
        }
      } else {
        setOptions(currentConditionMap[currentCondition.option]);
      }
    }
  }, [currentCondition.option, searchResults]); // listens for option change to set the options and whenever the current upc search results change
  const isArr = Array.isArray(currentCondition[currentConditionValueString]);
  return (
    <div>
      <Autocomplete
        value={
          isArr
            ? currentCondition[currentConditionValueString][0]
            : currentCondition[currentConditionValueString]
        }
        className={selectedClasses}
        blurOnSelect
        // onchange is what is SELECTED in autocomplete component
        onBlur={() => dispatch(clearSearchResults())}
        onChange={(event, newValue) => {
          setValue(newValue);
          if (newValue) {
            setInputValue(newValue);
            if (!customOption) {
              if (usesSearch[currentCondition.option]) {
                onBlurFunction(newValue);
              } else {
                onBlurFunction(newValue);
              }
            } else if (customOption === 'merchantName') {
              onBlurFunction(newValue);
            }
          } else {
            onBlurFunction(''); // we send two parameters just because we could be on the upc option
          }
        }}
        inputValue={inputValue || ''}
        // on input change is for what's typed inside of autocomplete component
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
          if (!customOption && usesSearch[currentCondition.option]) {
            // when only on the upc autocomplete
            if (newInputValue && newInputValue.length >= 3) {
              if (newInputValue.length === 3) {
                dispatch(
                  searchQuery({
                    category: currentCondition.option,
                    query: newInputValue,
                  })
                ); // dispatch search query when input length is three
                if (newInputValue === value) onBlurFunction(newInputValue); // trigger on blur function for upc which takes two parameters
              }
            } else {
              // when we get less than three letters we clear the upc search results
              setOptions([]);
              dispatch(clearSearchResults());
            }
          } else {
            // on all other autocompletes including the custom options
            if (newInputValue) {
              setValue(newInputValue);
              if (newInputValue === value) onBlurFunction(newInputValue);
            }
          }
        }}
        id={nanoid()}
        options={options}
        renderInput={(params) => (
          <TextField
            label={
              _.startCase(
                customLabel ||
                  ALTERNATIVE_NAMES_MAP[currentCondition.option] ||
                  currentCondition.option
              ) || ''
            }
            {...params}
            variant="outlined"
          />
        )}
      />
    </div>
  );
};

export default AutoCompleteModule;
