import Header from 'components/Header';
import React, { useCallback, useMemo, useState } from 'react';
import Table from 'components/Table';
import { Button } from '@klover/attain-design-system';
import * as Styled from './index.styles';

import Field from 'components/Field';
import Stack from '@mui/material/Stack';
import _debounce from 'lodash/debounce';
import getAuthorizedAxiosInstance from 'utils/getAuthorizedAxiosInstance';
import { API_BASE_ADDRESS } from 'pages/audienceManager/variables';
import { Box, CircularProgress } from '@material-ui/core';
import { FormikProvider, useFormik } from 'formik';
import { Link, useLocation } from 'react-router-dom';
import { Question } from 'interfaces/quickQuestionsManager';
import { QuestionAnswerRounded } from '@mui/icons-material';
import { QuickQuestionContext } from 'context';
import { TablePagination } from '@mui/material';

const QuickQuestionsManager = () => {
  const {
    setQuestionsLoading,
    setQuestions,
    questions,
    questionsLoading,
    setShowCreateQuickQuestion,
  } = React.useContext(QuickQuestionContext);
  const formik = useFormik({
    initialValues: {},
    onSubmit: (values) => console.log(values),
  });
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [search, setSearch] = useState('');
  const [oprations, setOprations] = useState({
    page: 0,
    rowsPerPage: 25,
    sortBy: 'createdat',
    sortOrder: 'DESC',
    search: '',
    status: searchParams.get('status') || 'all',
  });

  const getQuestions = async () => {
    try {
      const axiosInstance = await getAuthorizedAxiosInstance();
      if (axiosInstance) {
        const res = await axiosInstance.get(
          `${API_BASE_ADDRESS}/questions?search=${encodeURIComponent(
            oprations?.search
          )}&page=${oprations?.page}&rowsPerPage=${
            oprations?.rowsPerPage
          }&sortBy=${oprations?.sortBy}&sortOrder=${
            oprations?.sortOrder
          }&status=${oprations?.status}`
        );
        setQuestions(res.data);
        setQuestionsLoading(false);
      } else {
        throw new Error('Failed to authorize request');
      }
    } catch (e) {
      console.log(e);
      setQuestionsLoading(false);
    }
  };

  React.useEffect(() => {
    setQuestionsLoading(true);
    getQuestions();
    setURL(oprations?.status);
  }, [oprations]);

  const setURL = (status: string) => {
    window.history.replaceState(
      null,
      '',
      `${location.pathname}?status=${status}`
    );
  };

  const debounceFn = useCallback(
    _debounce((value) => {
      setOprations({
        ...oprations,
        page: 0,
        search: value,
      });
    }, 1000),
    [oprations]
  );

  return (
    <FormikProvider value={formik}>
      <Header
        title="Quick Questions Manager"
        action={
          <Styled.ButtonSpacing>
            <Button
              variant="contained"
              onClick={() => {
                setShowCreateQuickQuestion(true);
              }}
            >
              Create Question
            </Button>
          </Styled.ButtonSpacing>
        }
      />
      <Box height="100%" padding={2}>
        <Styled.SearchArea>
          <>
            <Field
              as="select"
              id="questionsFilter"
              name="selectField"
              value={oprations?.status}
              onChange={(e) => {
                setOprations({
                  ...oprations,
                  page: 0,
                  status: e.target.value,
                });
                setURL(e.target.value);
              }}
              disabled={questionsLoading}
            >
              <option value="all">Active/Inactive</option>
              <option value="active">Active</option>
              <option value="inactive">Inactive</option>
            </Field>

            <Styled.Form>
              <Field
                value={search}
                name="searchField"
                onChange={(e: any) => {
                  setSearch(e.target.value);
                  debounceFn(e.target.value);
                }}
                placeholder={'Search Questions'}
                disabled={questionsLoading}
              />
            </Styled.Form>
          </>
        </Styled.SearchArea>

        {questionsLoading ? (
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{ width: 1, height: '80vh' }}
          >
            <CircularProgress color="primary" size={40} />
          </Stack>
        ) : questions.length ? (
          <QuickQuestionTable
            questions={questions}
            oprations={oprations}
            setOprations={setOprations}
          />
        ) : (
          'No Questions'
        )}
      </Box>
    </FormikProvider>
  );
};

export default QuickQuestionsManager;

const QuickQuestionTable = ({
  questions,
  oprations,
  setOprations,
}: {
  questions: Question[];
  oprations: any;
  setOprations: any;
}) => {
  const {} = React.useContext(QuickQuestionContext);
  const columns = React.useMemo(
    () => [
      {
        Header: 'Question',
        accessor: 'questiontext',
        Cell: (row) => {
          return (
            <>
              <Styled.QuestionTextContainer>
                <Styled.StyledBackgroundCircle
                  active={row.cell.row.original.isactive ? true : false}
                  title={
                    row.cell.row.original.isactive
                      ? 'Status: ON'
                      : 'Status: OFF'
                  }
                >
                  <QuestionAnswerRounded style={{ width: 18, height: 18 }} />
                </Styled.StyledBackgroundCircle>
                <Link
                  to={{
                    pathname: `/main/quick-questions-manager/questions/${row.cell.row.original.questionid}`,
                    state: { isActive: oprations.status },
                  }}
                >
                  {row.cell.value}
                </Link>
              </Styled.QuestionTextContainer>
            </>
          );
        },
      },
      {
        Header: 'Answer Type',
        accessor: 'answertype',
        Cell: (row) => {
          return <>{row.cell.value}</>;
        },
      },
      {
        Header: 'Question ID',
        accessor: 'questionid',
        Cell: (row) => {
          return <>{row.cell.value}</>;
        },
      },
      {
        Header: 'Created By',
        accessor: 'createdby',
        Cell: (row) => <>{row.cell.value}</>,
      },
      {
        Header: 'Created On',
        accessor: 'createdat',
        Cell: (row) => (
          <>
            {row && row.cell.value
              ? new Date(parseInt(row.cell.value)).toLocaleDateString()
              : null}
          </>
        ),
      },
      {
        Header: 'Last Modified',
        accessor: 'updatedat',
        Cell: (row) => (
          <>
            {row && row.cell.value
              ? new Date(parseInt(row.cell.value)).toLocaleDateString()
              : null}
          </>
        ),
      },
    ],
    [oprations?.status]
  );
  const count = useMemo(() => {
    const totalCount =
      questions && questions?.length > 0
        ? typeof questions[0].totalCount === 'string'
          ? Number(questions[0].totalCount)
          : questions[0].totalCount
        : 1;
    return totalCount;
  }, [questions]);

  const handleChangePage = (event: any, newPage: number) => {
    setOprations({
      ...oprations,
      page: newPage,
    });
  };

  const handleChangeRowsPerPage = (event: any) => {
    const newPageSize = parseInt(event.target.value, 10);
    setOprations({
      ...oprations,
      page: 0,
      rowsPerPage: newPageSize,
    });
  };

  const handleOnSortChange = (sortInfo: any) => {
    let sortOrder = 'DESC';
    if (oprations.sortBy === sortInfo.column) {
      sortOrder = oprations.sortOrder == 'ASC' ? 'DESC' : 'ASC';
    }
    setOprations({
      ...oprations,
      sortBy: sortInfo.column,
      sortOrder: sortOrder,
      page: 0,
    });
  };

  return (
    <>
      <Table
        columns={columns}
        data={questions}
        onSort={handleOnSortChange}
        operations={oprations}
      />
      <TablePagination
        component="div"
        count={count}
        page={oprations?.page}
        onPageChange={handleChangePage}
        rowsPerPage={oprations?.rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[25, 50, 100]}
        sx={{
          '.MuiTablePagination-selectLabel': {
            paddingTop: '13px',
          },
          '.MuiTablePagination-displayedRows': {
            paddingTop: '13px',
          },
        }}
      />
    </>
  );
};
