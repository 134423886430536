import Select from 'components/Select';
import styled from 'styled-components';
import tokens from 'tokens';
import { Avatar } from '@material-ui/core';
import { Form as FormikForm } from 'formik';

export const ButtonSpacing = styled.div`
  display: flex;
  & > * + * {
    margin-left: ${tokens.spacing.sm};
  }
`;

export const Form = styled(FormikForm)`
  border-left: 1px solid ${tokens.colorGray40};
  display: inline-block;
  margin-left: ${tokens.spacing.md};
  padding-left: ${tokens.spacing.md};
`;

export const Or = styled.span`
  margin: 0 ${tokens.spacing.sm};
  text-transform: uppercase;
`;

export const SelectStatus = styled(Select)`
  width: 170px;
`;

export const Status = styled.div`
  padding: 0 15% 0 15%;
`;

export const QuestionTextContainer = styled.div`
  display: flex;
  max-width: 400px;
  & > * {
    margin-right: ${tokens.spacing.sm};
  }
`;

export const SearchArea = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: ${tokens.spacing.md};
`;

export const StyledBackgroundCircle = styled(Avatar)`
  background-color: ${({ active }: { active: boolean }) =>
    active ? tokens.colorMessage : null};
  height: 24px;
  width: 24px;
`;
