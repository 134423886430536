import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import styled from 'styled-components';
import tokens from 'tokens';
import * as Styled from 'components/Table/index.styles';

export const TableWrapper = styled.div`
  margin: 0 ${tokens.spacing.md} ${tokens.spacing.xl};
`;

export const HideStuff = styled.div`
  background: ${tokens.colorWarning};
  height: 16px;
  position: sticky;
  top: 0;
  z-index: 1;
`;

export const TableHeader = styled(Styled.TableHeader)`
  top: 16px;
`;

export const DragIcon = styled(DragIndicatorIcon)`
  float: left;
  height: 24px;
  opacity: 0.25;
  margin-right: ${tokens.spacing.sm};
  padding: ${tokens.spacing.xs} 0;
  width: 16px;
`;

export const TableBodyRow = styled(Styled.TableBodyRow)`
  &:hover {
    color: ${(props) =>
    props.$disableHover ? '' : tokens.colorPrimary};

    ${DragIcon} {
      opacity: 1;

      path {
        fill: ${tokens.colorPrimary};
      }
    }
  }
`;
