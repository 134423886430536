import _ from 'lodash';
import numberFormatter from 'utils/formatNumber';
import { API_BASE_ADDRESS } from 'pages/audienceManager/variables';
import { Button, Select } from '@klover/attain-design-system';
import {
  Dimension,
  DimensionCategory,
  DimensionCategoryCondition,
  ToggleDimensionDialogAction,
  currentCounts,
  deleteDimension,
  queryTotalAudienceSize,
  toggleDimensionDialog,
  updateSurveyMap,
} from '../slice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';

// Material UI Components
import AudienceIcon from 'assets/images/user-circle.svg';
import Box from '@material-ui/core/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import UserIcon from 'assets/images/supervisor-account.svg';
import {
  Theme,
  ThemeProvider,
  createStyles,
  createTheme,
  makeStyles,
} from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';

// Page Components
import AudienceCountDisplay from 'components/AudienceCountDisplay';
import DimensionGroupConnector from '../dimensionGroupConnector';
import DimensionIcon from '../dimensionIcon';
import { QuestionSelector } from '../dimensionDialog';

import axios from 'axios';
import { CheckBox } from '@material-ui/icons';

import tokens from 'tokens';
import { MULTIPLIER } from 'pages/variables';
import { StringMap } from 'interfaces';
import { nanoid } from 'nanoid';
import { useEffect, useState } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.background.default,
      padding: 0,
      borderRadius: 10,
      border: '1px solid ' + tokens.colorSuccess,
      boxShadow: '0px 3px 15px rgba(0, 0, 0, 0.07)',
      maxWidth: 1240,
      width: 'calc(100% - 64px)',
      flexShrink: 0,
      flexGrow: 1,
    },
    card: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
    },
    header: {
      paddingLeft: 23,
      paddingTop: 16,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    formControl: {
      minWidth: 100,
      maxWidth: 350,
    },
    title: {
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 19,
      lineHeight: '20px',
      color: tokens.colorTextSecondary,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    audienceSize: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    bankDataHeader: {
      backgroundColor: fade(tokens.colorIconInfo, 0.1),
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
    },
    condition: {
      height: '100%',
      padding: theme.spacing(1),

      fontStyle: 'normal',
      fontSize: 16,
      lineHeight: '16px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    conditionQualifiers: {
      color: tokens.colorDefault,
      backgroundColor: tokens.colorMessage,
    },
    // conditionSeprator: {
    //   color: tokens.colorSpinnerDark,
    //   backgroundColor: tokens.colorDanger,
    // },
    conditionAudience: {
      '& h5': {
        color: tokens.colorTextDefault + ' !important',
      },
      backgroundColor: theme.palette.text.primary,
    },
    demographicsHeader: {
      backgroundColor: fade(tokens.colorIconExtra, 0.1),
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
    },
    divider: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    filter: {
      color: tokens.colorButtonDefault,
      backgroundColor: tokens.colorMessage,
      marginRight: theme.spacing(3),
    },
    filterNone: {
      color: tokens.colorButtonDefault,
      backgroundColor: tokens.colorButtonGray10,
      marginRight: theme.spacing(3),
    },
    footer: {
      backgroundColor: tokens.colorFlesh,
      borderBottomLeftRadius: 10,
      borderBottomRightRadius: 10,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      flexGrow: 1,
      marginTop: '16px',
      padding: '6px 23px 6px 20px',
    },
    merchantHeader: {
      backgroundColor: fade(tokens.colorIconWarning, 0.1),
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
    },
    operatorControl: {
      minWidth: 30,
      marginLeft: 32,
      marginRight: 12,
    },
    productsHeader: {
      backgroundColor: fade(tokens.colorIconSuccess, 0.1),
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
    },
    surveyHeader: {
      backgroundColor: fade(tokens.colorIconInfo, 0.1),
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
    },
    surveyText: {
      lineHeight: 'normal',
    },
    transactionsHeader: {
      backgroundColor: fade(tokens.colorIconDanger, 0.1),
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
    },
    box: {
      display: 'flex',
      flexDirection: 'row',

      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 16,
      lineHeight: '24 px',
      marginBottom: 24,
    },
    col: {
      display: 'flex',
      flexDirection: 'column',
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
    },
    questionSelectorMenuItem: {
      backgroundColor: 'white',
      maxWidth: '500px',
      whiteSpace: 'normal',
    },
    list: {
      width: '100%',
    },
    listSubHeader: {
      fontWeight: 'bold',
      paddingLeft: theme.spacing(2),
    },
    typographyWeighted: {
      fontWeight: 300,
    },
    gridFill: {
      width: '100%',
    },
    questionSelectionContainer: {
      maxWidth: '500px',
      minWidth: '200px',
      marginLeft: 64,
      width: '35%',
    },
    respondentsContainer: {
      width: '100%',
      marginLeft: 64,
      marginRight: 16,
    },
  })
);

const spinner = createTheme({
  overrides: {
    MuiCircularProgress: {
      root: {
        maxHeight: 20,
        maxWidth: 20,
      },
      colorPrimary: {
        color: tokens.colorSpinnerDefault,
      },
    },
  },
});

interface Props {
  dimension: Dimension;
}

const ALTERNATIVE_NAMES_MAP: StringMap = {
  upc: 'Product Name',
  productMerchantName: 'Merchant Name',
};

const DimensionContainer = ({ dimension }: Props) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const conditions: DimensionCategoryCondition[] = useAppSelector((state) => {
    const conditions: DimensionCategoryCondition[] = [];
    dimension.conditions.forEach((id) => {
      const condition = state.audienceReducer.currentConditions.byId[id];
      if (condition && condition.option !== '' && condition.qualifiers !== '') {
        conditions.push(condition);
      }
    });
    return conditions;
  });

  const surveyResponses = useAppSelector(
    (state) => state.audienceReducer.currentSurveyResponses
  );

  const modeled = useAppSelector((state) => {
    return state.audienceReducer.ui.modeled;
  });

  const getAudienceSize = () => {
    let audienceSize;
    if (dimension.category === 'survey') {
      const questionCondition = conditions.find(
        (condition) => condition.option === 'question'
      );
      const conditionsWithoutQuestion = new Set();
      conditions.forEach((condition) => {
        if (condition.option !== 'question' && condition.option === 'answer') {
          conditionsWithoutQuestion.add(condition.qualifiers);
        }
      });
      const questionId = questionCondition?.qualifiers;
      if (questionId) {
        let total = 0;
        if (surveyResponses && surveyResponses[questionId]) {
          Object.keys(surveyResponses[questionId]).forEach((answerid) => {
            if (conditionsWithoutQuestion.has(answerid)) {
              total += surveyResponses[questionId][answerid];
            }
          });
        }
        if (modeled) {
          return (total * MULTIPLIER).toLocaleString();
        } else {
          return total.toLocaleString();
        }
      } else {
        return 0;
      }
    } else {
      audienceSize = dimension.audienceSize.toLocaleString();
      if (!audienceSize) return 0;
      else return audienceSize;
    }
  };

  const handleEditDimension = (dimension: Dimension) => () => {
    const actionProps: ToggleDimensionDialogAction = {
      dimension: dimension,
      open: true,
    };

    dispatch(toggleDimensionDialog(actionProps));
    localStorage.removeItem('cacheData');
  };

  const handleDeleteDimension = (dimension: Dimension) => () => {
    dispatch(deleteDimension(dimension));
    dispatch(queryTotalAudienceSize());
  };

  const hasFilters =
    dimension &&
    (dimension.category === 'transactions' || dimension.category === 'product');

  const headerStyle = (category: DimensionCategory) => {
    switch (category) {
      case 'bankData':
        return classes.bankDataHeader;
      case 'demographics':
        return classes.demographicsHeader;
      case 'merchant':
        return classes.merchantHeader;
      case 'product':
        return classes.productsHeader;
      case 'transactions':
        return classes.transactionsHeader;
      case 'survey':
        return classes.surveyHeader;
    }
  };

  const Title = () => (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <DimensionIcon category={dimension.category} />
      <Typography className={classes.title} style={{ marginLeft: 8 }}>
        {_.startCase(dimension.category)}
      </Typography>
    </div>
  );

  const renderConditions = () => {
    switch (dimension.category) {
      case 'survey':
        return <SurveyDimensionConditions dimension={dimension} />;
      default:
        return (
          <DimensionConditions
            dimension={dimension}
            conditions={conditions}
            modeled={modeled}
          />
        );
    }
  };

  return (
    <>
      <Grid item className={classes.card}>
        <Paper className={classes.root}>
          {/* Dimension Header */}
          <Box
            className={classes.header && headerStyle(dimension.category)}
            p={2}
          >
            <Grid container alignItems="center" spacing={1}>
              <Grid item>
                <Title />
              </Grid>
              {/* <Grid item xs={2}>
                Menu
              </Grid> */}
            </Grid>
          </Box>

          {/* Dimension Conditions */}
          {renderConditions()}

          {/* Dimension Filters */}
          {hasFilters && (
            <Box style={{ marginLeft: 120 }}>
              <Grid container alignItems="center">
                <Box marginBottom={1}>
                  <Typography
                    color="textSecondary"
                    component="h6"
                    variant="subtitle1"
                  >
                    Refining the list to find people who have:
                  </Typography>
                </Box>
              </Grid>
              <Grid
                container
                alignItems="center"
                style={{
                  marginRight: 24,
                  borderLeft: '3px solid ' + tokens.colorSuccess,
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginBottom: 24,
                }}
              >
                <Grid
                  item
                  className={classes.divider}
                  style={{ marginRight: 24 }}
                >
                  <Grid
                    container
                    className={classes.row}
                    style={{
                      paddingLeft: 4,
                    }}
                  >
                    <DimensionTimeframe dimension={dimension} />
                    <DimensionCount dimension={dimension} />
                    <DimensionAmount dimension={dimension} />
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          )}

          {/* Dimension Footer */}
          <Box className={classes.footer}>
            <div>
              <Button
                style={{ marginRight: 16 }}
                variant="contained"
                onClick={handleEditDimension(dimension)}
              >
                Edit
              </Button>
              <Button
                variant="contained"
                onClick={handleDeleteDimension(dimension)}
              >
                Delete
              </Button>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <img src={AudienceIcon} alt="" style={{ marginRight: 8 }} />
              <div>
                <Typography
                  className={classes.audienceSize}
                  component="h4"
                  variant="h6"
                >
                  {getAudienceSize()}
                </Typography>
              </div>
            </div>
          </Box>
        </Paper>
      </Grid>

      <Grid container alignItems="center" justifyContent="center">
        <Grid item>
          <DimensionGroupConnector id={dimension.id} />
        </Grid>
      </Grid>
    </>
  );
};

const DimensionAmount = ({ dimension }: Props) => {
  const classes = useStyles();
  let amount: string;

  if (!dimension.amount || dimension.amount === '') {
    amount = 'Total Amount';
  } else if (dimension.amount === 'custom' && dimension.amountCustom) {
    amount = `$${dimension.amountCustom}`;
  } else {
    amount = _.lowerFirst(dimension.amount);
  }

  const styleAmount =
    !dimension.amount || dimension.amount === ''
      ? classes.filterNone
      : classes.filter;

  return (
    <div className={classes.row}>
      <Paper
        className={classes.condition}
        elevation={0}
        variant="outlined"
        style={{
          height: 28,
          margin: 4,
          border: '1px solid ' + tokens.colorPaperOutlinedBorder,
        }}
      >
        {!dimension.amountOperator || dimension.amountOperator === ''
          ? 'of any'
          : dimension.amountOperator.replace(/\w+/g, _.lowerCase)}
      </Paper>
      <Paper
        className={`${classes.condition} ${styleAmount}`}
        elevation={0}
        color="success.main"
        style={{ height: 28, margin: 4 }}
      >
        {amount}
      </Paper>
    </div>
  );
};

interface DimensionConditionProps {
  dimension: Dimension;
  conditions: DimensionCategoryCondition[];
  modeled: boolean;
}

const DimensionConditions = ({
  dimension,
  conditions,
  modeled,
}: DimensionConditionProps) => {
  const classes = useStyles();
  return (
    <>
      <Grid item style={{ marginLeft: 140 }}>
        <Box paddingTop={3} paddingBottom={1}>
          <Typography
            color="textSecondary"
            component="h6"
            variant="subtitle1"
            style={{ fontWeight: 300 }}
          >
            Looking for people with {_.lowerCase(dimension.category)}:
          </Typography>
        </Box>
      </Grid>
      <Box className={classes.box}>
        <Grid
          item
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <FormControl
            color="primary"
            variant="outlined"
            className={classes.operatorControl}
            size="small"
          >
            <Select
              labelId="operator-label"
              id="operator-select"
              value={dimension.operator}
              disabled={true}
              style={{ minWidth: 75 }}
            >
              <MenuItem value={dimension.operator}>
                {_.startCase(dimension.operator)}
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid
          container
          className={classes.col}
          style={{
            marginRight: 24,
            borderLeft: '3px solid ' + tokens.colorSuccess,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          {conditions.map((condition) => (
            <Grid
              container
              key={condition.id}
              className={classes.divider}
              style={{ marginTop: 4, marginBottom: 4 }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <Typography
                  className={classes.condition}
                  component="h5"
                  variant="h5"
                >
                  {_.startCase(ALTERNATIVE_NAMES_MAP[condition.option]) ||
                    _.startCase(condition.option)}
                </Typography>
                <Paper
                  className={classes.condition}
                  style={{
                    height: '28px',
                    marginLeft: 8,
                    marginRight: 4,
                    border: '1px solid ' + tokens.colorPaperOutlinedBorder,
                  }}
                  elevation={0}
                  variant="outlined"
                >
                  <Typography
                    component="h5"
                    className={classes.condition}
                    style={{ padding: 0 }}
                  >
                    {condition.operator && _.startCase(condition.operator)
                      ? _.startCase(condition.operator)
                      : condition.operator}
                  </Typography>
                </Paper>
                <Grid item>
                  <Grid container style={{ marginLeft: 5, marginRight: 5 }}>
                    {condition.qualifiers &&
                    typeof condition.qualifiers === 'string' ? (
                      <Paper
                        className={`${classes.condition} ${classes.conditionQualifiers}`}
                        elevation={0}
                        style={{ height: '28px' }}
                        variant="outlined"
                      >
                        {condition.qualifiers.replace(/\w+/g, _.upperCase)}
                      </Paper>
                    ) : (
                      condition.qualifiers?.map((qualifier, i) => {
                        return (
                          <>
                            {i !== 0 && (
                              <Paper
                                style={{
                                  height: '28px',
                                  marginLeft: 5,
                                  marginRight: 5,
                                }}
                                className={`${classes.conditionSeprator}`}
                                elevation={0}
                                variant="outlined"
                              >
                                |
                              </Paper>
                            )}
                            <Paper
                              style={{
                                height: '28px',
                                marginLeft: 5,
                                marginRight: 5,
                              }}
                              className={`${classes.condition} ${classes.conditionQualifiers}`}
                              elevation={0}
                              variant="outlined"
                            >
                              {qualifier.replace(/\w+/g, _.upperCase)}
                            </Paper>
                          </>
                        );
                      })
                    )}
                  </Grid>
                </Grid>
              </div>
              <div>
                <Paper
                  className={`${classes.condition} ${classes.conditionAudience}`}
                  elevation={0}
                  style={{ height: '28px' }}
                >
                  <Grid container spacing={1}>
                    <Grid item>
                      <img src={UserIcon} alt="" />
                    </Grid>
                    <Grid item>
                      <Typography
                        component="h5"
                        variant="subtitle1"
                        className={classes.condition}
                        style={{ padding: 0 }}
                      >
                        {numberFormatter(condition.audienceSize, 1)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </div>
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
};

interface SurveyDimensionConditionsProps {
  dimension: Dimension;
}

const SurveyDimensionConditions = ({
  dimension,
}: SurveyDimensionConditionsProps) => {
  const classes = useStyles();

  return (
    <>
      <Grid
        container
        direction="row"
        wrap="nowrap"
        justifyContent="flex-start"
        className={classes.gridFill}
      >
        <Grid item className={classes.questionSelectionContainer}>
          <Box paddingTop={3} paddingBottom={1}>
            <Typography
              color="textSecondary"
              component="h6"
              variant="subtitle1"
              className={classes.typographyWeighted}
            >
              Looking for people with {_.lowerCase(dimension.category)}:
            </Typography>
          </Box>
          <QuestionSelection dimension={dimension} />
        </Grid>
        <Box className={(classes.box, classes.respondentsContainer)}>
          <Grid item direction="column" className={classes.gridFill}>
            <Box paddingTop={4} paddingBottom={1} paddingLeft={2}>
              <Grid item>
                <AnswerSelections dimension={dimension} />
              </Grid>
            </Box>
          </Grid>
        </Box>
      </Grid>
    </>
  );
};

interface QuestionSelectionProps {
  dimension: Dimension;
}

const QuestionSelection = ({ dimension }: QuestionSelectionProps) => {
  const classes = useStyles();

  const questions = useAppSelector((state) => state.audienceReducer.surveys);
  const conditions = useAppSelector(
    (state) => state.audienceReducer.currentConditions
  );
  let currentSurvey;
  if (
    dimension.conditions.length &&
    conditions.allIds.length &&
    conditions.byId[dimension.conditions[0]] &&
    conditions.byId[dimension.conditions[0]].option === 'question'
  ) {
    currentSurvey =
      questions.byId[conditions.byId[dimension.conditions[0]].qualifiers];
  }

  return (
    <Grid
      container
      direction="column"
      spacing={1}
      style={{ maxWidth: 'fit-content' }}
    >
      <Grid item>Question</Grid>
      <Grid item>
        <Paper
          className={`${classes.condition} ${classes.conditionQualifiers} ${classes.surveyText}`}
          elevation={0}
          variant="outlined"
        >
          {currentSurvey?.questionText || ''}
        </Paper>
      </Grid>
    </Grid>
  );
};

interface AnswerSelectionsProps {
  dimension: Dimension;
}

const AnswerSelections = ({ dimension }: AnswerSelectionsProps) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const questions = useAppSelector((state) => state.audienceReducer.surveys);
  const conditions = useAppSelector(
    (state) => state.audienceReducer.currentConditions
  );
  const modeled = useAppSelector((state) => state.audienceReducer.ui.modeled);
  const currentSurveyResponses = useAppSelector(
    (state) => state.audienceReducer.currentSurveyResponses
  );
  let questionid;
  let currentResponses;
  //set of selected answers for this question
  const selectedAnswers = new Set();
  //all answers for this question
  let allAnswers;

  const formatResponse = (response: any, totalCount: number) => {
    const returnObj: currentCounts = {};
    Object.keys(response).forEach((key) => {
      returnObj[key] = Math.floor((Number(response[key]) * totalCount) / 100);
    });
    return returnObj;
  };

  useEffect(() => {
    if (
      dimension.conditions.length &&
      conditions.allIds.length &&
      conditions.byId[dimension.conditions[0]] &&
      conditions.byId[dimension.conditions[0]].option === 'question'
    ) {
      axios
        .get(
          `${API_BASE_ADDRESS}/questions/${
            conditions.byId[dimension.conditions[0]].qualifiers
          }`
        )
        .then((res) => {
          const responsePayload = res.data.responses;
          const { responses, totalCount } = responsePayload;
          const formattedResponse: currentCounts = formatResponse(
            responses,
            totalCount
          );
          const payload: any = {};
          payload.questionid =
            conditions.byId[dimension.conditions[0]].qualifiers;
          payload.responses = formattedResponse;
          dispatch(updateSurveyMap(payload));
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [conditions]);

  if (
    dimension.conditions.length &&
    conditions.allIds.length &&
    conditions.byId[dimension.conditions[0]] &&
    conditions.byId[dimension.conditions[0]].option === 'question'
  ) {
    questionid = conditions.byId[dimension.conditions[0]].qualifiers;
    if (typeof questionid === 'string') {
      allAnswers = questions.byId[questionid].answers;
      if (currentSurveyResponses) {
        currentResponses = currentSurveyResponses[questionid];
      }
    }
  }

  if (conditions.allIds.length && dimension.conditions.length > 1) {
    for (let i = 1; i < dimension.conditions.length; i++) {
      if (conditions.byId[dimension.conditions[i]])
        selectedAnswers.add(
          conditions.byId[dimension.conditions[i]].qualifiers
        );
    }
  }

  return (
    <Grid container>
      <Grid item className={classes.listSubHeader}>
        Answer
      </Grid>
      <List className={classes.list}>
        {allAnswers &&
          allAnswers.map((currentAnswer: any) => {
            if (!selectedAnswers.has(currentAnswer.id)) {
              return null;
            }
            return (
              <ListItem key={currentAnswer.id}>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  spacing={4}
                  justifyContent="space-between"
                  wrap="nowrap"
                >
                  <Grid item>{currentAnswer.answerText}</Grid>
                  <Grid item>
                    <Grid
                      container
                      direction="row"
                      spacing={2}
                      alignItems="center"
                      wrap="nowrap"
                    >
                      <Grid>
                        <AudienceCountDisplay
                          audienceSize={
                            currentResponses &&
                            currentResponses[currentAnswer.id]
                              ? currentResponses[currentAnswer.id]
                              : 0
                          }
                          modeled={modeled}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </ListItem>
            );
          })}
      </List>
    </Grid>
  );
};

const DimensionCount = ({ dimension }: Props) => {
  const classes = useStyles();
  let count: string;

  if (!dimension.count || dimension.count === '') {
    count = '1 transaction';
  } else if (dimension.count === 'custom' && dimension.countCustom) {
    count = `${dimension.countCustom} transactions`;
  } else {
    count = `${_.lowerFirst(dimension.count)} transactions`;
  }

  const styleCount =
    !dimension.count || dimension.count === ''
      ? classes.filterNone
      : classes.filter;

  return (
    <div className={classes.row} style={{ marginRight: 40 }}>
      <Paper
        className={classes.condition}
        elevation={0}
        variant="outlined"
        style={{
          height: 28,
          margin: 4,
          border: '1px solid ' + tokens.colorPaperOutlinedBorder,
        }}
      >
        {!dimension.countOperator || dimension.countOperator === ''
          ? 'at least'
          : dimension.countOperator.replace(/\w+/g, _.lowerCase)}
      </Paper>
      <Paper
        className={`${classes.condition} ${styleCount}`}
        elevation={0}
        color="success.main"
        style={{ height: 28, margin: 4 }}
      >
        {count}
      </Paper>
    </div>
  );
};

const DimensionTimeframe = ({ dimension }: Props) => {
  const classes = useStyles();

  let styleTimeline = classes.filterNone;
  let timeframe: string;

  if (
    dimension.timeframeOperator === 'custom' &&
    dimension.timeframeStart &&
    dimension.timeframeEnd
  ) {
    styleTimeline = classes.filter;

    const startDate = new Date(dimension.timeframeStart);
    const endDate = new Date(dimension.timeframeEnd);
    timeframe = `${startDate.toLocaleDateString(
      'en-US'
    )} - ${endDate.toLocaleDateString('en-US')}`;
  } else if (!dimension.timeframe || dimension.timeframe === '') {
    styleTimeline = classes.filterNone;
    timeframe = 'Time';
  } else {
    styleTimeline = classes.filter;
    timeframe = _.lowerFirst(dimension.timeframe);
  }

  return (
    <div className={classes.row} style={{ marginRight: 40 }}>
      <Paper
        className={classes.condition}
        elevation={0}
        variant="outlined"
        style={{
          height: 28,
          margin: 4,
          border: '1px solid ' + tokens.colorPaperOutlinedBorder,
        }}
      >
        {!dimension.timeframeOperator || dimension.timeframeOperator === ''
          ? 'at any'
          : dimension.timeframeOperator.replace(/\w+/g, _.lowerCase)}
      </Paper>
      <Paper
        className={`${classes.condition} ${styleTimeline}`}
        elevation={0}
        color="success.main"
        style={{ height: 28, margin: 4 }}
      >
        {timeframe}
      </Paper>
    </div>
  );
};

export default DimensionContainer;
